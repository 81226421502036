import React, { useState } from 'react';

import Layout from '@/components/Layout';
import AboutHero from '@/modules/About/components/AboutHero';
import AboutMission from '@/modules/About/components/AboutMission';
import AboutCompany from '@/modules/About/components/AboutCompany';
import AboutTeam from '@/modules/About/components/AboutTeam';
import AboutLicenses from '@/modules/About/components/AboutLicenses';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';

function About() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="white" onPreloaderOut={handlePreloaderOut}>
      <AboutHero animate={isPreloaderOut} />
      <AboutMission />
      <AboutCompany />
      <AboutTeam />
      <AboutLicenses />
    </Layout>
  );
}

export default About;
