import React from 'react';
import styled from 'styled-components';

import * as S from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.styles';
import ArrowLinkWithLabel from '@/components/ArrowButtonLink';
import { colors } from '@/constants/theme';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import StatisticsItems from '@/modules/Stacks/components/StacksServices/components/StatisticsItems';
import ContentSmallContainer from '@/components/ContentSmallContainer';

interface Props {}

function StacksServices(props: Props) {
  const { containerRef, titleRef, textRef } = useSimplicityAnimation();

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <S.Inner>
          <S.Left>
            <Title ref={titleRef}>
              Stacks works faster than other services
            </Title>
          </S.Left>
        </S.Inner>
        <Text ref={textRef}>
          <p>Compare the time from login to money transfer:</p>
        </Text>
        <StatisticsItems />
        <Text className="bottom-text" ref={textRef}>
          <p>
            {
              'Yeah, a second saved is a penny earned. Just multiply your hourly rate by 10.\nTaking care of your time pays off, doesn’t it?'
            }
          </p>
        </Text>
        <ButtonWrapper>
          <ArrowLinkWithLabel
            label="our values"
            to="/culture"
            color={colors.stacksPrimary}
            hoverColor={colors.stacksPrimaryHover}
          />
        </ButtonWrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Title = styled(S.Title)`
  max-width: 950px;
  font-size: 102px;
`;

const Text = styled.div`
  font-weight: 500;
  margin-top: 80px;
  font-size: 24px;
  line-height: 145%;
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  margin-top: 80px;
`;

const Component = styled(S.Component)`
  padding-bottom: 250px !important;

  ${S.Inner} {
    margin: 0;
  }

  @media (max-width: 1260px) {
    padding-bottom: 140px !important;

    ${Title} {
      line-height: 92px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px !important;

    ${Title} {
      font-size: 72px;
      line-height: 84px;
    }

    ${Text} {
      margin-top: 10px;

      &.bottom-text {
        white-space: normal;
        margin-top: 49px;
      }
    }
  }

  @media (max-width: 414px) {
    ${ButtonWrapper} {
      margin-top: 30px;

      button {
        width: 68px;
        height: 68px;

        svg {
          width: 31px !important;
          height: 43px !important;
        }
      }
    }

    ${Title} {
      font-size: 50px;
      line-height: 54px;
    }

    ${Text} {
      font-weight: 500;
      font-size: 18px;
    }
  }
`;

export default StacksServices;
