import { Nullish } from '@tager/web-core';

import { PageModuleType, PagesTemplates } from '@/typings/common';
import DefaultTemplate from '@/modules/DefaultTemplate';
import Home from '@/modules/Home';
import Accounts from '@/modules/Accounts';
import Acquiring from '@/modules/Acquiring';
import Exchanges from '@/modules/Exchanges';
import Overview from '@/modules/Overview';
import Industry from '@/modules/Industry';
import Culture from '@/modules/Culture';
import About from '@/modules/About';
import PressCenter from '@/modules/PressCenter';
import Form from '@/modules/Form';
import Contacts from '@/modules/Contacts';
import Corebanking from '@/modules/Corebanking';
import Careers from '@/modules/Careers';
import Links from '@/modules/Links';
import CookiePolicy from '@/modules/CookiePolicy';
import { getVacanciesListThunk } from '@/store/reducers/tager/vacancies';
import PartnershipProgram from '@/modules/PartnershipProgram';
import Stacks from '@/modules/Stacks';
import ReleaseNotes from '@/modules/ReleaseNotes';
import Home2023 from '@/modules/Home2023';
import Singapore from '@/modules/Singapore';
import Prices from '@/modules/Prices';
import CollectPayments from '@/modules/CollectPayments';
import MakePayments from '@/modules/MakePayments';
import ReallyInstant from '@/modules/ReallyInstant';
import SingaporePricing from '@/modules/SingaporePricing';
import Pricing from '@/modules/Pricing';
import Landing from '@/modules/Landing';
import Thanks from '@/modules/Thanks';

const DEFAULT_PAGE_MODULE: PageModuleType = {
  component: DefaultTemplate,
  template: '',
};

const PAGE_MODULE_LIST: Array<PageModuleType> = [
  {
    template: 'home',
    component: Home,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'landing',
    component: Landing,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'home-2023',
    component: Home2023,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'collect-payments',
    component: CollectPayments,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'really-instant',
    component: ReallyInstant,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'make-payments',
    component: MakePayments,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'singapore',
    component: Singapore,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'prices',
    component: Prices,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'corebanking',
    component: Corebanking,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'accounts',
    component: Accounts,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'acquiring',
    component: Acquiring,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'overview',
    component: Overview,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'industry',
    component: Industry,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'exchanges',
    component: Exchanges,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'culture',
    component: Culture,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'about',
    component: About,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'contacts',
    component: Contacts,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'press-center',
    component: PressCenter,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'form',
    component: Form,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'form-conference',
    component: Form,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'form-links',
    component: Form,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'singapore-pricing',
    component: SingaporePricing,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'pricing',
    component: Pricing,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'careers',
    component: Careers,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacanciesListThunk())]);
    },
  },
  {
    template: 'careers',
    component: Careers,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacanciesListThunk())]);
    },
  },
  {
    template: 'careers-in',
    component: Careers,
    getInitialProps({ store }) {
      return Promise.all([store.dispatch(getVacanciesListThunk())]);
    },
  },
  {
    template: PagesTemplates.Links,
    component: Links,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: PagesTemplates.XPP,
    component: PartnershipProgram,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'stacks',
    component: Stacks,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'release-notes',
    component: ReleaseNotes,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'cookie-policy',
    component: CookiePolicy,
    getInitialProps() {
      return Promise.all([]);
    },
  },
  {
    template: 'thanks',
    component: Thanks,
    getInitialProps() {
      return Promise.all([]);
    },
  },
];

export function getPageModuleByTemplate(
  template: Nullish<string>
): PageModuleType {
  return (
    PAGE_MODULE_LIST.find((pageModule) => pageModule.template === template) ??
    DEFAULT_PAGE_MODULE
  );
}
