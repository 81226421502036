import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import * as S from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.styles';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import { media } from '@/utils/mixin';
import InfinitySlider, {
  Container as SliderContainer,
} from '@/modules/Home/components/HomeSimplicity/components/InfinitySlider';
import SimplicityItem, {
  Component as SimplicityItemComponent,
  Icon as SimplicityItemIcon,
} from '@/modules/Home/components/HomeSimplicity/components/SimplicityItem';
import { colors } from '@/constants/theme';
import ArrowLinkWithLabel from '@/components/ArrowButtonLink';

const SLIDE_LIST = [
  {
    num: '01',
    icon: '/stacks/icons/sent.html',
    title: 'Send',
  },
  {
    num: '02',
    icon: '/stacks/icons/top20up.html',
    title: 'Top-up',
  },
  {
    num: '03',
    icon: '/stacks/icons/recive.html',
    title: 'Receive',
  },
  {
    num: '04',
    icon: '/stacks/icons/payout.html',
    title: 'Payout',
  },
  {
    num: '05',
    icon: '/stacks/icons/split20bill.html',
    title: 'Exchange',
  },
];

function StacksSimplicity() {
  const { containerRef, titleRef, textRef, setSlideElem } =
    useSimplicityAnimation({ disableAnimationBgElements: true });

  const isMobile = useMedia(`(max-width: 768px)`);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <StyledInner>
          <S.Left>
            <Title ref={titleRef}>One gesture for everything</Title>
          </S.Left>
          <StyledRight>
            <StyledText ref={textRef}>
              Сonduct any financial transaction with a simple action. Just drag
              and drop a stack with your index finger.
            </StyledText>
          </StyledRight>
        </StyledInner>
        {isMobile ? (
          <List>
            {SLIDE_LIST.map((item, index) => (
              <SimplicityItem
                className="simplicity"
                id={`${index}`}
                num={item.num}
                color={'#F6F6F6'}
                icon={item.icon}
                title={item.title}
              />
            ))}
          </List>
        ) : (
          <InfinitySlider
            carouselDuration={30}
            slides={SLIDE_LIST}
            setSlideElem={setSlideElem}
          />
        )}

        {isMobile && (
          <WrapperButton>
            <ArrowLinkWithLabel
              to="mailto:hello@xpate.com"
              label="get in line"
              color={colors.stacksPrimary}
              hoverColor={colors.stacksPrimaryHover}
            />
          </WrapperButton>
        )}
      </ContentSmallContainer>
    </Component>
  );
}

const WrapperButton = styled.div`
  margin-top: 80px;
`;

const Title = styled(S.Title)`
  max-width: 700px;
  font-size: 102px;

  @media (max-width: 416px) {
    max-width: 300px !important;
  }
`;

const StyledInner = styled(S.Inner)`
  margin-bottom: -44px !important;
`;

const StyledRight = styled(S.Right)`
  margin-right: 28px;
  font-size: 24px;
  padding-top: 72px;
  max-width: 471px;
`;

const StyledText = styled(S.Text)`
  font-size: 24px;
  line-height: 145%;
`;

const List = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin: 0 -20px;

  ${SimplicityItemComponent} {
    width: 100% !important;

    .simplicity__inner {
      padding: 60px 60px 60px;
    }

    .simplicity__number {
      right: 50px;
      bottom: 40px;
    }

    .simplicity__title {
      margin-top: 80px;
    }

    height: 374px !important;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const Component = styled(S.Component)`
  padding: 200px 0;

  iframe {
    width: 160px;
    height: 190px;

    svg {
      width: 160px;
      height: 190px;
    }
  }

  .simplicity {
    width: 614px;
    height: 564px;

    &__inner {
      padding-top: 70px;
    }
  }

  ${SimplicityItemComponent} {
    color: black !important;

    ${SimplicityItemIcon} {
      transform: scale(1.5);
      margin-left: -70px;
    }
  }

  ${media.laptop(css`
    ${Title} {
      margin-left: 0;
      margin-top: 30px;
      font-size: 74px;
      line-height: 66px;
    }

    ${SliderContainer} {
      margin-top: 120px !important;
    }
  `)};

  @media (max-width: 1260px) {
    ${StyledRight} {
      padding-top: 40px;
    }

    ${SliderContainer} {
      margin-top: 60px !important;
    }
  }

  ${media.tabletLarge(css`
    ${Title} {
      margin-bottom: 40px;
      font-size: 64px;
      line-height: 56px;
    }

    ${S.Right} {
      margin-bottom: 50px;
    }
  `)};

  @media (max-width: 1024px) {
    padding-top: 100px;
    padding-bottom: 100px;

    ${StyledInner} {
      flex-direction: column;
    }

    ${Title} {
      font-size: 102px;
      line-height: 110px;
    }

    ${S.Right} {
      margin: 0 0 40px 0;
      padding-top: 0;
    }
  }

  @media (max-width: 768px) {
    ${SliderContainer} {
      margin-top: 60px !important;
    }

    ${StyledText} {
      margin-bottom: 40px;
    }

    ${WrapperButton} {
      margin-top: 30px;
    }

    ${Title} {
      font-size: 54px;
      line-height: 100%;

      .lineParent {
        line-height: 104%;
      }
    }

    ${S.Right} {
      padding-top: 30px;
    }

    ${List} {
      .simplicity {
        &__title {
          font-weight: bold;
          font-size: 26px;
          line-height: 100%;
        }
      }
    }
  }

  @media (max-width: 416px) {
    ${Title} {
      margin-bottom: 30px;
      font-size: 54px;
      line-height: 46px;
    }

    padding: 74px 0;

    ${StyledText} {
      margin-bottom: 0;
    }

    ${S.Right} {
      padding-top: 33px;
      margin-bottom: 100px;
      margin-right: 0;
    }

    ${StyledText} {
      max-width: 100%;
      font-size: 18px;
      line-height: 145%;
    }

    ${WrapperButton} {
      button {
        width: 68px !important;
        height: 68px !important;
      }
    }
  }
`;

export default StacksSimplicity;
