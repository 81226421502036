import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import { Nullish } from '@tager/web-core';

import { media } from '@/utils/mixin';

type Props = {
  firstTitle: string;
  secondTitle: string;
  className?: string;
  styles?: {
    firstTitle: {
      fontSize: Nullish<string>;
    };
    secondTitle: {
      fontSize: Nullish<string>;
    };
  };
};

function ContentDoubleTitle({
  firstTitle,
  secondTitle,
  styles,
  className,
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const firstTitleRef = useRef<HTMLSpanElement>(null);
  const secondTitleRef = useRef<HTMLSpanElement>(null);

  function getTitleOffset(
    titleRef: React.RefObject<HTMLSpanElement>,
    direction: 'left' | 'right'
  ) {
    const titleElem = titleRef.current;
    const containerElem = containerRef.current;
    if (!titleElem || !containerElem) return 0;

    const result = containerElem.clientWidth - titleElem.offsetWidth;

    if (direction === 'left') {
      return Math.sign(result) === -1 ? result : -result;
    } else {
      return Math.sign(result) === -1 ? -result : result;
    }
  }

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      const rightValue = getTitleOffset(secondTitleRef, 'right');
      const leftValue = getTitleOffset(secondTitleRef, 'left');
      let x = Math.max(leftValue, rightValue);

      if (x < 80) {
        x = 100;
      }

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          invalidateOnRefresh: true,
          trigger: containerRef.current,
          scrub: true,
        },
      });

      tl.to(
        firstTitleRef.current,
        {
          x: () => (Math.sign(x) === -1 ? -x : x),
          ease: 'linear',
        },
        0
      ).to(
        secondTitleRef.current,
        {
          x: () => (Math.sign(x) === -1 ? x : -x),
          ease: 'linear',
        },
        0
      );
    });

    return () => {
      delayCall.kill();
      tl?.kill();
    };
  }, []);

  return (
    <div ref={containerRef} className={className}>
      <FirstRow>
        <FirstTitle
          id="first-row-title"
          fontSize={styles?.firstTitle.fontSize}
          dangerouslySetInnerHTML={{ __html: firstTitle }}
          ref={firstTitleRef}
        />
      </FirstRow>
      <SecondRow>
        <SecondTitle
          id="second-row-title"
          fontSize={styles?.secondTitle.fontSize}
          dangerouslySetInnerHTML={{ __html: secondTitle }}
          ref={secondTitleRef}
        />
      </SecondRow>
    </div>
  );
}

const FirstRow = styled.div`
  height: 325px;
  position: relative;
  white-space: nowrap;

  ${media.laptop(css`
    height: 200px;
  `)}

  ${media.tabletLarge(css`
    height: 160x;
  `)}
  ${media.tabletSmall(css`
    height: 110px;
  `)}
  ${media.mobile(css`
    height: 60px;
  `)}
  ${media.mobileSmall(css`
    height: 50px;
  `)}


  > span {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const SecondRow = styled.div`
  text-align: right;
`;

const SecondTitle = styled.span<{ fontSize: Nullish<string> }>`
  position: relative;
  display: inline-block;
  padding: 0 10px;
  margin: 10px -25px 0;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '382px')};
  line-height: 277px;
  letter-spacing: -0.01em;
  font-weight: bold;
  white-space: nowrap;

  ${media.laptop(css`
    margin: 0;
    font-size: 240px;
    line-height: 80%;
  `)}

  ${media.tabletLarge(css`
    font-size: 200px;
  `)}

  ${media.tabletSmall(css`
    font-size: 160px;
  `)}

  ${media.mobile(css`
    font-size: 70px;
  `)}

  ${media.mobileSmall(css`
    font-size: 60px;
  `)}
`;

const FirstTitle = styled.span<{ fontSize: Nullish<string> }>`
  position: relative;
  display: inline-block;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '282px')};
  letter-spacing: -0.01em;
  font-weight: bold;

  ${media.laptop(css`
    font-size: 200px;
    line-height: 100%;
  `)}

  ${media.tabletLarge(css`
    font-size: 160px;
  `)}

  ${media.tabletSmall(css`
    font-size: 110px;
  `)}

  ${media.mobile(css`
    font-size: 60px;
  `)}

  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

export default ContentDoubleTitle;
