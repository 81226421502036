import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { createMediaMixin } from '@tager/web-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as CurrencyIcon } from '@/assets/svg/currency-ico.svg';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-3.svg';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function ExchangesIntro() {
  const bgRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subtitleRef = useRef<HTMLSpanElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '25%',
        },
        0
      ).to(
        innerRef.current,
        {
          ease: 'linear',
          y: -100,
        },
        0
      );
    });

    return () => {
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitle = new SplitText(titleRef.current, {
      type: 'chars, words',
    });
    const splitSubtitle = new SplitText(subtitleRef.current, {
      type: 'chars, words',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
        },
      });

      tl.from(
        splitTitle.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
          delay: 0.2,
        },
        0
      ).from(
        splitSubtitle.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
        },
        0.5
      );
    });

    return () => {
      splitTitle?.revert();
      splitSubtitle?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner ref={innerRef}>
          <TextBackground ref={bgRef} />
          <TextBlock>
            <Title ref={titleRef}>Muuuultii</Title>
            <Text ref={subtitleRef}>currency support</Text>
          </TextBlock>
          <Circle>
            <TextIcon>
              <CurrencyIcon />
            </TextIcon>
            <CircleArrow>
              <ArrowIcon />
            </CircleArrow>
            We cater for an ever-growing number of currencies, all at the best
            exchange rates for you.
            <br />
            Our mission? To make currency trading inexpensive, simple and, above
            all else, fast.
          </Circle>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 100px;
  overflow: hidden;

  ${media.mobile(css`
    padding-top: 50px;
  `)}
`;

const Inner = styled.div`
  position: relative;
  padding-bottom: 290px;

  ${media.laptop(css`
    padding-bottom: 270px;
  `)}

  ${media.tabletLarge(css`
    padding-bottom: 160px;
  `)}

  ${media.tabletSmall(css`
    padding-bottom: 60px;
  `)}
`;

const TextBackground = styled.div`
  position: absolute;
  background: ${colors.grayLight};
  left: 0;
  right: 0;
  top: 100px;
  bottom: 220px;
  z-index: 1;

  ${media.tabletLarge(css`
    top: 57px;
  `)}

  ${media.mobile(css`
    top: 37px;
    margin: 0 -20px;
  `)}
`;

const TextBlock = styled.div`
  position: relative;
  padding-left: 135px;
  padding-bottom: 310px;
  z-index: 3;

  ${media.tabletLarge(css`
    padding-left: 125px;
  `)}

  ${media.tabletSmall(css`
    padding-left: 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  font-weight: 700;
  font-size: 266px;
  line-height: 301px;
  letter-spacing: 0.034em;
  white-space: nowrap;

  ${createMediaMixin({ min: 1400, max: 1575 })(css`
    font-size: 158px;
    line-height: 171px;
  `)}

  ${media.laptop(css`
    font-size: 128px;
    line-height: 141px;
  `)}

  ${media.tabletLarge(css`
    font-size: 118px;
    line-height: 131px;
  `)}

  ${media.tabletSmall(css`
    font-size: 88px;
    line-height: 91px;
  `)}

  ${media.mobile(css`
    font-size: 78px;
    line-height: 81px;
  `)}

  ${media.mobileMedium(css`
    font-size: 58px;
    line-height: 61px;
  `)}
`;

const Text = styled.span`
  font-size: 228px;
  line-height: 100%;
  font-weight: 600;
  margin-top: -15px;
  display: block;
  z-index: 2;
  position: relative;
  width: auto;
  letter-spacing: 0;

  ${createMediaMixin({ min: 1400, max: 1575 })(css`
    font-size: 98px;
    line-height: 114px;
  `)}

  ${media.laptop(css`
    max-width: 730px;
    margin-top: 10px;
    font-size: 88px;
  `)}

  ${media.tabletLarge(css`
    max-width: 330px;
    font-size: 78px;
  `)}

  ${media.tabletSmall(css`
    font-size: 58px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Circle = styled.div`
  position: absolute;
  right: 0;
  bottom: 50px;
  display: flex;
  align-items: center;
  width: 737px;
  height: 737px;
  padding: 50px 80px 50px 100px;
  font-size: 37px;
  line-height: 100%;
  font-weight: bold;
  color: ${colors.black};
  letter-spacing: -0.01em;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${colors.exchangesPrimary};
    z-index: -1;
  }

  ${createMediaMixin({ max: 1575 })(css`
    right: 100px;
    width: 567px;
    height: 567px;
    font-size: 29px;
  `)}

  ${media.tabletLarge(css`
    right: 50px;
    width: 457px;
    height: 457px;
    padding: 80px;
    font-size: 23px;
  `)}

  ${media.tabletSmall(css`
    right: 80px;
    width: 357px;
    height: 357px;
    padding: 60px;
    font-size: 19px;
  `)}

  ${media.mobile(css`
    right: 0;
    width: 280px;
    height: 400px;
    padding-right: 0;
    line-height: 20px;

    &:before {
      width: calc(100% + 120px);
    }
  `)}
`;

const CircleArrow = styled.div`
  position: absolute;
  left: -330px;
  top: 50%;
  transform: translateY(-50%);
  width: 177px;
  height: 147px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    left: -250px;
    width: 157px;
    height: 127px;
  `)}

  ${media.tabletLarge(css`
    left: -200px;
    width: 127px;
    height: 97px;
  `)}

  ${media.tabletSmall(css`
    left: -150px;
    width: 107px;
    height: 77px;
  `)}

  ${media.mobile(css`
    top: 45px;
    left: auto;
    right: 20px;
    transform: rotate(90deg);
    width: 46px;
    height: 46px;
  `)}
`;

const TextIcon = styled.div`
  position: absolute;
  left: 50%;
  top: -50px;
  transform: translateX(-50%) translateY(-100%);
  width: 190px;
  height: 180px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    width: 120px;
    height: 110px;
  `)}

  ${media.tabletLarge(css`
    top: -30px;
    width: 120px;
    height: 110px;
  `)}

  ${media.tabletSmall(css`
    top: -25px;
    width: 90px;
    height: 80px;
  `)}

  ${media.mobileLarge(css`
    display: none;
  `)}
`;

export default ExchangesIntro;
