import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { max } from 'lodash';

import { nl2br } from '@tager/web-core';

import { media } from '@/utils/mixin';
import SectionTitle from '@/components/SectionTitle';
import { StringFieldType } from '@/typings/common';

type Props = {
  title: StringFieldType;
  text?: StringFieldType;
  maxWidth?: number | null;
  maxTextWidth?: number | null;
};

const SectionHeader: React.FC<Props> = ({
  title,
  text,
  maxTextWidth,
  maxWidth,
}) => (
  <Component>
    {title ? <SectionTitle title={title} maxWidth={maxWidth} /> : null}
    {text ? (
      <Text maxWidth={maxTextWidth ? maxTextWidth + 'px' : '100%'}>
        {nl2br(text)}
      </Text>
    ) : null}
  </Component>
);

export const Component = styled.header``;

const Text = styled.p<{ maxWidth: string }>`
  display: block;
  margin-top: 30px;
  max-width: ${(props) => props.maxWidth};
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;

  ${media.tablet(css`
    font-size: 24px;
    margin-top: 25px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    margin-top: 20px;
  `)}
`;

export default SectionHeader;
