import React from 'react';

import Layout from '@/components/Layout';

import MakePaymentsHero from './components/MakePaymentsHero';
import MakePaymentsIntro from './components/MakePaymentsIntro';
import MakePaymentsBenefits from './components/MakePaymentsBenefits';
import MakePaymentsFeatures from './components/MakePaymentsFeatures';
import MakePaymentsCallToAction from './components/MakePaymentsCallToAction';

function MakePayments() {
  return (
    <Layout theme="black" dark={true}>
      <MakePaymentsHero />
      <MakePaymentsIntro />
      <MakePaymentsBenefits />
      <MakePaymentsFeatures />
      <MakePaymentsCallToAction />
    </Layout>
  );
}

export default MakePayments;
