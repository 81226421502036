import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';
import { media } from '@/utils/mixin';

type Props = {
  animate?: boolean;
};

function CultureHero({ animate }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.set(titleRef.current, { yPercent: 100 });
    gsap.set(arrowRef.current, { scale: 0 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(
          titleRef.current,
          {
            ease: 'customEaseInOut',
            duration: 2.2,
            yPercent: 0,
          },
          0
        )
        .to(
          arrowRef.current,
          {
            duration: 1.2,
            delay: 0.65,
            scale: 1,
          },
          0
        );
    });

    return () => {
      tl?.kill();
    };
  }, [animate]);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Inner>
          <TitleWrapper>
            <Title ref={titleRef}>Culture</Title>
            <BackgroundTop />
          </TitleWrapper>
          <CircleArrow ref={arrowRef}>
            <HeroCircleArrowButton />
          </CircleArrow>
        </Inner>
      </ContentSmallContainer>
      <Background />
    </Component>
  );
}

const Component = styled.section`
  position: relative;
  overflow: hidden;
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 984px;
  max-height: 100vh;

  ${media.tabletSmall(css`
    height: calc(var(--vh, 1vh) * 100);
  `)}
`;

const TitleWrapper = styled.div`
  overflow: hidden;
  z-index: 1;

  ${media.mobile(css`
    margin-bottom: -15px;
  `)}
`;

const Title = styled.h1`
  padding: 0.2em 0;
  margin: 0 0 0 20px;
  font-size: 362px;
  line-height: 207px;
  letter-spacing: -0em;

  ${media.laptop(css`
    margin-left: 0;
    font-size: 240px;
    line-height: 1;
  `)}

  ${media.tabletSmall(css`
    font-size: 200px;
  `)}

  ${media.mobile(css`
    width: 100%;
    font-size: 80px;
    text-align: center;
  `)}

  ${media.mobileMedium(css`
    transform: translateY(-10px);
  `)}
`;

const CircleArrow = styled.div`
  position: absolute;
  right: 0;
  bottom: 80px;
  color: ${colors.acquiringPrimary};
  z-index: 2;
`;

const Background = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 490px;
  height: 50vh;
  background: ${colors.acquiringPrimary};
  z-index: 0;

  ${media.tabletSmall(css`
    max-height: 50vh;
    max-height: calc(var(--vh, 1vh) * 50);
  `)}
`;

const BackgroundTop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50vh;
  max-height: 490px;
  font-size: 2px;
  z-index: 1;

  background-image: repeating-linear-gradient(
    to bottom,
    ${colors.acquiringPrimary} 0 1em,
    rgba(255, 255, 255, 0) 1em 2.5em
  );

  ${media.tabletSmall(css`
    max-height: 50vh;
    max-height: calc(var(--vh, 1vh) * 50);
    font-size: 1px;

    background-image: repeating-linear-gradient(
      to bottom,
      ${colors.acquiringPrimary} 0 1em,
      rgba(255, 255, 255, 0) 1em 2em
    );
  `)}
`;

export default CultureHero;
