import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

const CollectPaymentsCustomer = () => {
  const {
    customerBottomText,
    customerImage,
    customerImage2x,
    customerText,
    customerTitle,
  } = useCurrentPage<CollectPaymentsPageType>().templateFields;
  return (
    <Component>
      <ContentSmallContainer>
        <Title>{customerTitle}</Title>
        <Text>{customerText}</Text>
        <Bottom>
          <BottomText>{customerBottomText}</BottomText>
          <BottomImage>
            <Picture
              src={customerImage?.url || ''}
              src2x={customerImage2x?.url}
            />
          </BottomImage>
        </Bottom>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  padding: 180px 0;

  ${media.tablet(css`
    padding: 120px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  max-width: 800px;
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  color: #ffffff;

  ${media.tabletSmall(css`
    font-size: 60px;
    line-height: 65px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
    line-height: 40px;
  `)}
`;

const Text = styled.p`
  margin-top: 50px;
  display: block;
  max-width: 1057px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #c2c2c8;

  font-size: 72px;
  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}

  ${media.tabletSmall(css`
    max-width: 500px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 50px;
  position: relative;
  display: flex;

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 30px;
  `)}
`;

const BottomText = styled.p`
  font-size: 24px;
  line-height: 145%;
  color: #ffffff;
  max-width: 768px;

  ${media.tabletSmall(css`
    max-width: 500px;
  `)}
`;

const BottomImage = styled.div`
  transform: translateY(-80px);

  ${media.tablet(css`
    transform: translateY(-40px);
  `)}

  ${media.mobile(css`
    transform: translateY(0);
    margin-top: 0;
  `)}
`;

export default CollectPaymentsCustomer;
