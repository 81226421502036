import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Hero from '@/modules/PartnershipProgram/components/Hero';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import Layout from '@/components/Layout';
import GlobalPayments from '@/modules/PartnershipProgram/components/GlobalPayments';
import IntroSection from '@/modules/PartnershipProgram/components/IntroSection';
import Capabilities from '@/modules/PartnershipProgram/components/Capabilities/Capabilities';
import SuperPower from '@/modules/PartnershipProgram/components/SuperPower/SuperPower';
import GetStarted, { Button, ButtonWrapper } from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import BecomePartner from '@/modules/PartnershipProgram/components/BecomePartner/BecomePartner';
import Faq from '@/modules/PartnershipProgram/components/Faq/Faq';
import Feedback from '@/modules/PartnershipProgram/components/Feedback/Feedback';
import { media } from '@/utils/mixin';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';

import Benefits from './components/Benefits';

function PartnershipProgram() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  const customStyles = css`
    ${media.mobile(css`
      ${ButtonWrapper} {
        display: flex;
        justify-content: flex-end;
      }

      ${Button} {
        margin-top: 0;
      }
    `)} @media (max-width: 320 px) {
      ${Button} {
        margin-top: 60px;
      }
    }
  `;

  return (
    <Layout dark={true} theme={'black'} onPreloaderOut={handlePreloaderOut}>
      <Hero animate={isPreloaderOut} />
      <Wrapper>
        <GlobalPayments />
        <IntroSection />
        <Benefits />
        <Capabilities />
        <SuperPower />
        <BecomePartner />
        <Faq />
        <Feedback />
      </Wrapper>
      <GetStarted
        color={colors.xppPrimary}
        hoverColor={colors.xppPrimaryHover}
        isTextLight={true}
        customStyles={customStyles}
        link={'mailto:hello@xpate.com'}
      >
        Become our
        <br />
        partner now!
      </GetStarted>
    </Layout>
  );
}

const Wrapper = styled.div`
  overflow: hidden;

  ${DoubleTitle} {
    ${media.mobile(css`
      span {
        font-size: 68px;
      }
    `)}
  }
`;

export default PartnershipProgram;
