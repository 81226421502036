import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-4.svg';
import SectionTitle, {
  Component as SectionTitleComponent,
} from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import { questionList } from '@/modules/PartnershipProgram/components/Faq/Faq.constans';
import { media } from '@/utils/mixin';

function Faq() {
  const questionRefList = Array.from({
    length: questionList.length,
  }).map((item) => React.createRef<HTMLLIElement>());

  useEffect(() => {
    let tl: gsap.core.Timeline;
    const tlList: Array<gsap.core.Timeline> = [];

    const delayedCall = gsap.delayedCall(0, () => {
      questionRefList.forEach((questionItem, index) => {
        if (!questionItem.current) return;

        tl = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: questionItem.current,
            start: 'top 100%',
            end: 'bottom bottom',
            scrub: 1,
          },
        });

        tlList[index] = tl.from(questionRefList[index].current, {
          duration: 0.8,
          ease: 'ease',
          opacity: 0,
          yPercent: 30,
        });
      }, 0.8);
    });

    return () => {
      delayedCall?.kill();
      tl?.kill();
      tlList?.forEach((item) => item?.kill());
    };
  }, []);

  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Badge>
            <BadgeLabel>xPP</BadgeLabel>
            <ArrowIcon />
          </Badge>
          <SectionTitle title={'FAQ'} />
          <Questions>
            {questionList.map((item, index) => (
              <Item ref={questionRefList[index]} key={index}>
                <ItemQuestion>{item.question}</ItemQuestion>
                <ItemAnswer dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Item>
            ))}
          </Questions>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  color: ${colors.black};

  ${SectionTitleComponent} {
    margin-top: 40px;
  }
`;

const Inner = styled.div`
  max-width: 901px;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 10px;
  }
`;

const BadgeLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const Questions = styled.ul`
  margin-top: 80px;

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Item = styled.li`
  margin-top: 80px;

  &:first-child {
    margin-top: 0 !important;
  }

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const ItemQuestion = styled.span`
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;
const ItemAnswer = styled.p`
  margin-top: 30px;
  font-weight: normal;
  font-size: 22px;
  line-height: 145%;

  ${media.mobile(css`
    font-size: 18px;

    margin-top: 20px;
  `)}
`;

export default Faq;
