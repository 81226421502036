import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import { createMediaMixin } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowBigHeaderSvg } from '@/assets/svg/arrow_big_header.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { scrollDown } from '@/utils/common';

type Props = {
  big?: boolean;
  small?: boolean;
  color: string;
  text: string;
  image?: string;
  leftImage?: string;
  rightImage?: string;
  animate?: boolean;
};

function HandsHero({
  image,
  leftImage,
  rightImage,
  text,
  color,
  big = false,
  small = false,
  animate,
}: Props) {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(innerRef.current, { y: 0 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(innerRef.current, {
        y: '25%',
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'bottom 100%',
          end: 'bottom 0',
          scrub: true,
        },
      });
    });

    return () => {
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(titleRef.current, { yPercent: 100 });

    gsap.delayedCall(0, () => {
      if (!animate) return;

      tw = gsap.to(titleRef.current, {
        ease: 'customEaseInOut',
        duration: 2.2,
        yPercent: 0,
      });
    });

    return () => {
      tw?.kill();
    };
  }, [animate]);

  return (
    <Component ref={containerRef} color={color}>
      <ContentSmallContainer>
        <Wrapper ref={innerRef}>
          <TitleWrapper small={small}>
            <Title
              ref={titleRef}
              dangerouslySetInnerHTML={{ __html: text }}
              big={big}
            />
          </TitleWrapper>
          <ButtonDown onClick={scrollDown}>
            <ArrowBigHeaderSvg />
          </ButtonDown>
        </Wrapper>
      </ContentSmallContainer>
      {image ? (
        <Hands>
          <img src={image} alt="hands" />
        </Hands>
      ) : (
        <>
          <HandLeft>
            <img src={leftImage} alt="left hand" />
          </HandLeft>
          <HandRight>
            <img src={rightImage} alt="right hand" />
          </HandRight>
        </>
      )}
    </Component>
  );
}

const Component = styled.div<{ color: string }>`
  position: relative;
  background: ${(props) => props.color};
  overflow: hidden;
`;

const HandLeft = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 44%;
  pointer-events: none;

  img {
    width: 100%;
  }

  @media (min-width: 1930px) {
    width: 40%;
  }

  @media (min-width: 1260px) and (max-height: 900px) {
    width: 43%;
  }

  @media (min-width: 1260px) and (max-height: 850px) {
    width: 42%;
  }

  @media (min-width: 1260px) and (max-height: 800px) {
    width: 41%;
  }

  @media (min-width: 1260px) and (max-height: 750px) {
    width: 40%;
  }

  @media (min-width: 1260px) and (max-height: 700px) {
    width: 39%;
  }
`;

const HandRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 48%;
  pointer-events: none;

  img {
    width: 100%;
  }

  @media (min-width: 1930px) {
    width: 40%;
  }

  @media (min-width: 1260px) and (max-height: 900px) {
    width: 47%;
  }

  @media (min-width: 1260px) and (max-height: 850px) {
    width: 46%;
  }

  @media (min-width: 1260px) and (max-height: 800px) {
    width: 45%;
  }

  @media (min-width: 1260px) and (max-height: 750px) {
    width: 44%;
  }

  @media (min-width: 1260px) and (max-height: 700px) {
    width: 43%;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

const ButtonDown = styled.button`
  position: absolute;
  right: 0;
  bottom: 40px;
  width: 95px;
  height: 131px;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.mobile(css`
    right: 20px;
    bottom: 25px;
    width: 51px;
    height: 65px;
  `)}
`;

const Hands = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  pointer-events: none;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TitleWrapper = styled.div<{ small: boolean }>`
  padding-left: 50vw;
  padding-right: 50vw;
  margin-left: -50vw;
  margin-right: -50vw;
  font-size: ${(props) => (props.small ? '135px' : '292px')};
  line-height: 90%;
  font-weight: 700;
  overflow: hidden;

  ${media.laptop(css`
    font-size: 240px;
  `)}

  ${media.tabletLarge(css`
    font-size: 150px;
  `)}

  ${media.tabletSmall(css`
    font-size: 120px;
  `)}

  ${media.mobile(css`
    font-size: 90px;
  `)}
`;

const Title = styled.h1<{ big: boolean }>`
  margin: ${(props) => (props.big ? '0 -80px' : '0')};
  padding-bottom: 0.15em;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  white-space: nowrap;
  text-align: center;
  color: ${colors.black};

  ${media.laptop(css`
    margin: 0;
  `)}

  ${media.mobile(css`
    white-space: normal;
  `)}

  ${media.mobileMedium(css`
    font-size: 71px;
  `)}

  ${createMediaMixin({ max: 320 })(css`
    font-size: 61px;
  `)}
`;

export default HandsHero;
