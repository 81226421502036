import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { PressCenterPageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowDownSvg } from '@/assets/svg/arrow-usp-1.svg';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function PressCenterFiles() {
  const page = useCurrentPage<PressCenterPageType>();
  if (!page) return null;

  if (!page.templateFields.files) return null;

  return (
    <Component>
      <ContentSmallContainer>
        <Row>
          {page.templateFields.files.map((file, index) => (
            <Cell key={index}>
              <Item target="_blank" href={file?.file?.url || undefined}>
                <Icon>
                  <ArrowDownSvg />
                </Icon>
                <Label>{file.label}</Label>
              </Item>
            </Cell>
          ))}
        </Row>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section``;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -19px -19px 0;

  ${media.tabletSmall(css`
    margin-top: -18px;
    margin-left: -9px;
    margin-right: -9px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 33.33333%;
  max-width: 100%;
  padding: 0 19px;
  margin-top: 19px;

  ${media.tabletSmall(css`
    margin-top: 18px;
    padding-left: 9px;
    padding-right: 9px;
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Label = styled.span`
  font-size: 31px;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 40px;
  margin-top: 46px;
  display: block;

  ${media.laptop(css`
    margin-top: 40px;
    font-size: 26px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 20px;
  `)}
`;

const Icon = styled.span`
  width: 53px;
  height: 53px;
  display: block;
  position: relative;
  padding-bottom: 20px;

  svg {
    width: 53px;
    height: 45px;
  }

  &:before {
    transition: 0.3s all cubic-bezier(0.315, 0.61, 0.355, 1);
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3px;
    background: ${colors.blue75};
    display: block;
  }

  ${media.tabletSmall(css`
    width: 43px;
    height: 43px;

    svg {
      width: 43px;
      height: 35px;
    }
  `)}
`;

const Item = styled.a`
  position: relative;
  padding: 55px 43px 85px;
  display: block;
  cursor: pointer;
  transition: 0.3s all cubic-bezier(0.315, 0.61, 0.355, 1);
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.gray};
    z-index: -1;
  }

  &:hover {
    ${Icon} {
      &:before {
        background: ${colors.blue75};
        height: 13px;
      }
    }
  }

  ${media.tabletSmall(css`
    padding-bottom: 65px;
  `)}

  ${media.tabletSmall(css`
    padding: 45px 33px 75px;
  `)}

  ${media.mobile(css`
    padding: 45px 0 65px;

    &:after {
      margin: 0 -20px;
    }
  `)}

  ${media.mobile(css`
    padding-bottom: 55px;
  `)}
`;

export default PressCenterFiles;
