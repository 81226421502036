import { useEffect, useMemo, useRef } from 'react';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger, SplitText);

interface Config {
  disableAnimationBgElements?: boolean;
}

function useSimplicityAnimation(config?: Config) {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);

  const slideElemMap: Record<string, HTMLDivElement> = {};

  function setSlideElem(elem: HTMLDivElement) {
    if (elem) slideElemMap[`+${elem.id}`] = elem;
  }

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 87%',
          },
        })
        .from(
          splitTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
          },
          0
        )
        .from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.25,
            delay: 0.4,
          },
          1
        );

      if (config?.disableAnimationBgElements) return;

      Object.values(slideElemMap).forEach((slideElem, index) => {
        if (!slideElem) return;

        tl.from(
          slideElem,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            duration: 1.75,
            delay: index / 8,
            scaleY: 0,
          },
          0.5
        );
      });
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return useMemo(
    () => ({
      textRef,
      titleRef,
      containerRef,
      labelRef,
      slideElemMap,
      setSlideElem,
    }),
    [setSlideElem, slideElemMap]
  );
}

export default useSimplicityAnimation;
