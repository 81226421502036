import React from 'react';
import { SplitText } from 'gsap/dist/SplitText';
import gsap from 'gsap';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import useIntroAnimation from '@/modules/common/Links/hooks/useIntroAnimation';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

import * as S from '../../common/Links/Intro.styles';

gsap.registerPlugin(SplitText);

function CollectPaymentsIntro() {
  const { introText, introTitle } =
    useCurrentPage<CollectPaymentsPageType>().templateFields;

  const { containerRef, bgRef, textRef, titleRef } = useIntroAnimation();

  return (
    <S.Component ref={containerRef}>
      <ContentContainer>
        <S.Inner>
          <S.TitleBackground ref={bgRef} />
          <S.Title ref={titleRef}>
            <TitleInner ref={textRef}>{introTitle}</TitleInner>
            <CircleContainer>
              <S.CircleArrow>
                <ArrowIcon />
              </S.CircleArrow>
              {introText}
            </CircleContainer>
          </S.Title>
        </S.Inner>
      </ContentContainer>
    </S.Component>
  );
}

const TitleInner = styled(S.TitleInner)`
  max-width: 900px;
  margin-left: -30px;

  ${media.laptop(css`
    max-width: 650px;
  `)}

  ${media.tabletLarge(css`
    max-width: 550px;
  `)}

  ${media.tabletSmall(css`
    max-width: 450px;
  `)}

  ${media.mobile(css`
    max-width: 100%;
    margin-left: 0;
  `)}
  
  font-size: 150px;
  line-height: 120%;

  @media (max-width: 1500px) {
    font-size: 110px;
    max-width: 650px;
  }

  ${media.laptop(css`
    font-size: 98px;
    line-height: 114px;
  `)}

  ${media.tabletLarge(css`
    font-size: 88px;
    line-height: 94px;
    padding-top: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 70px;
    line-height: 78px;
  `)}

  ${media.mobile(css`
    font-size: 50px;
    line-height: 120%;
  `)}
`;

const CircleContainer = styled(S.CircleContainer)`
  bottom: auto;
  top: 0;

  ${media.mobile(css`
    top: auto;
    bottom: 0;
  `)}
`;

export default CollectPaymentsIntro;
