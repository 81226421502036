import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power1 } from 'gsap';

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-5.svg';
import StacksStatisticsItem, {
  Text,
  ProgressLine,
} from '@/modules/Stacks/components/StacksServices/components/StacksStatisticsItem';
import { colors } from '@/constants/theme';
import { isIos } from '@/modules/Stacks/components/StacksServices/components/StacksStatisticsItem.helpers';

interface Props {}

const STATISTIC_ITEMS = [
  {
    prefix: <ArrowIcon />,
    text: { preText: 'seconds', lastText: ' — \ntraditional banks' },
    value: 70,
    placeholderColor: 'linear-gradient(90.01deg, #D4D4D4 0%, #898989 100%)',
  },
  {
    prefix: undefined,
    text: { preText: 'seconds', lastText: ' — \nxpate' },
    value: 8,
    placeholderColor: colors.stacksPrimary,
  },
] as const;

function StatisticsItems(props: Props) {
  const statisticsContainerRef = useRef<HTMLDivElement>(null);
  const [isMacOs, setIsMacOs] = useState(false);

  useLayoutEffect(() => {
    setIsMacOs(isIos());
  }, []);

  const statisticItemRefs = useRef(
    [70, 8].map((num) => ({
      progressRef: React.createRef<HTMLDivElement>(),
      valueRef: React.createRef<HTMLSpanElement>(),
      textRef: React.createRef<HTMLDivElement>(),
      number: num,
    }))
  );

  useEffect(() => {
    const statisticsItems = statisticItemRefs.current;
    const statisticsContainer = statisticsContainerRef.current;

    let tl: gsap.core.Timeline;

    const tlList: Array<gsap.core.Timeline> = [];

    if (!statisticsItems || !statisticsContainer) return;

    const delayCall = gsap.delayedCall(1, () => {
      statisticsItems.forEach((item, index) => {
        if (
          !item.progressRef.current ||
          !item.valueRef.current ||
          !item.textRef.current
        )
          return;

        gsap.set(item.progressRef.current, {
          xPercent: -100,
          overflow: 'hidden',
        });

        tl = gsap
          .timeline({
            scrollTrigger: {
              scroller: 'body',
              trigger: statisticsContainer,
              start: 'top 80%',
              end: 'bottom bottom',
            },
          })
          .add('start');

        tlList[index] = tl
          .from(
            item.valueRef.current,
            {
              textContent: 0,
              duration: 2,
              ease: Power1.easeOut,
              snap: { textContent: 1 },
              stagger: 0.7,
            },
            'start'
          )
          .to(
            item.progressRef.current,
            {
              xPercent: -(100 - item.number),
              ease: Power1.easeOut,
              duration: 2,
            },
            'start'
          )
          .from(
            item.textRef.current,
            {
              opacity: 0,
              duration: 0.8,
            },
            'start'
          );
      });
    });

    return () => {
      tl?.kill();
      delayCall?.kill();
      tlList.forEach((tl) => tl?.kill());
    };
  }, []);

  return (
    <StacksServicesItems isMacOs={isMacOs} ref={statisticsContainerRef}>
      {STATISTIC_ITEMS.map(
        ({ placeholderColor, value, text, prefix }, index) => (
          <StacksServicesItemWrapper key={index}>
            <StacksStatisticsItem
              className="statistics-item"
              prefix={prefix}
              valueRef={statisticItemRefs.current[index].valueRef}
              progressRef={statisticItemRefs.current[index].progressRef}
              textRef={statisticItemRefs.current[index].textRef}
              value={value}
              text={text}
              placeholderColor={placeholderColor}
            />
          </StacksServicesItemWrapper>
        )
      )}
    </StacksServicesItems>
  );
}

const StacksServicesItemWrapper = styled.div`
  margin-top: 20px;

  ${Text} {
    position: absolute;
  }

  &:first-child {
    ${Text} {
      left: 130px;
    }
  }

  &:last-child {
    ${Text} {
      left: 80px;
    }

    ${ProgressLine} {
      margin-top: 2px;
    }
  }

  @media (max-width: 768px) {
    ${Text} {
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      ${Text} {
        left: 65px;
      }
    }

    &:last-child {
      ${Text} {
        left: 40px;
      }
    }
  }
`;

const StacksServicesItems = styled.div<{ isMacOs: boolean }>`
  ${(props) =>
    props.isMacOs &&
    css`
      ${StacksServicesItemWrapper} {
        &:first-child {
          ${Text} {
            margin-bottom: 0;
            bottom: 16px;
          }

          ${ProgressLine} {
            margin-top: 2px;
          }
        }

        &:last-child {
          ${Text} {
            margin-bottom: 0;
            bottom: 16px;
          }

          ${ProgressLine} {
            margin-top: -8px;
          }
        }

        @media (max-width: 768px) {
          ${Text} {
            bottom: -14px !important;
          }

          ${ProgressLine} {
            margin-top: 2px !important;
          }
        }
      }
    `};

  @media (max-width: 768px) {
    margin: 40px 0 0 10px;

    ${StacksServicesItemWrapper} {
      svg {
        display: block;
        left: -20px;
        width: 18px;
        height: 18px;
      }

      ${ProgressLine} {
        margin-top: 7px;
        margin-left: -9px;
      }

      &:last-child {
        margin-top: 30px;
      }
    }
  }
`;

export default StatisticsItems;
