import React from 'react';
import styled, { css } from 'styled-components';

import Link from '@/components/Link';
import { ReactComponent as ArrowCircleLinkArrow } from '@/assets/svg/arrow-circle-link-arrow.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  link: string;
  label: string;
  color: string;
  textDark?: boolean;
  arrowColor?: string;
  onClick?: () => void;
};

const ArrowCircleLink: React.FC<Props> = ({
  link,
  label,
  color,
  textDark = true,
  arrowColor = colors.black,
  ...props
}) => {
  return (
    <Component to={link} {...props}>
      <Label isDark={textDark}>{label}</Label>
      <Circle background={color} color={arrowColor}>
        <ArrowCircleLinkArrow />
      </Circle>
    </Component>
  );
};

const Component = styled(Link)`
  display: flex;
  align-items: center;
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.75;
  }
`;

const Label = styled.span<{ isDark: boolean }>`
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.202786px;
  color: ${(props) => (props.isDark ? '#040312' : '#fff')};

  ${media.tablet(css`
    font-size: 26px; ;
  `)}
  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const Circle = styled.div<{ background: string; color: string }>`
  background: ${(props) => props.background};
  svg path {
    fill: ${(props) => props.color};
  }
  width: 133px;
  height: 133px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #040312;
  border-radius: 100%;

  ${media.tablet(css`
    width: 90px;
    height: 90px;
    margin-left: 20px;
  `)}

  ${media.mobile(css`
    width: 68px;
    height: 68px;
    margin-left: 12px;
  `)}
  
  svg {
    max-width: 60%;
  }
`;

export default ArrowCircleLink;
