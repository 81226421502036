import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import SectionName from '@/components/SectionName';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AboutPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AboutMission() {
  const containerRef = useRef<HTMLDivElement>(null);
  const boldTextRef = useRef<HTMLDivElement>(null);
  const regTextRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);

  const page = useCurrentPage<AboutPageType>();

  useEffect(() => {
    if (!page) return;

    let tl: gsap.core.Timeline;

    const splitTextChild = new SplitText(
      [boldTextRef.current, regTextRef.current],
      {
        linesClass: 'lineChild',
        type: 'words, lines',
      }
    );
    const splitTextParent = new SplitText(
      [boldTextRef.current, regTextRef.current],
      {
        linesClass: 'lineParent',
        type: 'words, lines',
      }
    );

    const delayedCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 87%',
        },
      });

      tl.from(
        splitTextChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      ).from(
        labelRef.current,
        {
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.25,
          delay: 0.1,
        },
        1
      );
    });

    return () => {
      splitTextParent?.revert();
      splitTextChild?.revert();
      delayedCall?.kill();
      tl?.kill();
    };
  }, [page]);

  if (!page) return null;

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        {page.templateFields.missionLabel ? (
          <LabelWrapper>
            <SectionName
              ref={labelRef}
              label={page.templateFields.missionLabel}
            />
          </LabelWrapper>
        ) : null}
        <Text>
          {page.templateFields.missionTextBold ? (
            <b ref={boldTextRef}>{page.templateFields.missionTextBold}</b>
          ) : null}
          <BlockText ref={regTextRef}>
            {page.templateFields.missionText}
          </BlockText>
        </Text>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 230px;

  @media (max-width: 1550px) {
    margin-top: 120px;
  }

  ${media.tabletSmall(css`
    margin-top: 450px;
  `)}

  ${media.mobile(css`
    margin-top: 300px;
  `)}

  ${media.mobileMedium(css`
    margin-top: 350px;
  `)}
`;

const LabelWrapper = styled.div`
  overflow: hidden;
`;

const Text = styled.p`
  margin-top: 40px;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  letter-spacing: -0.017em;
  max-width: 1110px;
  color: ${colors.textGray};

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  b {
    color: ${colors.black};
  }

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

const BlockText = styled.span`
  display: block;
`;

export default AboutMission;
