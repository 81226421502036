export const targetedCountriesList = [
  { label: 'EEA', value: 'EEA' },
  { label: 'CIS', value: 'CIS' },
  { label: 'Middle East', value: 'Middle East' },
  { label: 'Australia', value: 'Australia' },
  { label: 'LATAM', value: 'LATAM' },
  { label: 'Other (comment below)', value: 'Other (comment below)' },
];

export const mccList = [
  { label: '6211', value: '6211' },
  { label: '7995', value: '7995' },
  { label: '6012', value: '6012' },
  { label: 'Other (comment below)', value: 'Other (comment below)' },
];

export const processingList = [
  { label: 'yes', value: true },
  { label: 'no', value: false },
];
