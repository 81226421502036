import React, { useState } from 'react';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import Layout from '@/components/Layout';
import HandsHero from '@/components/HandsHero';
import AccountsIntro from '@/modules/Accounts/components/AccountsIntro';
import AccountsPlaces from '@/modules/Accounts/components/AccountsPlaces';
import AccountsBenefits from '@/modules/Accounts/components/AccountsBenefits';
import AccountsItems from '@/modules/Accounts/components/AccountsItems';
import AccountsBenefitsSecond from '@/modules/Accounts/components/AccountsBenefitsSecond';
import HandsImage from '@/assets/images/accounts/accounts-hands.png';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';

function Accounts() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="accounts" dark={false} onPreloaderOut={handlePreloaderOut}>
      <HandsHero
        image={HandsImage}
        color={colors.accountsPrimary}
        animate={isPreloaderOut}
        text="Accounts"
      />

      <AccountsIntro />
      <AccountsPlaces />
      <AccountsBenefits />
      <AccountsItems />
      <AccountsBenefitsSecond />

      <GetStarted
        color={colors.accountsPrimary}
        hoverColor={colors.accountsPrimaryHover}
      >
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Accounts;
