import React from 'react';
import styled, { css } from 'styled-components';
import { SplitText } from 'gsap/dist/SplitText';
import gsap from 'gsap';

import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import handImage from '@/assets/images/links/hand-with-card.png';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';
import { breakpoints, colors } from '@/constants/theme';
import ContentContainer from '@/components/ContentContainer';
import useHeroAnimation from '@/modules/common/Links/hooks/useHeroAnimation';

gsap.registerPlugin(SplitText);

type Props = {
  animate: boolean;
};

function LinksHero({ animate }: Props) {
  const isTabletLayout = useMedia(
    `(max-width: ${breakpoints.tabletLarge - 1}px)`
  );

  const { containerRef, buttonRef, textRef, titleInnerRef, innerRef } =
    useHeroAnimation({ config: { animate: animate } });

  return (
    <React.Fragment>
      <Component ref={containerRef}>
        <ContentSmallContainer>
          <Wrapper ref={innerRef}>
            <Inner>
              <TitleWrapper>
                <TitleInner ref={titleInnerRef}>
                  <Title>
                    xpate <span>Links</span>
                  </Title>
                </TitleInner>
              </TitleWrapper>
              {!isTabletLayout ? (
                <Row>
                  <HeroCircleArrowButton
                    ref={buttonRef}
                    color={colors.linksPrimary}
                    hoverColor={colors.linksPrimaryHover}
                  />

                  <Text ref={textRef}>
                    Helping acquirers and issuers to make the management of
                    "paying by cards" business easy and hassle-free
                  </Text>
                </Row>
              ) : null}
            </Inner>
            <Hand>
              <img src={handImage} alt="" />
            </Hand>
            {isTabletLayout && (
              <MobileInnerWrapper>
                <HeroCircleArrowButton
                  ref={buttonRef}
                  color={colors.linksPrimary}
                  hoverColor={colors.linksPrimaryHover}
                />
              </MobileInnerWrapper>
            )}
          </Wrapper>
        </ContentSmallContainer>
      </Component>
      {isTabletLayout ? (
        <ContentContainer>
          <Row>
            <Text ref={textRef}>
              Helping acquirers to make the management of “paying by cards”
              business easy and hassle free
            </Text>
          </Row>
        </ContentContainer>
      ) : null}
    </React.Fragment>
  );
}

export const Component = styled.div`
  background: #040312;
  overflow: hidden;

  ${media.mobile(css`
    position: relative;
  `)}
`;

export const MobileInnerWrapper = styled.div`
  button {
    position: absolute !important;
    bottom: 40px;

    @media (max-width: 416px) {
      bottom: 30px;
    }

    @media (max-width: 320px) {
      bottom: 48px;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 600px;
  z-index: 2;

  ${media.tabletSmall(css`
    padding-bottom: 120px;
  `)}

  ${media.mobile(css`
    justify-content: flex-end;
  `)}
`;

export const Inner = styled.div`
  position: relative;
  margin-top: -150px;
  margin-left: -5px;

  ${media.tabletSmall(css`
    margin-top: -50px;
  `)}

  ${media.mobile(css`
    position: static;
  `)}
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  padding-left: 50vw;
  padding-right: 50vw;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -10px;
  font-size: 246px;
  line-height: 100%;
  font-weight: 700;
  letter-spacing: 0;
  overflow: hidden;
  z-index: 1;

  @media (min-width: 1024px) and (max-height: 650px) {
    font-size: 220px;
  }

  ${media.laptop(css`
    font-size: 200px;
  `)}

  ${media.tabletLarge(css`
    font-size: 170px;
  `)}

  ${media.tabletSmall(css`
    font-size: 140px;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)}

  ${media.mobileMedium(css`
    font-size: 100px;
  `)}

  ${media.mobileMedium(css`
    font-size: 90px;
  `)}
`;

export const TitleInner = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0 -40px;

  ${media.tabletLarge(css`
    margin: 0 -30px;
  `)}

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}

  ${media.mobile(css`
    margin: 0 -15px;
    flex-shrink: 1;
  `)}
`;

export const Title = styled.h1`
  display: block;
  flex-shrink: 0;
  padding: 0 40px 0.15em;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  white-space: nowrap;
  margin: 0;

  span {
    color: ${colors.linksPrimary};
  }

  ${media.tabletLarge(css`
    padding: 0 30px 0.15em;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px 0.15em;
  `)}

  ${media.mobile(css`
    padding: 0 15px 0.15em;
    flex-shrink: 1;
    white-space: normal;
  `)}
`;

export const Row = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-left: 6px;
  z-index: 1;

  @media (min-width: 1024px) and (max-height: 900px) {
    margin-top: 20px;
  }

  ${media.tabletSmall(css`
    position: static;
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-left: 0;
  `)}
`;

export const Text = styled.p`
  max-width: 400px;
  margin-left: 90px;
  font-size: 31px;
  font-weight: 600;
  line-height: 40px;
  color: #fff;
  letter-spacing: -0.015em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 29px;
    line-height: 38px;
  `)}

  ${media.tabletSmall(css`
    margin-left: 0;
    margin-bottom: 40px;
    color: ${colors.black};
  `)}

  ${media.mobile(css`
    margin-bottom: 0;
    font-size: 20px;
    line-height: 30px;
  `)}
`;

export const Hand = styled.div`
  position: absolute;
  right: 2%;
  bottom: 0;

  img {
    display: block;
    max-height: 85vh;
    margin: 0 auto;
  }

  ${media.laptop(css`
    right: 8%;
  `)}

  ${media.mobile(css`
    right: 0;

    img {
      max-height: 75vh;
    }
  `)}

  ${media.mobileMedium(css`
    right: -16%;
  `)}
`;

export default LinksHero;
