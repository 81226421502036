import { css } from 'styled-components';

import { CssSnippet, PagesTemplates } from '@/typings/common';

import { Title } from './FormThanks';

export const stylesFormThanksByTheme: Record<PagesTemplates, CssSnippet> = {
  [PagesTemplates.Basic]: css``,
  [PagesTemplates.XPP]: css`
    ${Title} {
      &:after {
        background: rgba(149, 93, 255, 0.75);
      }
    }

    #success-form-icon {
      fill: rgba(149, 93, 255) !important;
    }
  `,
  [PagesTemplates.Singapore]: css`
    ${Title} {
      &:after {
        background: rgba(149, 93, 255, 0.75);
      }
    }

    #success-form-icon {
      fill: rgba(149, 93, 255) !important;
    }
  `,
  [PagesTemplates.Links]: css``,
  [PagesTemplates.Stacks]: css`
    ${Title} {
      &:after {
        background: rgba(0, 247, 236, 0.75);
      }
    }

    #success-form-icon {
      fill: rgba(0, 247, 236) !important;
    }
  `,
};
