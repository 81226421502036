import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';

const CorebankingText = () => {
  const { textTitle, textBody, textItems } =
    useCurrentPage<CorebankingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{textTitle}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: textBody
              ? textBody.replace(/\*(.+)\*/gi, '<em>$1</em>')
              : '',
          }}
        />
        {textItems ? (
          <Row>
            {textItems.split('\n').map((item) => (
              <Column>{item.trim()}</Column>
            ))}
          </Row>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  padding: 240px 0 275px;

  ${media.tablet(css`
    padding: 140px 0;
  `)}

  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;

const Title = styled.span`
  display: block;
  font-weight: 700;
  font-size: 102px;
  line-height: 102%;
  color: #040312;
  max-width: 750px;

  ${media.tablet(css`
    font-size: 70px;
  `)}

  ${media.mobile(css`
    font-size: 50px;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #c2c2c8;
  margin-top: 80px;
  display: block;
  max-width: 1057px;
  em {
    color: #040312;
    font-style: normal;
  }

  ${media.tablet(css`
    font-size: 50px;
    margin-top: 60px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
    margin-top: 40px;
  `)}
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px -25px 0;

  ${media.mobile(css`
    margin: 30px 0 0 0;
    display: block;
  `)}
`;

const Column = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  color: #040312;
  width: 500px;
  max-width: 100%;
  padding: 0 25px;
  ${media.mobile(css`
    margin-top: 30px;
    width: 100%;
    padding: 0;
  `)}
`;

export default CorebankingText;
