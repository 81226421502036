import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { FieldArray, Formik, FormikErrors } from 'formik';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import * as S from '@/components/Form.styles';
import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import TextInput from '@/components/TextInput';
import { submitFullForm } from '@/services/requests';
import CheckBox from '@/components/Checkbox/Checkbox';
import Select from '@/components/Select/Select';
import { media } from '@/utils/mixin';
import { scrollToElement } from '@/utils/common';
import { FormPageType } from '@/typings/model';

import {
  mccList,
  processingList,
  targetedCountriesList,
} from './FormConference.constans';
import {
  convertFormValues,
  getNewValues,
  handleValidateFormConference,
} from './FormConference.helpers';
import { FormConferenceValuesType } from './FormConference.types';

gsap.registerPlugin(ScrollTrigger);

type Props = {
  onSuccess: () => void;
};

function ScrollToError({
  errors,
  refMap,
}: {
  errors: FormikErrors<FormConferenceValuesType>;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
}) {
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length !== 0) {
      const errorField = refMap[keys[0]];
      if (errorField.current) {
        scrollToElement(errorField);
      }
    }
  }, [errors]);

  return null;
}

function FormConferenceInner({ onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitError, setSubmitError] = useState({
    state: false,
    value: 'An error occurred while submitting the form',
  });

  const refMap = {
    email: useRef<HTMLDivElement>(null),
    legalName: useRef<HTMLDivElement>(null),
    name: useRef<HTMLDivElement>(null),
    url: useRef<HTMLDivElement>(null),
    license: useRef<HTMLDivElement>(null),
    otherCountries: useRef<HTMLDivElement>(null),
    message: useRef<HTMLDivElement>(null),
    mcc: useRef<HTMLDivElement>(null),
    otherMcc: useRef<HTMLDivElement>(null),
    processingHistory: useRef<HTMLDivElement>(null),
    targetedCountries: useRef<HTMLDivElement>(null),
    volumePerMonth: useRef<HTMLDivElement>(null),
  };

  const page = useCurrentPage<FormPageType>();

  if (!page) return null;

  function handleClickOnButton(errors: FormikErrors<FormConferenceValuesType>) {
    const keys = Object.keys(errors);
    if (keys.length !== 0) {
      const errorField = refMap[keys[0] as keyof FormConferenceValuesType];
      if (errorField.current) {
        scrollToElement(errorField);
      }
    }
  }

  async function handleSubmitForm(values: FormConferenceValuesType) {
    setLoading(true);
    setSubmitError({ ...submitError, state: false });

    const payload = convertFormValues(values);
    const formId = page?.templateFields?.form?.id;

    if (!formId) {
      setLoading(false);
      return setSubmitError({
        ...submitError,
        value: 'there is no ID form, please contact support',
        state: true,
      });
    }
    try {
      await submitFullForm(payload, formId);
      if (onSuccess) onSuccess();
    } catch (error) {
      setLoading(false);
      setSubmitError({
        ...submitError,
        value: 'An error occurred while submitting the form',
        state: true,
      });
    }
  }

  return (
    <S.Component>
      <ContentContainer width={999}>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmitForm}
          validate={handleValidateFormConference}
          initialValues={
            {
              email: '',
              legalName: '',
              name: '',
              url: '',
              license: [],
              otherCountries: '',
              message: '',
              mcc: [],
              otherMcc: '',
              processingHistory: [],
              targetedCountries: [],
              volumePerMonth: '',
            } as FormConferenceValuesType
          }
        >
          {({ values, errors, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <S.FormInputs>
                  <S.FormRow ref={refMap.name}>
                    <TextInput
                      name="name"
                      value={values.name}
                      error={errors.name}
                      placeholder="Your Name"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.email}>
                    <TextInput
                      name="email"
                      value={values.email}
                      error={errors.email}
                      placeholder="Your E-Mail"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.legalName}>
                    <TextInput
                      name="legalName"
                      value={values.legalName}
                      error={errors.legalName}
                      placeholder="Legal name"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.url}>
                    <TextInput
                      name="url"
                      value={values.url}
                      error={errors.url}
                      placeholder="URL"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.license}>
                    <Select
                      name="license"
                      defaultValue="License"
                      error={
                        typeof errors.license === 'string'
                          ? errors.license
                          : undefined
                      }
                      value={values.license[0]?.label ?? ''}
                      options={[
                        { label: 'EU', value: 'eu' },
                        { label: 'Non EU ', value: 'non-eu' },
                        { label: 'No', value: 'no' },
                      ]}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.targetedCountries}>
                    <FieldTitle>
                      Targeted countries{' '}
                      {errors.targetedCountries ? (
                        <StaticError>{errors.targetedCountries}</StaticError>
                      ) : null}
                    </FieldTitle>

                    <FieldArray
                      name="targetedCountries"
                      render={() => (
                        <CheckBoxGroup>
                          {targetedCountriesList.map((item) => {
                            return (
                              <CheckBox
                                key={item.value}
                                value={item.value}
                                name="targetedCountries"
                                label={item.label}
                                checked={values.targetedCountries.some(
                                  (countryItem) =>
                                    countryItem.value === item.value
                                )}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const newFieldValue = e.target.checked
                                    ? [...values.targetedCountries, item]
                                    : values.targetedCountries.filter(
                                        (selectedCountryItem) =>
                                          selectedCountryItem.value !==
                                          item.value
                                      );

                                  setFieldValue(
                                    'targetedCountries',
                                    newFieldValue
                                  );
                                }}
                              />
                            );
                          })}
                        </CheckBoxGroup>
                      )}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.otherCountries}>
                    <TextInput
                      name="otherCountries"
                      value={values.otherCountries}
                      placeholder="Other countries"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChange(event);
                        const newValues = getNewValues(
                          values,
                          'targetedCountries'
                        );
                        setFieldValue('targetedCountries', newValues);
                      }}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.mcc}>
                    <FieldTitle>
                      MCC{' '}
                      {errors.mcc ? (
                        <StaticError>{errors.mcc}</StaticError>
                      ) : null}
                    </FieldTitle>

                    <FieldArray
                      name="mcc"
                      render={() => {
                        return (
                          <CheckBoxGroup>
                            {mccList.map((item) => (
                              <CheckBox
                                key={item.value}
                                value={item.value}
                                name="mcc"
                                label={item.label}
                                checked={values.mcc.some(
                                  (selectedMccItem) =>
                                    selectedMccItem.value === item.value
                                )}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const newFieldValue = e.target.checked
                                    ? [...values.mcc, item]
                                    : values.mcc.filter(
                                        (selectedMccItem) =>
                                          selectedMccItem.value !== item.value
                                      );

                                  setFieldValue('mcc', newFieldValue);
                                }}
                              />
                            ))}
                          </CheckBoxGroup>
                        );
                      }}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.otherMcc}>
                    <TextInput
                      name="otherMcc"
                      value={values.otherMcc}
                      placeholder="Other MCC"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        handleChange(event);
                        const newValues = getNewValues(values, 'mcc');
                        setFieldValue('mcc', newValues);
                      }}
                    />
                  </S.FormRow>
                  <Separator />
                  <S.FormRow ref={refMap.volumePerMonth}>
                    <TextInput
                      name="volumePerMonth"
                      value={values.volumePerMonth}
                      placeholder="Volume per month (EUR)"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.processingHistory}>
                    <FieldArray
                      name="processingHistory"
                      render={(arrayHelpers) => (
                        <GroupElements>
                          <StyledLabelWrapper>
                            <StyledLabel>Processing history</StyledLabel>
                            {errors.processingHistory ? (
                              <Error>{errors.processingHistory}</Error>
                            ) : null}
                          </StyledLabelWrapper>
                          <GroupElementsInner>
                            {processingList.map((item, index) => (
                              <FormElement key={`${index}-${item.value}`}>
                                <StyledLabel htmlFor={item.label}>
                                  {item.label}
                                </StyledLabel>
                                <CheckBox
                                  key={item.label}
                                  value={item.label}
                                  id={item.label}
                                  name="processingHistory"
                                  checked={values.processingHistory.some(
                                    (historyItem) =>
                                      historyItem.label === item.label
                                  )}
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    arrayHelpers.replace(0, item);
                                  }}
                                />
                              </FormElement>
                            ))}
                          </GroupElementsInner>
                        </GroupElements>
                      )}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.message}>
                    <TextInput
                      name="message"
                      value={values.message}
                      placeholder="Leave a message"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  {page.templateFields.agreementText ? (
                    <S.FormRow>
                      <S.CheckboxLabel>
                        <input
                          checked={agreementChecked}
                          onChange={(e) =>
                            setAgreementChecked(!agreementChecked)
                          }
                          type="checkbox"
                        />
                        <span
                          dangerouslySetInnerHTML={{
                            __html: page?.templateFields.agreementText,
                          }}
                        />
                      </S.CheckboxLabel>
                    </S.FormRow>
                  ) : null}
                </S.FormInputs>
                <S.Button
                  disabled={
                    !!page.templateFields.agreementText && !agreementChecked
                  }
                  type="submit"
                  loading={loading}
                  onClick={() => handleClickOnButton(errors)}
                >
                  {page.templateFields.submitButtonLabel}
                </S.Button>
                {submitError.state ? (
                  <S.FormError>{submitError.value}</S.FormError>
                ) : null}
                <ScrollToError errors={errors} refMap={refMap} />
              </S.Form>
            );
          }}
        </Formik>
      </ContentContainer>
    </S.Component>
  );
}

const FieldTitle = styled.span`
  display: flex;
  align-items: center;
  font-size: 31px;
  line-height: 24px;
  padding-bottom: 30px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);

  &:first-child {
    margin-top: 60px;
  }

  ${media.mobile(css`
    font-size: 22px;
  `)}
`;

const CheckBoxGroup = styled.div`
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 46px;

  & > div {
    width: 50%;
    margin-bottom: 40px;

    ${media.mobile(css`
      width: 100%;
      margin-bottom: 20px;
    `)}
  }

  ${media.mobile(css`
    padding-left: 0;
  `)}
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 31px;
  line-height: 36px;
  padding-right: 30px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  min-height: 100%;
  ${media.mobile(css`
    order: 1;
    font-size: 22px;
    padding-right: 0;
    padding-left: 30px;
    width: 100%;
  `)}
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -30px;
  color: red;
`;

const StaticError = styled(Error)`
  position: static;
  margin-left: 10px;
  color: red;
  font-size: 14px;
  font-weight: 400;
`;

const StyledLabelWrapper = styled.div`
  position: relative;

  ${media.mobile(css`
    width: 100%;

    & > ${StyledLabel} {
      padding-left: 0;
    }
  `)}
`;

const GroupElements = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 46px;
  margin: 56px 0;

  ${media.mobile(css`
    margin: 20px 0;
    flex-direction: column;
    padding-left: 0;
  `)}
`;

const GroupElementsInner = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${media.mobile(css`
    margin-top: 20px;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `)}
`;

const FormElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;
  height: 96px;

  ${media.mobile(css`
    width: 100%;
    height: 48px;
    justify-content: space-between;
    margin-right: 0;
    margin-bottom: 20px;
  `)}
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.14);
  margin-top: 40px;
  margin-bottom: 40px;
`;

export default FormConferenceInner;
