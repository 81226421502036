import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { media } from '@/utils/mixin';
import ChatImage from '@/assets/images/home/chat_2.png';
import ContentContainer from '@/components/ContentContainer';

gsap.registerPlugin(SplitText);

function HomeSupport() {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      ).from(
        textRef.current,
        {
          ease: Power2.easeOut,
          transformOrigin: '50% 100%',
          duration: 0.7,
          opacity: 0,
          delay: 0.2,
          y: 20,
        },
        1
      );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner>
          <Left>
            <Image>
              <img src={ChatImage} alt="24/7 support like never before" />
            </Image>
          </Left>
          <Right>
            <Title ref={titleRef}>
              Choose the support that works best for&nbsp;you
            </Title>
            <Text ref={textRef}>
              You’ll get your problem solved. And you’ll forget what it feels
              like to&nbsp;wait.
            </Text>
          </Right>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  padding: 85px 0 150px;
  color: #040312;
  background: #fff;
  overflow: hidden;

  ${media.tabletSmall(css`
    padding: 85px 125px;
  `)}

  ${media.mobile(css`
    padding: 85px 0 95px;
  `)}
  
  ${media.mobileSmall(css`
    padding: 85px 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
  display: flex;
`;

const Left = styled.div`
  position: relative;

  &:before {
    content: '';
    background: #e9e9e9;
    width: 1175px;
    height: 1175px;
    border-radius: 50%;
    position: absolute;
    right: 100px;
    display: block;
    z-index: 0;
  }

  ${media.laptop(css`
    width: 60%;

    &:before {
      right: 0;
    }
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

const Image = styled.div`
  position: relative;
  z-index: 1;

  ${media.laptop(css`
    max-width: 90%;
  `)}
`;

const Right = styled.div`
  position: relative;
  width: 600px;
  max-width: 100%;
  padding-top: 60px;
  margin-left: 18px;
  z-index: 1;

  ${media.laptop(css`
    width: 370px;
    padding-right: 5%;
  `)}

  ${media.tabletLarge(css`
    width: 300px;
  `)}
  
  ${media.tabletSmall(css`
    width: 370px;
    padding: 0;
    margin: 0;
  `)}
`;

const Title = styled.h2`
  font-size: 102px;
  letter-spacing: -0.01em;
  line-height: 104px;
  font-weight: 700;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 62px;
    line-height: 64px;
  `)}

  ${media.tabletLarge(css`
    font-size: 52px;
    line-height: 62px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 42px;
    line-height: 42px;
  `)}
`;

const Text = styled.p`
  margin-top: 40px;
  display: block;
  font-size: 31px;
  letter-spacing: -0.015em;
  line-height: 40px;
  font-weight: 500;
  max-width: 470px;
  margin-left: 5px;

  ${media.laptop(css`
    margin-left: 0;
    margin-top: 30px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

export default HomeSupport;
