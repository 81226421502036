import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as SentIcon } from '@/assets/svg/sent-ico.svg';
import { ReactComponent as ArrowCarousel } from '@/assets/svg/arrow_carousel.svg';
import { ButtonNextLink } from '@/components/Button/Button';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import { CssSnippet } from '@/typings/common';
import useRefreshScroll from '@/hooks/useRefreshScroll';

interface Props {
  stylesFormThanksByTheme?: CssSnippet;
}

function FormThanks({ stylesFormThanksByTheme }: Props) {
  const page = useCurrentPage<FormPageType>();

  useRefreshScroll({ deps: [] });

  if (!page) return null;

  return (
    <Component stylesFormThanksByTheme={stylesFormThanksByTheme}>
      <ContentContainer width={999}>
        <Inner>
          <Icon>
            <SentIcon />
          </Icon>
          <Title>{page.templateFields.successTitle}</Title>
          <Text>{page.templateFields.successText}</Text>
          <Button to={page.templateFields.successButtonUrl}>
            <ArrowCarousel />
            {page.templateFields.successButtonLabel}
          </Button>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

export const Title = styled.span`
  font-size: 90px;
  line-height: 94px;
  font-weight: bold;
  letter-spacing: -0.03em;
  margin-top: 64px;
  position: relative;

  ${media.tabletSmall(css`
    font-size: 60px;
    line-height: 65px;
  `)}

  ${media.mobile(css`
    font-size: 30px;
    line-height: 40px;
  `)}
`;

const Component = styled.section<{
  stylesFormThanksByTheme?: CssSnippet;
}>`
  margin-top: 150px;
  margin-bottom: 60px;

  ${media.mobile(css`
    margin-top: 100px;
    margin-bottom: 60px;
  `)}

  ${(props) => props.stylesFormThanksByTheme}
`;

const Inner = styled.div`
  text-align: center;
`;

const Icon = styled.div`
  svg {
    width: 180px;
    height: 165px;

    ${media.mobile(css`
      width: 90px;
      height: 82px;
    `)}
  }
`;

const Text = styled.p`
  display: block;
  color: #adadaf;
  font-size: 31px;
  line-height: 45px;
  font-weight: 500;
  max-width: 783px;
  margin: 42px auto 110px;

  ${media.mobile(css`
    font-size: 22px;
    line-height: 30px;
    margin: 20px 0 40px;
  `)}
`;

const Button = styled(ButtonNextLink)`
  background: #f8f8f8;
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  letter-spacing: -0.01em;
  font-weight: 600;

  ${media.mobile(css`
    height: 90px;
    font-size: 24px;
  `)}
  svg {
    margin-right: 31px;
    width: 47px;
    height: 41px;

    ${media.mobile(css`
      width: 24px;
      height: 20px;
      margin-right: 20px;
    `)}
  }

  &:hover {
    background: #f1f1f1;
  }
`;

export default FormThanks;
