import styled from 'styled-components';

import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';

const Presentation = styled.div`
  padding: 245px 385px 210px 0;

  margin-left: -90px;
`;

const PresentationWrapper = styled.div`
  max-width: 1234px;
  position: relative;
`;

const Container = styled.div`
  max-width: 1960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

const PresentationInner = styled.div`
  position: relative;
  padding: 40px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(30px);
  z-index: 200;
`;

const PositionElements = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
`;

const CircleImage = styled(Picture)`
  position: static !important;

  img {
    position: absolute;
    bottom: -127px;
    left: -19px;
  }
`;

const PresentationVideo = styled.div`
  padding-top: 61%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  z-index: 10;

  video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Messages = styled.div`
  position: absolute;
  right: -363px;
  top: 70%;
  transform: translateY(-70%);
`;

const Message = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  padding: 25px 50px;
  box-shadow: 0px 14px 26px rgba(130, 130, 130, 0.25);
  border-radius: 72px 72px 72px 0;
  background: white;
  color: black;
  opacity: 0;
  z-index: 5;

  &:first-child {
    transform: translate(-176px) scale(0.1);
    max-width: 385px;
  }

  &:last-child {
    transform: translate(-92px) scale(0.1);
    max-width: 215px;
    margin-top: 20px;
  }
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 0;

  right: -12px;

  svg {
    height: 120px;
    width: 100px;
    fill: white;
  }
`;

const Circle = styled.div`
  position: absolute;
  width: 280px;
  height: 280px;
  background: ${colors.stacksPrimary};
  border-radius: 50%;

  &.up {
    top: -67px;
    right: -118px;
  }

  &.down {
    width: 150px;
    height: 150px;
    top: 80%;
    transform: translateY(-80%);
    right: 0;
  }
`;

const Ballon = styled(Picture)`
  position: absolute;

  &.up {
    top: 37%;
    transform: translateY(-37%);
    right: -37px;
  }

  &.down {
    top: 83%;
    transform: translateY(-83%);
    right: -210px;
  }
`;

const NavigationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 80px;

  button {
    margin-top: 0 !important;
  }

  svg {
    path {
      fill: black;
    }
  }
`;

const NavigationText = styled.span`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 39.9724px;
  line-height: 100%;
  margin-right: 30px;
`;

const Component = styled.div`
  background: black;
  overflow: hidden;
  padding-bottom: 256px;

  @media (max-width: 1024px) {
    padding-bottom: 140px;

    ${NavigationText} {
      font-size: 34px;
    }

    ${Presentation} {
      padding-right: 250px;
    }

    ${Messages} {
      right: -230px;
    }

    ${Message} {
      line-height: 20px;
      padding: 25px 35px;
      font-size: 16px;

      &:first-child {
        width: 250px;
      }

      &:last-child {
        width: 200px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;

    ${NavigationText} {
      margin-right: 12px;
      font-size: 18px;
    }

    ${Presentation} {
      max-width: 100%;
      padding-right: 0;
    }

    ${Messages} {
      display: none;
    }

    ${NavigationButtonWrapper} {
      justify-content: flex-end;
    }
  }

  @media (max-width: 416px) {
    padding-bottom: 80px;

    ${Presentation} {
      margin-left: -34px;
      padding-top: 96px;
      padding-bottom: 70px;
    }

    ${NavigationButtonWrapper} {
      margin-top: 26px;
    }

    ${PresentationInner} {
      padding: 10px;
      border-radius: 12px;
    }

    ${CircleImage} {
      img {
        width: 68px;
        height: 68px;

        bottom: -26px;
        left: 0;
      }
    }

    ${Ballon} {
      &.up {
        top: 50%;
        right: -18px;
      }

      &.down {
        top: 110%;
        right: -65px;
      }

      img {
        width: 36px;
        height: 36px;
      }
    }

    ${Circle} {
      &.up {
        width: 88px;
        height: 88px;
        top: -40px;
        right: -32px;

        svg {
          width: 29px;
          height: 36px;
        }
      }
    }
  }
`;

export {
  Messages,
  Message,
  Presentation,
  PresentationInner,
  PresentationVideo,
  PresentationWrapper,
  CircleWrapper,
  Circle,
  CircleImage,
  Picture,
  Container,
  Ballon,
  Component,
  PositionElements,
  NavigationButtonWrapper,
  NavigationText,
};
