import React from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import Features from '@/components/Features';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType } from '@/typings/model';

gsap.registerPlugin(SplitText);

function HomeFeatures() {
  const { features } = useCurrentPage<Home2023PageType>().templateFields;

  return (
    <Features
      items={features.map((item) => {
        return {
          imgSrc: item.image?.url || '',
          imgSrc2x: item.image2x?.url || '',
          title: item.title || '',
          text: item.text || '',
          buttonLabel: item.buttonLabel || '',
          buttonLink: item.buttonLink || '',
          buttonNewTab: item.buttonNewTab || false,
        };
      })}
    />
  );
}

export default HomeFeatures;
