import React from 'react';

import Layout from '@/components/Layout';
import SingaporeHero from '@/modules/Singapore/components/SingaporeHero';
import SingaporeCta from '@/modules/Singapore/components/SingaporeCta';
import SingaporeFeatures from '@/modules/Singapore/components/SingaporeFeatures';
import SingaporeFingers from '@/modules/Singapore/components/SingaporeFingers';
import SingaporeWorld from '@/modules/Singapore/components/SingaporeWorld';
import SingaporeLicense from '@/modules/Singapore/components/SingaporeLicense';
import SingaporeSafe from '@/modules/Singapore/components/SingaporeSafe';
import SingaporeAbout from '@/modules/Singapore/components/SingaporeAbout';
import SingaporeFaq from '@/modules/Singapore/components/SingaporeFaq';

function Singapore() {
  return (
    <Layout theme="black" dark={true}>
      <SingaporeHero />
      <SingaporeFeatures />
      <SingaporeFingers />
      <SingaporeWorld />
      <SingaporeLicense />
      <SingaporeSafe />
      <SingaporeAbout />
      <SingaporeFaq />
      <SingaporeCta />
    </Layout>
  );
}

export default Singapore;
