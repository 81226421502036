import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { TextBlock } from '@/modules/Contacts/components/ContactsHero';
import LinkWithUnderlineLine from '@/components/LinkWithUnderlineLine';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ContactsPageType } from '@/typings/model';

function ContactSales() {
  const page = useCurrentPage<ContactsPageType>();
  const salesTitle = page?.templateFields?.salesTitle;
  const email = page?.templateFields?.salesEmail;
  const text = page?.templateFields?.salesText;

  if (!page) return null;
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Title>{salesTitle}</Title>
          <StyledTextBlock>
            <StyledText>{text}</StyledText>
            <WrapperLink>
              {email && (
                <LinkWithUnderlineLine
                  href={`mailto:${email}`}
                  label={email ?? ''}
                />
              )}
            </WrapperLink>
          </StyledTextBlock>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 37px;
  position: relative;
  padding-bottom: 265px;

  ${media.tabletLarge(css`
    padding-top: 0;
    padding-bottom: 160px;
  `)}

  ${media.mobile(css`
    font-size: 78px;
    line-height: 100%;
    padding-top: 0;
    padding-bottom: 70px;
  `)}
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTextBlock = styled(TextBlock)`
  margin-left: 0;
`;

const WrapperLink = styled.div``;

const Title = styled.span`
  font-weight: bold;
  font-size: 102px;
  line-height: 104px;

  ${media.mobile(css`
    font-size: 78px;
    line-height: 100%;
  `)}

  ${media.mobile(css`
    font-size: 65px;
  `)}
`;

const StyledText = styled.p`
  max-width: 667px;
  width: 100%;
  font-weight: normal;
  font-size: 31px;
  line-height: 40px;
  margin-bottom: 33px;

  ${media.laptop(css`
    margin-top: 40px;
    font-size: 27px;
    line-height: 36px;
  `)}

  ${media.tabletLarge(css`
    max-width: 500px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 30px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 0;
  `)}
`;

export default ContactSales;
