import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { PressCenterPageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';
import LinkWithUnderlineLine from '@/components/LinkWithUnderlineLine';
import { media } from '@/utils/mixin';

function PressCenterHero() {
  const page = useCurrentPage<PressCenterPageType>();
  if (!page) return null;

  return (
    <Component>
      <ContentSmallContainer>
        <Wrapper>
          <Title>{page.title ?? ''}</Title>
          <Bottom>
            <EmailBlock>
              <EmailTitle>{page.templateFields.emailLabel ?? ''}</EmailTitle>
              <EmailValue>
                <LinkWithUnderlineLine
                  href={`mailto:${page.templateFields.emailValue}`}
                  label={page.templateFields.emailValue}
                />
              </EmailValue>
            </EmailBlock>
          </Bottom>
          <Button>
            <HeroCircleArrowButton />
          </Button>
        </Wrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  overflow: hidden;
  padding: 0 0 177px;

  ${media.laptop(css`
    padding-bottom: 120px;
  `)}

  ${media.tabletSmall(css`
    padding-bottom: 100px;
  `)}

  ${media.mobile(css`
    padding-bottom: 50px;
  `)}
`;

const Title = styled.h1`
  font-size: 236px;
  line-height: 188px;
  letter-spacing: -0.01em;
  display: block;
  margin: 0;
  white-space: nowrap;
  margin-top: 30px;
  margin-left: -20px;

  ${media.laptop(css`
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    font-size: 190px;
    line-height: 90%;
  `)}

  ${media.tabletSmall(css`
    font-size: 130px;
  `)}


  ${media.mobile(css`
    margin-top: 40px;
    font-size: 58px;
  `)}

  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 100vh;
  height: 730px;

  ${media.laptop(css`
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  `)}
`;

const Bottom = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  ${media.laptop(css`
    position: relative;
    margin-left: 12px;
    margin-top: 38px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 18px;
  `)}

  ${media.mobile(css`
    margin-left: 0;
  `)}
`;

const EmailBlock = styled.div`
  ${media.mobileMedium(css`
    display: flex;
    flex-direction: column-reverse;
  `)}
`;

const EmailTitle = styled.span`
  font-size: 25px;
  line-height: 40px;
  display: block;
  letter-spacing: -0.02em;
  font-weight: 600;

  ${media.mobile(css`
    font-size: 19px;
    line-height: 24px;
  `)}

  ${media.mobileMedium(css`
    width: 260px;
    max-width: 100%;
    margin-top: 30px;
  `)}
`;

const EmailValue = styled.div`
  margin-top: 20px;

  ${media.laptop(css`
    margin-top: 10px;
  `)}

  ${media.mobile(css`
    margin-top: 5px;
  `)}

  ${media.mobileMedium(css`
    margin-top: 0;
  `)}
`;

const Button = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;

  ${media.laptop(css`
    right: 0;
    bottom: 110px;
  `)}

  ${media.tabletSmall(css`
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  ${media.mobileSmall(css`
    margin-top: 20px;
  `)}
`;

export default PressCenterHero;
