import React from 'react';

import Layout from '@/components/Layout';

import CollectPaymentsCallToAction from './components/CollectPaymentsCallToAction';
import CollectPaymentsIntro from './components/CollectPaymentsIntro';
import CollectPaymentsHero from './components/CollectPaymentsHero';
import CollectPaymentsFeatures from './components/CollectPaymentsFeatures';
import CollectPaymentsAdvantages from './components/CollectPaymentsAdvantages';
import CollectPaymentsCustomer from './components/CollectPaymentsCustomer';

function CollectPayments() {
  return (
    <Layout theme="black" dark={true}>
      <CollectPaymentsHero />
      <CollectPaymentsIntro />
      <CollectPaymentsFeatures />
      <CollectPaymentsCustomer />
      <CollectPaymentsAdvantages />
      <CollectPaymentsCallToAction />
    </Layout>
  );
}

export default CollectPayments;
