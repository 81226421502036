import { FormLinksValuesType } from './FormLinks.types';

export function convertFormValues(values: FormLinksValuesType) {
  const result: Record<string, string | Array<string>> = {};

  Object.keys(values).forEach((fieldName) => {
    const key = fieldName as keyof FormLinksValuesType;
    const field = values[key];

    if (Array.isArray(field)) {
      result[key] = field.map((item) => item.value);
    } else {
      result[key] = field;
    }
  });

  return result;
}
