import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { styleForDoubleTitle } from '@/constants/common';
import ArrowButtonLink from '@/components/ArrowButtonLink';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';
import Picture from '@/components/Picture';

const CorebankingBackoffice = () => {
  const {
    backofficeItems,
    backofficeSubtitle,
    backofficeTitle,
    backofficeButtonLabel,
    backofficeButtonLink,
  } = useCurrentPage<CorebankingPageType>().templateFields;

  const titleWords = backofficeTitle?.trim().split(' ') || [];

  return (
    <Component>
      <ContentSmallContainer>
        {titleWords.length > 0 ? (
          <DoubleTitle
            firstTitle={titleWords[0]}
            secondTitle={titleWords.length > 1 ? titleWords[1] : ''}
            styles={styleForDoubleTitle}
          />
        ) : null}
        <Subtitle>{backofficeSubtitle}</Subtitle>
        <GridWrapper>
          <Grid>
            {backofficeItems.map((item) => (
              <Cell key={item.title}>
                <Item>
                  <ItemIcon>
                    <Picture {...convertThumbnailToPictureProps(item.icon)} />
                  </ItemIcon>
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemContent
                    dangerouslySetInnerHTML={{ __html: item.text ?? '' }}
                  />
                </Item>
              </Cell>
            ))}
          </Grid>
        </GridWrapper>
        <ArrowButtonLink
          color={colors.blue}
          label={backofficeButtonLabel}
          to={backofficeButtonLink}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  padding: 180px 0 150px;
  background: #040312;
  color: ${colors.white};

  ${media.tablet(css`
    padding: 120px 0;
  `)}

  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;

const Subtitle = styled.span`
  font-weight: 700;
  font-size: 102px;
  line-height: 102%;
  letter-spacing: -1px;
  display: block;
  margin-top: 130px;
  max-width: 1100px;

  ${media.tablet(css`
    font-size: 70px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
  `)}
`;

const GridWrapper = styled.div`
  margin: 90px 0 90px;

  ${media.tablet(css`
    margin-top: 0;
    margin-bottom: 180px;
  `)}

  ${media.mobile(css`
    margin: 50px 0;
  `)}
`;

const Grid = styled.div`
  margin-top: -200px;
  display: flex;
  flex-wrap: wrap;
  margin-right: -100px;

  ${media.tablet(css`
    margin: 0 -20px;
  `)}

  ${media.mobile(css`
    margin: 0;
    padding: 0;
    display: block;
  `)}
`;

const Cell = styled.div`
  margin-top: 220px;
  width: 33.3333%;

  ${media.tablet(css`
    margin-top: 100px;
    width: 50%;
    padding: 0 20px;
  `)}

  ${media.mobile(css`
    width: 100%;
    padding: 0;
    margin-top: 60px;
  `)}
`;

const Item = styled.div`
  max-width: 350px;

  ${media.tablet(css`
    max-width: none;
  `)}
`;

const ItemIcon = styled.div`
  height: 70px;
  margin-bottom: 30px;
  > div {
    display: block;
  }
`;

const ItemTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
`;

const ItemContent = styled.div`
  margin-top: 30px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 500;

  ${media.mobile(css`
    margin-top: 15px;
    font-size: 18px;
  `)}

  p {
    margin-top: 20px;
  }

  ul {
    margin-top: 20px;
    li {
      padding-left: 14px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 2px;
        top: 12px;
        width: 4px;
        height: 4px;
        background: ${colors.white};
        border-radius: 50%;
      }
    }
  }

  > *:first-child {
    margin-top: 0;
  }
`;

export default CorebankingBackoffice;
