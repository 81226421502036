import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';
import { ButtonNextLink } from '@/components/Button/Button';
import { StringFieldType } from '@/typings/common';
import { ButtonVariant } from '@/components/Button/Button.style';

type targetTypes = '_blank' | '_self';

enum Target {
  _BLANK = '_blank',
  _SELF = '_self',
}

type Props = {
  to?: StringFieldType;
  label?: StringFieldType;
  variant?: ButtonVariant;
  newTab?: boolean;
};

function LearnMoreButton({
  to = '/',
  label = 'Learn more',
  variant = 'black',
  newTab = false,
}: Props) {
  const target: targetTypes = newTab ? Target._BLANK : Target._SELF;

  return (
    <Component to={to} variant={variant} target={target}>
      {label} <ArrowButton />
    </Component>
  );
}

const Component = styled(ButtonNextLink)`
  svg {
    margin-left: 15px;
  }
`;

export default LearnMoreButton;
