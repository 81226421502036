import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SplitText } from 'gsap/dist/SplitText';
import gsap, { Power4 } from 'gsap';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as LogoIcon0 } from '@/assets/svg/visa.svg';
import { ReactComponent as LogoIcon8 } from '@/assets/svg/logo-icon-8.svg';
import { ReactComponent as LogoIcon5 } from '@/assets/svg/logo-icon-5.svg';
import { ReactComponent as LogoIcon16 } from '@/assets/svg/logo-icon-16.svg';
import { ReactComponent as LogoIcon6 } from '@/assets/svg/logo-icon-6.svg';
import { ReactComponent as LogoIcon7 } from '@/assets/svg/logo-icon-7.svg';
import { ReactComponent as LogoIcon11 } from '@/assets/svg/logo-icon-11.svg';
import { ReactComponent as LogoIcon13 } from '@/assets/svg/logo-icon-13.svg';
import { ReactComponent as LogoIcon14 } from '@/assets/svg/logo-icon-14.svg';
import { ReactComponent as LogoIcon15 } from '@/assets/svg/logo-icon-15.svg';
import { ReactComponent as LogoIcon10 } from '@/assets/svg/logo-icon-10.svg';
import { ReactComponent as LogoIcon12 } from '@/assets/svg/logo-icon-12.svg';
import { ReactComponent as LogoIcon3 } from '@/assets/svg/logo-icon-3.svg';
import { ReactComponent as LogoIcon2 } from '@/assets/svg/logo-icon-2.svg';
import { ReactComponent as LogoIcon4 } from '@/assets/svg/logo-icon-4.svg';
import { ReactComponent as LogoIcon1 } from '@/assets/svg/logo-icon-1.svg';
import { ReactComponent as ArrowFinalIcon } from '@/assets/svg/logo-icon-final.svg';
import { colors } from '@/constants/theme';

gsap.registerPlugin(SplitText);

const LOGO_LIST = [
  { color: '#1A1F71', Icon: LogoIcon0 },
  { color: '#000000', Icon: LogoIcon8 },
  { color: '#000000', Icon: LogoIcon5 },
  { color: '#000000', Icon: LogoIcon16 },
  { color: '#B3131B', Icon: LogoIcon6 },
  { color: '#35373F', Icon: LogoIcon7 },
  { color: '#26397E', Icon: LogoIcon11 },
  { color: '#6D6E70', Icon: LogoIcon13 },
  { color: '#036CB5', Icon: LogoIcon14 },
  { color: '#F2A83C', Icon: LogoIcon15 },
  { color: '#000000', Icon: LogoIcon10 },
  { color: '#000000', Icon: LogoIcon12 },
  { color: '#000000', Icon: LogoIcon3 },
  { color: '#000000', Icon: LogoIcon2 },
  { color: '#000000', Icon: LogoIcon4 },
  { color: '#000000', Icon: LogoIcon1 },
  { color: colors.black, Icon: ArrowFinalIcon },
];

function HomePaymentMethods() {
  const containerRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const iconRef = useRef<HTMLDivElement>(null);
  const iconInnerRef = useRef<HTMLDivElement>(null);

  const [activeLogoIcon, setActiveLogoIcon] = useState(0);
  const [isLastIcon, setIsLastIcon] = useState(false);
  const ActiveLogoIcon = LOGO_LIST[activeLogoIcon].Icon;

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '15%',
        },
        0
      ).to(
        titleRef.current,
        {
          ease: 'linear',
          y: '-28%',
        },
        0
      );
    });

    return () => {
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    let generalTl: gsap.core.Timeline;
    let logoButtonTl: gsap.core.Timeline;

    const splitText = new SplitText(textRef.current, {
      type: 'chars, words',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      logoButtonTl = gsap.timeline();

      gsap.set(iconRef.current, {
        background: LOGO_LIST[0].color,
      });

      LOGO_LIST.slice(1, -1).forEach((logo, index) => {
        logoButtonTl.to(iconRef.current, {
          onStart: () => {
            setActiveLogoIcon(index + 1);
            if (index === LOGO_LIST.length - 3) {
              setIsLastIcon(true);
            }
          },
          background: logo.color,
          duration: 0.1,
          delay: 0.2,
        });
      });

      logoButtonTl
        .set(iconInnerRef.current, {
          rotate: -90,
          delay: 0.2,
          scale: 0,
        })
        .to(iconRef.current, {
          onStart: () => setActiveLogoIcon(LOGO_LIST.length - 1),
          background: LOGO_LIST[LOGO_LIST.length - 1].color,
          duration: 0.1,
          delay: 0.2,
        })
        .to(iconInnerRef.current, {
          ease: Power4.easeOut,
          duration: 0.8,
          delay: 0.2,
          rotate: 0,
          scale: 1,
        });

      generalTl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .from(
          bgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            duration: 2.2,
            delay: 0.2,
            scaleY: 0,
          },
          0
        )
        .from(
          splitText.chars,
          {
            stagger: 0.05,
            ease: 'customEaseInOut',
            duration: 0.6,
            opacity: 0,
            delay: 0.2,
          },
          0
        )
        .from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.2,
            delay: 0.2,
            scale: 0,
          },
          1.5
        )
        .add(logoButtonTl);
    });

    return () => {
      splitText?.revert();
      logoButtonTl?.kill();
      generalTl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Title>
          <TitleBackground ref={bgRef} />
          <Inner ref={titleRef}>
            <Inner ref={textRef}>Unified account for all payment methods</Inner>
            <Icon ref={iconRef}>
              <IconInner ref={iconInnerRef} isLast={isLastIcon}>
                <ActiveLogoIcon />
              </IconInner>
            </Icon>
          </Inner>
        </Title>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 97px 0 200px;
  color: #fff;
  background: #fff;
  overflow: hidden;

  ${media.tabletSmall(css`
    padding: 80px 0 160px;
  `)}

  ${media.mobile(css`
    padding: 50px 0 120px;
  `)}
`;

const Icon = styled.div`
  position: absolute;
  right: 15px;
  bottom: -15px;
  width: 219px;
  height: 219px;
  border-radius: 100%;
  background: ${colors.black};

  @media (max-width: 1599px) {
    right: 55px;
    bottom: -75px;
  }

  ${media.laptop(css`
    right: 0;
    bottom: -15px;
    width: 129px;
    height: 129px;
  `)}

  ${media.tabletLarge(css`
    width: 109px;
    height: 109px;
  `)}
  
  ${media.tabletSmall(css`
    width: 92px;
    height: 92px;
  `)}
  
  ${media.mobile(css`
    bottom: -20px;
    transform: translateY(100%);
  `)}
`;

const IconInner = styled.div<{ isLast: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  svg {
    width: ${(props) => (props.isLast ? '40%' : '57%')};
    height: 70%;
  }
`;

const Title = styled.h2`
  color: #040312;
  font-weight: bold;
  font-size: 198px;
  letter-spacing: -0.01em;
  line-height: 224px;
  position: relative;
  padding-left: 130px;
  max-width: 1250px;

  @media (max-width: 1599px) {
    max-width: 1150px;
    font-size: 168px;
    line-height: 174px;
  }

  ${media.laptop(css`
    max-width: 830px;
    padding-left: 200px;
    font-size: 98px;
    line-height: 114px;
  `)}

  ${media.tabletLarge(css`
    max-width: 720px;
    padding-left: 160px;
    font-size: 88px;
    line-height: 94px;
  `)}
  
  ${media.tabletSmall(css`
    max-width: 640px;
    padding-left: 0;
    font-size: 68px;
    line-height: 74px;
  `)}
  
  ${media.mobile(css`
    padding-right: 15%;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 58px;
    line-height: 64px;
  `)}
`;

const TitleBackground = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  bottom: 90px;
  width: 1552px;
  background: #fafafa;
  z-index: 0;

  ${media.laptop(css`
    top: 60px;
    bottom: 60px;
    width: calc(100% + 350px);
  `)}

  ${media.tabletLarge(css`
    width: calc(100% + 250px);
  `)}
  
  ${media.tabletSmall(css`
    top: 40px;
    bottom: 40px;
    margin: 0 -30px;
  `)}
  
  ${media.tabletSmall(css`
    top: 40px;
    bottom: 40px;
    margin: 0 -30px;
  `)}
  
  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const Inner = styled.span`
  position: relative;
  display: block;
  z-index: 1;
`;

export default HomePaymentMethods;
