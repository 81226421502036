import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import FeaturesGrid from '@/components/FeaturesGrid';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

const CollectPaymentsAdvantages = () => {
  const { advantagesItems, advantagesTitle } =
    useCurrentPage<CollectPaymentsPageType>().templateFields;
  return (
    <Component>
      <ContentSmallContainer>
        <Title>{advantagesTitle}</Title>
        <Grid
          items={advantagesItems.map((item) => {
            return {
              icon: item.icon?.url || '',
              title: item.title || '',
              text: item.text || '',
            };
          })}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 104px;
  color: #040312;

  ${media.laptop(css`
    font-size: 62px;
    line-height: 64px;
  `)}

  ${media.tabletLarge(css`
    font-size: 54px;
    line-height: 60px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
    line-height: 60px;
    margin-top: 30px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 42px;
    line-height: 42px;
  `)}
`;

const Grid = styled(FeaturesGrid)`
  margin-top: 80px;
`;

export default CollectPaymentsAdvantages;
