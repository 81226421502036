import React, { useState } from 'react';

import { colors } from '@/constants/theme';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import { CorebankingPageType } from '@/typings/model';
import Layout from '@/components/Layout';
import useCurrentPage from '@/hooks/useCurrentPage';
import CorebankingHero from '@/modules/Corebanking/components/CorebankingHero';
import CorebankingIntegration from '@/modules/Corebanking/components/CorebankingIntegration';
import CorebankingDiagram from '@/modules/Corebanking/components/CorebankingDiagram';
import CorebankingText from '@/modules/Corebanking/components/CorebankingText';
import CorebankingBackoffice from '@/modules/Corebanking/components/CorebankingBackoffice';
import CorebankingPlatform from '@/modules/Corebanking/components/CorebankingPlatform';
import GetStarted from '@/components/GetStarted';

function Corebanking() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());
  const { ctaOpenInNewTab } =
    useCurrentPage<CorebankingPageType>().templateFields;

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout
      theme="black"
      dark={true}
      onPreloaderOut={handlePreloaderOut}
      footerNoCta={true}
    >
      <CorebankingHero animate={isPreloaderOut} />
      <CorebankingIntegration />
      <CorebankingPlatform />
      <CorebankingBackoffice />
      <CorebankingDiagram />
      <CorebankingText />

      <GetStarted
        color={colors.blue}
        openInNewTab={ctaOpenInNewTab}
        hoverColor={colors.blueHover}
        isTextLight={true}
      >
        Contact us
      </GetStarted>
    </Layout>
  );
}

export default Corebanking;
