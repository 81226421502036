import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';

const CorebankingDiagram = () => {
  const { diagramImage, diagramTitle } =
    useCurrentPage<CorebankingPageType>().templateFields;
  return (
    <Component>
      <ContentSmallContainer>
        <Title>{diagramTitle}</Title>
        {diagramImage ? (
          <Image>
            <Picture {...convertThumbnailToPictureProps(diagramImage)} />
          </Image>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  padding: 240px 0;
  background: #f6f6f6;

  ${media.tablet(css`
    padding: 160px 0;
  `)}

  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 102%;
  color: #040312;
  max-width: 920px;

  ${media.tablet(css`
    font-size: 80px;
  `)}

  ${media.mobile(css`
    font-size: 60px;
  `)}
`;

const Image = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 60px;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

export default CorebankingDiagram;
