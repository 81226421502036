import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import { styleForDoubleTitle } from '@/constants/common';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import Picture from '@/components/Picture';
import { ButtonNextLink } from '@/components/Button/Button';
import { ReactComponent as ButtonArrowSvg } from '@/assets/svg/button-arrow.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ReallyInstantPageType } from '@/typings/model';

const ReallyInstantWhy = () => {
  const {
    whyText,
    whyItems,
    whyTitle,
    whyButtonLabel,
    whyButtonLink,
    whySubtitleFirst,
    whySubtitleSecond,
  } = useCurrentPage<ReallyInstantPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <DoubleTitle
          firstTitle={whySubtitleFirst || ''}
          secondTitle={whySubtitleSecond || ''}
          styles={styleForDoubleTitle}
        />
        <Title>{whyTitle}</Title>
        <Text>{nl2br(whyText)}</Text>
        {whyItems ? (
          <Grid>
            {whyItems.map((item) => (
              <Item>
                <ItemIcon>
                  <Picture {...convertThumbnailToPictureProps(item.icon)} />
                </ItemIcon>
                <ItemTitle>{item.title}</ItemTitle>
                <ItemText>{nl2br(item.text)}</ItemText>
              </Item>
            ))}
          </Grid>
        ) : null}
        <ButtonRow>
          <ButtonNextLink to={whyButtonLink} variant="whiteRounded">
            {whyButtonLabel} <ButtonArrowSvg />
          </ButtonNextLink>
        </ButtonRow>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  padding: 180px 0;

  ${media.tablet(css`
    padding: 120px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  color: #ffffff;
  margin-top: 130px;

  ${media.tablet(css`
    font-size: 60px;
    line-height: 65px;
    margin-top: 80px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
    line-height: 40px;
    margin-top: 60px;
  `)}
`;

const Text = styled.p`
  margin-top: 50px;
  display: block;
  max-width: 1057px;
  font-weight: 700;
  letter-spacing: -1px;
  color: #c2c2c8;

  p:not(:first-child) {
    margin-top: 15px;
  }

  font-size: 72px;
  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}

  ${media.tabletSmall(css`
    max-width: 500px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 0px;
    flex-wrap: wrap;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
    display: block;
  `)}
`;

const Item = styled.div`
  color: #fff;
  max-width: 350px;
  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
    &:not(:first-child) {
      margin-top: 40px;
    }
  `)}
`;

const ItemTitle = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  margin: 30px 0;
  display: block;
`;

const ItemIcon = styled.div`
  display: inline-flex;
`;

const ItemText = styled.div`
  font-size: 22px;
  line-height: 32px;
`;

const ButtonRow = styled.div`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

export default ReallyInstantWhy;
