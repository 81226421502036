import React from 'react';

import { nl2br } from '@tager/web-core';

import GetStarted from '@/components/GetStarted';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ReallyInstantPageType } from '@/typings/model';

const CollectPaymentsCallToAction = () => {
  const { ctaLink, ctaTitle, ctaOpenInNewTab } =
    useCurrentPage<ReallyInstantPageType>().templateFields;

  return (
    <GetStarted
      link={ctaLink || ''}
      openInNewTab={ctaOpenInNewTab}
      color="#0DDF7A"
      hoverColor="#0DDF7A"
      isBig={true}
      isTextLight={false}
      buttonPosition={'bottom'}
    >
      {nl2br(ctaTitle)}
    </GetStarted>
  );
};

export default CollectPaymentsCallToAction;
