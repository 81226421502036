import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ContentDoubleTitle from '@/modules/Culture/components/ContentDoubleTitle';
import Text from '@/modules/Culture/components/Text';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import CultureSimple from '@/modules/Culture/components/CultureSimple';

const styles = {
  firstTitle: {
    fontSize: '282px',
  },
  secondTitle: {
    fontSize: '282px',
  },
};

function CultureSimpleBlock() {
  return (
    <Component>
      <ContentDoubleTitle
        firstTitle={'pure &'}
        secondTitle={'simple'}
        styles={styles}
      />
      <ContentSmallContainer>
        <TextContainer>
          <TextRow>
            <Text
              textBold={
                'We strongly believe that cool things should be clear and simple.'
              }
              text={
                'At the same time everything we do should be aesthetically assessed in every single way.'
              }
              arrow={false}
              textBoldColor={colors.black}
              textColor={colors.textGray}
            />
          </TextRow>
        </TextContainer>
      </ContentSmallContainer>
      <CultureSimple />
    </Component>
  );
}

const Component = styled.section`
  padding: 165px 0 185px;
  background: ${colors.acquiringPrimary};
  color: ${colors.black};
  margin-top: 8px;
  margin-bottom: 20px;

  ${media.laptop(css`
    margin-bottom: 0;
    padding: 125px 0 140px;
  `)}
  ${media.tabletLarge(css`
    padding: 105px 0 120px;
  `)}
  ${media.tabletLarge(css`
    padding: 85px 0 100px;
  `)}
  ${media.mobile(css`
    padding: 65px 0 80px;
  `)}
`;

const TextRow = styled.div`
  margin-top: 152px;
  margin-left: -3px;

  ${media.laptop(css`
    margin-top: 90px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    margin-top: 70px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 50px;
  `)}
`;

const TextContainer = styled.div`
  max-width: 991px;
  width: 100%;
`;

export default CultureSimpleBlock;
