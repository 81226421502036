import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Linear } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Draggable } from 'gsap/dist/Draggable';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';

import { generateNumberArray, Nullable, useMedia } from '@tager/web-core';
import { ThumbnailType } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import { getElemOuterWidth } from '@/utils/common';
import { breakpoints } from '@/constants/theme';

import SimplicityItem from './SimplicityItem';

gsap.registerPlugin(ScrollTrigger, InertiaPlugin);

type Props = {
  setSlideElem: (slideElem: HTMLDivElement) => void;
  carouselDuration?: number;
  slides: Array<{
    num: string;
    color?: string;
    icon: string | ThumbnailType | null;
    title: string;
    text?: string;
  }>;
};

function InfinitySlider({
  setSlideElem,
  carouselDuration = 10,
  slides,
}: Props) {
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const cellRef = useRef<HTMLDivElement>(null);
  const itemRef = useRef<Nullable<HTMLDivElement>>(null);
  const groupRef = useRef<HTMLDivElement>(null);

  const isTabletLayout = useMedia(
    `(max-width: ${breakpoints.tabletLarge - 1}px)`
  );

  function setInitSliderInnerOffset() {
    if (!groupRef.current) return;

    gsap.set(innerRef.current, {
      x: -groupRef.current.offsetWidth,
    });
  }

  useEffect(() => {
    if (isTabletLayout) return;

    const innerElem = innerRef.current;
    const groupElem = groupRef.current;
    const containerElem = containerRef.current;

    if (!groupElem) return;
    if (!innerElem) return;
    if (!containerElem) return;

    let tw: gsap.core.Tween;
    let twWrapper: gsap.core.Tween;

    setInitSliderInnerOffset();

    const initAnimations = gsap.delayedCall(0, () => {
      tw = gsap
        .to(innerElem, {
          x: () => {
            setInitSliderInnerOffset();
            return -groupElem.offsetWidth * 2;
          },
          repeat: -1,
          ease: 'linear',
          duration: carouselDuration,
          scrollTrigger: {
            id: 'slider',
            scroller: 'body',
            trigger: containerElem,
            invalidateOnRefresh: true,
            onLeaveBack: () => tw?.pause(),
            onLeave: () => tw?.pause(),
            onEnterBack: () => tw?.play(),
            onEnter: () => tw?.play(),
          },
        })
        .timeScale(0);

      twWrapper = gsap
        .to(tw, {
          duration: 1.5,
          ease: Linear.easeNone,
          timeScale: 1,
          paused: true,
        })
        .play();
    });

    innerElem.addEventListener('mouseenter', handleSliderInnerEnter);

    function handleSliderInnerEnter() {
      twWrapper.reverse();
    }

    innerElem.addEventListener('mouseleave', handleSliderInnerLeave);

    function handleSliderInnerLeave() {
      twWrapper.play();
    }

    return () => {
      initAnimations.kill();
      innerElem.removeEventListener('mouseenter', handleSliderInnerEnter);
      innerElem.removeEventListener('mouseleave', handleSliderInnerLeave);
      ScrollTrigger.getById('slider')?.kill();
      twWrapper?.kill();
      tw?.kill();
    };
  }, [isTabletLayout]);

  useEffect(() => {
    if (!isTabletLayout) return;

    const cellElem = cellRef.current;
    const innerElem = innerRef.current;
    const groupElem = groupRef.current;
    const containerElem = containerRef.current;

    if (!cellElem) return;
    if (!groupElem) return;
    if (!innerElem) return;
    if (!containerElem) return;

    let dr: Draggable[] = [];

    gsap.registerPlugin(Draggable);

    const proxy = document.createElement('div');
    let progressWrap = gsap.utils.wrap(
      -groupElem.offsetWidth,
      -groupElem.offsetWidth * 2
    );

    gsap.delayedCall(0, () => {
      dr = Draggable.create(proxy, {
        onDrag: updateProgress,
        onThrowUpdate: updateProgress,
        throwProps: true,
        trigger: containerElem,
        inertia: true,
        type: 'x',
        snap: {
          x: (x) => {
            const elemOuterWidth = getElemOuterWidth(cellElem);
            return Math.round(x / elemOuterWidth) * elemOuterWidth;
          },
        },
      });
    });

    window.addEventListener('resize', handleResize);

    function handleResize() {
      const groupElem = groupRef.current;
      if (!groupElem) return;

      dr[0]?.update();
      progressWrap = gsap.utils.wrap(
        -groupElem.offsetWidth,
        -groupElem.offsetWidth * 2
      );
    }

    function updateProgress(this: any) {
      const groupElem = groupRef.current;
      if (!groupElem) return;

      gsap.set(innerElem, {
        x: progressWrap(this.x),
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      dr[0]?.kill();
    };
  }, [isTabletLayout]);

  return (
    <Container ref={containerRef}>
      <CarouselInner ref={innerRef}>
        {generateNumberArray(3).map((_, parentIndex) => (
          <CarouselGroup ref={groupRef} key={parentIndex}>
            {slides.map((slide, index) => {
              function setSlideRef(elem: HTMLDivElement) {
                itemRef.current = elem;
                setSlideElem(elem);
              }

              return (
                <Cell ref={cellRef} key={`${parentIndex}${index}`}>
                  <SimplicityItem
                    id={`${parentIndex}${index}`}
                    ref={setSlideRef}
                    num={slide.num}
                    color={slide.color ?? '#F6F6F6'}
                    icon={slide.icon}
                    title={slide.title}
                    text={slide.text}
                  />
                </Cell>
              );
            })}
          </CarouselGroup>
        ))}
      </CarouselInner>
    </Container>
  );
}

export const Container = styled.div`
  position: relative;
  display: flex;
  margin-top: 126px;
  overflow: visible;

  ${media.laptop(css`
    margin-top: 60px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    padding-left: 0;
  `)}

  ${media.mobile(css`
    margin-left: -20px;
    margin-right: -20px;
  `)}
`;

const CarouselInner = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const CarouselGroup = styled.div`
  display: flex;
  flex-shrink: 0;
  padding: 0 64px;
  background: #ffffff;

  ${media.laptop(css`
    padding: 0 32px;
  `)}

  ${media.tabletLarge(css`
    padding: 0 22px;
  `)}

  ${media.tabletSmall(css`
    padding: 0 12px;
  `)}

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const Cell = styled.div`
  flex-shrink: 0;

  &:not(:first-child) {
    margin-left: 48px;
  }

  ${media.laptop(css`
    &:not(:first-child) {
      margin-left: 48px;
    }
  `)}

  ${media.tabletLarge(css`
    &:not(:first-child) {
      margin-left: 44px;
    }
  `)}

  ${media.tabletSmall(css`
    &:not(:first-child) {
      margin-left: 24px;
    }
  `)}

  ${media.mobile(css`
    &:not(:first-child) {
      margin-left: 0;
    }
  `)}
`;

export default InfinitySlider;
