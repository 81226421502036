import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

const SingaporePricingNavigation = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Item>
            <ItemLink to="/xpate-sg">xpate SG</ItemLink>
          </Item>
          <Item>
            <ItemLink to="/xpate-sg#faq">FAQ</ItemLink>
          </Item>
          <Item>
            <ItemActive>Prices</ItemActive>
          </Item>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 108px 0 45px;
`;

const Inner = styled.ul`
  border-top: 1px solid rgba(4, 3, 18, 0.3);
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  ${media.mobile(css`
    display: none;
  `)}
`;

const Item = styled.li`
  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const ItemLink = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #040312;
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.5;
  }
`;

const ItemActive = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #040312;
  opacity: 0.5;
`;

export default SingaporePricingNavigation;
