import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';
import { colors } from '@/constants/theme';

const PricingPrices = () => {
  const { pricesSections, pricesTitle } =
    useCurrentPage<PricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{pricesTitle}</Title>
        <Inner>
          {pricesSections?.map((section) => (
            <Section>
              <SectionTitle>
                {section.icon?.url ? (
                  <SectionIcon>
                    <img src={section.icon.url} />
                  </SectionIcon>
                ) : null}
                {section.title}
              </SectionTitle>
              {section.items.map((item, index) => (
                <Row>
                  <Left>
                    <Name
                      dangerouslySetInnerHTML={{ __html: item.name ?? '' }}
                    />
                    <Text
                      dangerouslySetInnerHTML={{
                        __html: item.description ?? '',
                      }}
                    />
                    {item.icons ? (
                      <Icons>
                        {item.icons.map((icon) => (
                          <img src={icon.url || ''} />
                        ))}
                      </Icons>
                    ) : null}
                  </Left>
                  <Right>
                    <RightItem>
                      {index > 0 ? (
                        <RightItemMobile>
                          {section.items[0].price}
                        </RightItemMobile>
                      ) : null}
                      <Price
                        dangerouslySetInnerHTML={{ __html: item.price ?? '' }}
                      />
                      <PriceText>{nl2br(item.priceText)}</PriceText>
                    </RightItem>
                    {item.price2 ? (
                      <RightItem>
                        {index > 0 ? (
                          <RightItemMobile>
                            {section.items[0].price2}
                          </RightItemMobile>
                        ) : null}
                        <Price
                          dangerouslySetInnerHTML={{
                            __html: item.price2 ?? '',
                          }}
                        />
                        <PriceText>{nl2br(item.price2Text)}</PriceText>
                      </RightItem>
                    ) : null}
                  </Right>
                </Row>
              ))}
            </Section>
          ))}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  color: ${colors.black};
  margin: 180px 0;
  ${media.laptop(css`
    margin: 150px 0;
  `)}
  ${media.tablet(css`
    margin: 100px 0;
  `)}
  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  width: 958px;
  max-width: 100%;
  margin: 0 auto;
  ${media.tablet(css`
    font-size: 50px;
  `)}
  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const Inner = styled.div`
  background: #f6f6f6;
  margin-top: 70px;

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const Section = styled.div`
  padding: 70px 100px 70px 116px;

  ${media.tablet(css`
    padding: 50px 50px 50px 100px;
  `)}

  ${media.mobile(css`
    padding: 30px;
  `)}
`;

const SectionTitle = styled.span`
  position: relative;
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  display: block;

  ${media.mobile(css`
    font-size: 24px;
  `)}
`;

const SectionIcon = styled.div`
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translate(-100%, -50%);

  ${media.mobile(css`
    position: static;
    transform: none;
    left: auto;
    top: auto;
    margin-bottom: 20px;
  `)}
`;

const Row = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    margin-top: 50px;
    display: block;
  `)}
`;

const Left = styled.div`
  width: 50%;

  ${media.tablet(css`
    width: 100%;
  `)}
`;

const Right = styled.div`
  display: flex;
  ${media.mobile(css`
    display: block;
  `)}
`;

const RightItem = styled.div`
  width: 250px;
  margin-left: 30px;

  ${media.tablet(css`
    width: 100%;
    margin-top: 20px;
    margin-left: 0;
  `)}
`;

const RightItemMobile = styled.span`
  display: none;
  ${media.mobile(css`
    display: block;
    font-size: 18px;
    color: #555;
    margin-bottom: 10px;
  `)}
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  display: block;

  ${media.mobile(css`
    font-size: 24px;
  `)}
`;

const Text = styled.p`
  display: block;
  margin-top: 10px;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const Price = styled.span`
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  display: block;
  ${media.mobile(css`
    font-size: 20px;
  `)}
`;

const PriceText = styled.p`
  margin-top: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const Icons = styled.div`
  margin-top: 10px;
  display: flex;

  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

export default PricingPrices;
