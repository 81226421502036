import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import ContentContainer from '@/components/ContentContainer';
import Circles from '@/modules/Culture/components/Circles';
import { media } from '@/utils/mixin';

import ContentTitle from './ContentTitle';
import ContentDoubleTitle from './ContentDoubleTitle';
import Text from './Text';

type Props = {
  id: string;
  textWidth: number;
  title?: string;
  firstTitle?: string;
  secondTitle?: string;
  textBold: string;
  text: string;
  items: string[];
};

function ContentBlock({
  id,
  textWidth,
  title,
  firstTitle,
  secondTitle,
  textBold,
  text,
  items,
}: Props) {
  return (
    <Component>
      {firstTitle && secondTitle ? (
        <ContentContainer>
          <ContentDoubleTitle
            firstTitle={firstTitle}
            secondTitle={secondTitle}
          />
        </ContentContainer>
      ) : null}
      {title ? (
        <ContentSmallContainer>
          <ContentTitle title={title} />
        </ContentSmallContainer>
      ) : null}
      <ContentSmallContainer>
        <TextRow>
          <Text
            width={textWidth}
            arrow={true}
            textBold={textBold}
            text={text}
          />
        </TextRow>
      </ContentSmallContainer>
      <CirclesRow>
        <ContentSmallContainer>
          <Circles id={id} items={items} />
        </ContentSmallContainer>
      </CirclesRow>
    </Component>
  );
}

const Component = styled.section`
  margin: 95px 0 230px;
  overflow: hidden;

  ${media.laptop(css`
    margin: 95px 0 130px;
  `)}

  ${media.tabletSmall(css`
    margin: 75px 0 100px;
  `)}
  
  ${media.mobile(css`
    margin: 55px 0;
  `)}
`;

const TextRow = styled.div`
  margin-top: 152px;
  margin-left: -3px;

  ${media.laptop(css`
    margin-top: 90px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    margin-top: 70px;
  `)}
  
  ${media.tabletSmall(css`
    margin-top: 50px;
  `)}
`;

const CirclesRow = styled.div`
  margin-top: 90px;
  overflow: hidden;

  ${media.tabletSmall(css`
    margin-top: 70px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

export default ContentBlock;
