import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import LearnMoreButton from '@/components/LearnMoreButton';
import mastercardImg from '@/assets/images/mastercard.svg';
import visaImg from '@/assets/images/visa.svg';

gsap.registerPlugin(SplitText);

type Props = {
  items: Array<{
    imgSrc: string;
    imgSrc2x: string;
    title: string;
    text?: string;
    buttonLabel?: string;
    buttonLink?: string;
    buttonNewTab?: boolean;
  }>;
};

function Features({ items }: Props) {
  const section1 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageCoverRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    labelRef: useRef<HTMLSpanElement>(null),
    titleRef: useRef<HTMLDivElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
    buttonRef: useRef<HTMLDivElement>(null),
  };

  const section2 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageCoverRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    labelRef: useRef<HTMLSpanElement>(null),
    titleRef: useRef<HTMLDivElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
    buttonRef: useRef<HTMLDivElement>(null),
  };

  const section3 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageCoverRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    labelRef: useRef<HTMLSpanElement>(null),
    titleRef: useRef<HTMLDivElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
    buttonRef: useRef<HTMLDivElement>(null),
  };

  const refs = [section1, section2, section3];

  useEffect(() => {
    const twList: Array<gsap.core.Tween> = [];
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      refs.forEach((section, index) => {
        const { containerRef } = section;
        if (!containerRef.current) return;

        const { imageRef } = section;
        twList[index] = gsap.to(imageRef.current, {
          y: '20%',
          ease: 'linear',
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 32%',
            scrub: true,
          },
        });

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 82%',
          },
        });

        const { imageCoverRef } = section;
        tlList[index].fromTo(
          imageCoverRef.current,
          {
            scaleY: 1,
          },
          {
            ease: 'customEaseInOut',
            transformOrigin: '10% 0',
            duration: 1.85,
            scaleY: 0,
          },
          0
        );

        const { titleRef } = section;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.45,
            delay: 0.3,
          },
          0
        );

        const { textRef } = section;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            delay: 0.2,
            y: 20,
          },
          1
        );

        const { buttonRef } = section;
        tlList[index].from(
          buttonRef.current,
          {
            ease: Power2.easeOut,
            duration: 0.7,
            opacity: 0,
            delay: 0.4,
            y: 20,
          },
          1
        );

        const { labelRef } = section;
        tlList[index].from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.25,
            delay: 0.2,
          },
          1
        );
      });
    });

    return () => {
      twList.forEach((tw) => tw?.kill());
      tlList.forEach((tl) => tl?.kill());
    };
  }, []);

  return (
    <Component>
      <ContentContainer>
        {items.map((item, index) => (
          <Section ref={refs[index].containerRef}>
            <Image>
              <ImageContainer>
                <div ref={refs[index].imageRef}>
                  <Picture
                    src={item.imgSrc}
                    src2x={item.imgSrc2x}
                    alt={item.title}
                  />
                </div>
              </ImageContainer>
              <ImageCover ref={refs[index].imageCoverRef} />
            </Image>
            <Content>
              <Title ref={refs[index].titleRef}>{item.title}</Title>
              {item.text ? (
                <Text
                  ref={refs[index].textRef}
                  dangerouslySetInnerHTML={{
                    __html: item.text
                      .replaceAll(':visa', `<img src="${visaImg}"/>`)
                      .replaceAll(
                        ':mastercard',
                        `<img src="${mastercardImg}"/>`
                      ),
                  }}
                />
              ) : null}
              {item.buttonLabel ? (
                <Bottom>
                  <ButtonContainer ref={section1.buttonRef}>
                    <LearnMoreButton
                      to={item.buttonLink}
                      newTab={item.buttonNewTab}
                    />
                  </ButtonContainer>
                </Bottom>
              ) : null}
            </Content>
          </Section>
        ))}
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  color: #040312;
  margin: 180px 0 140px;

  ${media.tabletSmall(css`
    margin: 90px 0;
  `)}
  ${media.mobile(css`
    margin: 45px 0;
  `)}
`;

const Content = styled.div`
  width: 100%;

  ${media.tabletSmall(css`
    padding: 0;
  `)}

  >* {
    width: 100%;
    max-width: 650px;

    ${media.laptop(css`
      max-width: 560px;
    `)}
  }
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 180px;
  gap: 150px;

  ${media.laptop(css`
    gap: 80px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 80px;
    gap: 20px;
  `)}
  
  
  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n + 1) {
    ${media.laptopOnly(css`
      padding-right: 80px;
    `)}
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    ${Content} {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      ${media.tabletSmall(css`
        display: block;
      `)}
    }
  }

  ${media.laptop(css`
    &:nth-child(2n) {
      ${Content} {
        padding-right: 10%;
        padding-left: 5%;
      }
    }
  `)}

  ${media.tabletSmall(css`
    flex-direction: column;
    width: 100%;
    padding: 0;

    &:nth-child(2n) {
      flex-direction: column;

      ${Content} {
        padding: 0;
      }
    }
  `)}
  
  ${media.mobile(css`
    margin-top: 54px;
  `)}
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    position: relative;
    max-width: 97%;
    max-height: 97%;
  }

  ${media.tabletSmall(css`
    top: 50%;
    transform: translate(-50%, -50%) !important;
    img {
      height: 250px;
    }
  `)}
`;

const Image = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 50%;
  max-width: 700px;
  max-height: 700px;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    background: #fafafa;
  }

  ${ImageContainer} {
    left: 50%;
    transform: translateX(-50%);
  }

  ${media.tabletSmall(css`
    width: 100%;
    margin-bottom: 30px;
    max-width: 100%;
    max-height: none;

    &:before {
      padding-top: 50%;
      margin: 0 -20px;
    }
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
  
  ${media.mobileLarge(css`
    &:before {
      padding-top: 100%;
    }
  `)}
`;

const ImageCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 1;
`;

const Title = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  font-weight: bold;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 74px;
    line-height: 90%;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 54px;
  `)}
`;

const Text = styled.div`
  display: block;
  margin-top: 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  margin-left: 5px;

  img {
    max-height: 22px;
  }

  ${media.laptop(css`
    margin-top: 30px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
  
  p, ul {
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
  }

  li {
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    position: relative;
    padding-left: 30px;

    &:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #000;
      position: absolute;
      left: 12px;
      top: 12px;
    }

    img {
      display: inline-block;
    }

    .mc-image {
      transform: translateY(10px);
    }
  }
`;

const Bottom = styled.div`
  margin-top: 40px;
  margin-left: 5px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const ButtonContainer = styled.div`
  display: inline-block;

  button {
    padding: 0 26px 0 29px;
  }
`;

export default Features;
