import React from 'react';

import Layout from '@/components/Layout';
import PricingCta from '@/modules/Pricing/components/PricingCta';
import PricingMain from '@/modules/Pricing/components/PricingMain';
import PricingIncluded from '@/modules/Pricing/components/PricingIncluded';
import PricingPrices from '@/modules/Pricing/components/PricingPrices';
import PricingTariffs from '@/modules/Pricing/components/PricingTariffs';

function Pricing() {
  return (
    <Layout theme="white" dark={false}>
      <PricingMain />
      <PricingIncluded />
      <PricingPrices />
      <PricingTariffs />
      <PricingCta />
    </Layout>
  );
}

export default Pricing;
