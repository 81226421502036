import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import Adv01 from '@/assets/images/acquiring/adv_01.svg';
import Adv02 from '@/assets/images/acquiring/adv_02.svg';
import Adv03 from '@/assets/images/acquiring/adv_03.svg';
import { ReactComponent as ArrowLinkSvg } from '@/assets/svg/arrow_link.svg';
import Link from '@/components/Link';

gsap.registerPlugin(ScrollTrigger, SplitText);

const ITEM_LIST = [
  {
    num: '01',
    image: Adv01,
    title: 'Worldwide card acceptance',
    text: `If it’s a global name, we work with them. With xpate you can accept online payments with Visa, Mastercard in EUR, USD and KZT.`,
  },
  {
    num: '02',
    image: Adv02,
    title: 'Complete payment security',
    text: `Our payment security is second-to-none: we’re compliant to PCI DSS Level 1, while online card payments are 3D secure.`,
  },
  {
    num: '03',
    image: Adv03,
    title: 'A global platform, a local vision',
    text: `We support a huge range of APMs for your customers, while our comprehensive local acquirers’ network means you save on interchange fees.`,
  },
];

function AcquiringAdvantages() {
  const titleRef = useRef<HTMLHeadingElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: ITEM_LIST.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        bgRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tw = gsap.from(splitTitleChild.lines, {
        stagger: 0.15,
        ease: 'customEaseInOut',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { bgRef } = cell;
        tlList[index].from(
          bgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 5,
            duration: 1.65,
            scaleY: 0,
          },
          0
        );

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            delay: index / 8,
            duration: 1.45,
            scale: 0,
          },
          0.3
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            duration: 1.45,
            yPercent: 100,
          },
          0.5
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            delay: index / 8,
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1.3
        );
      });
    });

    return () => {
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component>
      <ContentSmallContainer>
        <Title ref={titleRef}>Advantages</Title>
        <Grid>
          {ITEM_LIST.map((item, index) => (
            <Cell key={index}>
              <Card ref={cellRefList[index].cardRef}>
                <CardBackground ref={cellRefList[index].bgRef} />
                <CardIcon>
                  <img ref={cellRefList[index].iconRef} src={item.image} />
                </CardIcon>
                <CardTitle ref={cellRefList[index].titleRef}>
                  {item.title}
                </CardTitle>
                <CardText
                  ref={cellRefList[index].textRef}
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
                <CardNumber>{item.num}</CardNumber>
              </Card>
            </Cell>
          ))}
          <Cell>
            <Link to="mailto:hello@xpate.com">
              <StartCardInner>
                <StartCardTitle>Contact us</StartCardTitle>
                <ArrowLinkSvg />
              </StartCardInner>
            </Link>
          </Cell>
        </Grid>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 0px;
`;

const Title = styled.h2`
  display: block;
  color: ${colors.black};
  font-size: 102px;
  line-height: 106px;
  font-weight: bold;
  margin-bottom: 82px;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-bottom: 62px;
    font-size: 74px;
    line-height: 78px;
  `)}

  ${media.tabletLarge(css`
    margin-bottom: 42px;
    font-size: 64px;
    line-height: 68px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 32px;
    font-size: 50px;
    line-height: 58px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -48px -24px 0;

  ${media.laptop(css`
    margin-top: -40px;
    margin-left: -20px;
    margin-right: -20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: -24px;
    margin-left: -12px;
    margin-right: -12px;
  `)}
`;

const Cell = styled.div`
  flex-basis: 50%;
  max-width: 100%;
  margin-top: 48px;
  padding: 0 24px;

  ${media.laptop(css`
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  `)}
  
  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Card = styled.div`
  position: relative;
  min-height: 624px;
  height: 100%;
  padding: 72px 70px 90px;
  z-index: 0;

  ${media.laptop(css`
    min-height: 460px;
    padding: 72px 50px 90px;
  `)}

  ${media.tabletLarge(css`
    min-height: 360px;
    padding: 72px 40px 90px;
  `)}
  
  ${media.tabletSmall(css`
    min-height: 340px;
    padding-top: 60px;
  `)}
  
  ${media.mobile(css`
    padding: 40px 0 90px;
  `)}
`;

const CardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.acquiringPrimary};
  z-index: -1;

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const CardIcon = styled.div`
  height: 60px;
  display: flex;
  align-items: flex-end;
`;

const CardTitle = styled.span`
  display: block;
  font-size: 30px;
  font-weight: 600;
  margin-top: 35px;
  letter-spacing: -0.01em;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 26px;
  `)}

  ${media.tabletLarge(css`
    font-size: 24px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const CardNumber = styled.span`
  position: absolute;
  right: 65px;
  bottom: 65px;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
  font-weight: 600;

  ${media.laptop(css`
    right: 45px;
    bottom: 45px;
  `)}

  ${media.mobile(css`
    right: 0;
  `)}
`;

const CardText = styled.p`
  display: block;
  margin-top: 28px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  max-width: 440px;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

const StartCardInner = styled.div`
  position: relative;
  display: block;
  min-height: 624px;
  height: 100%;
  overflow: hidden;

  svg {
    opacity: 0;
    position: absolute;
    right: 12px;
    bottom: 16px;
    transform: rotate(90deg);
    transition: 0.3s all ease;
  }

  &:hover {
    &:before {
      transform: scale(1.5);
    }
    svg {
      opacity: 1;
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 320px 320px;
    border-color: transparent transparent ${colors.black} transparent;
    transform-origin: 100% 100%;
    transition: transform 0.3s ease;
  }

  ${media.laptop(css`
    min-height: 460px;

    &:before {
      border-width: 0 0 290px 290px;
    }
  `)}

  ${media.tabletLarge(css`
    min-height: 360px;

    &:before {
      border-width: 0 0 260px 260px;
    }
  `)}
  
  ${media.tabletSmall(css`
    &:before {
      border-width: 0 0 230px 230px;
    }
  `)}
  
   ${media.tabletSmall(css`
    &:before {
      border-width: 0 0 170px 170px;
    }
  `)}
`;

const StartCardTitle = styled.span`
  font-size: 134px;
  display: block;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.01em;
  transform: translateY(-15px);
  line-height: 100%;
  padding-top: 12px;

  ${media.laptop(css`
    font-size: 94px;
  `)}

  ${media.tabletLarge(css`
    font-size: 74px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 64px;
  `)}
  
  ${media.mobile(css`
    padding-top: 20px;
    font-size: 60px;
  `)}
`;

export default AcquiringAdvantages;
