import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  color: string;
  hoverColor?: string;
  to?: string | null;
  label?: string | null;
  arrowColor?: string;
}

function ArrowLinkWithLabel({
  to,
  color,
  hoverColor,
  label,
  arrowColor,
  ...rest
}: Props) {
  return (
    <StyledLink
      styledColor={color}
      hoverColor={hoverColor ?? color}
      to={to}
      {...rest}
    >
      {label && <ButtonLabel>{label}</ButtonLabel>}
      <CircleButton color={arrowColor}>
        <ArrowSvg />
      </CircleButton>
    </StyledLink>
  );
}

export const ButtonLabel = styled.span`
  font-size: 40px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-right: 40px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 34px;
  `)}

  ${media.mobile(css`
    margin-right: 12px;
    font-size: 18px;
  `)}
`;

const CircleButton = styled.button<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 133px;
  height: 133px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
  color: inherit;
  z-index: 10;

  svg {
    height: 54px;
    color: ${(props) => (props.color ? props.color : 'inherit')};
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    width: 68px;
    height: 68px;

    svg {
      width: 31px !important;
      height: 43px !important;
    }
  `)}
`;

const StyledLink = styled(Link)<{ styledColor: string; hoverColor: string }>`
  display: flex;
  align-items: center;

  ${CircleButton} {
    background: ${(props) => props.styledColor ?? 'black'};

    &:hover {
      background: ${(props) => props.hoverColor ?? 'black'};
    }
  }
`;

export default ArrowLinkWithLabel;
