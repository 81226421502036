import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import Layout from '@/components/Layout';
import { media } from '@/utils/mixin';

function DefaultTemplate() {
  const page = useCurrentPage();

  return (
    <Layout theme="white">
      <Container>
        <h1>{page?.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: page?.body ?? '' }} />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 200px auto;
  ${media.tablet(css`
    margin: 150px 0;
  `)}
  ${media.mobile(css`
    margin: 100px 0;
  `)}
`;

export default DefaultTemplate;
