import React, { useState } from 'react';

import Layout from '@/components/Layout';
import CareersHero from '@/modules/Careers/components/CareersHero';
import CareersBeOneOfUs from '@/modules/Careers/components/CareersBeOneOfUs';
import CareersLinks from '@/modules/Careers/components/CareersLinks';
import CareersVacancies from '@/modules/Careers/components/CareersVacancies';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';

function Careers() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="white" onPreloaderOut={handlePreloaderOut}>
      <CareersHero animate={isPreloaderOut} />
      <CareersVacancies />
      <CareersBeOneOfUs />
      <CareersLinks />
    </Layout>
  );
}

export default Careers;
