import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import handImage from '@/assets/images/about/hand.png';
import { AboutPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';

gsap.registerPlugin(SplitText);

type Props = {
  animate: boolean;
};

function ContactsHero({ animate }: Props) {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const page = useCurrentPage<AboutPageType>();

  useEffect(() => {
    if (!page) return;

    let tl: gsap.core.Timeline;

    const splitTextChild = new SplitText(textRef.current, {
      linesClass: 'lineChild',
      type: 'lines',
    });
    const splitTextParent = new SplitText(textRef.current, {
      linesClass: 'lineParent',
      type: 'lines',
    });

    gsap.set(buttonRef.current, { scale: 0 });
    gsap.set(titleRef.current, { yPercent: 100 });
    gsap.set(splitTextChild.lines, { yPercent: 100 });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(
          titleRef.current,
          {
            ease: 'customEaseInOut',
            duration: 2.2,
            yPercent: 0,
          },
          0
        )
        .to(
          buttonRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.2,
            delay: 0.65,
            scale: 1,
          },
          0
        )
        .to(
          splitTextChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            duration: 1.5,
            delay: 0.65,
            yPercent: 0,
          },
          0
        );
    });

    return () => {
      splitTextParent?.revert();
      splitTextChild?.revert();
      delayedCall?.kill();
      tl?.kill();
    };
  }, [animate, page]);

  if (!page) return null;

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Wrapper>
          <BackgroundBlack>
            <Image>
              <img
                src={handImage}
                alt={page.templateFields.introText || undefined}
              />
            </Image>
          </BackgroundBlack>
          <ContentBlock>
            <TitleWrapper>
              <Title ref={titleRef}>{page.title}</Title>
            </TitleWrapper>
            <StyledWrapper>
              <HeroCircleArrowButton ref={buttonRef} />
              <TextBlock>
                <Text ref={textRef}>{page.templateFields.introText}</Text>
              </TextBlock>
            </StyledWrapper>
          </ContentBlock>
        </Wrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.grayLight};

  @media (max-width: 1550px) {
    overflow: hidden;
  }

  ${media.tabletSmall(css`
    overflow: visible;
  `)}
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 60px;

  ${media.tabletLarge(css`
    margin-top: 30px;
  `)}

  ${media.tabletSmall(css`
    display: flex;
    position: absolute;
    bottom: -340px;
    flex-direction: column;
    align-items: flex-start;

    button {
      order: 1;
      margin-top: 40px;
    }
  `)}

  ${media.mobile(css`
    bottom: -288px;

    p,
    div {
      color: black;
      mix-blend-mode: normal;
    }
  `)}
`;

const BackgroundBlack = styled.div`
  position: absolute;
  width: 648px;
  top: 115px;
  right: -138px;
  bottom: -80px;
  background: #000;
  overflow: hidden;

  ${media.tabletLarge(css`
    right: -20px;
    max-width: 57vw;
  `)}

  ${media.tabletSmall(css`
    top: 84px;
    bottom: 0;
  `)}

  ${media.mobileMedium(css`
    top: 78px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  bottom: 0;
  left: 48%;
  transform: translateX(-50%);

  ${media.mobile(css`
    max-width: 60%;
    max-height: 80vh;
  `)}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const TextBlock = styled.div`
  display: flex;
  align-items: center;
  mix-blend-mode: difference;

  p {
    color: ${colors.white};
  }

  ${media.laptop(css`
    transform: translateY(0);
  `)}

  ${media.tabletSmall(css`
    p {
      font-size: 30px;
      font-weight: bold;
      line-height: 40px;
      margin-left: 0;
      color: ${colors.black};
      mix-blend-mode: normal;
    }
  `)}

  ${media.mobile(css`
    p {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
  `)}
`;

const TitleWrapper = styled.div`
  margin: 0 0 0 -80px;
  overflow: hidden;

  ${media.laptop(css`
    margin-left: 0;
  `)}
`;

const Title = styled.h1`
  margin: 0;
  font-size: 292px;
  line-height: 100%;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
  mix-blend-mode: difference;

  @media (min-width: 1024px) and (max-height: 700px) {
    font-size: 240px;
  }

  @media (min-width: 1024px) and (max-height: 650px) {
    font-size: 220px;
  }

  ${media.laptop(css`
    font-size: 240px;
    text-align: left;
  `)}

  ${media.tabletLarge(css`
    font-size: 190px;
  `)}

  ${media.tabletSmall(css`
    font-size: 140px;
  `)}

  ${media.mobile(css`
    font-size: 70px;
  `)}
`;

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding-top: 80px;
`;

const Text = styled.p`
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.015em;
  max-width: 475px;
  font-weight: 500;
  margin-left: 80px;
  color: ${colors.black};

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 27px;
    line-height: 36px;
  `)}
`;

// ${media.tabletSmall(css`
//   display: none;
// `)}
//
// ${media.mobile(css`
//   display: block;
// `)}

export default ContactsHero;
