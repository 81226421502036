import React from 'react';
import styled, { css } from 'styled-components';

import ArrowCircleLink from '@/components/ArrowCircleLink';
import { ReactComponent as BackgroundIcon } from '@/assets/svg/singapore-world.svg';
import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';

const SingaporeWorld = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Title>Cross border payments for SMEs made easy</Title>
          <Text>
            Make your international payments 5x cheaper than the old-school
            traditional banks.
          </Text>
          <Button
            link={'/xpate-sg/form'}
            color={'#955DFF'}
            label={'try for free'}
            textDark={true}
          />
          <Background>
            <BackgroundIcon />
          </Background>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  margin: 250px 0;
  padding-top: 140px;

  ${media.laptop(css`
    margin: 180px 0;
    padding-top: 100px;
  `)}
  ${media.tablet(css`
    margin: 140px 0;
    padding-top: 75px;
  `)}
  ${media.mobile(css`
    margin: 100px 0;
    padding-top: 50px;
  `)}
`;

const Inner = styled.div`
  background: #f6f6f6;
  margin: 0 -138px;
  padding: 0 190px 125px;
  position: relative;
  ${media.tablet(css`
    margin: 0 -20px;
    padding: 0 40px 60px;
  `)}
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 198px;
  line-height: 110%;
  letter-spacing: -2.49524px;
  color: #040312;
  display: block;
  position: relative;
  z-index: 1;

  transform: translateY(-140px);
  margin-bottom: -140px;

  ${media.laptop(css`
    font-size: 140px;
    transform: translateY(-100px);
    margin-bottom: -100px;
  `)}

  ${media.tablet(css`
    font-size: 100px;
    transform: translateY(-75px);
    margin-bottom: -75px;
  `)}
  
  ${media.mobile(css`
    font-size: 64px;
    margin-top: -20px;
    transform: translateY(-50px);
    margin-bottom: -50px;
  `)}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  margin-top: 85px;
  color: #040312;
  max-width: 483px;

  ${media.tablet(css`
    font-size: 21px;
    margin-top: 60px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    margin-top: 40px;
  `)}
`;

const Button = styled(ArrowCircleLink)`
  margin-top: 60px;

  ${media.mobile(css`
    margin-top: 40px;
    justify-content: flex-end;
  `)}
`;

const Background = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  ${media.tablet(css`
    display: none;
  `)}
`;

export default SingaporeWorld;
