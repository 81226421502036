import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as EuroSumIcon } from '@/assets/svg/stacks/euro-sum.svg';

interface Props extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
}

function StackCard({
  backgroundColor = 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
  ...rest
}: Props) {
  return (
    <Component {...rest} color={backgroundColor}>
      <Inner>
        <Currency>Euros</Currency>
        <Rate>
          <EuroSumIcon />
        </Rate>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
`;

const Currency = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`;

const Rate = styled.div`
  align-self: flex-end;
  svg {
    height: 26px;
    width: 144px;
  }
`;

const Component = styled.div<{ color: string }>`
  width: 100%;
  padding: 30px 33px;
  background: ${(props) => props.color};
  box-shadow: 10px 17px 50px rgba(0, 129, 184, 0.16);
  border-radius: 10px;
  overflow: hidden;
  max-height: 180px;

  @media (max-width: 768px) {
    padding: 10px 15px;

    ${Inner} {
      min-height: 60px;
    }

    ${Currency} {
      font-size: 12px;
    }

    ${Rate} {
      svg {
        height: 13px;
        width: 76px;
      }
    }
  }
`;

export default StackCard;
