export const appropriateList = [
  {
    label: 'Currently taking card payments',
    value: 'Currently taking card payments',
  },
  {
    label: 'New to taking card payments',
    value: 'New to taking card payments',
  },
];

export const interestList = [
  {
    label: 'White Label Payment Gateway turnkey solution',
    value: 'White Label Payment Gateway',
  },
  {
    label: 'Consultancy services - Issuing',
    value: 'Issuing',
  },
  {
    label: 'Consultancy services - Acquiring',
    value: 'Acquiring',
  },
];
