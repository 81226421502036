import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ReallyInstantPageType } from '@/typings/model';

const ReallyInstantHow = () => {
  const { howItems, howTitle } =
    useCurrentPage<ReallyInstantPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{howTitle}</Title>
        {howItems ? (
          <Grid>
            {howItems.map((item) => (
              <Cell>
                <Item>
                  <ItemImage>
                    <Picture {...convertThumbnailToPictureProps(item.icon)} />
                  </ItemImage>
                  <ItemText>{item.text}</ItemText>
                </Item>
              </Cell>
            ))}
          </Grid>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  color: #040312;
  margin: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  font-size: 102px;
  ${media.laptop(css`
    font-size: 74px;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
  margin-top: 40px;
`;

const Grid = styled.div`
  margin: -40px -40px 0;
  display: flex;
  flex-wrap: wrap;

  ${media.tablet(css`
    margin-top: 0;
  `)}

  ${media.mobile(css`
    margin: 0;
  `)}
`;

const Item = styled.div`
  max-width: 350px;

  ${media.tablet(css`
    max-width: 100%;
    padding-top: 0;
  `)}
`;

const Cell = styled.div`
  padding: 0 40px;
  margin-top: 80px;

  ${media.tablet(css`
    width: 50%;
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    width: 100%;
    padding: 0;
    &:not(:first-child) {
      margin-top: 60px;
    }
  `)}
`;

const ItemImage = styled.div`
  display: inline-flex;
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  /* or 32px */

  color: #040312;
  margin-top: 35px;
  display: block;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

export default ReallyInstantHow;
