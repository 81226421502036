import React from 'react';
import styled from 'styled-components';

import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import IntroBlock, { ButtonWrap } from '@/components/IntroBlock';
import { colors } from '@/constants/theme';
import { roadmapList } from '@/modules/Stacks/components/StacksRoadmap/StacksRoadmap.constants';

function StacksRoadmap() {
  return (
    <Component>
      <DoubleTitle firstTitle="Stacks" secondTitle="Roadmap" />
      <IntroBlockWrapper>
        <IntroBlock
          options={{
            isShowButton: true,
            text: 'See what we did and what coming up soon.',
            buttonColor: colors.stacksPrimary,
            buttonHoverColor: colors.stacksPrimaryHover,
            textColor: '#C2C2C8',
            boldTextColor: 'white',
            buttonTitle: 'release notes',
            buttonUrl: '/stacks/release-notes',
            boldText: 'We’re constantly improving Stacks. ',
            arrowIconColor: 'black',
          }}
        >
          <RoadmapSteps>
            {roadmapList.map((roadmapItem, index) => (
              <RoadMapStep key={index}>
                <RoadMapStepLabel>{roadmapItem.label}</RoadMapStepLabel>
                <RoadMapStepOptions>
                  {roadmapItem.options.map((option, index) => (
                    <RoadMapStepOption key={index}>
                      <span>{option.label}</span>
                    </RoadMapStepOption>
                  ))}
                </RoadMapStepOptions>
              </RoadMapStep>
            ))}
          </RoadmapSteps>
        </IntroBlock>
      </IntroBlockWrapper>
    </Component>
  );
}

const IntroBlockWrapper = styled.div`
  margin-top: 180px;
`;

const RoadmapSteps = styled.div`
  margin: 150px 0 180px;
  display: flex;
  justify-content: space-between;
`;

const RoadMapStep = styled.div`
  max-width: 550px;
  padding-right: 20px;
`;

const RoadMapStepLabel = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
`;
const RoadMapStepOptions = styled.ul`
  margin-top: 30px;
  padding-left: 20px;
`;

const RoadMapStepOption = styled.li`
  position: relative;
  margin-top: 30px;
  font-size: 22px;
  line-height: 145%;

  &:first-child {
    margin-top: 0;
  }

  &:before {
    top: 13px;
    left: -18px;
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${colors.stacksPrimary};
  }
`;

const Component = styled.div`
  padding: 180px 0 250px;
  background: black;
  color: white;

  ${ButtonWrap} {
    text-align: start;
  }

  a {
    span {
      color: white !important;
    }
  }

  svg {
    path {
      color: black !important;
    }
  }

  @media (max-width: 1024px) {
    padding: 80px 0 140px;

    ${RoadmapSteps} {
      margin-top: 80px;
      flex-direction: column;
    }

    ${RoadMapStep} {
      max-width: 100%;

      &:last-child {
        margin-top: 80px;
      }
    }

    ${ButtonWrap} {
      margin-top: -110px;
    }

    ${IntroBlockWrapper} {
      margin-top: 80px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;

    ${RoadmapSteps} {
      margin-bottom: 80px;
    }

    ${RoadMapStepOption} {
      font-size: 20px;
      line-height: 130%;
    }

    ${ButtonWrap} {
      margin-top: -20px;
    }
  }

  @media (max-width: 416px) {
    #first-row-title {
      font-size: 70px;
    }

    #second-row-title {
      font-size: 70px;
    }

    ${ButtonWrap} {
      margin-top: -35px;
    }
  }
`;

export default StacksRoadmap;
