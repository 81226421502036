import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { media } from '@/utils/mixin';
import SectionLabel from '@/modules/Home/components/SectionLabel';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowBigHeaderSvg } from '@/assets/svg/arrow-our-industries.svg';
import { ReactComponent as ArrowLinkSvg } from '@/assets/svg/arrow-link.svg';
import Link from '@/components/Link';

gsap.registerPlugin(SplitText);

function HomeMarkets() {
  const [isFirstItemActive, setFirstItemActive] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const itemBackgroundRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const iconRef = useRef<HTMLElement>(null);

  function handleGridOver() {
    const gridElem = gridRef.current;
    if (!gridElem) return;

    gridElem.removeEventListener('mouseover', handleGridOver);
    setFirstItemActive(false);
  }

  function handleItemAnimationEnd() {
    const gridElem = gridRef.current;
    if (!gridElem) return;

    gridElem.addEventListener('mouseover', handleGridOver);
  }

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.from(backgroundRef.current, {
        ease: 'linear',
        scaleX: 0.7,
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          end: 'top 14%',
          scrub: true,
        },
      });
    });

    return () => {
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          duration: 1.45,
          yPercent: 100,
        },
        0
      )
        .from(
          itemBackgroundRef.current,
          {
            clearProps: 'all',
            onComplete: handleItemAnimationEnd,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            duration: 1.65,
            scaleY: 0,
          },
          1
        )
        .from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            yPercent: -100,
            duration: 1.75,
            delay: 0.2,
          },
          1
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.25,
            delay: 0.1,
          },
          1
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <Background ref={backgroundRef} />
      <ContentSmallContainer>
        <TextWrapper>
          <SectionLabel ref={labelRef} white label="Our industries" />
        </TextWrapper>
        <TitleWrapper>
          <Title ref={titleRef}>We become experts in what you do</Title>
          <TitleIconWrapper>
            <TitleIcon ref={iconRef}>
              <ArrowBigHeaderSvg />
            </TitleIcon>
          </TitleIconWrapper>
        </TitleWrapper>
        <Grid ref={gridRef}>
          <Item to="/education">
            <ItemBackground />
            <ItemInner>
              <ItemName>Online Education and EdTech</ItemName>
              <ItemNumber>01</ItemNumber>
              <Icon>
                <ArrowLinkSvg />
              </Icon>
            </ItemInner>
          </Item>
          <Item to="/marketplaces">
            <ItemBackground />
            <ItemInner>
              <ItemName>Marketplaces and online shops</ItemName>
              <ItemNumber>02</ItemNumber>
              <Icon>
                <ArrowLinkSvg />
              </Icon>
            </ItemInner>
          </Item>
          <Item to="/online-services">
            <ItemBackground />
            <ItemInner>
              <ItemName>Online services</ItemName>
              <ItemNumber>03</ItemNumber>
              <Icon>
                <ArrowLinkSvg />
              </Icon>
            </ItemInner>
          </Item>
          <Item to="/it-services">
            <ItemBackground />
            <ItemInner>
              <ItemName>IT Services</ItemName>
              <ItemNumber>04</ItemNumber>
              <Icon>
                <ArrowLinkSvg />
              </Icon>
            </ItemInner>
          </Item>
        </Grid>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 140px 0 160px;
  color: #fff;
  z-index: 0;

  ${media.tabletSmall(css`
    padding: 100px 0;
  `)}

  ${media.mobile(css`
    padding: 90px 0;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #040312;
  z-index: -1;
`;

const TextWrapper = styled.div`
  overflow: hidden;
`;

const TitleWrapper = styled.div`
  position: relative;
  max-width: 730px;
  margin-top: 40px;
  margin-left: -5px;

  ${media.laptop(css`
    max-width: 530px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    max-width: 450px;
  `)}
`;

const Title = styled.h2`
  font-size: 102px;
  letter-spacing: -0.01em;
  line-height: 104px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 72px;
    line-height: 74px;
  `)}

  ${media.tabletLarge(css`
    font-size: 62px;
    line-height: 64px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 62px;
    line-height: 54px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 117px;

  ${media.laptop(css`
    margin-top: 60px;
  `)}

  ${media.mobile(css`
    display: block;
    margin: 40px -20px 0;
  `)}
`;

const TitleIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: -150px;
  transform: translateY(-50%);
  display: inline-flex;
  overflow: hidden;
`;

const TitleIcon = styled.i`
  display: block;
  width: 73px;
  height: 87px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    width: 99px;
    height: 99px;
  `)}

  ${media.tabletSmall(css`
    width: 69px;
    height: 69px;
  `)}
  
  ${media.mobile(css`
    display: none;
  `)}
`;

const Icon = styled.i`
  position: absolute;
  right: 10px;
  top: 10px;
  display: block;
  width: 27px;
  height: 26px;
  opacity: 0;
  transition: all ease 0.3s;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tabletSmall(css`
    width: 21px;
    height: 20px;
  `)}

  ${media.mobileMedium(css`
    width: 16px;
    height: 15px;
  `)}
`;

const ItemBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0060f5;
  transition: transform 0.35s ease-in-out;
  transform-origin: 50% 100%;
  transform: scaleY(0);
  z-index: -1;
`;

const Item = styled(Link)<{ isActive?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding-top: 33.333%;
  border-bottom: 1px solid #292935;
  border-right: 1px solid #292935;
  z-index: 0;
  overflow: hidden;

  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    border-bottom: 0 none;
  }

  &:nth-child(2n) {
    border-right: 0 none;
  }

  &:hover {
    ${Icon} {
      opacity: 1;
    }

    ${ItemBackground} {
      transform: scaleY(1);
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${Icon} {
        opacity: 1;
      }

      ${ItemBackground} {
        transform: none;
        transition: none;
      }
    `}

  ${media.mobile(css`
    width: 100%;

    &:not(:last-child) {
      border-bottom: 1px solid #444;
    }
  `)}
`;

const ItemInner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemName = styled.span`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 34px;
  color: #fff;
  width: 250px;
  text-align: center;
  font-weight: 600;
  height: 100px;
  margin-top: 60px;

  ${media.laptop(css`
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}
  
   ${media.mobile(css`
    font-size: 20px;
    line-height: 30px;
    width: auto;
  `)}
   
   ${media.mobileSmall(css`
    font-size: 17px;
    line-height: 27px;
  `)}
`;

const ItemNumber = styled.span`
  position: absolute;
  right: 35px;
  bottom: 33px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 700;

  ${media.mobile(css`
    bottom: 10px;
    right: 10px;
  `)}
`;

export default HomeMarkets;
