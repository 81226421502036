import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';

import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';

gsap.registerPlugin(ScrollTrigger, SplitText);

function LinksItemList({
  itemList,
  children,
  mainColor = colors.white,
}: {
  itemList: Array<{ image: string; title?: string; text: string }>;
  children?: React.ReactNode;
  mainColor?: string;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: itemList.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 87%',
        },
      });
    });

    return () => {
      delayCall.kill();
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    const delayCall = gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.45,
            delay: index / 8,
            scale: 0,
          },
          0
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            yPercent: 100,
            duration: 1.45,
          },
          0.2
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        );
      });
    });

    return () => {
      delayCall.kill();
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component mainColor={mainColor} ref={containerRef}>
      <ContentSmallContainer>
        <Row>
          {itemList.map((item, index) => (
            <Item key={index}>
              <Icon ref={cellRefList[index].cardRef}>
                <img ref={cellRefList[index].iconRef} src={item.image} />
              </Icon>
              {item.title ? (
                <ItemTitle ref={cellRefList[index].titleRef}>
                  {item.title}
                </ItemTitle>
              ) : null}
              <ItemText
                ref={cellRefList[index].textRef}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </Item>
          ))}
          {children}
        </Row>
      </ContentSmallContainer>
    </Component>
  );
}

export const Component = styled.section<{ mainColor?: string }>`
  ${(props) =>
    props.mainColor
      ? css`
          * {
            color: ${props.mainColor} !important;
          }
        `
      : null}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -40px;

  ${media.laptop(css`
    margin: 0 -20px;
  `)}

  ${media.tabletSmall(css`
    justify-content: flex-start;
  `)}
`;

export const Item = styled.div`
  flex: 1 1 430px;
  max-width: 430px;
  padding: 0 40px;
  margin-top: 150px;

  ${media.laptop(css`
    flex-basis: 29%;
    max-width: 29%;
    padding: 0 20px;
    margin-top: 70px;
  `)}

  ${media.tabletLarge(css`
    flex-basis: 320px;
    max-width: 320px;
  `)}

  ${media.tabletSmall(css`
    max-width: 80%;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}

  ${media.mobileSmall(css`
    max-width: 90%;
  `)}
`;

export const Icon = styled.div`
  margin-bottom: 30px;
`;

export const ItemTitle = styled.span`
  font-size: 30px;
  display: block;
  margin-top: 10px;
  letter-spacing: -0.01em;
  font-weight: 600;
  line-height: 100%;
  color: #fff;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 22px;
  `)}
`;

export const ItemText = styled.p`
  font-size: 22px;
  line-height: 30px;
  margin-top: 25px;
  font-weight: 500;
  color: ${colors.white};
  white-space: pre-wrap;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

export const ButtonWrap = styled.div`
  width: 100%;
  margin: 150px 40px auto;

  ${media.laptop(css`
    margin: 70px 20px auto;
  `)}

  ${media.mobile(css`
    margin: 60px 20px auto;
  `)}
`;

export const ButtonLabel = styled.span`
  font-size: 40px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: ${colors.white};
  margin-right: 40px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 34px;
  `)}

  ${media.mobile(css`
    margin-right: 30px;
    font-size: 28px;
  `)}
`;

export const ButtonIcon = styled.span`
  background: ${colors.linksPrimary};
  color: ${colors.black};
  width: 142px;
  height: 142px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      opacity: 0.85;
    }
  }

  svg {
    max-width: 50%;
    transition: 0.3s all ease;
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    width: 88px;
    height: 88px;

    svg {
      width: 31px;
      height: 43px;
    }
  `)}
`;

export default LinksItemList;
