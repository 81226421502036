import React from 'react';

import Layout from '@/components/Layout';

function Prices() {
  return (
    <Layout theme="black" dark={true}>
      ...Collect Payments...
    </Layout>
  );
}

export default Prices;
