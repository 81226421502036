import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';
import scrollManager from '@/services/scroll';

const SingaporeNavigation = () => {
  const onFaqClick = () => {
    const element = document.getElementById('faq');
    if (!element) return;

    scrollManager.getLocomotiveScroll()?.scrollTo(element);
  };

  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Item>
            <ItemActive>xpate SG</ItemActive>
          </Item>
          <Item>
            <ItemLink
              to="#faq"
              onClick={(e) => {
                e.preventDefault();
                onFaqClick();
              }}
            >
              FAQ
            </ItemLink>
          </Item>
          <Item>
            <ItemLink to="/xpate-sg/prices">Prices</ItemLink>
          </Item>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  ${media.mobile(css`
    display: none;
  `)}
`;

const Inner = styled.ul`
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: flex-end;
  padding-top: 12px;
  position: relative;
`;

const Item = styled.li`
  &:not(:first-child) {
    margin-left: 30px;
  }
`;

const ItemLink = styled(Link)`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  transition: 0.3s all ease;
  &:hover {
    opacity: 0.5;
  }
`;

const ItemActive = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  opacity: 0.5;
`;

export default SingaporeNavigation;
