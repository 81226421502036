import React, { useState } from 'react';

import { colors } from '@/constants/theme';
import Layout from '@/components/Layout';
import GetStarted from '@/components/GetStarted';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import LinksConsulting from '@/modules/Links/components/LinksConsulting';

import LinksHero from './components/LinksHero';
import LinksBenefits from './components/LinksBenefits';
import LinksGlanceBlock from './components/LinksGlanceBlock';
import LinksText from './components/LinksText';
import LinksIntro from './components/LinksIntro';

function Links() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout
      theme="black"
      dark={true}
      onPreloaderOut={handlePreloaderOut}
      footerNoCta={true}
    >
      <LinksHero animate={isPreloaderOut} />
      <LinksIntro />
      <LinksText />
      <LinksBenefits />
      <LinksGlanceBlock />
      <LinksConsulting />
      <GetStarted
        link="mailto:hello@xpate.com"
        color={colors.linksPrimary}
        hoverColor={colors.linksPrimaryHover}
      >
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Links;
