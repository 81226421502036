import React from 'react';

import GetStarted from '@/components/GetStarted';

const SingaporeCta = () => {
  return (
    <GetStarted
      link={'/xpate-sg/form'}
      openInNewTab={true}
      color="#955DFF"
      hoverColor="#955DFF"
      isBig={false}
      isTextLight={true}
      text="Sign up here and we'll be in touch"
    >
      Become an
      <br />
      xpate SG client
    </GetStarted>
  );
};

export default SingaporeCta;
