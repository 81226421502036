import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { CssSnippet } from '@/typings/common';

type Props = {
  title: string;
  text?: string;
  imgSrc: string;
  img2xSrc: string;
  imgMobileSrc: string;
  imgMobile2xSrc: string;
  imgCustomStyles?: CssSnippet;
};

const CollectPaymentsHero: React.FC<Props> = ({
  imgSrc,
  img2xSrc,
  imgMobileSrc,
  imgMobile2xSrc,
  title,
  text,
  imgCustomStyles,
}: Props) => {
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Left>
            <Title>{nl2br(title)}</Title>
            {text ? <Text>{nl2br(text)}</Text> : null}
          </Left>
        </Inner>
      </ContentSmallContainer>
      <Image customStyles={imgCustomStyles}>
        <Picture
          mobileSmall={{
            src: imgMobileSrc,
            src2x: imgMobile2xSrc,
          }}
          laptop={{
            src: imgSrc,
            src2x: img2xSrc,
          }}
          alt={title}
        />
      </Image>
    </Component>
  );
};

const Component = styled.section`
  background: #000;
  position: relative;
`;

const Title = styled.h1`
  display: block;
  font-weight: 700;
  font-size: 200px;
  line-height: 100%;
  position: relative;
  margin: 0;
  z-index: 1;
  max-width: 900px;

  ${media.laptop(css`
    font-size: 140px;
  `)}

  ${media.tablet(css`
    font-size: 65px;
    text-align: left;
    margin-top: auto;
  `)}
`;

const Image = styled.div<{
  customStyles?: CssSnippet;
}>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  max-height: 100%;

  > div,
  img {
    height: calc(var(--vh, 1vh) * 100);
    max-height: 900px;
    ${media.tabletOnly(css`
      max-height: 600px;
    `)}

    ${media.mobile(css`
      height: auto;
      width: 100vw;
    `)}
  }

  ${media.tablet(css`
    left: 0;
  `)}

  ${(props) => (props.customStyles ? props.customStyles : null)}
`;

const Inner = styled.div`
  position: relative;

  ${media.tablet(css`
    display: flex;
    flex-direction: column;
  `)}

  height: calc(var(--vh, 1vh) * 100);
  max-height: 900px;

  ${media.tabletOnly(css`
    min-height: 600px;
  `)}

  ${media.mobile(css`
    max-height: none;
  `)}

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Left = styled.div`
  height: 100%;
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 930px;
  padding-top: 108px;

  ${media.tablet(css`
    padding-top: 0;
    padding-bottom: 90px;
  `)}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  margin-top: 60px;
`;

export default CollectPaymentsHero;
