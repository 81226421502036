import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import heroImg from '@/assets/images/singapore/hero.jpeg';
import heroMobileImg from '@/assets/images/singapore/hero-mobile.jpeg';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import SingaporeNavigation from '@/modules/Singapore/components/SingaporeNavigation';

const SingaporeHero = () => {
  return (
    <Component>
      <Background>
        <Picture
          tabletSmall={{
            src: heroImg,
          }}
          mobileSmall={{
            src: heroMobileImg,
          }}
        />
      </Background>
      <ContentContainer>
        <Wrapper>
          <SingaporeNavigation />
          <Inner>
            <TitleWrapper>
              <Title>xpate SG everywhere</Title>
            </TitleWrapper>
            <Text>The PayOut solution for your business.</Text>
          </Inner>
        </Wrapper>
      </ContentContainer>
    </Component>
  );
};

const Component = styled.section`
  position: relative;
  color: #fff;
`;

const Background = styled.div`
  position: absolute;
  inset: 0;
  > div,
  img {
    width: 100%;
    position: absolute;
    inset: 0;
    height: 100%;
  }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  padding: 108px 0 58px;
  display: flex;
  flex-direction: column;
  ${media.mobile(css`
    padding: 75px 0 40px;
  `)}
`;

const Inner = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  flex: 1 1 1px;
  padding: 50px 0;

  ${media.mobile(css`
    padding: 0;
  `)}
`;

const TitleWrapper = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 200px;
  line-height: 100%;
  display: block;
  margin: 0;

  ${media.laptop(css`
    font-size: 160px;
  `)}

  ${media.tablet(css`
    font-size: 120px;
  `)}
  
  ${media.mobile(css`
    font-size: 50px;
  `)}
`;

const Text = styled.p`
  font-weight: 800;
  font-size: 19px;
  line-height: 145%;
  margin-top: auto;
  display: block;

  ${media.mobile(css`
    max-width: 200px;
    margin: auto auto 0;
  `)}
`;

export default SingaporeHero;
