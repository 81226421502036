import { ColorCard, TextCard } from './StacksInterface.types';

export function getBaseCardsForOneRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #FFD500 0%, #E59A10 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FD9030 0%, #E65828 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #61D1A8 0%, #289F93 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FD7B5E 0%, #DB3D3D 100%)',
      type: 'card',
    },
  ];
}

export function getBaseCardsForTwoRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #FD7B5E 0%, #DB3D3D 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #61D1A8 0%, #289F93 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
  ];
}

export function getBaseCardsForThreeRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #61D1A8 0%, #289F93 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FD9030 0%, #E65828 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
  ];
}

export function getBaseCardsForFourRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg,#61D1A8 0%,#289F93 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
  ];
}

export function getBaseCardsForMobileOneRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #61D1A8 0%, #289F93 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FD7B5E 0%, #DB3D3D 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B785FA 0%, #8251DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
      type: 'card',
    },
  ];
}

export function getBaseCardsForMobileTwoRow(): Array<ColorCard> {
  return [
    {
      color: 'linear-gradient(180deg, #D5E868 0%, #A7CC38 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FFD500 0%, #E59A10 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #FD9030 0%, #E65828 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #529DFA 0%, #4D59DB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #5DD1F4 0%, #3D9EDF 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #39DBDB 0%, #2FB0C1 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
    {
      color: 'linear-gradient(180deg, #B7E684 0%, #73C757 100%)',
      type: 'card',
    },
  ];
}

export const stackRows: Array<Array<ColorCard | TextCard>> = [
  [
    { type: 'title', text: 'Even bigwigs' } as TextCard,
    ...getBaseCardsForOneRow(),
    { type: 'title', text: 'Even bigwigs' } as TextCard,
    ...getBaseCardsForOneRow(),
  ],
  [
    { type: 'title', text: 'are <span>People</span>' } as TextCard,
    ...getBaseCardsForTwoRow(),
    { type: 'title', text: 'are <span>People</span>' } as TextCard,
    ...getBaseCardsForTwoRow(),
  ],
  [...getBaseCardsForThreeRow(), ...getBaseCardsForThreeRow()],
  [...getBaseCardsForMobileOneRow(), ...getBaseCardsForMobileOneRow()],
  [...getBaseCardsForMobileTwoRow(), ...getBaseCardsForMobileTwoRow()],
  [
    ...getBaseCardsForFourRow(),
    { type: 'title', text: 'first of all' } as TextCard,
    ...getBaseCardsForFourRow(),
    { type: 'title', text: 'first of all' } as TextCard,
  ],
];
