import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { fonts } from '@/constants/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  min-header: calc(var(--vh, 1vh) * 100);
  font-family: ${fonts.Gilroy};
  background: linear-gradient(to right, #ffffff 50%, #000000 50%);
  overflow: hidden;
`;

export const Header = styled.header`
  padding: 40px 0 20px;

  ${media.mobile(css`
    padding-top: 30px;
  `)}
`;

export const HeaderLogo = styled.a`
  display: inline-flex;
  color: #000000;
`;

export const HeaderLogoSvg = styled.object`
  pointer-events: none;
`;

export const Content = styled.main`
  padding-bottom: 55px;

  ${media.mobile(css`
    padding-top: 15px;
  `)}
`;

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 1024px;
  `)}

  ${media.tabletSmall(css`
    max-width: 795px;
  `)}

  ${media.mobile(css`
    max-width: 355px;
  `)}
`;

export const TextContent = styled.div`
  position: relative;
  margin-bottom: 120px;
  text-align: center;
  color: #ffffff;

  ${media.laptop(css`
    margin-bottom: 80px;
  `)}

  ${media.mobile(css`
    padding-bottom: 95px;
    margin-bottom: 32px;
  `)}
`;

export const PageTitle = styled.h1`
  margin: 0 0 40px;
  font-size: 362px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 75%;
  mix-blend-mode: difference;

  ${media.laptop(css`
    font-size: 292px;
  `)}

  ${media.tabletSmall(css`
    font-size: 222px;
  `)}

  ${media.mobile(css`
    margin-bottom: 27px;
    font-size: 150px;
  `)}
`;

export const PageDescription = styled.span`
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 100%;
  mix-blend-mode: difference;

  ${media.laptop(css`
    font-size: 40px;
  `)}

  ${media.tabletSmall(css`
    font-size: 30px;
  `)}

  ${media.mobile(css`
    font-size: 25px;
  `)}
`;

export const ArrowButton = styled.button`
  position: absolute;
  left: calc(100% + 70px);
  bottom: 90px;
  display: inline-flex;
  width: 76px;
  color: #ffffff;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    left: calc(100% + 62px);
    bottom: 82px;
    width: 63px;
  `)}

  ${media.tabletSmall(css`
    left: calc(100% + 54px);
    bottom: 72px;
    width: 53px;
  `)}

  ${media.mobile(css`
    left: auto;
    right: 62px;
    bottom: 0;
    width: 43px;
  `)}
`;

export const EmailLink = styled.a`
  position: relative;
  margin-left: auto;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 100%;

  span {
    mix-blend-mode: difference;
  }

  &:after {
    content: '';
    position: absolute;
    left: -0.1em;
    right: -0.1em;
    bottom: -0.1em;
    height: 0.45em;
    background: rgb(0, 96, 245, 0.75);
    transform-origin: 50% 100%;
    transition: transform 0.15s ease;
  }

  &:hover:after {
    transform: scaleY(0.35);
  }

  ${media.laptop(css`
    font-size: 40px;
  `)}

  ${media.tabletSmall(css`
    font-size: 30px;
  `)}

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;

export const Footer = styled.footer`
  padding: 25px 0 50px;
  flex-shrink: 0;

  ${media.mobile(css`
    padding-bottom: 30px;
  `)}
`;

export const FooterInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;

  ${media.tabletSmall(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

export const CopyrightText = styled.span`
  display: inline-block;
  margin-right: 35px;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 150%;
  mix-blend-mode: difference;

  ${media.tablet(css`
    margin-right: 0;
  `)}
`;

export const LinkList = styled.ul`
  display: flex;
  align-items: center;

  ${media.tabletSmall(css`
    margin-top: 12px;
  `)}

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
    margin-top: 22px;
  `)}
`;

export const LinkListItem = styled.li`
  &:not(:last-child) {
    margin-right: 35px;
  }

  ${media.tabletSmall(css`
    &:not(:last-child) {
      margin-right: 15px;
    }
  `)}
`;

export const LinkItem = styled.a`
  font-size: 13px;
  letter-spacing: 0;
  line-height: 34px;
  mix-blend-mode: difference;

  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
`;
