import React from 'react';

import { nl2br } from '@tager/web-core';

import GetStarted from '@/components/GetStarted';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

const CollectPaymentsCallToAction = () => {
  const { ctaLink, ctaText, ctaOpenInNewTab } =
    useCurrentPage<CollectPaymentsPageType>().templateFields;
  if (!ctaText || !ctaLink) return null;

  return (
    <GetStarted
      link={ctaLink}
      openInNewTab={ctaOpenInNewTab}
      color="#00F7EC"
      hoverColor="#00F7EC"
      isBig={true}
      isTextLight={false}
      buttonPosition={'bottom'}
    >
      {nl2br(ctaText)}
    </GetStarted>
  );
};

export default CollectPaymentsCallToAction;
