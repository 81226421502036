import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import ArrowCircleLink from '@/components/ArrowCircleLink';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';

const PricingMain = () => {
  const {
    mainItems,
    mainList,
    mainText,
    mainTitle,
    mainButtonLabel,
    mainButtonLink,
  } = useCurrentPage<PricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{mainTitle}</Title>
        <Text>{mainText}</Text>
        <Inner>
          <Row>
            {mainItems.map((item) => (
              <Cell>
                <Name>{item.title}</Name>
                <Price>{item.price}</Price>
                <ItemText>{nl2br(item.text)}</ItemText>
              </Cell>
            ))}
          </Row>
          {mainList ? (
            <List>
              {mainList.split('\n').map((item) => (
                <ListItem bold={item.startsWith('[bold]')}>
                  {item.replaceAll('[bold]', '')}
                </ListItem>
              ))}
            </List>
          ) : null}
        </Inner>
        {mainButtonLink && mainButtonLabel ? (
          <Button
            link={mainButtonLink}
            label={mainButtonLabel}
            color={colors.blue}
            textDark={true}
            arrowColor="white"
          />
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  margin: 240px 0 180px;
  color: ${colors.black};

  ${media.laptop(css`
    margin: 160px 0;
  `)}
  ${media.tablet(css`
    margin: 120px 0;
  `)}
  ${media.mobile(css`
    margin: 120px 0 60px;
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 292px;
  line-height: 100%;
  display: block;
  margin: 0;

  ${media.laptop(css`
    font-size: 240px;
  `)}
  ${media.tablet(css`
    font-size: 180px;
  `)}
  ${media.mobile(css`
    font-size: 90px;
  `)}
`;

const Text = styled.p`
  max-width: 1000px;
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  margin-top: 80px;

  ${media.laptop(css`
    margin-top: 60px;
    font-size: 40px;
  `)}
  ${media.tablet(css`
    margin-top: 40px;
    font-size: 36px;
    max-width: none;
  `)}
  ${media.mobile(css`
    margin-top: 30px;
    font-size: 32px;
    margin-top: 20px;
  `)}
`;

const Inner = styled.div`
  margin-top: 100px;
  background: #f6f6f6;
  padding: 75px 100px 100px 100px;

  ${media.laptop(css`
    margin-top: 80px;
    padding: 75px;
  `)}
  ${media.tablet(css`
    margin-top: 60px;
    padding: 50px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
    padding: 30px;
  `)}
`;

const Button = styled(ArrowCircleLink)`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Row = styled.div`
  display: flex;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 1px;

  ${media.mobile(css`
    &:not(:first-child) {
      margin-top: 30px;
    }
  `)}
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 120%;
  display: block;
  ${media.tablet(css`
    font-size: 30px;
  `)}
  ${media.mobile(css`
    font-size: 20px;
  `)}
`;

const Price = styled.span`
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  display: block;
  margin-top: 30px;

  ${media.tablet(css`
    font-size: 44px;
  `)}
  ${media.mobile(css`
    font-size: 30px;
    margin-top: 20px;
  `)}
`;

const ItemText = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  color: #58585d;
  margin-top: 20px;

  ${media.mobile(css`
    font-size: 18px;
    margin-top: 15px;
  `)}
`;

const List = styled.ul`
  margin-top: 70px;
  ${media.tablet(css`
    margin-top: 50px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const ListItem = styled.li<{ bold?: boolean }>`
  font-weight: ${(props) => (props.bold ? '700' : '500')};
  font-size: 24px;
  line-height: 145%;
  color: ${(props) => (props.bold ? '#347657' : colors.black)};
  position: relative;
  padding-left: 30px;
  &:not(:first-child) {
    margin-top: 10px;
  }

  ${media.tablet(css`
    font-size: 21px;
    padding-left: 24px;
  `)}
  ${media.mobile(css`
    font-size: 18px;
  `)}

&:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${(props) => (props.bold ? '#347657' : colors.black)};
    left: 6px;
    top: 11px;
    ${media.tablet(css`
      width: 6px;
      height: 6px;
    `)}
  }
`;

export default PricingMain;
