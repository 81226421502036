import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';
import { useMedia } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { scrollDown } from '@/utils/common';
import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useHeroAnimation from '@/modules/common/Links/hooks/useHeroAnimation';
import { ReactComponent as ArrowBigHeaderSvg } from '@/assets/svg/arrow_big_header.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';
import Picture from '@/components/Picture';

type Props = {
  animate: boolean;
};

function CorebankingHero({ animate }: Props) {
  const { heroImage, heroTitle, heroTitleMobile } =
    useCurrentPage<CorebankingPageType>().templateFields;

  const { containerRef, buttonRef, titleInnerRef, innerRef } = useHeroAnimation(
    { config: { animate: animate } }
  );

  const isMobile = useMedia(`(max-width: 1259px)`);

  const titleWord = isMobile ? heroTitleMobile ?? heroTitle : heroTitle;

  const titleWords = titleWord
    ? titleWord
        .trim()
        .split(' ')
        .map((item) => item.trim())
    : [];

  return (
    <React.Fragment>
      <Component ref={containerRef}>
        <ContentSmallContainer>
          <Wrapper ref={innerRef}>
            <Inner>
              <TitleWrapper>
                <TitleInner ref={titleInnerRef}>
                  {titleWords.length > 0 ? (
                    <Title>
                      {titleWords[0]}
                      {titleWords.length > 1 ? (
                        <>
                          <br />
                          {titleWords[1]}
                        </>
                      ) : null}
                    </Title>
                  ) : null}
                </TitleInner>
              </TitleWrapper>
            </Inner>
            {heroImage ? (
              <Image>
                <Picture {...convertThumbnailToPictureProps(heroImage)} />
              </Image>
            ) : null}
            <ButtonDown onClick={scrollDown} ref={buttonRef}>
              <ArrowBigHeaderSvg />
            </ButtonDown>
          </Wrapper>
        </ContentSmallContainer>
      </Component>
    </React.Fragment>
  );
}

export const Component = styled.div`
  background: #040312;
  overflow: hidden;

  ${media.mobile(css`
    position: relative;
  `)}
`;

export const Inner = styled.div`
  position: relative;

  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 50px;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  padding-left: 50vw;
  padding-right: 50vw;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -10px;
  line-height: 100%;
  font-weight: 700;
  letter-spacing: 0;
  overflow: hidden;
  z-index: 1;

  font-size: 200px;
  ${media.tablet(css`
    font-size: 150px;
  `)}
  ${media.mobile(css`
    font-size: 70px;
    margin: 0;
    padding: 0;
  `)}
`;

export const TitleInner = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0 -40px;

  ${media.tabletLarge(css`
    margin: 0 -30px;
  `)}

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}

  ${media.mobile(css`
    margin: 0;
  `)}
`;

export const Title = styled.h1`
  display: block;
  flex-shrink: 0;
  padding: 0 40px 0.15em;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  white-space: nowrap;
  margin: 0;

  span {
    color: ${colors.linksPrimary};
  }

  ${media.tabletLarge(css`
    padding: 0 30px 0.15em;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px 0.15em;
  `)}

  ${media.mobile(css`
    padding: 0;
    flex-shrink: 1;
    white-space: normal;
  `)}
`;

export const Image = styled.div`
  position: absolute;
  right: -15%;
  top: 20%;

  img {
    display: block;
    max-height: 85vh;
    margin: 0 auto;
  }

  ${media.laptop(css`
    right: 8%;
  `)}

  ${media.tabletLarge(css`
    right: -5%;
    img {
      max-height: 65vh;
    }
  `)}
  
  ${media.tabletSmall(css`
    right: -5%;
    img {
      max-height: 55vh;
    }
  `)}

  ${media.mobile(css`
    bottom: 20%;
    left: 50%;
    right: auto;
    width: 90%;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
  `)}
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 600px;
  z-index: 2;

  ${media.tabletSmall(css`
    padding-bottom: 120px;
  `)}

  ${media.mobile(css`
    justify-content: flex-end;
  `)}
`;

const ButtonDown = styled.button`
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 95px;
  height: 131px;
  z-index: 1;
  color: ${colors.white};

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.mobile(css`
    right: 20px;
    bottom: 25px;
    width: 51px;
    height: 65px;
  `)}
`;

export default CorebankingHero;
