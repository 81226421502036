import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Icon01 from '@/assets/images/accounts/icon_01.svg';
import Icon02 from '@/assets/images/accounts/icon_02.svg';
import Icon03 from '@/assets/images/accounts/icon_03.svg';
import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';
import Button from '@/components/Button';
import Link from '@/components/Link';
import { ButtonNextLink } from '@/components/Button/Button';

gsap.registerPlugin(ScrollTrigger, SplitText);

const ITEM_LIST = [
  {
    image: Icon01,
    title: `It's a single account`,
    text: `One platform where you can manage incoming and outgoing payments, settlements and currency exchanges. It’s simplicity made real.`,
  },
  {
    image: Icon02,
    title: `It’s fully transparent`,
    text: `We put you in control. You can access local and international payment schemes without hidden fees and monitor intelligent transaction routing.`,
  },
  {
    image: Icon03,
    title: `It's flexible`,
    text: `Limits won’t help your business to grow — that’s why we made them flexible. You’re free to pay anyone on the planet, either individually or through a bulk payment.`,
  },
];

function AccountsBenefits() {
  const labelRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: ITEM_LIST.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: titleRef.current,
            start: 'top 82%',
          },
        })
        .from(
          splitTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
          },
          0
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
            delay: 0.3,
          },
          0
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.45,
            delay: index / 8,
            scale: 0,
          },
          0
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            yPercent: 100,
            duration: 1.45,
          },
          0.2
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        );
      });
    });

    return () => {
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component>
      <ContentSmallContainer>
        <SubTitleWrapper>
          <SubTitle ref={labelRef}>One Account</SubTitle>
        </SubTitleWrapper>
        <Title ref={titleRef}>Three huge benefits</Title>
        <Row>
          {ITEM_LIST.map((item, index) => (
            <Cell key={index}>
              <Item ref={cellRefList[index].cardRef}>
                <ItemIcon>
                  <img ref={cellRefList[index].iconRef} src={item.image} />
                </ItemIcon>
                <ItemTitle ref={cellRefList[index].titleRef}>
                  {item.title}
                </ItemTitle>
                <ItemText ref={cellRefList[index].textRef}>
                  {item.text}
                </ItemText>
              </Item>
            </Cell>
          ))}
        </Row>
        <Bottom>
          <ButtonNextLink to="mailto:hello@xpate.com" variant="blue">
            Contact us <ArrowButton />
          </ButtonNextLink>
        </Bottom>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 155px;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}
  
  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

const SubTitleWrapper = styled.div`
  overflow: hidden;
`;

const SubTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 100%;
  font-weight: bold;
  text-transform: uppercase;
`;

const Title = styled.h2`
  display: block;
  margin-top: 38px;
  font-size: 102px;
  line-height: 86px;
  letter-spacing: -0.02em;
  font-weight: bold;
  max-width: 520px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 30px;
    font-size: 74px;
    line-height: 100%;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
    line-height: 58px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 50px;
    line-height: 54px;
  `)}
`;

const Row = styled.div`
  display: flex;
  margin: 90px -70px 0;

  ${media.laptop(css`
    flex-wrap: wrap;
    margin: 30px -20px 0;
  `)}

  ${media.tabletSmall(css`
    margin: 10px -20px 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Cell = styled.div`
  max-width: 80%;
  flex: 1 1 400px;
  margin-top: 60px;
  padding: 0 70px;

  &:last-child {
    padding-left: 60px;
  }

  ${media.laptop(css`
    margin-top: 40px;
    padding: 0 20px;

    &:last-child {
      padding-left: 20px;
    }
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
`;

const ItemIcon = styled.div`
  width: 55px;
  height: 55px;

  i {
    width: 100%;
    height: 100%;
    display: block;
    background: ${colors.black};
    border-radius: 100%;
  }
`;

const ItemTitle = styled.span`
  font-size: 30px;
  line-height: 100%;
  font-weight: 600;
  display: block;
  margin-top: 30px;
  letter-spacing: -0.01em;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 22px;
  `)}
`;

const ItemText = styled.p`
  display: block;
  margin-top: 25px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-right: -2px;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 170px;

  ${media.laptop(css`
    margin-top: 80px;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}
  
  ${media.mobileSmall(css`
    margin-top: 40px;
  `)}
`;

export default AccountsBenefits;
