import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap from 'gsap';

import StacksTechnologyTitle from '@/modules/Stacks/components/StacksTechnology/components/StacksTechnologyTitle';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import StacksTechnologySocials from '@/modules/Stacks/components/StacksTechnology/components/StacksTechnologySocials';
import Picture from '@/components/Picture';
import Dinosaur from '@/assets/images/stacks/t-rex.png';

function StacksTechnology() {
  const imageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    textRef: sloganRef,
    titleRef: textRef,
    containerRef: textContainerRef,
  } = useSimplicityAnimation();

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const delayedCall = gsap.delayedCall(0, () => {
      if (!imageRef.current || !containerRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 150%',
            scrub: 1,
          },
        })
        .from(imageRef.current, {
          yPercent: 15,
        });
    });

    return () => {
      delayedCall?.kill();
      tl?.kill();
    };
  }, []);

  return (
    <Component>
      <StacksTechnologyTitle />
      <ContentSmallContainer>
        <GroupContent ref={containerRef}>
          <GroupContentInner>
            <TextWrapper ref={textContainerRef}>
              <Text ref={textRef}>
                Find your best way to use Stacks. Experiment! It is easy to
                manage your money with Stacks.
                <br />
                Allow yourself more with us.
              </Text>
            </TextWrapper>
            <Slogan ref={sloganRef}>join the community</Slogan>
            <StacksTechnologySocials />
          </GroupContentInner>
          <Image ref={imageRef}>
            <TransparentCircle />
            <StyledPicture src={Dinosaur} />
          </Image>
        </GroupContent>
      </ContentSmallContainer>
    </Component>
  );
}

const GroupContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GroupContentInner = styled.div`
  min-width: 515px;
`;

const Image = styled.div``;

const StyledPicture = styled(Picture)`
  position: relative;
  top: -20%;
  right: -15%;
  z-index: 5;
`;

const TransparentCircle = styled.div`
  position: absolute;
  top: -25%;
  left: 10%;
  width: 600px;
  height: 600px;
  background: rgba(255, 255, 255, 0.1);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(15px);
  border-radius: 50%;
  z-index: 4;
`;

const TextWrapper = styled.div`
  margin-top: 120px;
  max-width: 515px;
`;

const Text = styled.p`
  display: block;
  font-size: 24px;
  line-height: normal;

  * {
    font-weight: 500;
    line-height: 110%;
    overflow: hidden;
  }
`;

const Slogan = styled.span`
  margin-top: 100px;
  display: block;
  max-width: 349px;
  font-weight: 600;
  font-size: 39.9724px;
  line-height: 120%;
`;

const Component = styled.div`
  overflow: hidden;
  color: black;
  background: #f7f7f7;

  @media (min-width: 1440px) {
    ${StyledPicture} {
      img {
        max-width: initial;
      }
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 120px;

    ${GroupContent} {
      flex-direction: column;
    }

    ${Image} {
      order: -1;
    }

    ${StyledPicture} {
      top: 0;
      right: 0;
    }

    ${TransparentCircle} {
      top: -5%;
      left: -4%;
    }

    ${TextWrapper} {
      margin-top: 60px;
    }

    ${GroupContentInner} {
      min-width: initial;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;

    ${TransparentCircle} {
      width: 300px;
      height: 300px;
    }

    ${TextWrapper} {
      margin-top: 40px;
    }

    ${Text} {
      font-size: 20px;
      line-height: 26px;
    }

    ${Slogan} {
      font-size: 28px;
      line-height: 110%;
      margin-top: 40px;
    }
  }

  @media (max-width: 414px) {
    ${Text} {
      font-size: 18px;
      line-height: 145%;
    }
  }
`;

export default StacksTechnology;
