import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { max } from 'lodash';

import { media } from '@/utils/mixin';

type Props = {
  title: string;
  maxWidth?: number | null;
};

const SectionTitle = forwardRef<HTMLHeadingElement, Props>(
  ({ title, maxWidth = 550 }, ref) => (
    <Component ref={ref} maxWidth={maxWidth ? maxWidth + 'px' : '100%'}>
      {title}
    </Component>
  )
);

export const Component = styled.h2<{ maxWidth: string }>`
  font-size: 102px;
  line-height: 100%;
  font-weight: bold;
  margin-bottom: 0;
  max-width: ${(props) => props.maxWidth};
  letter-spacing: -0.02em;
  display: block;
  z-index: 1;

  ${media.laptop(css`
    font-size: 74px;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}

  ${media.mobile(css`
    font-size: 54px;
  `)}

  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

export default SectionTitle;
