import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import feature01 from '@/assets/images/singapore/feature_01.svg';
import feature02 from '@/assets/images/singapore/feature_02.svg';
import feature03 from '@/assets/images/singapore/feature_03.svg';
import feature04 from '@/assets/images/singapore/feature_04.svg';
import ArrowCircleLink from '@/components/ArrowCircleLink';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import FeaturesGrid from '@/components/FeaturesGrid';
import Link from '@/components/Link';

const SingaporeFeatures = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <Title>Overseas money transfers for businesses</Title>
        <Text>
          fast, with competitive exchange rates and{' '}
          <Link to="/xpate-sg/prices">low fees</Link> to pay.
        </Text>
        <Grid
          items={[
            { icon: feature01, title: 'Create', text: 'your xpate account' },
            { icon: feature02, title: 'Top Up', text: 'your account' },
            {
              icon: feature03,
              title: 'PayOut',
              text: 'from SGD in 20+ currencies with real exchange rate',
            },
            {
              icon: feature04,
              title: 'Save',
              text: 'on fees and grow your business',
            },
          ]}
        />
        <ButtonRow
          link="/xpate-sg/form"
          label="try for free"
          color="#955DFF"
          textDark={true}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 250px 0 310px;

  ${media.laptop(css`
    margin: 160px 0;
  `)}
  ${media.tablet(css`
    margin: 120px 0;
  `)}
  ${media.mobile(css`
    margin: 100px 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  color: #040312;
  display: block;
  width: 1144px;
  max-width: 100%;

  font-size: 102px;
  line-height: 100%;
  ${media.tablet(css`
    font-size: 70px;
  `)}
  ${media.mobile(css`
    font-size: 45px;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1px;
  color: #c2c2c8;
  margin-top: 70px;
  display: block;
  width: 1057px;
  max-width: 100%;

  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    font-size: 38px;
    margin-top: 30px;
  `)}
  
  a {
    color: #7b3dff;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const Grid = styled(FeaturesGrid)`
  margin-top: 150px;
`;

const ButtonRow = styled(ArrowCircleLink)`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 40px;
  `)}
  ${media.mobile(css`
    justify-content: flex-end;
  `)}
`;

export default SingaporeFeatures;
