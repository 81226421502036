import React, { useEffect } from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import Layout from '@/components/Layout';

function CookiePolicy() {
  const page = useCurrentPage();

  useEffect(() => {
    const script = document.createElement('script');

    script.src =
      'https://consent.cookiebot.com/22cbfb16-03e7-48d2-9c72-0496dda9dcf0/cd.js';
    script.async = true;
    script.id = 'CookieDeclaration';

    document.getElementById('container')?.appendChild(script);
  }, []);

  return (
    <Layout theme="white">
      <Container>
        <h1>{page?.title}</h1>
        <div id="container" />
      </Container>
    </Layout>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 100px auto;
`;

export default CookiePolicy;
