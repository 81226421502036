import React, { useState } from 'react';

import { colors } from '@/constants/theme';
import HomeHero from '@/modules/Home/components/HomeHero';
import HomeSupport from '@/modules/Home/components/HomeSupport';
import HomeMarkets from '@/modules/Home/components/HomeMarkets';
import HomeSimplicity from '@/modules/Home/components/HomeSimplicity';
import HomePaymentMethods from '@/modules/Home/components/HomePaymentMethods';
import HomeSections from '@/modules/Home/components/HomeSections';
import GetStarted from '@/components/GetStarted';
import Layout from '@/components/Layout';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import HomeSass from '@/modules/Home/components/HomeSass';

function Home() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="black" dark={true} onPreloaderOut={handlePreloaderOut}>
      <HomeHero animate={isPreloaderOut} />
      <HomePaymentMethods />
      <HomeSections />
      <HomeSass />
      <HomeSimplicity />
      <HomeMarkets />
      <HomeSupport />
      <GetStarted
        link="https://doc.sg.xpate.com/card_np_API_ecommerce_index.html"
        openInNewTab={true}
        color={colors.blue}
        isBig={true}
        isTextLight={true}
      >
        Easy
        <br />
        integration
      </GetStarted>
    </Layout>
  );
}

export default Home;
