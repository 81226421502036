import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Layout from '@/components/Layout';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import StacksHero from '@/modules/Stacks/components/StacksHero';
import StacksIntro from '@/modules/Stacks/components/StacksIntro';
import StacksAbout from '@/modules/Stacks/components/StacksAbout';
import StacksAdvantages from '@/modules/Stacks/components/StacksAdvantages/StacksAdvantages';
import StacksSimplicity from '@/modules/Stacks/components/StacksSimplicity';
import GetStarted, { ButtonWrapper } from '@/components/GetStarted';
import StacksServices from '@/modules/Stacks/components/StacksServices/StacksServices';
import StacksInterface from '@/modules/Stacks/components/StacksInterface';
import StacksTechnology from '@/modules/Stacks/components/StacksTechnology/StacksTechnology';
import StacksRoadmap from '@/modules/Stacks/components/StacksRoadmap/StacksRoadmap';
import { colors } from '@/constants/theme';

function Stacks() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="black" dark={true} onPreloaderOut={handlePreloaderOut}>
      <StacksHero animate={isPreloaderOut} />
      <StacksIntro />
      <StacksAbout />
      <StacksAdvantages />
      <StacksSimplicity />
      <StacksServices />
      <StacksInterface />
      <StacksTechnology />
      <StacksRoadmap />
      <GetStarted
        link="mailto:hello@xpate.com"
        customStyles={customStylesForGetStarted}
        color={colors.stacksPrimary}
        hoverColor={colors.stacksPrimaryHover}
      >
        <GetStartedText>It's time to open an account!</GetStartedText>
      </GetStarted>
    </Layout>
  );
}

const customStylesForGetStarted = css`
  @media (max-width: 768px) {
    ${ButtonWrapper} {
      display: flex;
      justify-content: flex-end;
      top: 150px !important;
    }
  }

  @media (max-width: 460px) {
    ${ButtonWrapper} {
      top: 200px !important;
    }
  }
`;

const GetStartedText = styled.p`
  max-width: 819px;
  margin-bottom: 20px;
`;

export default Stacks;
