import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';
import { nl2br } from '@tager/web-core';

import Layout from '@/components/Layout';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ThanksPageType } from '@/typings/model';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

const Thanks = () => {
  const { title, text, icon } = useCurrentPage<ThanksPageType>().templateFields;

  return (
    <Layout theme="white">
      <Container>
        <Title>{title}</Title>
        <Text>{nl2br(text)}</Text>
        {icon ? (
          <Image>
            <Picture {...convertThumbnailToPictureProps(icon)} />
          </Image>
        ) : null}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  max-width: 1200px;
  margin: 150px auto;

  ${media.mobile(css`
    margin: 100px 0;
  `)}
`;

const Title = styled.h1`
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  width: 958px;
  max-width: 100%;
  margin: 0 auto;
  ${media.tablet(css`
    font-size: 50px;
  `)}
  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const Text = styled.p`
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  width: 900px;
  max-width: 100%;
  margin: 40px auto 0;

  ${media.tablet(css`
    font-size: 24px;
  `)}
  ${media.mobile(css`
    font-size: 18px;
    max-width: 90%;
    text-align: justify;
  `)}
`;

const Image = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;

  ${media.mobile(css`
    margin-top: 20px;
  `)}

  img {
    max-width: 300px;
    ${media.mobile(css`
      max-width: 250px;
    `)}
  }
`;

export default Thanks;
