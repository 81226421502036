import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

function useIntroAnimation() {
  const textRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const bgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '15%',
        },
        0
      ).to(
        titleRef.current,
        {
          ease: 'linear',
          y: '-15%',
        },
        0
      );
    });

    return () => {
      delayCall.kill();
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitText = new SplitText(textRef.current, {
      type: 'chars, words',
    });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .from(
          bgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            duration: 2.2,
            delay: 0.2,
            scaleY: 0,
          },
          0
        )
        .from(
          splitText.chars,
          {
            stagger: 0.05,
            ease: 'customEaseInOut',
            duration: 0.6,
            opacity: 0,
            delay: 0.2,
          },
          0
        );
    });

    return () => {
      delayCall.kill();
      splitText?.revert();
      tl?.kill();
    };
  }, []);

  return {
    bgRef,
    titleRef,
    textRef,
    containerRef,
  };
}

export default useIntroAnimation;
