import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import included from '@/assets/images/pricing/included.png';
import included2x from '@/assets/images/pricing/included@2x.png';
import { ReactComponent as PricingContentArrow } from '@/assets/svg/pricing-content-arrow.svg';
import Picture from '@/components/Picture';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';

const PricingIncluded = () => {
  const {
    includedFirstTitle,
    includedSecondTitle,
    includedText,
    includedContentText,
    includedContentList,
    includedContentTitle,
    includedGrid,
  } = useCurrentPage<PricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <DoubleTitle
            firstTitle={includedFirstTitle || ''}
            secondTitle={includedSecondTitle || ''}
            styles={{
              firstTitle: {
                fontSize: '282px',
              },
              secondTitle: {
                fontSize: '282px',
              },
            }}
          />
          <Text>{includedText}</Text>
          <Grid>
            {includedGrid.map((item) => (
              <Item>
                {item.icon && item.icon.url ? (
                  <img src={item.icon.url} alt={item.text || ''} />
                ) : null}
                <ItemText>{item.text}</ItemText>
              </Item>
            ))}
          </Grid>
          <Content>
            <ContentArrow>
              <PricingContentArrow />
            </ContentArrow>

            <ContentImage src={included} src2x={included2x} />

            <ContentTitle>{includedContentTitle}</ContentTitle>
            <ContentText>{includedContentText}</ContentText>
            {includedContentList ? (
              <ContentList>
                {includedContentList.split('\n').map((item) => (
                  <ContentListItem>{item}</ContentListItem>
                ))}
              </ContentList>
            ) : null}
          </Content>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  background: ${colors.black};
  color: ${colors.white};
  padding: 180px 0;
  ${media.laptop(css`
    padding: 150px 0;
  `)}
  ${media.tablet(css`
    padding: 100px 0;
  `)}
  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Inner = styled.div``;

const Text = styled.p`
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #c2c2c8;
  margin-top: 80px;
  display: block;
  width: 1057px;
  max-width: 100%;

  ${media.tablet(css`
    font-size: 50px;
    margin-top: 50px;
  `)}
  ${media.mobile(css`
    font-size: 30px;
    margin-top: 30px;
  `)}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;
  margin-top: 90px;

  ${media.tablet(css`
    margin-top: 50px;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  `)}

  ${media.mobile(css`
    grid-template-columns: 1fr;
    gap: 40px;
  `)}
`;

const Item = styled.div`
  img {
    max-height: 70px;
  }
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  margin-top: 30px;
  ${media.mobile(css`
    font-size: 19px;
    margin-top: 20px;
  `)}
`;

const Content = styled.div`
  margin-top: 200px;
  background: #e5e8ed;
  padding: 100px 140px;
  position: relative;
  color: ${colors.black};

  ${media.tablet(css`
    padding: 60px 100px;
    margin-top: 140px;
  `)}

  ${media.mobile(css`
    padding: 80px 20px 20px;
  `)}
`;

const ContentArrow = styled.div`
  position: absolute;
  top: 115px;
  left: 54px;

  ${media.tablet(css`
    top: 65px;
    left: 24px;
  `)}

  ${media.mobile(css`
    display: none;
  `)}
`;

const ContentImage = styled(Picture)`
  position: absolute;
  top: 0;
  right: -20px;
  transform: translateY(-50%) rotate(15.22deg);
  ${media.tablet(css`
    max-width: 200px;
  `)}
`;

const ContentTitle = styled.span`
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  display: block;

  ${media.tablet(css`
    font-size: 50px;
  `)}
`;

const ContentText = styled.p`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  display: block;
  margin-top: 30px;
  ${media.tablet(css`
    font-size: 24px;
    margin-top: 20px;
  `)}
`;

const ContentList = styled.ul`
  margin-top: 40px;
  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

const ContentListItem = styled.li`
  &:not(:first-child) {
    margin-top: 15px;
  }

  font-size: 24px;
  line-height: 145%;
  position: relative;
  padding-left: 20px;

  ${media.tablet(css`
    font-size: 20px;
  `)}

  &:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 12px;
    width: 6px;
    height: 6px;
    background: ${colors.black};
    border-radius: 50%;
    ${media.tablet(css`
      top: 10px;
    `)}
  }
`;

export default PricingIncluded;
