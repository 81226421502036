import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors, fonts } from '@/constants/theme';
import Hands from '@/assets/images/xpp/xppHands.webp';
import { media } from '@/utils/mixin';

function Hero({ animate }: { animate: boolean }) {
  const titleRef = useRef<HTMLHeadingElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(innerRef.current, { y: 0 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(innerRef.current, {
        y: '25%',
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'bottom 100%',
          end: 'bottom 0',
          scrub: true,
        },
      });
    });

    return () => {
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(titleRef.current, { yPercent: 100 });

    gsap.delayedCall(0, () => {
      if (!animate) return;

      tw = gsap.to(titleRef.current, {
        ease: 'customEaseInOut',
        duration: 2.2,
        yPercent: 0,
      });
    });

    return () => {
      tw?.kill();
    };
  }, [animate]);

  return (
    <Component ref={containerRef}>
      <Wrapper ref={innerRef}>
        <ContentSmallContainer>
          <Inner>
            <TitleWrapper>
              <Title ref={titleRef}>xPP</Title>
            </TitleWrapper>
          </Inner>
        </ContentSmallContainer>
        <HandsWrapper>
          <img src={Hands} alt="hans image" />
        </HandsWrapper>
      </Wrapper>
      <BottomText>*xpate Partnership Program</BottomText>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background: ${colors.xppPrimary};
  color: ${colors.white};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto !important;
  min-height: calc(var(--vh, 1vh) * 100) !important;
`;

const Inner = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`;

const HandsWrapper = styled.div`
  img {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
    transform: translateY(-50%);
  }

  ${media.mobile(css`
    img {
      transform: translateY(-50%) scale(1.5);
    }
  `)}

  user-select: none;
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const Title = styled.h1`
  font-family: ${fonts.Gilroy};
  font-style: normal;
  font-weight: 900;
  font-size: 500px;
  text-align: center;
  line-height: 100%;
  margin: 0;

  user-select: none;

  ${media.tabletSmall(css`
    font-size: 120px;
    line-height: 100%;
  `)}
`;

const BottomText = styled.span`
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 145%;
  bottom: 100px;

  ${media.tabletSmall(css`
    bottom: 50px;
  `)}
`;

export default Hero;
