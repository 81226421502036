import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { styleForDoubleTitle } from '@/constants/common';
import SuperPowerItemList from '@/modules/Links/components/LinksItemList';
import CircleSvg from '@/assets/svg/stacks/advatages/presentation/circle.png';
import BallonImage from '@/assets/images/stacks/presentation/ballon.png';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import ArrowLinkWithLabel from '@/components/ArrowButtonLink';

import * as S from './StacksAdvantages.styles';
import { ITEM_LIST } from './StacksAdvantages.constants';

interface Props {}

function StacksAdvantages(props: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
    Array.from({ length: 2 }).map(() => React.createRef())
  );

  useEffect(() => {
    const containerElement = containerRef.current;
    const messages = messageRefs.current;

    let tl: gsap.core.Timeline;
    const tlList: Array<gsap.core.Timeline> = [];

    if (!messages || !containerElement) return;

    const delayedCall = gsap.delayedCall(0, () => {
      messages.forEach((message, index) => {
        if (!message.current) return;

        tl = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerElement,
            start: 'top 50%',
            end: 'bottom bottom',
          },
        });

        tlList[index] = tl.to(messages[index].current, {
          duration: 0.8,
          ease: 'ease',
          translateX: 0,
          scale: 1,
          opacity: 1,
          delay: index * 0.8,
        });
      }, 0.8);
    });

    return () => {
      tl?.kill();
      delayedCall?.kill();
      tlList.forEach((tl) => tl?.kill());
    };
  }, []);

  return (
    <S.Component ref={containerRef}>
      <S.Container>
        <S.Presentation>
          <S.PresentationWrapper>
            <S.PresentationInner>
              <S.PresentationVideo>
                <video
                  width="100%"
                  height="auto"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source
                    src="/static/videos/xpate-stacks.mp4"
                    type="video/mp4"
                  />
                </video>
              </S.PresentationVideo>
              <S.Messages>
                {[
                  'We have designed the interface easy enough even for a child to\n' +
                    '                use it',
                  'A Truly Magical Experience',
                ].map((string, index) => (
                  <S.Message key={index} ref={messageRefs.current[index]}>
                    <span>{string}</span>
                  </S.Message>
                ))}
              </S.Messages>
            </S.PresentationInner>
            <S.PositionElements>
              <S.CircleImage src={CircleSvg} />
              <S.Ballon className="down" src={BallonImage} />
              <S.Ballon className="up" src={BallonImage} />
              <S.Circle className="up">
                <S.CircleWrapper>
                  <ArrowIcon />
                </S.CircleWrapper>
              </S.Circle>
              <S.Circle className="down" />
            </S.PositionElements>
          </S.PresentationWrapper>
        </S.Presentation>
      </S.Container>
      <DoubleTitle
        firstTitle={'What does'}
        secondTitle={'each user get:'}
        styles={styleForDoubleTitle}
      />
      <SuperPowerItemList itemList={ITEM_LIST} />
      <ContentSmallContainer>
        <S.NavigationButtonWrapper>
          <ArrowLinkWithLabel
            to="mailto:hello@xpate.com"
            label="get in line"
            color={colors.stacksPrimary}
            hoverColor={colors.stacksPrimaryHover}
          />
        </S.NavigationButtonWrapper>
      </ContentSmallContainer>
    </S.Component>
  );
}

export default StacksAdvantages;
