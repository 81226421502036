import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Text from '@/modules/Culture/components/Text';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import consulting1Icon from '@/assets/svg/links/consulting-1.svg';
import consulting2Icon from '@/assets/svg/links/consulting-2.svg';
import consulting3Icon from '@/assets/svg/links/consulting-3.svg';
import consulting4Icon from '@/assets/svg/links/consulting-4.svg';
import consulting5Icon from '@/assets/svg/links/consulting-5.svg';
import consulting6Icon from '@/assets/svg/links/consulting-6.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/svg/arrow-4.svg';

import LinksItemList, {
  ButtonIcon,
  ButtonLabel,
  ButtonWrap,
} from './LinksItemList';

const styles = {
  firstTitle: {
    fontSize: '282px',
  },
  secondTitle: {
    fontSize: '282px',
  },
};

const ITEM_LIST = [
  {
    image: consulting1Icon,
    text: `Independent expertise and objective assessment which goes beyond the framework established by the company.`,
  },
  {
    image: consulting2Icon,
    text: `Professional consulting services on various subjects related to IPS, projects with IPS and other related partners, IPS services, rules, etc.`,
  },
  {
    image: consulting3Icon,
    text: `Extensive experience in solving problems and finding solutions related to cards and card payments in different organisations.`,
  },
  {
    image: consulting4Icon,
    text: `Preparing of detailed product and/or service research. We help acquirers and issuers to find the most suitable IPS’ products and services for their business needs.`,
  },
  {
    image: consulting5Icon,
    text: `Providing consulting on specific topics such as tools for merchant underwriting and IPS AML compliance.`,
  },
  {
    image: consulting6Icon,
    text: `Support in obtaining membership status in IPS and related partner selection.`,
  },
];

function LinksConsulting() {
  return (
    <Component>
      <DoubleTitle
        firstTitle={'Сonsulting'}
        secondTitle={'services'}
        styles={styles}
      />
      <ContentSmallContainer>
        <TextContainer>
          <TextRow>
            <Text
              classNameForBoldText="consulting-bold-text"
              classNameForText="consulting-text"
              textBold={
                'Our consultants are experts in cards acquiring, issuing and IPS’s requirements with 10+ years of experience.'
              }
              text={
                'We help acquirers and issuers to find the most suitable IPS’ products and services for their business needs.'
              }
              textBoldColor={colors.textGray}
              textColor={colors.black}
              arrow={false}
            />
          </TextRow>
        </TextContainer>
        <StyledButtonWrapper>
          <Button href="mailto:hello@xpate.com">
            <ButtonLabel>Contact us</ButtonLabel>
            <ButtonIcon>
              <ArrowSvg />
            </ButtonIcon>
          </Button>
        </StyledButtonWrapper>
        <AdvantagesBlock>
          <AdvantagesPreTitle>
            Сonsulting services <ArrowDownIcon />
          </AdvantagesPreTitle>
          <AdvantagesTitle>Advantages</AdvantagesTitle>
        </AdvantagesBlock>
        <LinksItemList itemList={ITEM_LIST} mainColor={colors.black} />
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  overflow: hidden;
  padding: 165px 0 185px;
  background: ${colors.white};
  color: ${colors.black};

  ${media.laptop(css`
    padding: 125px 0 140px;
  `)}

  ${media.tabletLarge(css`
    padding: 85px 0 100px;
  `)}

  ${media.mobile(css`
    padding: 65px 0 80px;
  `)}
`;

const StyledButtonWrapper = styled(ButtonWrap)`
  margin: 40px 0 auto;
  display: flex;
  justify-content: flex-end;

  ${ButtonLabel} {
    color: ${colors.black};
  }

  ${media.mobile(css`
    justify-content: flex-start;
    margin-left: 0;
  `)}
`;

const AdvantagesBlock = styled.div`
  margin-top: 150px;

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}
`;

const AdvantagesTitle = styled.span`
  margin-top: 40px;
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 102px;
  line-height: 104px;
  word-break: break-all;

  ${media.tabletSmall(css`
    font-size: 64px;
    line-height: 76px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
    font-size: 48px;
    line-height: 60px;
  `)}
`;

const AdvantagesPreTitle = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  svg {
    margin-left: 8px;
    margin-bottom: -2px;
  }
`;

const Button = styled.a`
  display: inline-flex;
  align-items: center;
`;

const TextRow = styled.div`
  margin-top: 152px;
  margin-left: -3px;

  ${media.laptop(css`
    margin-top: 90px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    margin-top: 70px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 50px;
  `)}
`;

const TextContainer = styled.div`
  max-width: 1165px;
  width: 100%;
`;

export default LinksConsulting;
