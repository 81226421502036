import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as SingaporePricingArrow } from '@/assets/svg/singapore-pricing-arrow.svg';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { SingaporePricingPageType } from '@/typings/model';

const SingaporePricingInfo = () => {
  const { info } = useCurrentPage<SingaporePricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        {info.map((item) => (
          <Row>
            <Name>
              {item.name} <SingaporePricingArrow />
            </Name>
            <Title>{item.title}</Title>
            <Content dangerouslySetInnerHTML={{ __html: item.content || '' }} />
          </Row>
        ))}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 150px 0 200px;
  color: #040312;

  ${media.tablet(css`
    margin: 100px 0;
  `)}

  ${media.mobile(css`
    margin: 50px 0;
  `)}
`;

const Row = styled.div`
  &:not(:first-child) {
    margin-top: 100px;
    ${media.mobile(css`
      margin-top: 50px;
    `)}
  }
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  margin-top: 30px;
  display: block;

  ${media.tablet(css`
    font-size: 36px;
  `)}
  ${media.mobile(css`
    font-size: 26px;
    margin-top: 20px;
  `)}
`;

const Content = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: #58585d;
  margin-top: 30px;

  p:not(:first-child) {
    margin-top: 20px;
  }

  ${media.tablet(css`
    font-size: 20px;
  `)}
  ${media.mobile(css`
    font-size: 18px;
    margin-top: 20px;
  `)}
`;

export default SingaporePricingInfo;
