import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

export const Component = styled.div`
  padding: 140px 0;
  color: #040312;
  background: #fff;
  overflow: hidden;

  ${media.tabletSmall(css`
    padding: 100px 0;
  `)}
  ${media.mobile(css`
    padding: 60px 0 0 0;
  `)}
`;

export const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    max-width: 1068px;
    margin-left: auto;
  `)}

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

export const Left = styled.div``;

export const Right = styled.div`
  padding-top: 87px;

  ${media.laptop(css`
    padding-top: 60px;
    margin: 0 5%;
  `)}

  ${media.tabletSmall(css`
    padding: 0;
    margin: 0;
  `)}
`;

export const TextWrapper = styled.div`
  overflow: hidden;
`;

export const Text = styled.p`
  max-width: 541px;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: -0.015em;
  line-height: 40px;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    max-width: 444px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    max-width: 424px;
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 514px;
  `)}

  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

export const Title = styled.h2`
  display: block;
  margin-top: 45px;
  margin-left: -10px;
  font-size: 114px;
  letter-spacing: -0.01em;
  line-height: 106px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-left: 0;
    margin-top: 30px;
    font-size: 74px;
    line-height: 66px;
  `)}

  ${media.tabletLarge(css`
    margin-bottom: 40px;
    font-size: 64px;
    line-height: 56px;
  `)}

  ${media.mobileSmall(css`
    margin-bottom: 30px;
    font-size: 54px;
    line-height: 46px;
  `)}
`;
