import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import { createMediaMixin } from '@tager/web-components';

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-header.svg';
import { media } from '@/utils/mixin';
import { colors, fonts } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { scrollDown } from '@/utils/common';
import handImage from '@/assets/images/careers/hand-careers.png';
import { CareersPageType } from '@/typings/model';

function CareersHero({ animate }: { animate: boolean }) {
  const page = useCurrentPage<CareersPageType>();
  const pageTitle = page?.title;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  gsap.set(buttonRef.current, { scale: 0 });

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.set(buttonRef.current, { scale: 0 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(
          buttonRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.2,
            delay: 0.65,
            scale: 1,
          },
          0
        );
    });
    return () => {
      tl?.kill();
    };
  }, [animate]);

  return (
    <Container ref={containerRef}>
      <MainWrapper>
        <InnerWrapper>
          <WrapperTitle>
            <Title>{pageTitle}</Title>
          </WrapperTitle>
          <WrapperImage>
            <Image>
              <img src={handImage} alt="hand" />
            </Image>
          </WrapperImage>
        </InnerWrapper>
        <Arrow ref={buttonRef} onClick={scrollDown}>
          <ArrowIcon />
        </Arrow>
      </MainWrapper>
    </Container>
  );
}

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${createMediaMixin({ min: 1024 })(css`
    padding-top: 112px;
  `)}

  ${media.tabletSmall(css`
    padding-top: 85px;
  `)}

  ${media.mobile(css`
    padding-top: 75px;
  `)}
`;

const Image = styled.div`
  position: absolute;
  right: 98px;
  bottom: 0;

  ${createMediaMixin({ max: 1600 })(css`
    img {
      max-height: 400px;
    }
  `)}

  ${media.tabletSmall(css`
    max-width: 80%;
    max-height: 80vh;
    bottom: -85px;
  `)}

  ${media.mobile(css`
    max-width: 80%;
    top: 32%;
    right: 30px;
    bottom: 0;
  `)}

  ${createMediaMixin({ max: 416 })(css`
    max-width: 50%;
    top: 50%;
  `)}

  ${media.mobileSmall(css`
    max-width: 48%;
    top: 52%;
  `)}
`;

const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const WrapperImage = styled.div`
  position: absolute;
  width: 50%;
  left: 50%;
  top: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;

  ${media.mobile(css`
    width: 100%;
    left: 0;
  `)}
`;

const MainWrapper = styled.div`
  position: relative;
  max-width: 1552px;
  width: 100%;
  height: auto;
  margin: 0 auto 0;
  background: #1442fa;

  min-height: calc(var(--vh, 1vh) * 100 - 125px);
  ${createMediaMixin({ min: 1024 })(css`
    min-height: 630px;
  `)}
  ${createMediaMixin({ max: 1600 })(css`
    min-height: 500px;
  `)}
  ${createMediaMixin({ max: 1400 })(css`
    min-height: 400px;
  `)}
  ${createMediaMixin({ max: 767 })(css`
    min-height: 75vh;
  `)}
`;

const WrapperTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  padding-bottom: 75px;
  max-height: calc(var(--vh, 1vh) * 100 - 50px);

  ${media.mobile(css`
    padding-bottom: 100px;
  `)}
`;

const Title = styled.h1`
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  font-size: 292px;
  line-height: 100%;
  text-align: center;
  transform: translateY(38px) translateX(10px);
  color: ${colors.white};
  margin: 0;

  @media (max-width: 1470px) {
    font-size: 222px;
  }

  ${media.laptop(css`
    font-size: 202px;
  `)}

  ${media.tabletLarge(css`
    font-size: 190px;
  `)}

  ${media.tabletSmall(css`
    font-size: 172px;
  `)}

  ${media.mobile(css`
    font-size: 87px;
    white-space: normal;
    text-align: center;
    display: block;
    margin-left: -32px;
  `)}

  ${media.mobileSmall(css`
    font-size: 71px;
    margin-left: -25px;
  `)}
`;

const Arrow = styled.button`
  position: absolute;
  width: 95px;
  height: 131px;
  left: 7.5%;
  bottom: -57px;
  color: ${colors.black};
  z-index: 500;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    bottom: -50px;
    width: 74px;
    height: 96px;
  `)}

  ${media.tabletSmall(css`
    width: 64px;
    height: 86px;
  `)}

  ${media.mobile(css`
    bottom: -40px;
    width: 54px;
    height: 76px;
  `)}
`;

export default CareersHero;
