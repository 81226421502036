import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';
import { convertThumbnailToPictureProps } from '@tager/web-modules';

import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import LearnMoreButton from '@/components/LearnMoreButton';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import { scrollDown } from '@/utils/common';

const LandingHero = () => {
  const {
    heroImage,
    heroText,
    heroTitle,
    heroButtonLink,
    heroButtonLabel,
    heroButtonNewTab,
  } = useCurrentPage<LandingTemplatePageType>().templateFields;

  return (
    <Component>
      <Background>
        <Picture {...convertThumbnailToPictureProps(heroImage)} />
      </Background>
      <ContentSmallContainer>
        <Inner>
          <Title>{heroTitle}</Title>
          <Text>{nl2br(heroText)}</Text>
          <ButtonRow>
            <LearnMoreButton
              to={heroButtonLink}
              label={heroButtonLabel}
              variant="whiteRounded58"
              newTab={heroButtonNewTab}
            />
          </ButtonRow>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  overflow: hidden;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 50%;

  ${media.tablet(css`
    position: static;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 40);

    img {
      max-height: calc(var(--vh, 1vh) * 40);
    }
  `)}

  ${media.mobile(css`
    height: calc(var(--vh, 1vh) * 60);
    position: absolute;

    img {
      max-height: calc(var(--vh, 1vh) * 60);
      max-width: none;
    }
  `)}
`;

const Inner = styled.div`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
  position: relative;
  padding-top: 113px;

  ${media.tablet(css`
    padding-top: 0;
    min-height: calc(var(--vh, 1vh) * 40);
  `)}

  ${media.mobile(css`
    justify-content: flex-end;
    padding-bottom: 30px;
    padding-top: calc(var(--vh, 1vh) * 40);
    min-height: calc(var(--vh, 1vh) * 100);
  `)}
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 120px;
  line-height: 100%;
  display: block;
  margin: 0;
  width: 800px;
  max-width: 100%;

  ${media.laptop(css`
    font-size: 90px;
  `)}

  ${media.tablet(css`
    width: 100%;
    font-size: 60px;
  `)}
  
  ${media.mobile(css`
    width: 100%;
    font-size: 50px;
  `)}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  display: block;
  width: 800px;
  max-width: 100%;
  margin-top: 50px;

  ${media.laptop(css`
    margin-top: 40px;
    font-size: 20px;
  `)}

  ${media.tablet(css`
    margin-top: 30px;
    font-size: 16px;
  `)}
`;

const ButtonRow = styled.div`
  margin-top: 60px;
  ${media.laptop(css`
    margin-top: 40px;
  `)}
  ${media.tablet(css`
    margin-top: 30px;
  `)}
`;

export default LandingHero;
