import { Nullish } from '@tager/web-core';

import { monthMap } from '@/modules/ReleaseNotes/components/ReleaseNotesHero.constants';
import { ReleaseNotesNewsItemModel } from '@/typings/model';

export function convertedNotesDate(date: Nullish<string>) {
  if (!date) return null;

  const splitDate = date.split('-');

  const day = splitDate[2].replace('0', '');
  const month = splitDate[1];
  const year = splitDate[0];

  return `${monthMap[month]} ${day}, ${year}`;
}

export function sortByDate(array: ReleaseNotesNewsItemModel[]) {
  return array?.sort(
    (a, b) =>
      new Date(b.date ?? '').getTime() - new Date(a.date ?? '').getTime()
  );
}
