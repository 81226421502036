import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import ArrowCircleLink from '@/components/ArrowCircleLink';
import { media } from '@/utils/mixin';
import licenseImg from '@/assets/images/singapore/license.png';
import scrollManager from '@/services/scroll';

const SingaporeLicense = () => {
  const onFaqClick = () => {
    const element = document.getElementById('faq');
    if (!element) return;

    scrollManager.getLocomotiveScroll()?.scrollTo(element);
  };
  return (
    <Component>
      <ContentSmallContainer>
        <Title>
          We are a licensed financial institution
          <img src={licenseImg} />
        </Title>
        <Text>
          which is regulated by the Monetary Authority of Singapore as a Major
          Payment Institution
        </Text>
        <Notes>
          <p>
            We follow strict regulation supervised by the Monetary Authority of
            Singapore under license (No. 201808498D) registered in Singapore for
            Novum Financial Technologies Pte. Ltd.
          </p>
          <p>
            xpate is a payments platform that prioritises simplicity, speed and
            user experience. We always act fairly to our client and protect
            their interests.
          </p>
        </Notes>
        <Button
          label={'learn more'}
          onClick={onFaqClick}
          link={'#faq'}
          color={'#955DFF'}
          textDark={false}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  overflow: hidden;

  padding: 250px 0;
  ${media.laptop(css`
    padding: 200px 0;
  `)}
  ${media.tablet(css`
    padding: 120px 0;
  `)}
  ${media.mobile(css`
    padding: 90px 0;
  `)}
  
  background: #040312;
  color: #fff;
`;

const Title = styled.h2`
  font-weight: 700;
  width: 950px;
  max-width: 100%;
  position: relative;

  font-size: 102px;
  line-height: 100%;
  ${media.tablet(css`
    font-size: 70px;
    width: 100%;
    padding-right: 200px;
  `)}

  ${media.mobile(css`
    font-size: 45px;
    padding-right: 20px;
  `)}
  
  img {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(100%, -50%) rotate(9.23deg);
    ${media.tablet(css`
      transform: translate(0, -50%) rotate(9.23deg);
      top: 50%;
      right: 0;
    `)}
    ${media.mobile(css`
      top: 20px;
      right: -20px;
      max-width: 132px;
    `)}
  }
`;

const Text = styled.p`
  color: #c2c2c8;
  line-height: 100%;
  letter-spacing: -1px;
  color: #c2c2c8;
  display: block;
  font-weight: 700;

  margin-top: 80px;
  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    font-size: 38px;
    margin-top: 40px;
  `)}
`;

const Notes = styled.div`
  margin-top: 65px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 168px;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  ${media.laptop(css`
    gap: 100px;
  `)}
  ${media.tablet(css`
    gap: 40px;
    grid-template-columns: 1fr;
    font-size: 21px;
    margin-top: 40px;
  `)}
  ${media.mobile(css`
    gap: 20px;
    font-size: 18px;
  `)}
`;

const Button = styled(ArrowCircleLink)`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
    justify-content: flex-end;
    margin-right: 10px;
  `)}
`;

export default SingaporeLicense;
