import React from 'react';

import Layout from '@/components/Layout';
import SingaporePricingHeader from '@/modules/SingaporePricing/components/SingaporePricingHeader';
import SingaporePricingTariffs from '@/modules/SingaporePricing/components/SingaporePricingTariffs';
import SingaporePricingButton from '@/modules/SingaporePricing/components/SingaporePricingButton';
import SingaporePricingInfo from '@/modules/SingaporePricing/components/SingaporePricingInfo';
import SingaporePricingNavigation from '@/modules/SingaporePricing/components/SingaporePricingNavigation';
import SingaporeCta from '@/modules/Singapore/components/SingaporeCta';

function SingaporePricing() {
  return (
    <Layout theme="white" dark={false}>
      <SingaporePricingNavigation />
      <SingaporePricingHeader />
      <SingaporePricingTariffs />
      <SingaporePricingButton />
      <SingaporePricingInfo />
      <SingaporeCta />
    </Layout>
  );
}

export default SingaporePricing;
