import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-text.svg';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

type Props = {
  width?: number;
  textBold: string;
  text: string;
  arrow: boolean;
  textBoldColor?: string;
  textColor?: string;
  classNameForBoldText?: string;
  classNameForText?: string;
};

function Text({
  textBold,
  text,
  width,
  arrow,
  textBoldColor,
  textColor,
  classNameForBoldText,
  classNameForText,
}: Props) {
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTextParent = new SplitText(textRef.current, {
      wordsClass: 'lineParent',
      type: 'words',
    });

    const splitTextChild = new SplitText(textRef.current, {
      wordsClass: 'lineChild',
      type: 'words',
    });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.from(splitTextChild.words, {
        stagger: 0.05,
        ease: 'customEaseInOut',
        transformOrigin: '50% 100%',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      delayCall.kill();
      splitTextParent?.revert();
      splitTextChild?.revert();
      tw?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef} width={width}>
      <TextWrapper ref={textRef}>
        <Bold className={classNameForBoldText} textBoldColor={textBoldColor}>
          {textBold}
        </Bold>{' '}
        <TextNode className={classNameForText} textColor={textColor}>
          {text}
        </TextNode>
      </TextWrapper>
      {arrow ? (
        <Arrow>
          <ArrowIcon />
        </Arrow>
      ) : null}
    </Component>
  );
}

const Component = styled.p<{ width?: number }>`
  max-width: ${(props) => (props.width ? props.width + 'px' : '100%')};
  font-size: 72px;
  line-height: 100%;
  display: block;
  letter-spacing: -0.015em;
  color: ${colors.textGray};
  font-weight: 600;

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

const TextWrapper = styled.div`
  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }
`;

const Bold = styled.b<{ textBoldColor: string | undefined }>`
  font-weight: bold;
  color: ${({ textBoldColor }) =>
    !textBoldColor ? colors.black : textBoldColor};
`;

const Arrow = styled.div`
  margin-top: 54px;
  margin-left: -5px;
  color: #000;

  ${media.laptop(css`
    margin-left: 0;
  `)}

  ${media.mobile(css`
    margin-top: 40px;

    svg {
      width: 42px;
      height: 50px;
    }
  `)}
`;

const TextNode = styled.span<{ textColor: string | undefined }>`
  color: ${({ textColor }) => (!textColor ? colors.textGray : textColor)};
`;

export default Text;
