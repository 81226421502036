import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-header.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { OverviewPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { scrollDown } from '@/utils/common';

function OverviewHero() {
  const page = useCurrentPage<OverviewPageType>();
  if (!page) return null;

  const { theme } = page.templateFields;

  let themeColor = null;
  if (theme === 'acquiring') {
    themeColor = colors.acquiringPrimary;
  } else if (theme === 'accounts') {
    themeColor = colors.accountsPrimary;
  } else if (theme === 'exchanges') {
    themeColor = colors.exchangesPrimary;
  } else if (theme === 'links') {
    themeColor = colors.linksPrimary;
  }

  return (
    <Component color={themeColor}>
      <Inner>
        <ContentSmallContainer>
          <Wrapper>
            <Title>Overview</Title>
          </Wrapper>
        </ContentSmallContainer>
      </Inner>
      <ContentSmallContainer>
        <ArrowWrapper>
          <Arrow onClick={scrollDown}>
            <ArrowIcon />
          </Arrow>
        </ArrowWrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section<{ color?: any }>`
  position: relative;
  background: ${(props) => props.color};
`;

const Inner = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 689px;
  max-height: calc(var(--vh, 1vh) * 100 - 50px);
`;

const Title = styled.h1`
  font-size: 292px;
  line-height: 100%;
  font-weight: bold;
  transform: translateY(38px) translateX(10px);
  text-align: center;

  @media (max-width: 1470px) {
    font-size: 222px;
  }

  @media (max-width: 1470px) {
    font-size: 222px;
  }

  ${media.laptop(css`
    font-size: 172px;
  `)}

  ${media.tabletLarge(css`
    font-size: 152px;
  `)}

  ${media.tabletSmall(css`
    font-size: 122px;
  `)}

  ${media.mobile(css`
    font-size: 71px;
    white-space: normal;
    text-align: center;
    display: block;
    margin-left: -32px;
  `)}

  ${media.mobileSmall(css`
    font-size: 61px;
    margin-left: -25px;
  `)}
`;

const ArrowWrapper = styled.div`
  position: relative;
`;

const Arrow = styled.button`
  position: absolute;
  bottom: -70px;
  right: 0;
  width: 95px;
  height: 131px;
  color: ${colors.black};

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    bottom: -50px;
    width: 74px;
    height: 96px;
  `)}

  ${media.tabletSmall(css`
    width: 64px;
    height: 86px;
  `)}

  ${media.mobile(css`
    bottom: -40px;
    width: 54px;
    height: 76px;
  `)}
`;

export default OverviewHero;
