import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  items: Array<{
    icon: string;
    title: string;
    text: string;
  }>;
};

const FeaturesGrid: React.FC<Props> = ({ items, ...props }) => {
  return (
    <Grid {...props}>
      {items.map((item, index) => (
        <Item>
          <ItemIcon>
            <img src={item.icon} alt={item.title} />
          </ItemIcon>
          <ItemTitle>{item.title}</ItemTitle>
          <ItemText>{item.text}</ItemText>
          <ItemNumber>0{index + 1}</ItemNumber>
        </Item>
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;
  ${media.laptop(css`
    margin-top: 100px;
  `)}
  ${media.tablet(css`
    margin-top: 80px;
    gap: 20px;
  `)}
  ${media.mobile(css`
    grid-template-columns: 1fr;
    margin-top: 60px;
  `)}
`;

const Item = styled.div`
  background: #f6f6f6;
  padding: 70px 70px 140px 70px;
  min-height: 564px;
  position: relative;
  color: #040312;

  ${media.tablet(css`
    padding: 30px 30px 60px;
    min-height: 300px;
  `)}

  ${media.mobile(css`
    padding: 80px 20px 100px;
    min-height: 420px;
  `)}
`;

const ItemIcon = styled.div`
  margin-bottom: 30px;
  ${media.tablet(css`
    margin-bottom: 20px;
  `)}
`;

const ItemTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 30px;
  line-height: 100%;
  margin-bottom: 30px;

  ${media.tablet(css`
    font-size: 26px;
    margin-bottom: 20px;
  `)}
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: #040312;
  display: block;
  ${media.tablet(css`
    font-size: 18px;
  `)}
`;

const ItemNumber = styled.span`
  position: absolute;
  right: 70px;
  bottom: 70px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  ${media.tablet(css`
    right: 30px;
    bottom: 30px;
  `)}
`;

export default FeaturesGrid;
