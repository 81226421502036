import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import SectionName from '@/components/SectionName';
import SectionTitle from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowBottomIcon } from '@/assets/svg/arrow-usp-1.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AboutPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AboutCompany() {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const contentBgRef = useRef<HTMLDivElement>(null);
  const contentTextRef = useRef<HTMLParagraphElement>(null);
  const contentArrowRef = useRef<HTMLDivElement>(null);

  const page = useCurrentPage<AboutPageType>();

  useEffect(() => {
    if (!page) return;

    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const splitContentTextChild = new SplitText(contentTextRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitContentTextParent = new SplitText(contentTextRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 87%',
          },
        })
        .from(
          splitTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            duration: 1.45,
            yPercent: 100,
          },
          0
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            duration: 1.25,
            yPercent: 100,
            delay: 0.1,
          },
          1
        )
        .from(
          contentBgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '0 50%',
            duration: 1.55,
            scaleX: 0,
          },
          0.5
        )
        .from(
          splitContentTextChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
            delay: 0.45,
          },
          0.5
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      splitContentTextParent?.revert();
      splitContentTextChild?.revert();
      delayedCall?.kill();
      tl?.kill();
    };
  }, [page]);

  if (!page) return null;

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        {page.templateFields.companyLabel ? (
          <LabelWrapper>
            <SectionName
              ref={labelRef}
              label={page.templateFields.companyLabel}
            />
          </LabelWrapper>
        ) : null}
        {page.templateFields.companyTitle ? (
          <Title>
            <SectionTitle
              ref={titleRef}
              title={page.templateFields.companyTitle}
            />
            <TitleWord>xpate</TitleWord>
          </Title>
        ) : null}
      </ContentSmallContainer>
      <Main>
        <ContentSmallContainer>
          <MainInner>
            <Content>
              <ContentBackground ref={contentBgRef} />
              <ArrowContentText ref={contentArrowRef}>
                <ArrowBottomIcon />
              </ArrowContentText>
              <ContentInner ref={contentTextRef}>
                {page.templateFields.companyText}
              </ContentInner>
            </Content>
          </MainInner>
        </ContentSmallContainer>
      </Main>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 147px;
  overflow: hidden;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

const LabelWrapper = styled.div`
  overflow: hidden;
`;

const Title = styled.div`
  position: relative;
  margin-top: 35px;
  z-index: 1;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 30px;
  `)}
`;

const Main = styled.div`
  margin-top: 95px;
  background: ${colors.grayLight};
  padding-bottom: 140px;

  ${media.laptop(css`
    padding-bottom: 120px;
  `)}

  ${media.tabletSmall(css`
    padding-bottom: 0;
  `)}
`;

const TitleWord = styled.span`
  font-size: 280px;
  line-height: 100%;
  display: block;
  color: rgba(0, 0, 0, 0.03);
  letter-spacing: -0.02em;
  font-weight: bold;
  position: absolute;
  left: 280px;
  top: -80px;
  z-index: -1;

  ${media.laptop(css`
    top: -60px;
    left: 200px;
    font-size: 210px;
  `)}

  ${media.tabletLarge(css`
    top: -40px;
    left: 200px;
    font-size: 140px;
  `)}
  
  ${media.mobile(css`
    top: auto;
    left: 0;
    bottom: -20px;
    font-size: 100px;
  `)}
`;

const Content = styled.div`
  position: relative;
  padding: 50px 135px 95px 60px;
  margin-top: -125px;
  width: 901px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  margin-right: -138px;
  letter-spacing: -0.01em;
  color: ${colors.white};
  z-index: 0;

  p:not(:first-child) {
    margin-top: 30px;
  }

  ${media.laptop(css`
    margin-top: -65px;
    margin-right: -85px;
  `)}

  ${media.tabletLarge(css`
    margin-top: -35px;
    margin-right: -55px;
    font-size: 20px;
    line-height: 30px;

    p:not(:first-child) {
      margin-top: 20px;
    }
  `)}
  
  ${media.tabletSmall(css`
    padding: 50px 0;
    margin-top: -45px;
    margin-right: 0;
  `)}
`;

const ContentBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.blue};
  z-index: -1;

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}
`;

const ArrowContentText = styled.div`
  display: inline-flex;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tabletLarge(css`
    width: 36px;
    height: 44px;
  `)}
`;

const ContentInner = styled.div`
  margin-top: 25px;

  .lineParent {
    overflow: hidden;
  }
`;

const MainInner = styled.div`
  display: flex;
  justify-content: flex-end;

  ${media.tabletSmall(css`
    justify-content: flex-start;
  `)}
`;

export default AboutCompany;
