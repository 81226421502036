import React from 'react';

import { nl2br } from '@tager/web-core';

import GetStarted from '@/components/GetStarted';
import useCurrentPage from '@/hooks/useCurrentPage';
import { MakePaymentsPageType } from '@/typings/model';

const MakePaymentsCallToAction = () => {
  const { ctaLink, ctaText, ctaOpenInNewTab } =
    useCurrentPage<MakePaymentsPageType>().templateFields;
  if (!ctaText || !ctaLink) return null;

  return (
    <GetStarted
      link={ctaLink}
      openInNewTab={ctaOpenInNewTab}
      color="#3BDAFF"
      hoverColor="#3BDAFF"
      isBig={true}
      isTextLight={false}
      buttonPosition={'bottom'}
    >
      {nl2br(ctaText)}
    </GetStarted>
  );
};

export default MakePaymentsCallToAction;
