import React from 'react';

import { nl2br } from '@tager/web-core';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';

const PricingCta = () => {
  const { ctaLink, ctaText, ctaOpenInNewTab } =
    useCurrentPage<PricingPageType>().templateFields;

  if (!ctaLink || !ctaText) return null;

  return (
    <GetStarted
      link={ctaLink}
      openInNewTab={ctaOpenInNewTab}
      color={colors.blue}
      hoverColor={colors.blueHover}
      isBig={false}
      isTextLight={true}
      buttonPosition={'bottom'}
    >
      {nl2br(ctaText)}
    </GetStarted>
  );
};

export default PricingCta;
