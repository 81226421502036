import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ContentDoubleTitle from '@/modules/Culture/components/ContentDoubleTitle';
import CultureSynergy from '@/modules/Culture/components/CultureSynergy';
import Text from '@/modules/Culture/components/Text';
import ContentSmallContainer from '@/components/ContentSmallContainer';

const styles = {
  firstTitle: {
    fontSize: '282px',
  },
  secondTitle: {
    fontSize: '282px',
  },
};

function CultureSynergyBlock() {
  return (
    <Component>
      <ContentDoubleTitle
        firstTitle={'synergy'}
        secondTitle={'of equals'}
        styles={styles}
      />
      <ContentSmallContainer>
        <TextContainer>
          <TextRow>
            <Text
              textBold={'We are all different, yet equal'}
              text={'And when we do something together, no one can beat us! '}
              arrow={false}
              textBoldColor={colors.white}
              textColor={colors.textGray}
            />
          </TextRow>
        </TextContainer>
      </ContentSmallContainer>
      <CultureSynergy />
    </Component>
  );
}

const Component = styled.section`
  background: ${colors.black};
  padding: 165px 0 180px;
  color: ${colors.white};
  margin-bottom: 20px;

  ${media.laptop(css`
    margin-bottom: 0;
    padding: 125px 0 140px;
  `)}
  ${media.tabletLarge(css`
    padding: 105px 0 120px;
  `)}
  ${media.tabletLarge(css`
    padding: 85px 0 100px;
  `)}
  ${media.mobile(css`
    padding: 65px 0 80px;
  `)}
`;

const TextRow = styled.div`
  margin-top: 152px;
  margin-left: -3px;

  ${media.laptop(css`
    margin-top: 90px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    margin-top: 70px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 50px;
  `)}
`;

const TextContainer = styled.div`
  max-width: 973px;
  width: 100%;
`;

export default CultureSynergyBlock;
