import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  href: string;
  label: Nullable<string>;
  color?: string;
};

function LinkWithUnderlineLine({ href, label, color = colors.blue75 }: Props) {
  return (
    <Component href={href} customColor={color}>
      {label}
    </Component>
  );
}

const Component = styled.a<{ customColor: string }>`
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -0.01em;
  font-weight: bold;
  position: relative;

  text-decoration: none;
  cursor: pointer;
  background-size: 100% 200%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    to top,
    ${(props) => props.customColor} 50%,
    rgba(0, 0, 0, 0) 50.1%
  );
  background-position: left 0 bottom 60%;
  transition: background 340ms cubic-bezier(0.315, 0.61, 0.355, 1);

  &:focus,
  &:hover {
    outline: none;
  }

  &:hover {
    background-size: 100% 200%;
    background-position: left 0 top 60%;
  }

  ${media.laptop(css`
    font-size: 64px;
  `)}

  ${media.tabletLarge(css`
    font-size: 56px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 48px;
  `)}
  
  ${media.mobile(css`
    font-size: 36px;
  `)}
`;

export default LinkWithUnderlineLine;
