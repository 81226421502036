import React from 'react';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import Layout from '@/components/Layout';
import IndustryHero from '@/modules/Industry/components/IndustryHero';
import IndustryText from '@/modules/Industry/components/IndustryText';
import IndustryGrid from '@/modules/Industry/components/IndustryGrid';

function Industry() {
  return (
    <Layout theme="black" dark={true}>
      <IndustryHero />
      <IndustryText />
      <IndustryGrid />

      <GetStarted
        color={colors.blue}
        isTextLight={true}
        link="mailto:hello@xpate.com"
      >
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Industry;
