import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { IndustryPageType } from '@/typings/model';
import Link from '@/components/Link';
import { media } from '@/utils/mixin';

function IndustryGrid() {
  const page = useCurrentPage<IndustryPageType>();
  if (!page) return null;

  const grid = page.templateFields.grid ?? [];

  return (
    <Component>
      <ContentSmallContainer>
        <Grid>
          {grid.map((cell, index) => (
            <Cell>
              <Card>
                {cell.icon ? (
                  <Icon>
                    <img src={cell.icon.url} />
                  </Icon>
                ) : null}
                <CardTitle>{cell.title}</CardTitle>
                <CardText>{cell.text}</CardText>
                <CardNumber>0{index + 1}</CardNumber>
              </Card>
            </Cell>
          ))}
          <Cell>
            <StartCard to="mailto:hello@xpate.com">
              <StartCardTitle>Contact us</StartCardTitle>
            </StartCard>
          </Cell>
        </Grid>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  background: ${colors.white};
  color: ${colors.black};
  padding: 135px 0 150px;

  ${media.laptop(css`
    padding: 120px 0 135px;
  `)}

  ${media.tabletSmall(css`
    padding: 100px 0 115px;
  `)}

  ${media.mobile(css`
    padding: 60px 0 75px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -48px -24px 0;

  ${media.laptop(css`
    margin-top: -40px;
    margin-left: -20px;
    margin-right: -20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: -24px;
    margin-left: -12px;
    margin-right: -12px;
  `)}
`;

const Cell = styled.div`
  flex-basis: 50%;
  max-width: 100%;
  margin-top: 48px;
  padding: 0 24px;

  ${media.laptop(css`
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Icon = styled.div`
  height: 48px;
  min-width: 48px;
`;

const Card = styled.div`
  position: relative;
  min-height: 624px;
  height: 100%;
  padding: 77px 10px 20px 70px;
  z-index: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.gray};
    z-index: -1;
  }

  ${media.laptop(css`
    min-height: 460px;
    padding: 72px 50px 90px;
  `)}

  ${media.tabletLarge(css`
    min-height: 360px;
    padding: 72px 40px 90px;
  `)}

  ${media.tabletSmall(css`
    min-height: 340px;
    padding-top: 60px;
  `)}

  ${media.mobile(css`
    padding: 40px 0 90px;

    &:after {
      margin: 0 -20px;
    }
  `)}
`;

const CardTitle = styled.span`
  font-size: 30px;
  display: block;
  font-weight: 600;
  margin-top: 40px;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    font-size: 26px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const CardNumber = styled.span`
  position: absolute;
  right: 65px;
  bottom: 65px;
  font-size: 16px;
  line-height: 22px;
  color: ${colors.black};
  font-weight: 600;

  ${media.laptop(css`
    right: 45px;
    bottom: 45px;
  `)}

  ${media.mobile(css`
    right: 0;
  `)}
`;

const CardText = styled.p`
  display: block;
  margin-top: 30px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  max-width: 470px;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

const StartCard = styled(Link)`
  position: relative;
  min-height: 624px;
  height: 100%;
  display: block;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 320px 320px;
    border-color: transparent transparent ${colors.black} transparent;
  }

  ${media.laptop(css`
    min-height: 460px;

    &:before {
      border-width: 0 0 290px 290px;
    }
  `)}

  ${media.tabletLarge(css`
    min-height: 360px;

    &:before {
      border-width: 0 0 260px 260px;
    }
  `)}

  ${media.tabletSmall(css`
    &:before {
      border-width: 0 0 230px 230px;
    }
  `)}

  ${media.tabletSmall(css`
    &:before {
      border-width: 0 0 170px 170px;
    }
  `)}
`;

const StartCardTitle = styled.span`
  font-size: 134px;
  display: block;
  white-space: nowrap;
  font-weight: 500;
  transform: translateY(-20px);
  letter-spacing: 0.015em;

  ${media.laptop(css`
    font-size: 94px;
  `)}

  ${media.tabletLarge(css`
    font-size: 74px;
  `)}

  ${media.tabletSmall(css`
    font-size: 64px;
  `)}

  ${media.mobile(css`
    font-size: 60px;
  `)}
`;

export default IndustryGrid;
