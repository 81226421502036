import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Icon01 from '@/assets/images/exchanges/icon_01.svg';
import Icon02 from '@/assets/images/exchanges/icon_02.svg';
import Icon03 from '@/assets/images/exchanges/icon_03.svg';

gsap.registerPlugin(ScrollTrigger, SplitText);

const ITEM_LIST = [
  {
    image: Icon01,
    title: 'Time is money',
    text: `That’s why we find the very best exchange rate at the very moment you do the deal. It means you don’t waste time finding the best rates; instead, you spend time focussing on growing your business.`,
  },
  {
    image: Icon02,
    title: 'A single solution',
    text: ` With xpate you can exchange more than 30 currencies. And you won’t stop there: you can go on to&nbsp;make purchases in numerous local currencies and carry out any&nbsp;number of international transactions.`,
  },
  {
    image: Icon03,
    title: 'We put security first',
    text: `As all of your transactions are online, there are none of the risks associated with the handling, transportation of storage of cash. Simultaneously, we maintain full reserves, enjoy healthy banking relationships around the globe and meet PCI DSS Level 1: the highest level of compliance.`,
  },
];

function ExchangesAdvantages() {
  const titleRef = useRef<HTMLHeadingElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: ITEM_LIST.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tw = gsap.from(splitTitleChild.lines, {
        stagger: 0.15,
        ease: 'customEaseInOut',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.45,
            delay: index / 8,
            scale: 0,
          },
          0
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            yPercent: 100,
            duration: 1.45,
          },
          0.2
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        );
      });
    });

    return () => {
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component>
      <ContentContainer>
        <Inner>
          <Title ref={titleRef}>Advantages</Title>
          <Row>
            {ITEM_LIST.map((item, index) => (
              <Cell key={index}>
                <Item ref={cellRefList[index].cardRef}>
                  <ItemIcon>
                    <img ref={cellRefList[index].iconRef} src={item.image} />
                  </ItemIcon>
                  <ItemTitle
                    ref={cellRefList[index].titleRef}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <ItemText
                    ref={cellRefList[index].textRef}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                </Item>
              </Cell>
            ))}
          </Row>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 140px;
  background: ${colors.grayLight};

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Inner = styled.div`
  position: relative;
  padding: 160px 130px 190px;
  z-index: 0;

  ${media.laptop(css`
    padding: 90px 100px 120px;
  `)}

  ${media.tabletSmall(css`
    padding: 60px 0 80px;
  `)}

  ${media.mobile(css`
    padding: 40px 0;
  `)}
`;

const Title = styled.h2`
  font-size: 102px;
  font-weight: bold;
  display: block;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 74px;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}

  ${media.mobile(css`
    font-size: 54px;
  `)}

  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

const Row = styled.div`
  display: flex;
  margin: 110px -60px 0;

  ${media.laptop(css`
    flex-wrap: wrap;
    margin: 30px -40px 0;
  `)}

  ${media.tabletSmall(css`
    margin: 10px -20px 0;
  `)}

  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Cell = styled.div`
  max-width: 80%;
  flex: 1 1 400px;
  margin-top: 60px;
  padding: 0 55px 0 65px;

  &:last-child {
    padding-left: 60px;
  }

  ${media.laptop(css`
    margin-top: 40px;
    padding: 0 40px;

    &:last-child {
      padding-left: 40px;
    }
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px;

    &:last-child {
      padding-left: 20px;
    }
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
`;

const ItemIcon = styled.div`
  width: 55px;
  height: 55px;
  margin-bottom: 28px;

  ${media.laptop(css`
    margin-bottom: 25px;
  `)}
`;

const ItemTitle = styled.span`
  font-size: 30px;
  line-height: 34px;
  display: block;
  font-weight: 600;
  letter-spacing: -0.01em;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 22px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    line-height: 26px;
  `)}
`;

const ItemText = styled.p`
  margin-top: 25px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 500;
  display: block;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

export default ExchangesAdvantages;
