import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { SingaporePricingPageType } from '@/typings/model';
import Picture from '@/components/Picture';

const SingaporePricingTariffs = () => {
  const { tariffs } = useCurrentPage<SingaporePricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          {tariffs.map((item) => (
            <Row>
              <Left>
                <Icon>
                  <Picture {...convertThumbnailToPictureImage(item.icon)} />
                </Icon>
                <Name>{item.name}</Name>
              </Left>
              <Right dangerouslySetInnerHTML={{ __html: item.price || '' }} />
            </Row>
          ))}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 80px 0 70px;
  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Inner = styled.div`
  background: #f6f6f6;
  padding: 80px 80px 80px 80px;
  ${media.tablet(css`
    padding: 40px;
  `)}
  ${media.mobile(css`
    padding: 50px 20px;
  `)}
`;

const Row = styled.div`
  display: flex;
  &:not(:first-child) {
    margin-top: 40px;
  }

  ${media.mobile(css`
    display: block;
  `)}
`;

const Left = styled.div`
  display: flex;
  width: 610px;
  ${media.tablet(css`
    width: 400px;
    min-width: 400px;
  `)}
  ${media.mobile(css`
    width: auto;
    min-width: 0;
  `)}
`;

const Icon = styled.div`
  margin-right: 30px;
  width: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;

  ${media.mobile(css`
    margin-right: 10px;
    width: 20px;
    min-width: 20px;
    img {
      max-height: 20px;
      margin-top: -2px;
    }
  `)}
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  display: block;

  ${media.tablet(css`
    font-size: 24px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const Right = styled.div`
  font-weight: 500;
  font-size: 30px;
  line-height: 120%;
  flex: 1 1 1px;
  padding-left: 40px;

  ${media.tablet(css`
    font-size: 24px;
    padding-left: 30px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    padding: 0;
    margin-top: 10px;
    padding-left: 30px;
  `)}
`;

export default SingaporePricingTariffs;
