import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Image01 from '@/assets/images/accounts/icon_2_01.svg';
import Image02 from '@/assets/images/accounts/icon_2_02.svg';
import Image03 from '@/assets/images/accounts/icon_2_03.svg';
import Button from '@/components/Button';
import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';

gsap.registerPlugin(ScrollTrigger, SplitText);

const ITEM_LIST = [
  {
    image: Image01,
    title: 'Ease of access',
    text: ` All the services you need can be found through a clear, easy and intuitive interface, personalised precisely for your needs.`,
  },
  {
    image: Image02,
    title: 'Headaches resolved',
    text: `We resolve complicated processes such as onboarding and anti-money laundering regulations. Without these concerns, growing your business becomes your top priority.`,
  },
  {
    image: Image03,
    title: 'Security is uppermost',
    text: `All of your funds are kept in separate bank accounts across numerous banks. This safeguards against risk while ensuring settlements can be completed quickly and securely.`,
  },
];

function AccountsBenefitsSecond() {
  const titleRef = useRef<HTMLHeadingElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: ITEM_LIST.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tw = gsap.from(splitTitleChild.lines, {
        stagger: 0.15,
        ease: 'customEaseInOut',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.45,
            delay: index / 8,
            scale: 0,
          },
          0
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            yPercent: 100,
            duration: 1.45,
          },
          0.2
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        );
      });
    });

    return () => {
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component>
      <ContentContainer>
        <Inner>
          <Header>
            <Title ref={titleRef}>
              We host the&nbsp;account. You enjoy the&nbsp;benefits.
            </Title>
          </Header>
          <Row>
            {ITEM_LIST.map((item, index) => (
              <Cell key={index}>
                <Item ref={cellRefList[index].cardRef}>
                  <ItemIcon>
                    <img ref={cellRefList[index].iconRef} src={item.image} />
                  </ItemIcon>
                  <ItemTitle ref={cellRefList[index].titleRef}>
                    {item.title}
                  </ItemTitle>
                  <ItemText ref={cellRefList[index].textRef}>
                    {item.text}
                  </ItemText>
                </Item>
              </Cell>
            ))}
          </Row>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  margin: 150px 0 0;
  background: ${colors.black};
  color: ${colors.white};

  ${media.laptop(css`
    margin: 120px 0 0;
  `)}

  ${media.tabletSmall(css`
    margin: 100px 0 0;
  `)}
  
  ${media.mobile(css`
    margin: 50px 0 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
  padding: 150px 137px 205px;
  z-index: 0;

  ${media.laptop(css`
    padding: 90px 100px 120px;
  `)}

  ${media.tabletSmall(css`
    padding: 60px 0 80px;
  `)}
  
  ${media.mobile(css`
    padding: 40px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  max-width: 411px;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}

  ${media.mobile(css`
    font-size: 54px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

const Row = styled.div`
  display: flex;
  margin: 110px -60px 0;

  ${media.laptop(css`
    flex-wrap: wrap;
    margin: 40px -40px 0;
  `)}

  ${media.tabletSmall(css`
    margin: 10px -20px 0;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
  `)}
`;

const Cell = styled.div`
  max-width: 80%;
  flex: 1 1 400px;
  margin-top: 60px;
  padding: 0 65px;

  ${media.laptop(css`
    margin-top: 40px;
    padding: 0 40px;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
`;

const ItemIcon = styled.div`
  width: 55px;
  height: 55px;

  i {
    display: block;
    width: 100%;
    height: 100%;
    background: ${colors.black};
    border-radius: 100%;
  }
`;

const ItemTitle = styled.span`
  font-size: 30px;
  display: block;
  margin-top: 10px;
  letter-spacing: -0.01em;
  font-weight: 600;
  line-height: 100%;
  color: #fff;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 22px;
  `)}
`;

const ItemText = styled.p`
  font-size: 22px;
  line-height: 30px;
  margin-top: 25px;
  font-weight: 500;
  color: #828285;

  ${media.laptop(css`
    margin-top: 25px;
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const Right = styled.div`
  padding-top: 5px;

  ${media.mobile(css`
    padding-top: 0;
    margin-top: 35px;
  `)}
`;

export default AccountsBenefitsSecond;
