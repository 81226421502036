import React from 'react';
import styled, { css } from 'styled-components';

import superPower1Icon from '@/assets/svg/xpp/superpower/payments-icon.svg';
import superPower2Icon from '@/assets/svg/xpp/superpower/subscriptions-icon.svg';
import superPower3Icon from '@/assets/svg/xpp/superpower/payouts-icon.svg';
import superPower4Icon from '@/assets/svg/xpp/superpower/alternative-payments icon.svg';
import superPower5Icon from '@/assets/svg/xpp/superpower/ibans-icon.svg';
import superPower6Icon from '@/assets/svg/xpp/superpower/support-icon.svg';
import { colors } from '@/constants/theme';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { styleForDoubleTitle } from '@/constants/common';
import SuperPowerItemList, {
  Item,
} from '@/modules/Links/components/LinksItemList';
import IntroBlock, {
  ButtonIcon,
  ButtonLabel,
  ButtonWrap,
  Component as IntoBlockComponent,
} from '@/components/IntroBlock/IntroBlock';
import { media } from '@/utils/mixin';

const ITEM_LIST = [
  {
    image: superPower1Icon,
    title: 'Payments',
    text: `Market leading payment gateway, acquiring service and fraud tools and invoicing.`,
  },
  {
    image: superPower2Icon,
    title: 'Subscriptions',
    text: `Recurring payments daily, weekly or monthly.`,
  },
  {
    image: superPower3Icon,
    title: 'Payouts',
    text: `Pay anyone anywhere in the world.`,
  },
  {
    image: superPower4Icon,
    title: 'Alternative Payments',
    text: `Localised payment options for your customers.`,
  },
  {
    image: superPower5Icon,
    title: 'IBANs',
    text: `Digital banking with named accounts.`,
  },
  {
    image: superPower6Icon,
    title: 'Support',
    text: `Best support in the industry 24/7, a dedicated relationship manager.`,
  },
];

function SuperPower() {
  return (
    <Component>
      <DoubleTitle
        firstTitle={'xPP'}
        secondTitle={'superpowers'}
        styles={styleForDoubleTitle}
      />
      <SuperPowerItemList itemList={ITEM_LIST} />
      <IntroBlock
        options={{
          buttonColor: colors.xppPrimary,
          textColor: '#c2c2c8',
          boldTextColor: colors.white,
          text: ` of xpate's capabilities.`,
          boldText: `Take full advantage`,
          buttonUrl: 'mailto:hello@xpate.com',
          buttonTitle: 'become our partner',
        }}
      />
    </Component>
  );
}

const Component = styled.div`
  padding: 165px 0 185px;
  background: ${colors.black};
  color: ${colors.white};

  ${IntoBlockComponent} {
    margin-top: 180px;
  }

  ${ButtonWrap} {
    margin-top: 50px;

    &:hover {
      ${ButtonIcon} {
        background: ${colors.xppPrimaryHover};
      }
    }
  }

  ${ButtonLabel} {
    color: ${colors.white};
  }

  ${media.mobile(css`
    padding: 80px 0 100px;

    ${IntoBlockComponent} {
      margin-top: 80px;
    }

    ${Item} {
      max-width: 100%;
    }

    ${ButtonWrap} {
      text-align: end;
    }
  `)}
`;

export default SuperPower;
