import React from 'react';

import Link from '@/components/Link';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import LogoIcon from '@/assets/svg/xpate.svg';
import { ReactComponent as ArrowHeaderSvg } from '@/assets/svg/arrow-header.svg';
import { useRegion } from '@/hooks/useRegion';
import useSettingItem from '@/hooks/useSettingItem';
import { REGION } from '@/utils/region';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { MenuAlias } from '@/typings/common';

import * as S from './NotFound.styles';

function NotFound() {
  const [region] = useRegion();
  const copyright = useSettingItem('FOOTER_COPYRIGHT');

  const bottomAlias =
    region === REGION.ASIA ? MenuAlias.BottomAsia : MenuAlias.Bottom;

  const bottomMenuItemList =
    useTypedSelector((state) =>
      selectMenuItemListByAlias(state, bottomAlias)
    ) ?? [];

  return (
    <S.Container>
      <S.Header>
        <ContentSmallContainer>
          <S.HeaderLogo href="https://xpate.com/homepage">
            <S.HeaderLogoSvg data={LogoIcon} type="image/svg+xml" />
          </S.HeaderLogo>
        </ContentSmallContainer>
      </S.Header>
      <S.Content>
        <ContentSmallContainer>
          <S.ContentInner>
            <S.TextContent>
              <S.PageTitle>404</S.PageTitle>
              <S.PageDescription>something is missing</S.PageDescription>
              <S.ArrowButton onClick={() => {}}>
                <ArrowHeaderSvg />
              </S.ArrowButton>
            </S.TextContent>
            <S.EmailLink href="mailto:hello@xpate.com">
              <span>hello@xpate.com</span>
            </S.EmailLink>
          </S.ContentInner>
        </ContentSmallContainer>
      </S.Content>
      <S.Footer>
        <ContentSmallContainer>
          <S.FooterInner>
            <S.CopyrightText>{copyright}</S.CopyrightText>
            <S.LinkList>
              {bottomMenuItemList.map((menuItem) => (
                <S.LinkListItem key={menuItem.id}>
                  <Link
                    as={S.LinkItem}
                    to={menuItem.link}
                    target={menuItem.isNewTab ? '_blank' : '_self'}
                  >
                    {menuItem.label}
                  </Link>
                </S.LinkListItem>
              ))}
            </S.LinkList>
          </S.FooterInner>
        </ContentSmallContainer>
      </S.Footer>
    </S.Container>
  );
}

export default NotFound;
