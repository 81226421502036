import React from 'react';
import styled from 'styled-components';

import { ReactComponent as LinkedInIcon } from '@/assets/svg/stacks/social/linkedin-icon.svg';
import { ReactComponent as FacebookIcon } from '@/assets/svg/stacks/social/facebook-icon.svg';
import { ReactComponent as InstagramIcon } from '@/assets/svg/stacks/social/instagram-icon.svg';
import { ReactComponent as TwitterIcon } from '@/assets/svg/stacks/social/twitter-icon.svg';
import Link from '@/components/Link';

interface Props {}

function getSocialLinks(config: {
  linkedIn: string;
  instagram: string;
  facebook: string;
  twitter: string;
}) {
  return [
    { url: config.linkedIn, icon: <LinkedInIcon /> },
    { url: config.facebook, icon: <FacebookIcon /> },
    { url: config.instagram, icon: <InstagramIcon /> },
    { url: config.twitter, icon: <TwitterIcon /> },
  ];
}

function StacksTechnologySocials(props: Props) {
  const socials = getSocialLinks({
    linkedIn: 'https://www.linkedin.com/company/xpate/',
    facebook: 'https://www.facebook.com/xpate/',
    instagram: 'https://www.instagram.com/xpate.official/',
    twitter: 'https://twitter.com/_xpate/',
  });
  return (
    <Component>
      {socials.map((social, index) => (
        <Social key={index}>
          <SocialIcon>{social.icon}</SocialIcon>
          <SocialLink to={social.url} target="_blank" />
        </Social>
      ))}
    </Component>
  );
}

const Social = styled.div`
  margin-top: 50px;
  position: relative;
`;
const SocialIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SocialLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Component = styled.div`
  display: flex;

  & > div {
    margin-right: 15px;
  }
`;

export default StacksTechnologySocials;
