import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-4.svg';
import SectionTitle from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const questionList = [
  {
    question: 'What are the requirements to open an xpate SG account?',
    answer:
      'If you are a company registered in Singapore, you are eligible to open an account. All the relevant documents will be collected during the onboarding process. Not a business in Singapore and interested in opening an account, contact us.',
  },
  {
    question: 'How do I apply for an account?',
    answer: 'Click on here and you will get you started.',
  },
  {
    question: 'Are there any costs to open an account?',
    answer:
      "Opening your xpate.sg account is free! No hidden monthly charges or minimum balances need to be maintained, isn't that great!",
  },
  {
    question: 'How long does the account opening take?',
    answer:
      'We work to get it done within 2 – 3 working days, but depending on your business operations it could be the same day.',
  },
  {
    question: 'Is xpate SG safe?',
    answer:
      'xpate.sg is a trading name of Novum Financial Technologies Pte. Ltd. (UEN: 201808498D) which is regulated by the Monetary Authority of Singapore as Major Payment Institution under Licence No. PS20200538. In keeping with the Payment Services Act, we ensure that we have the best practices in place to safeguard our client’s funds.',
  },
  {
    question: 'How does xpate SG keep your money safe?',
    answer:
      'We safeguard your money in accounts in Singaporean dedicated top banks.<br/><br/>We also have internal dedicated team of professionals that work to secure your money. They are constantly reviewing our best in class safety policies. Because of their work we are at the cutting edge of financial security.',
  },
  {
    question: 'What would happen if xpate became insolvent?',
    answer:
      'Safeguarded funds are inaccessible to our creditors, our banks or any other third parties. So your money will always be available to you in the normal course of our business.\n' +
      'The protections afforded by safeguarding stay in place even in the unlikely event that xpate were to become insolvent. However, if this happens, some of this money might be used by an insolvency administrator to pay for their own costs. This means the money returned to you could be lower than the total amount you had in your account. It might also take some time for that insolvency administrator to return the rest of your money back to you.\n' +
      'We don’t want to go out of business, and certainly not in an insolvent way. We are required to hold regulatory capital as part of our license. This capital is designed to protect xpate, and your money, against any unexpected market events.\n' +
      'We also carry out regular stress testing of our business and how we operate. We’ll think of the worse case scenario — then work backwards to make sure that we’re covered if that scenario happens.\n' +
      "We regularly test and monitor the amount of capital we hold, to make sure it's enough to cover any risks our business might face.",
  },
];

function Faq() {
  const questionRefList = Array.from({
    length: questionList.length,
  }).map((item) => React.createRef<HTMLLIElement>());

  useEffect(() => {
    let tl: gsap.core.Timeline;
    const tlList: Array<gsap.core.Timeline> = [];

    const delayedCall = gsap.delayedCall(0, () => {
      questionRefList.forEach((questionItem, index) => {
        if (!questionItem.current) return;

        tl = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: questionItem.current,
            start: 'top 100%',
            end: 'bottom bottom',
            scrub: 1,
          },
        });

        tlList[index] = tl.from(questionRefList[index].current, {
          duration: 0.8,
          ease: 'ease',
          opacity: 0,
          yPercent: 30,
        });
      }, 0.8);
    });

    return () => {
      delayedCall?.kill();
      tl?.kill();
      tlList?.forEach((item) => item?.kill());
    };
  }, []);

  return (
    <Component id="faq">
      <ContentSmallContainer>
        <Inner>
          <Badge>
            <BadgeLabel>xpate SG</BadgeLabel>
            <ArrowIcon />
          </Badge>
          <SectionTitle title={'FAQ'} />
          <Questions>
            {questionList.map((item, index) => (
              <Item ref={questionRefList[index]} key={index}>
                <ItemQuestion>{item.question}</ItemQuestion>
                <ItemAnswer
                  dangerouslySetInnerHTML={{
                    __html: item.answer.replaceAll('\n', '<br/><br/>'),
                  }}
                />
              </Item>
            ))}
          </Questions>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  padding-top: 50px;
  margin: 200px 0;
  color: #040312;
  ${media.laptop(css`
    margin: 150px 0;
  `)}
  ${media.tablet(css`
    margin: 70px 0;
  `)}
  ${media.mobile(css`
    margin: 40px 0;
  `)}
`;

const Inner = styled.div`
  max-width: 901px;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  svg {
    margin-left: 10px;
  }
`;

const BadgeLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const Questions = styled.ul`
  margin-top: 80px;

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Item = styled.li`
  margin-top: 80px;

  &:first-child {
    margin-top: 0 !important;
  }

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const ItemQuestion = styled.span`
  font-weight: bold;
  font-size: 44px;
  line-height: 120%;

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;

const ItemAnswer = styled.div`
  margin-top: 30px;
  font-weight: normal;
  font-size: 22px;
  line-height: 145%;

  ${media.mobile(css`
    font-size: 18px;

    margin-top: 20px;
  `)}
`;

export default Faq;
