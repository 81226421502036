import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { SingaporePricingPageType } from '@/typings/model';

const SingaporePricingHeader = () => {
  const { title, text } =
    useCurrentPage<SingaporePricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  color: #040312;
  margin: 45px 0 80px;

  ${media.mobile(css`
    margin: 40px 0 50px;
  `)}
`;

const Title = styled.h1`
  display: block;
  text-align: center;
  margin: 0;

  font-weight: 700;
  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
  `)}
  ${media.mobile(css`
    font-size: 45px;
  `)}
`;

const Text = styled.p`
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  max-width: 960px;
  margin: 50px auto 0;

  ${media.tablet(css`
    margin-top: 40px;
    font-size: 21px;
  `)}
  ${media.mobile(css`
    margin-top: 30px;
    font-size: 18px;
  `)}
`;

export default SingaporePricingHeader;
