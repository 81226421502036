import React, { useState } from 'react';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import AcquiringFlexibility from '@/modules/Acquiring/components/AcquiringFlexibility';
import Layout from '@/components/Layout';
import HandsHero from '@/components/HandsHero';
import AcquiringAdvantages from '@/modules/Acquiring/components/AcquiringAdvantages';
import AcquiringItems from '@/modules/Acquiring/components/AcquiringItems';
import AcquiringConvenience from '@/modules/Acquiring/components/AcquiringConvenience';
import AcquiringManager from '@/modules/Acquiring/components/AcquiringManager';
import HandsImage from '@/assets/images/acquiring/acquiring-hands.png';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import AcquiringBanner from '@/modules/Acquiring/components/Banner';

function Acquiring() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="acquiring" dark={false} onPreloaderOut={handlePreloaderOut}>
      <HandsHero
        image={HandsImage}
        color={colors.acquiringPrimary}
        animate={isPreloaderOut}
        text={`Processing`}
      />

      <AcquiringFlexibility />
      <AcquiringAdvantages />
      <AcquiringItems />
      <AcquiringBanner />
      <AcquiringConvenience />
      <AcquiringManager />

      <GetStarted
        color={colors.acquiringPrimary}
        hoverColor={colors.acquiringPrimaryHover}
      >
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Acquiring;
