import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import * as S from '@/components/Form.styles';
import ContentContainer from '@/components/ContentContainer';
import Static from '@/components/Static';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import TextInput from '@/components/TextInput';
import { submitFullForm } from '@/services/requests';
import { FormLinksValuesType } from '@/modules/Form/components/FormLinks/FormLinks.types';
import { stylesFormByTheme } from '@/modules/Form/components/FormShort/FormInner.constants';
import Select from '@/components/Select/Select';

import { FormValuesModel } from './FormConferenceSimple.types';

type Props = {
  onSuccess: () => void;
};

const jobFunctionOptions: string[] = [
  'C-Level / Board / Founder / Owner / Director',
  'Finance / Accounting / Audit',
  'Legal / Regulation / Compliance / Risk / Advisory',
  'Sales / Business Development / Account Management',
  'HR & Administration',
  'Marketing / Digital',
  'Creative / Content / Design',
  'Operations',
  'Product',
  'IT / Technical',
  'Consultant / Investor / Recruitment',
  'Student',
];

function FormConferenceSimple({ onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const page = useCurrentPage<FormPageType>();

  if (!page) {
    return null;
  }

  async function handleSubmitForm(
    values: FormValuesModel,
    formikHelpers: FormikHelpers<FormValuesModel>
  ) {
    setLoading(true);
    setSubmitError(false);

    try {
      if (page?.templateFields.form) {
        await submitFullForm(
          values as unknown as Record<string, string>,
          page?.templateFields.form.id
        );
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      const errors = convertRequestErrorToMap(error);

      if (Object.keys(errors).length !== 0) {
        formikHelpers.setErrors(errors as FormikErrors<FormLinksValuesType>);
      } else {
        setSubmitError(true);
      }

      setLoading(false);
    }
  }

  return (
    <S.Component stylesByTheme={stylesFormByTheme[page.templateFields.theme]}>
      <ContentContainer width={999}>
        <Formik<FormValuesModel>
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmitForm}
          initialValues={{
            email: '',
            name: '',
            company: '',
            jobFunction: null,
          }}
        >
          {({ values, errors, handleSubmit, handleChange }) => (
            <S.Form onSubmit={handleSubmit}>
              <S.FormInputs>
                <S.FormRow>
                  <TextInput
                    name="name"
                    value={values.name}
                    error={errors.name}
                    placeholder="Your Name & Surname"
                    onChange={handleChange}
                  />
                </S.FormRow>
                <S.FormRow>
                  <TextInput
                    name="email"
                    value={values.email}
                    error={errors.email}
                    placeholder="Your email"
                    onChange={handleChange}
                  />
                </S.FormRow>
                <S.FormRow>
                  <TextInput
                    name="company"
                    value={values.company}
                    error={errors.company}
                    placeholder="Your company"
                    onChange={handleChange}
                  />
                </S.FormRow>
                <S.FormRow>
                  <Select
                    name="jobFunction"
                    defaultValue="Select your Job Function"
                    error={
                      typeof errors.jobFunction === 'string'
                        ? errors.jobFunction
                        : undefined
                    }
                    value={
                      values.jobFunction && values.jobFunction[0]?.label
                        ? values.jobFunction[0]?.label
                        : ''
                    }
                    options={jobFunctionOptions.map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                  />
                </S.FormRow>
                {page.templateFields.agreementText ? (
                  <S.FormRow>
                    <S.CheckboxLabel>
                      <input
                        checked={agreementChecked}
                        onChange={(e) => setAgreementChecked(!agreementChecked)}
                        type="checkbox"
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: page.templateFields.agreementText,
                        }}
                      />
                    </S.CheckboxLabel>
                  </S.FormRow>
                ) : null}
              </S.FormInputs>
              {page.templateFields.afterFormText ? (
                <S.StaticText>
                  <Static body={page.templateFields.afterFormText} />
                </S.StaticText>
              ) : null}
              <S.Button
                disabled={
                  !!page.templateFields.agreementText && !agreementChecked
                }
                type="submit"
                loading={loading}
              >
                {page.templateFields.submitButtonLabel}
              </S.Button>
              {submitError ? (
                <S.FormError>
                  An error occurred while submitting the form
                </S.FormError>
              ) : null}
            </S.Form>
          )}
        </Formik>
      </ContentContainer>
    </S.Component>
  );
}

export default FormConferenceSimple;
