import React from 'react';
import styled from 'styled-components';

import { ReactComponent as SubmitCvArrow } from '@/assets/svg/submit-cv-arrow.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';

type Props = {
  id?: string;
  title?: string;
};

function CareersSubmitCvButton({ title }: Props) {
  return (
    <ButtonRow>
      <Button href={`mailto:job@xpate.com?subject=${title}`}>
        submit CV
        <ButtonIcon>
          <SubmitCvArrow />
        </ButtonIcon>
      </Button>
    </ButtonRow>
  );
}

const ButtonRow = styled.div`
  margin-top: 56px;
`;

const Button = styled.a`
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.202786px;
`;

const ButtonIcon = styled.i`
  width: 133px;
  height: 133px;
  background: ${colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-left: 30px;
`;

export default CareersSubmitCvButton;
