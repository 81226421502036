import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import BulbImage from '@/assets/images/xpp/bulb.webp';
import { media } from '@/utils/mixin';
import { getStringAsHtml } from '@/utils/common';

const textList = [
  {
    text: 'Our platform simplifies payments, providing a payment gateway, risk management system, and acquirer all rolled into one.',
    className: 'first-text',
  },
  {
    text: 'We help you manage the whole payment flow with IBANs, FX an Payouts. No matter what industry or size of customer xpate has a flexible solution.',
    className: '',
  },
  /*{
    text:
      'And in addition, you can check <a href="https://doc.sg.xpate.com/card_np_API_ecommerce_index.html" target="_blank">our public API</a> at any time.',
    className: '',
  },*/
];

function Capabilities() {
  const textRef = useRef<HTMLParagraphElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const textRefs = Array.from({ length: textList.length }).map((item) =>
    React.createRef<HTMLParagraphElement>()
  );

  useEffect(() => {
    let tl: gsap.core.Timeline;
    let tlForImage: gsap.core.Timeline;

    const splitText = new SplitText(textRef.current, {
      type: 'chars, words',
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!componentRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: componentRef.current,
        },
      });

      tl.from(
        splitText.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
          delay: 0.2,
        },
        0
      );

      if (!imageRef.current) return;

      tlForImage = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: imageRef.current,
          start: 'top 120%',
          end: 'center 80%',
          scrub: 1.6,
        },
      });

      tlForImage.from(imageRef.current, {
        ease: 'customEaseInOut',
        opacity: 0,
        yPercent: 20,
      });
    });

    return () => {
      splitText?.revert();
      tl?.kill();
      delayedCall?.kill();
      tlForImage?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;
    const tlList: Array<gsap.core.Timeline> = [];

    const delayedCall = gsap.delayedCall(0, () => {
      textRefs.forEach((textItem, index) => {
        if (!textItem.current) return;

        tl = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: textItem.current,
            start: 'top 100%',
            end: 'bottom bottom',
            scrub: 1,
          },
        });

        tlList[index] = tl.from(textRefs[index].current, {
          duration: 0.8,
          ease: 'ease',
          opacity: 0,
          yPercent: 30,
        });
      }, 0.8);
    });

    return () => {
      delayedCall?.kill();
      tl?.kill();
      tlList?.forEach((item) => item?.kill());
    };
  }, []);

  return (
    <Component ref={componentRef}>
      <ContentSmallContainer>
        <Inner>
          <TextSection>
            <MainText ref={textRef}>
              xpate empowers partners to grow their customer’s business
            </MainText>
            {textList.map((textItem, index) => (
              <Text
                key={index}
                ref={textRefs[index]}
                className={textItem.className}
                dangerouslySetInnerHTML={{
                  __html: getStringAsHtml(textItem.text),
                }}
              />
            ))}
          </TextSection>
          <ImageSection>
            <StyledImage imageRef={imageRef} src2x={BulbImage} />
          </ImageSection>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  background: ${colors.xppPrimary};
  padding: 250px 0 193px;

  ${media.tabletSmall(css`
    padding: 150px 0 135px;
  `)}

  ${media.mobile(css`
    padding: 80px 0 63px;
  `)}
`;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tabletSmall(css`
    flex-direction: column;
  `)}
`;

const Text = styled.p`
  max-width: 483px;
  font-size: 24px;
  line-height: 145%;

  transform: translateY(0%);

  a {
    color: ${colors.black};
    transition: color 0.7s;

    &:hover {
      color: #5020ac;
    }
  }

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const TextSection = styled.div`
  ${Text} {
    margin-top: 40px;

    &.first-text {
      margin-top: 100px;
    }
  }

  ${media.mobile(css`
    ${Text} {
      margin-top: 18px;

      &.first-text {
        margin-top: 40px;
      }
    }
  `)}
`;
const ImageSection = styled.div`
  margin-left: -250px;
  margin-top: 179px;
  transform: translateX(-10%);

  ${media.laptop(css`
    margin-left: -200px;
  `)}

  ${media.tabletSmall(css`
    margin-left: 0;
    transform: translateX(0);
  `)}

  ${media.mobile(css`
    margin-top: 64px;
  `)}
`;

const MainText = styled.p`
  position: relative;
  max-width: 900px;
  font-weight: bold;
  font-size: 102px;
  line-height: 104px;
  z-index: 10;

  ${media.mobile(css`
    font-size: 54px;
    line-height: 54px;
  `)}
`;

const StyledImage = styled(Picture)`
  img {
    transform: translateY(0);
  }
`;

export default Capabilities;
