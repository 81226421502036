import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import SectionTitle from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import LearnMoreButton from '@/components/LearnMoreButton';
import SectionHeader from '@/components/SectionHeader';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';

const LandingFeatures = () => {
  const {
    featuresEnabled,
    featuresItems,
    featuresText,
    featuresTitle,
    featuresButtonLabel,
    featuresButtonLink,
    featuresButtonNewTab,
  } = useCurrentPage<LandingTemplatePageType>().templateFields;
  if (!featuresEnabled) return null;

  return (
    <Component>
      <ContentSmallContainer>
        {featuresTitle ? (
          <SectionHeader
            title={featuresTitle}
            text={featuresText}
            maxWidth={null}
            maxTextWidth={900}
          />
        ) : null}

        {featuresItems.length > 0 ? (
          <Grid>
            {featuresItems.map(({ icon, title, text }) => (
              <Item>
                {icon ? (
                  <ItemIcon>
                    <Picture {...convertThumbnailToPictureProps(icon)} />
                  </ItemIcon>
                ) : null}
                {title ? <ItemTitle>{title}</ItemTitle> : null}
                {text ? <ItemText>{text}</ItemText> : null}
              </Item>
            ))}
          </Grid>
        ) : null}

        {featuresButtonLabel && featuresButtonLink ? (
          <ButtonRow>
            <LearnMoreButton
              label={featuresButtonLabel}
              to={featuresButtonLink}
              variant="blackRounded58"
              newTab={featuresButtonNewTab}
            />
          </ButtonRow>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  color: ${colors.black};
  margin: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const ButtonRow = styled.div`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 50px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 50px;
    gap: 60px;
    grid-template-columns: 1fr 1fr;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
    gap: 50px;
    grid-template-columns: 1fr;
  `)}
`;

const Item = styled.div``;

const ItemIcon = styled.div`
  height: 70px;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
  ${media.mobile(css`
    margin-bottom: 15px;
  `)}
`;

const ItemTitle = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  display: block;
  margin-bottom: 30px;
  ${media.mobile(css`
    font-size: 24px;
    margin-bottom: 15px;
  `)}
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  display: block;
  line-height: 145%;
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

export default LandingFeatures;
