import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-3.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import LinkWithUnderlineLine from '@/components/LinkWithUnderlineLine';
import Picture from '@/components/Picture';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props {
  mainColor?: string;
  text?: string;
  email?: string;
  image: string;
  color?: string;
  bgColor?: string;
}

function Feedback({
  image,
  color,
  email,
  text,
  bgColor = '#f3f4f7',
  mainColor = colors.black,
}: Props) {
  if (!text && !email) return null;
  return (
    <Component mainColor={mainColor}>
      <ContentSmallContainer>
        <Inner bgColor={bgColor}>
          {image && (
            <StyledPicture
              {...{
                src2x: image,
                src: image,
                srcWebp: image,
                srcWebp2x: image,
              }}
            />
          )}
          {text && (
            <TextWrapper>
              <ArrowIcon />
              <Text>{text}</Text>
            </TextWrapper>
          )}
          {email && (
            <EmailWrapper>
              <LinkWithUnderlineLine
                color={color}
                label={email}
                href={`mailto:${email}`}
              />
            </EmailWrapper>
          )}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div<{ mainColor: string }>`
  color: ${(props) => props.mainColor};
`;

const StyledPicture = styled(Picture)`
  position: static !important;

  img {
    position: absolute;
    top: -22%;
    right: 30px;
  }

  ${media.mobile(css`
    img {
      width: 138px;
      height: 138px;
      top: -16%;
      right: 0;
    }
  `)}
`;

const Inner = styled.div<{ bgColor: string }>`
  position: relative;
  width: 100%;
  padding: 142px 325px 142px 145px;
  background: ${(props) => props.bgColor};

  ${media.mobile(css`
    padding: 100px 20px 65px;

    a {
      font-size: 32px !important;
      margin-left: -5px;
    }
  `)}
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;

  ${media.mobile(css`
    font-size: 26px;
  `)}
`;

const TextWrapper = styled.div`
  position: relative;
  max-width: 840px;

  svg {
    position: absolute;
    width: 50px;
    height: 45px;
    left: -97px;
  }

  ${media.mobile(css`
    svg {
      left: 0;
      top: -65px;
      transform: rotate(90deg);
    }
  `)}
`;

const EmailWrapper = styled.div`
  margin-top: 40px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

export default Feedback;
