import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import SectionName from '@/components/SectionName';
import { CareersPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import { colors, fonts } from '@/constants/theme';
import Link from '@/components/Link';
import { ReactComponent as SubmitCvArrow } from '@/assets/svg/submit-cv-arrow.svg';
import CareersSubmitCvButton from '@/modules/Careers/components/CareersSubmitCvButton';

import { Text } from './CareersStyles';

function CareersBeOneOfUs() {
  const page = useCurrentPage<CareersPageType>();

  const introText = page?.templateFields?.introText ?? '';
  const introTitle = page?.templateFields?.introTitle ?? '';

  return (
    <Container>
      <ContentSmallContainer>
        <LabelWrapper>
          <CustomSection label={introTitle} />
        </LabelWrapper>
        <TextContainer>
          <StyledText>{introText}</StyledText>
        </TextContainer>
        <CareersSubmitCvButton />
      </ContentSmallContainer>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 158px;

  ${media.mobile(css`
    padding-top: 100px;
  `)}
`;

const StyledText = styled(Text)`
  margin-top: 34px;
`;

const LabelWrapper = styled.div`
  overflow: hidden;
  color: ${colors.black};
  font-family: ${fonts.Gilroy};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const CustomSection = styled(SectionName)`
  color: #c2c2c8;
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  font-size: 72px;
  line-height: 72px;
`;

const TextContainer = styled.div`
  position: relative;
  max-width: 1140px;
  width: 100%;
  height: auto;
`;

export default CareersBeOneOfUs;
