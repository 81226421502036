import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { nl2br } from '@tager/web-core';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';
import GrowImageSrc from '@/assets/images/home-2023/grow.png';
import { TryButton } from '@/components/Layout/components/Footer/components/Slogan/Slogan.styles';
import Picture from '@/components/Picture';
import SectionLabel from '@/modules/Home/components/SectionLabel';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType } from '@/typings/model';

gsap.registerPlugin(SplitText);

const HomeGrow = () => {
  const {
    growButtonLink,
    growItems,
    growButtonLabel,
    growSubtitle,
    growTitle,
    growPhoneImage,
    growPhoneText,
  } = useCurrentPage<Home2023PageType>().templateFields;

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      ).from(
        textRef.current,
        {
          ease: Power2.easeOut,
          transformOrigin: '50% 100%',
          duration: 0.7,
          opacity: 0,
          delay: 0.2,
          y: 20,
        },
        1
      );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Inner>
          <Content>
            <SectionLabel white label={growSubtitle || ''} />
            <Title ref={titleRef}>{growTitle}</Title>
            {growItems ? (
              <List ref={textRef}>
                {growItems.split('\n').map((item) => (
                  <ListItem>{item.trim()}</ListItem>
                ))}
              </List>
            ) : null}
            <ButtonRow>
              <TryButton to={growButtonLink} variant="whiteRounded">
                <span>
                  {growButtonLabel} <ArrowButton />
                </span>
              </TryButton>
            </ButtonRow>
          </Content>
          <Image>
            <ImageText>{nl2br(growPhoneText)}</ImageText>
            <Picture src={growPhoneImage?.url || ''} alt={growTitle || ''} />
          </Image>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  padding: 180px 0 140px;

  ${media.tabletSmall(css`
    padding: 90px 0;
  `)}
  ${media.mobile(css`
    padding: 45px 0;
  `)}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;

  ${media.tablet(css`
    flex-direction: column-reverse;
  `)}
`;

const Content = styled.div`
  color: #fff;
  width: 775px;

  ${media.tabletLarge(css`
    width: auto;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 104px;
  letter-spacing: -0.517105px;
  margin-top: 40px;

  ${media.laptop(css`
    font-size: 62px;
    line-height: 64px;
  `)}

  ${media.tabletLarge(css`
    font-size: 54px;
    line-height: 60px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
    line-height: 60px;
    margin-top: 30px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 42px;
    line-height: 42px;
  `)}
`;

const List = styled.ul`
  margin-top: 50px;
  max-width: 570px;

  ${media.mobile(css`
    max-width: none;
    margin-top: 30px;
  `)}
`;

const ListItem = styled.li`
  font-size: 24px;
  line-height: 145%;
  position: relative;
  padding-left: 40px;

  ${media.tablet(css`
    font-size: 24px;
    padding-left: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    padding-left: 17px;
  `)}
  
  &:not(:first-child) {
    margin-top: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    display: block;
    left: 12px;
    top: 12px;

    ${media.tablet(css`
      width: 6px;
      height: 6px;
      left: 3px;
      top: 12px;
    `)}

    ${media.mobile(css`
      width: 5px;
      height: 5px;
      left: 2px;
      top: 10px;
    `)}
  }
`;

const ButtonRow = styled.div`
  margin-top: 50px;

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Image = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 1px;
`;

const ImageText = styled.span`
  display: block;
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -0.517105px;
  top: 134px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  ${media.mobile(css`
    font-size: 28px;
  `)}
`;

export default HomeGrow;
