import React from 'react';
import styled, { css } from 'styled-components';

import FeedbackInner from '@/components/Feedback/Feedback';
import FeedbackImage from '@/assets/images/xpp/question.webp';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function Feedback() {
  return (
    <Component>
      <FeedbackInner
        mainColor={colors.black}
        color={'#ad83fd'}
        email={'sales@xpate.com'}
        image={FeedbackImage}
        text={
          'Do you want to know more about xpate partner program? Or you have any question?'
        }
      />
    </Component>
  );
}

const Component = styled.div`
  margin: 180px 0;

  ${media.mobile(css`
    margin: 108px 0 20px;
  `)}
`;

export default Feedback;
