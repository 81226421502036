import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import Link from '@/components/Link';

gsap.registerPlugin(SplitText);

function LinksText() {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTextParent = new SplitText(textRef.current, {
      wordsClass: 'lineParent',
      type: 'words',
    });
    const splitTextChild = new SplitText(textRef.current, {
      wordsClass: 'lineChild',
      type: 'words',
    });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.from(splitTextChild.words, {
        stagger: 0.05,
        ease: 'customEaseInOut',
        transformOrigin: '50% 100%',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      delayCall.kill();
      splitTextParent?.revert();
      splitTextChild?.revert();
      tw?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Text ref={textRef}>
          <b>Easily deployed turnkey payment solution along with</b> fraud
          control, dispute management, reporting and an extensive scope of
          consultancy support
        </Text>
        <ButtonWrap>
          <Link as={Button} to="mailto:hello@xpate.com">
            <ButtonLabel>Contact us</ButtonLabel>
            <ButtonIcon>
              <ArrowSvg />
            </ButtonIcon>
          </Link>
        </ButtonWrap>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 50px;
`;

const Text = styled.p`
  max-width: 1068px;
  display: block;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: ${colors.black};

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  b {
    color: ${colors.textGray};
  }

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

const ButtonWrap = styled.div`
  margin-top: -60px;
  text-align: right;

  ${media.laptop(css`
    margin-top: 50px;
    text-align: left;
  `)}

  ${media.tabletLarge(css`
    margin-top: 40px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const Button = styled.a`
  display: inline-flex;
  align-items: center;
`;

const ButtonLabel = styled.span`
  font-size: 40px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: ${colors.black};
  margin-right: 40px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 34px;
  `)}

  ${media.mobile(css`
    margin-right: 30px;
    font-size: 28px;
  `)}
`;

const ButtonIcon = styled.span`
  background: ${colors.linksPrimary};
  color: ${colors.black};
  width: 142px;
  height: 142px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;

  &:hover {
    background: ${colors.linksPrimaryHover};
  }

  svg {
    max-width: 50%;
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    width: 88px;
    height: 88px;

    svg {
      width: 31px;
      height: 43px;
    }
  `)}
`;

export default LinksText;
