import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';

import { colors } from '@/constants/theme';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import RethinkingIcon from '@/assets/images/culture/rethinking.svg';
import ProcessIcon from '@/assets/images/culture/process.svg';
import CommunicationIcon from '@/assets/images/culture/communication.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';

const ITEM_LIST = [
  {
    image: ProcessIcon,
    text: `We keep our processes as simple as we can and keep the essence of it.`,
  },
  {
    image: CommunicationIcon,
    text: `We streamline our communications and processes.`,
  },
  {
    image: RethinkingIcon,
    text: `We are rethinking classic processes and simultaneously staying in comfort zone for the customer.`,
  },
];

function CultureSimple() {
  const containerRef = useRef<HTMLDivElement>(null);

  const cellRefList = useMemo(
    () =>
      Array.from({ length: ITEM_LIST.length }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    []
  );

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 87%',
        },
      });
    });

    return () => {
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.45,
            delay: index / 8,
            scale: 0,
          },
          0
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          0.7
        );
      });
    });

    return () => {
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Row>
          {ITEM_LIST.map((item, index) => (
            <Item key={index}>
              <Icon ref={cellRefList[index].cardRef}>
                <img ref={cellRefList[index].iconRef} src={item.image} />
              </Icon>
              <ItemText ref={cellRefList[index].textRef}>{item.text}</ItemText>
            </Item>
          ))}
          <ButtonWrap>
            <Button to="mailto:hello@xpate.com">
              <ButtonLabel>get in touch</ButtonLabel>
              <ButtonIcon>
                <ArrowSvg />
              </ButtonIcon>
            </Button>
          </ButtonWrap>
        </Row>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section``;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -40px;

  ${media.laptop(css`
    margin: 0 -20px;
  `)}

  ${media.tabletSmall(css`
    justify-content: flex-start;
  `)}
`;

const Item = styled.div`
  flex: 1 1 410px;
  max-width: 410px;
  padding: 0 40px;
  margin-top: 230px;

  ${media.laptop(css`
    flex-basis: 29%;
    max-width: 29%;
    padding: 0 20px;
    margin-top: 70px;
  `)}

  ${media.tabletLarge(css`
    flex-basis: 320px;
    max-width: 320px;
  `)}

  ${media.tabletSmall(css`
    flex-basis: 220px;
    max-width: 80%;
  `)}

  ${media.mobile(css`
    margin-top: 60px;
  `)}

  ${media.mobileSmall(css`
    max-width: 90%;
  `)}
`;

const Icon = styled.div`
  margin-bottom: 30px;
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.01em;

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.mobileMedium(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const ButtonWrap = styled.div`
  width: 100%;
  margin: 180px 40px auto;

  ${media.laptop(css`
    margin: 70px 20px auto;
  `)}

  ${media.mobile(css`
    margin: 60px 20px auto;
  `)}
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

const ButtonLabel = styled.span`
  font-size: 40px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: -0.01em;
  color: ${colors.black};
  margin-right: 40px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 34px;
  `)}

  ${media.mobile(css`
    margin-right: 30px;
    font-size: 28px;
  `)}
`;

const ButtonIcon = styled.span`
  background: ${colors.black};
  color: ${colors.acquiringPrimary};
  width: 142px;
  height: 142px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      opacity: 0.85;
    }
  }

  svg {
    max-width: 50%;
    transition: 0.3s all ease;
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    width: 88px;
    height: 88px;

    svg {
      width: 31px;
      height: 43px;
    }
  `)}
`;

export default CultureSimple;
