import React from 'react';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import Layout from '@/components/Layout';
import OverviewHero from '@/modules/Overview/components/OverviewHero';
import OverviewFeatures from '@/modules/Overview/components/OverviewFeatures';
import useCurrentPage from '@/hooks/useCurrentPage';

function Overview() {
  const page = useCurrentPage();

  return (
    <Layout
      theme={
        page?.path.includes('links')
          ? 'links'
          : page?.path.includes('processing')
          ? 'acquiring'
          : page?.path.includes('exchanges')
          ? 'exchanges'
          : 'accounts'
      }
      dark={false}
    >
      <OverviewHero />
      <OverviewFeatures />

      <GetStarted color={colors.grayLight} hoverColor={colors.grayLight}>
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Overview;
