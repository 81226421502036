import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as EuroSumIcon } from '@/assets/svg/stacks/euro-sum.svg';
import IntroBlock, { ButtonIcon, ButtonWrap } from '@/components/IntroBlock';
import { colors } from '@/constants/theme';
import useHover from '@/hooks/useHover';

interface Props {}

function StacksAbout(props: Props) {
  const [animationCard, setAnimationCard] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);

  const isHoverCard = useHover(cardRef);

  useEffect(() => {
    if (!cardRef.current) return;

    const observer = new IntersectionObserver(
      () => {
        if (!cardRef.current) return;

        setTimeout(() => {
          setAnimationCard(true);
        }, 500);

        setTimeout(() => {
          setAnimationCard(false);
        }, 2400);
      },
      {
        threshold: 1,
      }
    );

    observer.observe(cardRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Component>
      <IntroBlock
        options={{
          isShowButton: true,
          boldText: 'You can perform any asset management activity ',
          text: 'with a simple drag-and-drop gesture',
          boldTextColor: '#C2C2C8',
          textColor: 'black',
          buttonUrl: 'mailto:hello@xpate.com',
          buttonTitle: 'get in line',
          buttonColor: colors.stacksPrimary,
        }}
        children={
          <Content>
            <Card>
              <CardWrapper isAnimation={isHoverCard || animationCard}>
                <CardInner ref={cardRef}>
                  <CardCurrency>Euros</CardCurrency>
                  <CardSum>
                    <EuroSumIcon />
                  </CardSum>
                </CardInner>
                <CardPlaceholder />
              </CardWrapper>
            </Card>
            <TextWrapper>
              <Text>
                We take the physical objects and transfer their behaviour into
                our interface. Money is still kept in stacks, transaction
                movements remind of the actions we make when dealing with
                banknotes.
              </Text>
            </TextWrapper>
          </Content>
        }
      />
    </Component>
  );
}

const Card = styled.div`
  flex: 1;
  user-select: none;
`;

const CardPlaceholder = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 12px;
  transition: all 1s ease-in-out !important;
  background: linear-gradient(180deg, #39dbdb 0%, #2fb0c1 100%);

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    border-radius: inherit;
    left: 0;
    width: 100%;
    background: inherit;
    height: 30px;
    transition: all 1s ease-in-out !important;
    z-index: 1;
  }

  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    border-radius: inherit;
    width: 100%;
    background: inherit;
    height: 30px;
    transition: all 1s ease-in-out !important;
    z-index: -1;
  }
`;

const CardInner = styled.div`
  position: relative;
  display: flex;
  padding: 24px 30px 30px 30px;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 12px;
  height: 100%;
  color: white;
  background: linear-gradient(180deg, #39dbdb 0%, #2fb0c1 100%);
  box-shadow: 10px 17px 50px rgba(0, 47, 52, 0.1);
  transition: all 1s ease-in-out;
  z-index: 10;
`;

const CardWrapper = styled.div<{ isAnimation: boolean }>`
  position: relative;
  max-width: 506px;
  height: 200px;
  margin-left: 65px;

  ${(props) =>
    props.isAnimation
      ? css`
          ${CardInner} {
            transform: scale(1.1) rotate(-6deg);
          }

          ${CardPlaceholder} {
            transform: scale(1.1) rotate(-6deg);

            &:before,
            &:after {
            }

            &:before {
              left: 8%;
              width: 89%;
              bottom: -16px;
            }

            &:after {
              left: 16%;
              width: 77%;
              bottom: -30px;
            }

            @media (max-width: 768px) {
              &:before,
              &:after {
                height: 15px;
                transform: scale(1.05);
              }

              &:before {
                left: 12%;
                bottom: -8px;
                width: 80%;
              }

              &:after {
                left: 18%;
                bottom: -16px;
                width: 70%;
              }
            }

            @media (max-width: 416px) {
              &:before {
                left: 11%;
                bottom: -8px;
                width: 85%;
              }

              &:after {
                left: 18%;
                bottom: -16px;
                width: 74%;
              }
            }
          }
        `
      : null}
`;

const CardCurrency = styled.span`
  font-size: 24px;
  line-height: 145%;
`;

const CardSum = styled.span`
  align-self: flex-end;

  svg {
    height: 33px;
    width: 174px;
  }
`;

const TextWrapper = styled.div`
  max-width: 530px;
  padding-left: 30px;
  flex: 1;
`;

const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  color: black;
`;

const Content = styled.div`
  margin-top: 126px;
  display: flex;
  padding-bottom: 65px;
`;

const Component = styled.div`
  padding-bottom: 250px;
  overflow: hidden;

  ${ButtonWrap} {
    margin-top: -12px;
  }

  ${ButtonIcon} {
    &:hover {
      background: ${colors.stacksPrimaryHover};
    }
  }

  @media (max-width: 1260px) {
    padding-bottom: 140px;

    ${CardWrapper} {
      margin: 0 auto;
    }

    ${Content} {
      flex-direction: column;
    }

    ${Card} {
      padding: 40px 20px;
    }

    ${TextWrapper} {
      max-width: 100%;
      margin-top: 60px;
      padding-left: 0;
    }

    ${ButtonWrap} {
      margin: 60px 10px 0 0;
    }

    ${Content} {
      margin-top: 50px;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 1024px) {
    ${ButtonWrap} {
      margin: 20px 10px 0 0;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 100px;

    ${CardCurrency} {
      font-size: 16px;
    }

    ${Text} {
      font-size: 18px;
      line-height: 145%;
    }

    ${Card} {
      padding: 15px 20px;
    }

    ${CardInner} {
      padding: 15px 15px 5px 15px;
    }

    ${CardInner} {
      max-width: 100%;
      margin: 0;
    }

    ${CardWrapper} {
      max-width: 375px;
      margin: 0 auto;
      height: 120px;
    }

    ${CardSum} {
      svg {
        height: 15px;
        width: 100px;
      }
    }
  }

  @media (max-width: 416px) {
    ${CardWrapper} {
      max-width: 280px;
    }

    ${CardPlaceholder} {
      border-radius: 6px;
    }

    ${CardInner} {
      padding-top: 8px;
      padding-right: 8px;
      padding-bottom: 10px;
      border-radius: 6px;

      svg {
        height: 14px;
      }
    }

    ${CardCurrency} {
      font-size: 12px;
    }

    ${TextWrapper} {
      margin-top: 90px;
    }

    ${CardWrapper} {
      height: 90px;
    }
  }
`;

export default StacksAbout;
