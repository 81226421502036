import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

type Props = {
  title: string;
};

function ContentTitle({ title }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);

  function getTitleOffset() {
    const titleElem = titleRef.current;
    const containerElem = containerRef.current;
    if (!titleElem || !containerElem) return 0;

    return containerElem.clientWidth - titleElem.offsetWidth;
  }

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(titleRef.current, {
        x: () => getTitleOffset(),
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          invalidateOnRefresh: true,
          trigger: containerRef.current,
          scrub: true,
        },
      });
    });

    return () => {
      tw?.kill();
    };
  }, []);

  return (
    <div ref={containerRef}>
      <Title ref={titleRef}>{title}</Title>
    </div>
  );
}

const Title = styled.span`
  position: relative;
  display: inline-block;
  margin-left: -15px;
  font-size: 282px;
  letter-spacing: -0.01em;
  font-weight: bold;

  ${media.laptop(css`
    margin-left: 0;
    font-size: 240px;
    line-height: 100%;
  `)}

  ${media.tabletLarge(css`
    font-size: 150px;
  `)}

  ${media.tabletSmall(css`
    font-size: 120px;
  `)}

  ${media.mobile(css`
    font-size: 90px;
  `)}
`;

export default ContentTitle;
