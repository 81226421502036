import stacks1Icon from '@/assets/svg/stacks/advatages/stacks-advantages-1.svg';
import stacks2Icon from '@/assets/svg/stacks/advatages/stacks-advantages-2.svg';
import stacks3Icon from '@/assets/svg/stacks/advatages/stacks-advantages-3.svg';
import stacks4Icon from '@/assets/svg/stacks/advatages/stacks-advantages-4.svg';
import stacks5Icon from '@/assets/svg/stacks/advatages/stacks-advantages-5.svg';
import stacks6Icon from '@/assets/svg/stacks/advatages/stacks-advantages-6.svg';

export const ITEM_LIST = [
  {
    image: stacks1Icon,
    text: `EUR bank account`,
  },
  {
    image: stacks2Icon,
    text: `Unique design solution`,
  },
  {
    image: stacks3Icon,
    text: `Seamless xpate-to-xpate transfers`,
  },
  {
    image: stacks4Icon,
    text: `Cross-border bank transfers in EU and beyond`,
  },
  {
    image: stacks5Icon,
    text: `Individual International Bank Account Number (IBAN) issued in United Kingdom`,
  },
  {
    image: stacks6Icon,
    text: `No monthly maintenance fee & transfers for free \n
* For beta users until the end of 2021`,
  },
];
