import React from 'react';
import { SplitText } from 'gsap/dist/SplitText';
import gsap from 'gsap';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-make-payments-intro.svg';
import useIntroAnimation from '@/modules/common/Links/hooks/useIntroAnimation';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { MakePaymentsPageType } from '@/typings/model';

gsap.registerPlugin(SplitText);

function MakePaymentsIntro() {
  const { introText, introTitle } =
    useCurrentPage<MakePaymentsPageType>().templateFields;
  const { containerRef, bgRef, textRef, titleRef } = useIntroAnimation();

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner>
          <TitleBackground ref={bgRef} />
          <Title ref={titleRef}>
            <TitleInner ref={textRef}>{introTitle}</TitleInner>
            <CircleContainer>
              <CircleArrow>
                <ArrowIcon />
              </CircleArrow>
              {introText}
            </CircleContainer>
          </Title>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 400px 0 180px;
  color: #040312;
  background: #fff;
  overflow: hidden;

  ${media.laptop(css`
    padding: 300px 0 120px;
  `)}

  ${media.tablet(css`
    padding: 200px 0 100px;
  `)}
  
  ${media.tabletSmall(css`
    padding: 120px 0 60px;
  `)}
  
  
  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Inner = styled.h2`
  position: relative;
  font-weight: bold;
  font-size: 198px;
  letter-spacing: -0.01em;
  line-height: 120%;

  ${media.tablet(css`
    font-size: 120px;
  `)}

  ${media.tabletSmall(css`
    font-size: 90px;
  `)}
  
  ${media.mobile(css`
    font-size: 40px;
  `)}
`;

const TitleBackground = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  bottom: 250px;
  right: 0;
  background: #f6f6f6;
  z-index: 0;

  ${media.mobile(css`
    bottom: 0;
    top: 0;
  `)}
`;

const CircleContainer = styled.div<{ color?: string }>`
  position: absolute;
  top: -155px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 494px;
  height: 494px;
  padding: 125px 65px;
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  color: ${colors.black};
  z-index: 0;

  ${media.tabletSmall(css`
    position: relative;
    top: auto;
    right: auto;
    width: 400px;
    height: 400px;
    padding: 60px 30px;
    font-size: 24px;
  `)}

  ${media.mobile(css`
    width: 300px;
    height: 300px;
    font-size: 18px;
    padding: 40px 20px;

    left: 50%;
    transform: translateX(-50%);
  `)}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #3bdaff;
    z-index: -1;
  }
`;

const Title = styled.div`
  position: relative;
  display: block;
  z-index: 1;
`;

const TitleInner = styled.span`
  position: relative;
  padding: 70px 0 60px 140px;
  display: block;
  z-index: 1;

  ${media.laptop(css`
    padding: 70px 0 60px 70px;
  `)}

  ${media.tablet(css`
    padding: 70px 0 60px 40px;
  `)}
  
  ${media.mobile(css`
    padding: 0 20px 40px;
    text-align: center;
  `)}
`;

const CircleArrow = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  svg {
    width: 100%;
    height: 100%;
  }

  ${media.mobile(css`
    max-width: 80px;
  `)}
`;

export default MakePaymentsIntro;
