import React from 'react';
import styled, { css } from 'styled-components';

import { createMediaMixin } from '@tager/web-components';

import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { styleForDoubleTitle } from '@/constants/common';
import { colors } from '@/constants/theme';
import IntroBlock, {
  Button,
  ButtonIcon,
  ButtonLabel,
  ButtonWrap,
  Component as IntroComponent,
  Text,
} from '@/components/IntroBlock/IntroBlock';
import becomePartner1Icon from '@/assets/svg/xpp/become-partner/1-icon.svg';
import becomePartner2Icon from '@/assets/svg/xpp/become-partner/2-icon.svg';
import becomePartner3Icon from '@/assets/svg/xpp/become-partner/3-icon.svg';
import BecomePartnerItemList, {
  ItemTitle,
  Item,
  ItemText,
} from '@/modules/Links/components/LinksItemList';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import Link from '@/components/Link';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';

const ITEM_LIST = [
  {
    image: becomePartner1Icon,
    title: 'Sign up',
    text: `Please fill in the <a href="mailto:hello@xpate.com">application form</a>. It won't take more than 2 minutes.`,
  },
  {
    image: becomePartner2Icon,
    title: 'Onboarding',
    text: `We will contact you to confirm your application and share our test account afterwards. `,
  },
  {
    image: becomePartner3Icon,
    title: 'Start earning',
    text: `Everything is ready for you to start earning today!`,
  },
];

function BecomePartner() {
  return (
    <Component>
      <DoubleTitle
        firstTitle={'Become'}
        secondTitle={'our partner'}
        styles={styleForDoubleTitle}
      />
      <IntroBlock
        options={{
          isShowButton: false,
          text: " we'd love to work with you. It takes just 3 easy steps.",
          boldText: 'If you are interested in becoming xpate partner,',
          textColor: colors.black,
          boldTextColor: '#c2c2c8',
        }}
      />
      <BecomePartnerItemList mainColor={colors.black} itemList={ITEM_LIST} />
      <ContentSmallContainer>
        <StyledButtonWrap>
          <Link as={Button} to="mailto:hello@xpate.com">
            <ButtonLabel>become our partner</ButtonLabel>
            <ButtonIcon bgColor={colors.xppPrimary}>
              <ArrowSvg />
            </ButtonIcon>
          </Link>
        </StyledButtonWrap>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  padding: 165px 0 185px;
  background: ${colors.white};
  color: ${colors.black};

  ${IntroComponent} {
    margin-top: 150px;
  }

  ${ItemText} {
    a {
      color: #955dff !important;
    }
  }

  ${Text} {
    max-width: 1168px;
  }

  ${ItemTitle} {
    margin-top: -58px;
    line-height: 115% !important;
  }

  ${Item} {
    &:first-child {
      img {
        margin-left: 0;
      }
    }

    img {
      margin-left: 10px;
    }
  }

  ${createMediaMixin({ max: 1400 })(css`
    ${ItemTitle} {
      margin-top: -50.5px;
    }
  `)}

  ${media.mobile(css`
    padding: 80px 0 80px;

    ${IntroComponent} {
      margin-top: 50px;
    }

    ${ItemTitle} {
      margin-top: -48.8px;
    }

    ${Item} {
      max-width: 100%;
    }
  `)}
`;

const StyledButtonWrap = styled(ButtonWrap)`
  text-align: start;
  margin-top: 150px;

  a:hover {
    ${ButtonIcon} {
      background: ${colors.xppPrimaryHover};
    }
  }

  ${media.mobile(css`
    margin-top: 50px;
    text-align: end;
  `)}
`;
export default BecomePartner;
