import React from 'react';
import { SplitText } from 'gsap/dist/SplitText';
import gsap from 'gsap';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import useIntroAnimation from '@/modules/common/Links/hooks/useIntroAnimation';

import * as S from '../../common/Links/Intro.styles';

gsap.registerPlugin(SplitText);

function LinksIntro() {
  const { containerRef, bgRef, textRef, titleRef } = useIntroAnimation();

  return (
    <S.Component ref={containerRef}>
      <ContentContainer>
        <S.Inner>
          <S.TitleBackground ref={bgRef} />
          <S.Title ref={titleRef}>
            <S.TitleInner ref={textRef}>
              White label payment gateway solution
            </S.TitleInner>
            <S.CircleContainer>
              <S.CircleArrow>
                <ArrowIcon />
              </S.CircleArrow>
              A white label payment gateway specifically tailored for acquirers'
              needs accompanied by the full handling of operational risk
              processes
            </S.CircleContainer>
          </S.Title>
        </S.Inner>
      </ContentContainer>
    </S.Component>
  );
}

export default LinksIntro;
