import React from 'react';

import Layout from '@/components/Layout';
import PricingPrices from '@/modules/Pricing/components/PricingPrices';
import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import PricingTariffs from '@/modules/Pricing/components/PricingTariffs';

import LandingHero from './components/LandingHero';
import LandingFeatures from './components/LandingFeatures';
import LandingProcess from './components/LandingProcess';
import LandingImageBlocks from './components/LandingImageBlocks';
import LandingFaq from './components/LandingFaq';
import LandingCta from './components/LandingCta';

function Landing() {
  const { pricesEnabled, tariffsEnabled, headerMenuHidden } =
    useCurrentPage<LandingTemplatePageType>().templateFields;

  return (
    <Layout theme="black" dark={true} headerMenuHidden={headerMenuHidden}>
      <LandingHero />
      <LandingFeatures />
      {pricesEnabled ? <PricingPrices /> : null}
      {tariffsEnabled ? <PricingTariffs /> : null}
      <LandingProcess />
      <LandingImageBlocks />
      <LandingFaq />
      <LandingCta />
    </Layout>
  );
}

export default Landing;
