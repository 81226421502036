import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import { styleForDoubleTitle } from '@/constants/common';
import ArrowButtonLink from '@/components/ArrowButtonLink';
import { colors } from '@/constants/theme';
import InfinitySlider from '@/modules/Home/components/HomeSimplicity/components/InfinitySlider';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import icon01 from '@/assets/images/corebanking/platform_01.svg';
import icon02 from '@/assets/images/corebanking/platform_02.svg';
import icon03 from '@/assets/images/corebanking/platform_03.svg';
import icon04 from '@/assets/images/corebanking/platform_04.svg';
import icon05 from '@/assets/images/corebanking/platform_05.svg';
import icon06 from '@/assets/images/corebanking/platform_06.svg';
import icon07 from '@/assets/images/corebanking/platform_07.svg';
import icon08 from '@/assets/images/corebanking/platform_08.svg';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';
import SimplicityItem, {
  Component as SimplicityItemComponent,
} from '@/modules/Home/components/HomeSimplicity/components/SimplicityItem';

const SLIDE_LIST = [
  {
    num: '01',
    title: 'Account initiation',
    icon: icon01,
    text: '<p>Private and Pooled Accounts are generated by the platform.</p>',
  },
  {
    num: '02',
    icon: icon02,
    title: 'Top-up',
    text:
      '<p>Based on the open account, there are different incoming channels for making payments.</p>' +
      '<p>IBAN serves as a primary channel.</p>' +
      '<p>Email, phone, company name and card number are possible upcoming channels used for payment creation.</p>',
  },
  {
    num: '03',
    icon: icon03,
    title: 'Chart of accounts functionality',
    text:
      '<p>The functionality provides transaction mapping to financial chart of the account (CoA).</p>' +
      '<p>The CoA has 6-7 hierarchy levels and balance is calculated on the fly.</p>',
  },
  {
    num: '04',
    icon: icon04,
    title: 'Financial accounting engine',
    text:
      '<p>The engine matches original transaction to financial accounting.</p>' +
      '<p>The engine ensures:</p>' +
      '<ul><li>DR and CR account mapping;</li>' +
      '<li>double entry principle;</li>' +
      '<li>accounting equation principle</li></ul>',
  },
  {
    num: '05',
    icon: icon05,
    title: 'Tariff management',
    text:
      '<p>The funcionality covers tariff plan applied per specific customer account.</p>' +
      '<p>The contract contains tariff plan for combination of:</p>' +
      '<ul><li>payment type: incoming/outcoming</li>' +
      '<li>payment method: SEPA/TARGET2/SWIFT/xpate2xpate</li>' +
      '<li>rate type: fixed/min/max</li></ul>',
  },
  {
    num: '06',
    icon: icon06,
    title: 'Transaction log',
    text: `<p>Transaction log keeps all historical info about	the moves. </p>
      <p>Upon each move the balance in recalculated. </p>
        <p>Essential part of the log engine is input generation for balance creation. </p>`,
  },
  {
    num: '07',
    icon: icon07,
    title: 'Space management',
    text: `<p>The functionality includes space for user to access customer data.</p>
<p>It contains main info about the customer:</p>
<ul>
<li>for individuals</li>
<li>for businesses</li>
</ul>`,
  },
  {
    num: '08',
    icon: icon08,
    title: 'Clearing',
    text: `<p>Clearing provides routing logic inside the corebanking to allocate amounts to correct accounts.</p>`,
  },
];

const CorebankingPlatform = () => {
  const {
    platformItems,
    platformSubtitle,
    platformTitle,
    platformButtonLabel,
    platformButtonLink,
  } = useCurrentPage<CorebankingPageType>().templateFields;

  const isMobile = useMedia(`(max-width: 768px)`);

  const { containerRef, setSlideElem } = useSimplicityAnimation({
    disableAnimationBgElements: true,
  });

  const titleWords = platformTitle
    ? platformTitle.split(' ').map((item) => item.trim())
    : [];
  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        {titleWords.length > 0 ? (
          <DoubleTitle
            firstTitle={titleWords[0]}
            secondTitle={titleWords.length > 1 ? titleWords[1] : ''}
            styles={styleForDoubleTitle}
          />
        ) : null}
        <Subtitle>{platformSubtitle}</Subtitle>
        <Row>
          {isMobile ? (
            <List>
              {platformItems.map((item, index) => (
                <SimplicityItem
                  className="simplicity"
                  id={`${index}`}
                  num={'0' + (index + 1)}
                  color={'#F6F6F6'}
                  icon={item.icon}
                  title={item.title || ''}
                  text={item.text || ''}
                />
              ))}
            </List>
          ) : (
            <InfinitySlider
              carouselDuration={30}
              slides={platformItems.map((item, index) => {
                return {
                  num: '0' + (index + 1),
                  title: item.title || '',
                  text: item.text || '',
                  icon: item.icon,
                };
              })}
              setSlideElem={setSlideElem}
            />
          )}
        </Row>
        <ArrowButtonLink
          color={colors.blue}
          arrowColor={colors.white}
          label={platformButtonLabel}
          to={platformButtonLink}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const List = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  margin: 0 -20px;

  ${SimplicityItemComponent} {
    width: 100% !important;

    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const Component = styled.section`
  margin: 0 0 240px;
  color: #040312;

  ${media.tablet(css`
    margin: 0 0 160px;
  `)}
  ${media.mobile(css`
    margin: 0 0 80px;
  `)}
`;

const Subtitle = styled.span`
  display: block;
  font-weight: 700;
  font-size: 102px;
  line-height: 102%;
  color: #040312;
  max-width: 1000px;
  margin-top: 130px;

  ${media.tablet(css`
    font-size: 70px;
  `)}

  ${media.mobile(css`
    font-size: 50px;
  `)}
`;

const Row = styled.div`
  margin: 80px 0;
  ${media.tablet(css`
    margin: 60px 0;
  `)}
  ${media.mobile(css`
    margin: 40px 0;
  `)}
`;

export default CorebankingPlatform;
