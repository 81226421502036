import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-3.svg';
import ContentContainer from '@/components/ContentContainer';
import { media } from '@/utils/mixin';
import SupportImage from '@/assets/images/acquiring/support.png';
import LearnMoreButton from '@/components/LearnMoreButton';
import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';
import Button from '@/components/Button';

gsap.registerPlugin(SplitText);

function AcquiringManager() {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      ).from(
        textRef.current,
        {
          ease: Power2.easeOut,
          duration: 0.7,
          opacity: 0,
          delay: 0.2,
          y: 20,
        },
        1
      );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner>
          <Image>
            <img
              src={SupportImage}
              alt="Personal account manager and support 24/7"
            />
          </Image>
          <Content>
            <Title ref={titleRef}>
              Personal account manager and support 24/7
            </Title>
            <Text ref={textRef}>
              We believe our customers should choose the channel that works best
              for them. You focus on growing your business, so we focus on
              addressing your needs.
            </Text>
          </Content>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  margin: 183px 0 0;
  padding: 200px 0 210px;
  background: ${colors.black};
  color: ${colors.white};

  ${media.laptop(css`
    margin: 120px 0 0;
  `)}

  ${media.tabletSmall(css`
    margin: 100px 0 0;
  `)}
  
  ${media.mobile(css`
    margin: 50px 0 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 90px;

  ${media.laptop(css`
    padding: 90px 80px;
  `)}

  ${media.tabletLarge(css`
    padding: 40px 30px;
  `)}

  ${media.tabletSmall(css`
    flex-direction: column;
    padding: 40px 0;
  `)}
`;

const Image = styled.div`
  position: relative;
  display: block;
  width: 600px;
  max-width: 60%;
  flex-basis: 600px;
  border-radius: 100%;

  ${media.laptop(css`
    max-width: 48%;
  `)}

  ${media.tabletSmall(css`
    width: 462px;
    max-width: none;
    flex-basis: 462px;
    margin-bottom: 30px;
  `)}
  
  ${media.mobileLarge(css`
    width: 100%;
    max-width: 462px;
    flex-basis: 100%;
  `)}
`;

const Content = styled.div`
  position: relative;
  flex: 1 1 1px;
  margin: 0 0 0 160px;

  ${media.laptop(css`
    margin-left: 80px;
  `)}

  ${media.tabletLarge(css`
    margin-left: 60px;
  `)}
  
  ${media.tabletSmall(css`
    width: 100%;
    margin-left: 0;
  `)}
`;

const Bottom = styled.div`
  margin-top: 40px;
`;

const Title = styled.h3`
  display: block;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}

  ${media.mobileMedium(css`
    font-size: 54px;
  `)}
`;

const Text = styled.div`
  max-width: 490px;
  margin-top: 40px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #828285;

  ${media.tabletLarge(css`
    margin-top: 30px;
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 512px;
    margin-top: 20px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

export default AcquiringManager;
