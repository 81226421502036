import React from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import Link from '@/components/Link';
import useIntroBlockAnimation from '@/components/IntroBlock/IntroBlock.hooks';

gsap.registerPlugin(SplitText);

interface Props {
  options: {
    buttonColor?: string;
    buttonHoverColor?: string;
    buttonTitle?: string;
    buttonUrl?: string;
    arrowIconColor?: string;
    boldText: string;
    text: string;
    boldTextColor: string;
    textColor: string;
    isShowButton?: boolean;
  };
  children?: React.ReactNode;
}

function IntroBlock({
  options: {
    boldText,
    text,
    boldTextColor,
    buttonColor,
    buttonHoverColor,
    buttonTitle,
    textColor,
    buttonUrl,
    isShowButton = true,
  },
  children,
}: Props) {
  const { containerRef, textRef } = useIntroBlockAnimation();

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Text ref={textRef} boldTextColor={boldTextColor} textColor={textColor}>
          <b>{boldText}</b>
          {text}
        </Text>
        {children && <AdditionalContent>{children}</AdditionalContent>}
        {isShowButton && (
          <ButtonWrap>
            <Link as={Button} to={buttonUrl}>
              <ButtonLabel>{buttonTitle}</ButtonLabel>
              <ButtonIcon
                bgColor={buttonColor}
                buttonHoverColor={buttonHoverColor}
              >
                <ArrowSvg />
              </ButtonIcon>
            </Link>
          </ButtonWrap>
        )}
      </ContentSmallContainer>
    </Component>
  );
}

export const Component = styled.section`
  margin-top: 50px;
`;

export const Text = styled.p<{ boldTextColor: string; textColor: string }>`
  max-width: 1068px;
  display: block;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: ${(props) => props.textColor};

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  b {
    font-weight: bold;
    color: ${(props) => props.boldTextColor};
  }

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

const AdditionalContent = styled.div``;

export const ButtonWrap = styled.div`
  margin-top: -60px;
  text-align: right;

  ${media.laptop(css`
    margin-top: 50px;
    text-align: left;
  `)}

  ${media.tabletLarge(css`
    margin-top: 40px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 30px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
    text-align: end;
  `)}
`;

export const Button = styled.a`
  display: inline-flex;
  align-items: center;
`;

export const ButtonLabel = styled.span`
  font-size: 40px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: ${colors.black};
  margin-right: 40px;
  display: block;

  ${media.tabletSmall(css`
    font-size: 34px;
  `)}

  ${media.mobile(css`
    margin-right: 12px;
    font-size: 18px;
  `)}
`;

export const ButtonIcon = styled.span<{
  bgColor?: string;
  buttonHoverColor?: string;
}>`
  background: ${(props) => props.bgColor ?? 'white'};
  color: ${colors.black};
  width: 142px;
  height: 142px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;

  &:hover {
    background: ${(props) =>
      props.buttonHoverColor
        ? props.buttonHoverColor
        : colors.linksPrimaryHover};
  }

  svg {
    max-width: 50%;
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    min-width: 88px;
    min-height: 88px;
    width: initial;
    height: auto;

    svg {
      width: 31px;
      height: 43px;
    }
  `)}

  ${media.mobileMedium(css`
    min-width: 68px;
    min-height: 68px;
  `)}
`;

export default IntroBlock;
