import styled, { css } from 'styled-components';

import { colors, fonts } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const Text = styled.p`
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  margin-top: 40px;
  max-width: 1110px;
  font-size: 72px;
  line-height: 72px;
  color: ${colors.textGray};
  letter-spacing: -2px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  b {
    color: ${colors.black};
  }

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
    line-height: 60px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

export const Email = styled(Text)`
  max-width: 517px;
  display: inline;
  width: 100%;
  position: relative;
  color: black;

  &:after {
    position: absolute;
    display: block;
    content: '';
    left: -11px;
    bottom: -1px;
    width: 105%;
    height: 30px;
    border: 2px solid #3277cc;
    opacity: 0.75;
    background-color: #0060f5;
  }

  ${media.mobile(css`
    &:after {
      width: 105%;
      height: 20px;
      left: -6px;
    }
  `)}
`;
