import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import * as S from '@/components/Form.styles';
import ContentContainer from '@/components/ContentContainer';
import Static from '@/components/Static';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import TextInput from '@/components/TextInput';
import { submitFullForm } from '@/services/requests';
import { FormLinksValuesType } from '@/modules/Form/components/FormLinks/FormLinks.types';

import { stylesFormByTheme } from './FormInner.constants';
import { FormValuesType } from './Form.types';

type Props = {
  onSuccess: () => void;
};

function FormInner({ onSuccess }: Props) {
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const page = useCurrentPage<FormPageType>();

  if (!page) {
    return null;
  }

  async function handleSubmitForm(
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) {
    setLoading(true);
    setSubmitError(false);

    try {
      if (page?.templateFields.form) {
        await submitFullForm(values, page?.templateFields.form.id);
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      const errors = convertRequestErrorToMap(error);

      if (Object.keys(errors).length !== 0) {
        formikHelpers.setErrors(errors as FormikErrors<FormLinksValuesType>);
      } else {
        setSubmitError(true);
      }

      setLoading(false);
    }
  }

  return (
    <S.Component stylesByTheme={stylesFormByTheme[page.templateFields.theme]}>
      <ContentContainer width={999}>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmitForm}
          initialValues={{
            email: '',
            message: '',
            name: '',
          }}
        >
          {({ values, errors, handleSubmit, handleChange }) => (
            <S.Form onSubmit={handleSubmit}>
              <S.FormInputs>
                <S.FormRow>
                  <TextInput
                    name="name"
                    value={values.name}
                    error={errors.name}
                    placeholder="Your Name"
                    onChange={handleChange}
                  />
                </S.FormRow>
                <S.FormRow>
                  <TextInput
                    name="email"
                    value={values.email}
                    error={errors.email}
                    placeholder="Your E-Mail"
                    onChange={handleChange}
                  />
                </S.FormRow>
                <S.FormRow>
                  <TextInput
                    name="message"
                    value={values.message}
                    error={errors.message}
                    placeholder="Leave a message"
                    onChange={handleChange}
                  />
                </S.FormRow>
                {page.templateFields.agreementText ? (
                  <S.FormRow>
                    <S.CheckboxLabel>
                      <input
                        checked={agreementChecked}
                        onChange={(e) => setAgreementChecked(!agreementChecked)}
                        type="checkbox"
                      />
                      <span
                        dangerouslySetInnerHTML={{
                          __html: page.templateFields.agreementText,
                        }}
                      />
                    </S.CheckboxLabel>
                  </S.FormRow>
                ) : null}
              </S.FormInputs>
              {page.templateFields.afterFormText ? (
                <S.StaticText>
                  <Static body={page.templateFields.afterFormText} />
                </S.StaticText>
              ) : null}
              <S.Button
                disabled={
                  !!page.templateFields.agreementText && !agreementChecked
                }
                type="submit"
                loading={loading}
              >
                {page.templateFields.submitButtonLabel}
              </S.Button>
              {submitError ? (
                <S.FormError>
                  An error occurred while submitting the form
                </S.FormError>
              ) : null}
            </S.Form>
          )}
        </Formik>
      </ContentContainer>
    </S.Component>
  );
}

export default FormInner;
