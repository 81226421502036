import React from 'react';

import Layout from '@/components/Layout';
import PressCenterHero from '@/modules/PressCenter/components/PressCenterHero';
import PressCenterFiles from '@/modules/PressCenter/components/PressCenterFiles';
import PressCenterNews from '@/modules/PressCenter/components/PressCenterNews';

function PressCenter() {
  return (
    <Layout theme="white">
      <PressCenterHero />
      <PressCenterFiles />
      <PressCenterNews />
    </Layout>
  );
}

export default PressCenter;
