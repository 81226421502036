import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

function useIntroBlockAnimation() {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTextParent = new SplitText(textRef.current, {
      wordsClass: 'lineParent',
      type: 'words',
    });
    const splitTextChild = new SplitText(textRef.current, {
      wordsClass: 'lineChild',
      type: 'words',
    });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.from(splitTextChild.words, {
        stagger: 0.05,
        ease: 'customEaseInOut',
        transformOrigin: '50% 100%',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      delayCall.kill();
      splitTextParent?.revert();
      splitTextChild?.revert();
      tw?.kill();
    };
  }, []);

  return { containerRef, textRef };
}

export default useIntroBlockAnimation;
