import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { PressCenterPageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/article-link-arrow.svg';
import { media } from '@/utils/mixin';

function PressCenterNews() {
  const page = useCurrentPage<PressCenterPageType>();
  if (!page) return null;

  const news = page.templateFields?.newsItems ?? [];

  return (
    <Component>
      <ContentSmallContainer>
        <Header>
          <Title>{page.templateFields.newsTitle}</Title>
        </Header>
        <List>
          {news.length !== 0
            ? news.map((item, index) => (
                <Item key={index}>
                  <ItemHeader>
                    <ItemBrand>{item.brand ?? ''}</ItemBrand>
                    <ItemDate>{item.date ?? ''}</ItemDate>
                  </ItemHeader>
                  <ItemContent href={item.url || undefined} target="_blank">
                    {item.title}
                    <ItemArrow>
                      <ArrowIcon />
                    </ItemArrow>
                  </ItemContent>
                </Item>
              ))
            : null}
        </List>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin: 110px 0 200px;

  ${media.laptop(css`
    margin-bottom: 160px;
  `)}

  ${media.tabletSmall(css`
    margin: 100px 0 140px;
  `)}

  ${media.mobile(css`
    margin: 60px 0 120px;
  `)}
`;

const Header = styled.header``;

const List = styled.div`
  margin-top: 80px;

  ${media.tabletSmall(css`
    margin-top: 60px;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Title = styled.h2`
  font-size: 56px;
  letter-spacing: -0.01em;
  line-height: 100%;
  position: relative;
  display: inline-block;

  ${media.mobile(css`
    font-size: 36px;
  `)}
`;

const Item = styled.div`
  &:not(:first-child) {
    padding-top: 65px;
    margin-top: 60px;
    border-top: 1px solid ${colors.grayDark};
  }

  ${media.laptop(css`
    &:not(:first-child) {
      padding-top: 45px;
      margin-top: 40px;
    }
  `)}

  ${media.mobile(css`
    &:not(:first-child) {
      padding-top: 35px;
      margin-top: 30px;
    }
  `)}
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 55px;

  ${media.laptop(css`
    margin-bottom: 30px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}

  ${media.mobile(css`
    margin-bottom: 15px;
  `)}
`;

const ItemBrand = styled.span`
  color: ${colors.black20};
  font-size: 31px;
  font-weight: bold;
  letter-spacing: -0.01em;
  line-height: 40px;
  padding-right: 15px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    height: 25px;
    top: 50%;
    transform: translateY(-50%);
    background: #e0e0e0;
    width: 1px;
  }

  ${media.tabletLarge(css`
    font-size: 26px;
    line-height: 35px;
  `)}

  ${media.mobile(css`
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.mobileSmall(css`
    font-size: 21px;
    line-height: 30px;
  `)}
`;

const ItemDate = styled.span`
  color: ${colors.black20};
  font-size: 15px;
  padding-left: 10px;
  font-weight: 600;

  ${media.mobileSmall(css`
    font-size: 13px;
  `)}
`;

const ItemContent = styled.a`
  font-size: 31px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: -0.016em;
  cursor: pointer;
  position: relative;
  padding-right: 49px;
  color: #000;
  display: block;

  &:hover {
    color: ${colors.blue};
  }

  ${media.laptop(css`
    display: inline-block;
    max-width: 960px;
  `)}

  ${media.tabletLarge(css`
    max-width: 860px;
    font-size: 26px;
    line-height: 35px;
  `)}

  ${media.tabletSmall(css`
    max-width: 640px;
  `)}

  ${media.mobileSmall(css`
    font-size: 21px;
    line-height: 30px;
  `)}
`;

const ItemArrow = styled.div`
  position: absolute;
  right: 0;
  top: -9px;
`;

export default PressCenterNews;
