import React from 'react';

import Features from '@/components/Features';
import useCurrentPage from '@/hooks/useCurrentPage';
import { MakePaymentsPageType } from '@/typings/model';

function CollectPaymentsFeatures() {
  const { features } = useCurrentPage<MakePaymentsPageType>().templateFields;
  return (
    <Features
      items={features.map((item) => {
        return {
          imgSrc: item.image?.url || '',
          imgSrc2x: item.image2x?.url || '',
          title: item.title || '',
          text: item.text || '',
        };
      })}
    />
  );
}

export default CollectPaymentsFeatures;
