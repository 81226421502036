import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import HandRightImage from '@/assets/images/accounts/one-account.png';

gsap.registerPlugin(SplitText);

function AccountsPlaces() {
  const containerRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(imageRef.current, {
        y: '20%',
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 32%',
          scrub: true,
        },
      });

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      )
        .from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.8,
            opacity: 0,
            delay: 0.2,
            y: 20,
          },
          1
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.25,
            delay: 0.1,
          },
          1
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
      tw?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Inner>
          <Content>
            <SubTitleWrapper>
              <SubTitle ref={labelRef}>One Account</SubTitle>
            </SubTitleWrapper>
            <Title ref={titleRef}>Countless places to go</Title>
            <Text ref={textRef}>
              Imagine one account with all the functionality you’ll ever need.
              Pay-in and pay-out transfers, via SWIFT and SEPA, can be completed
              in seconds; online payments in several major currencies, are quick
              and easy. It’s the only e&#8209;wallet your business will ever
              need.
            </Text>
          </Content>
          <Image>
            <ImageInner>
              <img ref={imageRef} src={HandRightImage} />
            </ImageInner>
          </Image>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 25px;
  overflow: hidden;

  ${media.tabletSmall(css`
    margin-top: 0;
  `)}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;

  ${media.tabletSmall(css`
    flex-direction: column-reverse;
  `)}
`;

const Content = styled.div`
  margin-right: 60px;
  flex: 1 1 1px;

  ${media.laptop(css`
    margin-right: 80px;
  `)}

  ${media.tabletLarge(css`
    margin-right: 60px;
  `)}
  
  ${media.tabletSmall(css`
    width: 100%;
    margin-right: 0;
  `)}
`;

const SubTitleWrapper = styled.div`
  overflow: hidden;
`;

const SubTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 100%;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`;

const Title = styled.h2`
  display: block;
  margin-top: 38px;
  font-size: 102px;
  line-height: 86px;
  letter-spacing: -0.02em;
  font-weight: bold;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 30px;
    font-size: 80px;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
    line-height: 64px;
  `)}

  ${media.mobile(css`
    font-size: 54px;
    line-height: 54px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 48px;
    line-height: 48px;
  `)}
`;

const Text = styled.p`
  margin-top: 45px;
  display: block;
  max-width: 500px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    margin-top: 30px;
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 514px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const Image = styled.div`
  position: relative;
  width: 630px;
  max-width: 60%;
  flex-basis: 630px;
  height: 630px;

  ${media.laptop(css`
    max-width: 48%;
    height: auto;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }
  `)}

  ${media.tabletSmall(css`
    width: calc(100% + 40px);
    max-width: none;
    flex-basis: 462px;
    margin: 0 -20px 30px;
    overflow: hidden;

    &:before {
      padding-top: 90%;
    }
  `)}
  
  ${media.mobileLarge(css`
    flex-basis: 100%;
  `)}
`;

const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  padding-top: 10px;
  padding-right: 20px;

  img {
    max-width: 160%;
  }

  ${media.laptop(css`
    padding-top: 0;

    img {
      max-width: 150%;
    }
  `)}

  ${media.tabletSmall(css`
    padding-top: 10px;
    padding-right: 0;

    img {
      max-width: 100%;
    }
  `)}
  
  ${media.mobile(css`
    padding-left: 10px;

    img {
      max-width: 160%;
    }
  `)}
`;

export default AccountsPlaces;
