import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowRightIcon } from '@/assets/svg/arrow-usp-3.svg';
import { ReactComponent as ArrowBottomIcon } from '@/assets/svg/arrow-usp-1.svg';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AccountsIntro() {
  const bgRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const subtitleRef = useRef<HTMLSpanElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '25%',
        },
        0
      ).to(
        innerRef.current,
        {
          ease: 'linear',
          y: -100,
        },
        0
      );
    });

    return () => {
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitle = new SplitText(titleRef.current, {
      type: 'chars, words',
    });
    const splitSubtitle = new SplitText(subtitleRef.current, {
      type: 'chars, words',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
        },
      });

      tl.from(
        splitTitle.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
          delay: 0.2,
        },
        0
      ).from(
        splitSubtitle.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
        },
        0.5
      );
    });

    return () => {
      splitTitle?.revert();
      splitSubtitle?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner ref={innerRef}>
          <TextBlock>
            <TextBackground ref={bgRef} />
            <Title ref={titleRef}>Wiiiiiiidee</Title>
            <SubTitle ref={subtitleRef}>correspondent network</SubTitle>
            <FirstCircle>
              <FirstCircleArrow>
                <ArrowBottomIcon />
              </FirstCircleArrow>
              Your account is connected to&nbsp;a&nbsp;network of corresponding
              global banks.
            </FirstCircle>
            <SecondCircle>
              <SecondCircleArrow>
                <ArrowRightIcon />
              </SecondCircleArrow>
              While your customers might live all over the world, you get
              domestic clearing, and can make and receive payments, just like a
              local.
            </SecondCircle>
          </TextBlock>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 125px;
  overflow: hidden;

  ${media.mobile(css`
    padding-top: 50px;
  `)}
`;

const Inner = styled.div`
  padding-bottom: 350px;
`;

const TextBlock = styled.div`
  position: relative;
  padding-bottom: 420px;
  padding-left: 140px;
  z-index: 3;

  @media (max-width: 1640px) {
    padding-bottom: 380px;
  }

  ${media.laptop(css`
    padding-bottom: 220px;
  `)}

  ${media.tabletLarge(css`
    padding-bottom: 100px;
  `)}
  
  ${media.tabletSmall(css`
    padding-left: 0;
  `)}
  
  @media (max-width: 560px) {
    padding-bottom: 180px;
  }
`;

const TextBackground = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.grayLight};
  z-index: -1;

  ${media.tabletLarge(css`
    top: 57px;
  `)}

  ${media.mobile(css`
    top: 37px;
    margin: 0 -20px;
  `)}
`;

const Title = styled.h2`
  position: relative;
  display: block;
  font-size: 266px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0.02em;
  white-space: nowrap;
  z-index: 2;

  @media (max-width: 1640px) {
    font-size: 228px;
  }

  ${media.laptop(css`
    font-size: 128px;
  `)}

  ${media.tabletLarge(css`
    font-size: 118px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 82px;
  `)}
  
  ${media.mobileMedium(css`
    font-size: 64px;
  `)}
`;

const SubTitle = styled.span`
  position: relative;
  display: block;
  font-size: 190px;
  line-height: 218px;
  margin-left: -5px;
  font-weight: 600;
  z-index: 2;

  @media (max-width: 1640px) {
    font-size: 148px;
    line-height: 158px;
  }

  ${media.laptop(css`
    max-width: 730px;
    font-size: 88px;
    line-height: 98px;
  `)}

  ${media.tabletLarge(css`
    max-width: 600px;
    font-size: 78px;
    line-height: 88px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 58px;
    line-height: 68px;
  `)}
  
  ${media.mobile(css`
    margin-top: 0;
  `)}
  
  ${media.mobileLarge(css`
    font-size: 52px;
    line-height: 58px;
  `)}
  
  ${media.mobileMedium(css`
    font-size: 42px;
    line-height: 48px;
  `)}
`;

const FirstCircle = styled.div`
  position: absolute;
  right: 45px;
  top: 515px;
  display: flex;
  align-items: center;
  width: 560px;
  height: 560px;
  border-radius: 100%;
  padding: 0 12px 0 32px;
  font-size: 37px;
  font-weight: bold;
  line-height: 100%;
  letter-spacing: -0.01em;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${colors.accountsPrimary};
    z-index: -1;
  }

  @media (max-width: 1640px) {
    top: 446px;
    right: 85px;
  }

  ${media.laptop(css`
    top: 200px;
    width: 367px;
    height: 367px;
    padding: 70px;
    font-size: 29px;
  `)}

  ${media.tabletLarge(css`
    top: 230px;
    width: 267px;
    height: 267px;
    padding: 50px;
    font-size: 23px;
  `)}
  
  ${media.tabletSmall(css`
    top: 170px;
    width: 247px;
    height: 247px;
    font-size: 19px;
  `)}
  
  ${media.mobile(css`
    right: 0;
    width: 240px;
    height: 320px;
    padding-right: 0;
    font-size: 24px;

    &:before {
      width: calc(100% + 80px);
    }
  `)}
  
  ${media.mobileMedium(css`
    top: 110px;
  `)}
`;

const FirstCircleArrow = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  width: 53px;
  height: 65px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.mobile(css`
    top: 45px;
    left: auto;
    right: 20px;
    width: 46px;
    height: 46px;
  `)}
`;

const SecondCircle = styled.div`
  position: absolute;
  right: 515px;
  bottom: -286px;
  display: flex;
  align-items: center;
  width: 560px;
  height: 560px;
  border-radius: 100%;
  padding: 0 40px 0 90px;
  font-size: 37px;
  font-weight: bold;
  line-height: 100%;
  letter-spacing: -0.01em;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${colors.accountsPrimary};
    z-index: -1;
  }

  @media (max-width: 1640px) {
    right: 580px;
  }

  ${media.laptop(css`
    right: 412px;
    width: 467px;
    height: 467px;
    padding: 70px;
    font-size: 29px;
  `)}

  ${media.tabletLarge(css`
    right: 360px;
    width: 357px;
    height: 357px;
    font-size: 23px;
  `)}
  
  ${media.tabletSmall(css`
    right: 280px;
    width: 327px;
    height: 327px;
    padding: 60px;
    font-size: 19px;
  `)}
  
  ${media.mobile(css`
    right: 240px;
    width: 320px;
    height: 320px;
  `)}
  
  @media (max-width: 560px) {
    left: 50%;
    right: auto;
    bottom: -330px;
    transform: translateX(-50%);
  }
`;

const SecondCircleArrow = styled.div`
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  width: 65px;
  height: 53px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.mobile(css`
    top: 30px;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
    width: 46px;
    height: 46px;
  `)}
`;

export default AccountsIntro;
