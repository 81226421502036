import React from 'react';
import styled, { css } from 'styled-components';

import { nl2br } from '@tager/web-core';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { ButtonNextLink } from '@/components/Button/Button';
import { ReactComponent as ButtonArrowSvg } from '@/assets/svg/button-arrow.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/svg/arrow-4.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { MakePaymentsPageType } from '@/typings/model';

const MakePaymentsBenefits = () => {
  const {
    benefitsSubtitle,
    benefitsItems,
    benefitsTitle,
    benefitsButtonLabel,
    benefitsButtonLink,
  } = useCurrentPage<MakePaymentsPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Subtitle>
          {benefitsSubtitle} <ArrowDownIcon />
        </Subtitle>
        <Title>{benefitsTitle}</Title>
        <Grid>
          {benefitsItems.map((item) => (
            <Item>
              <ItemIcon>
                <Picture src={item.icon?.url || ''} />
              </ItemIcon>
              <ItemTitle>{item.title}</ItemTitle>
              <ItemText>{nl2br(item.text)}</ItemText>
            </Item>
          ))}
        </Grid>
        <ButtonRow>
          <ButtonNextLink to={benefitsButtonLink} variant="whiteRounded">
            {benefitsButtonLabel} <ButtonArrowSvg />
          </ButtonNextLink>
        </ButtonRow>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  padding: 180px 0;

  ${media.tablet(css`
    padding: 120px 0;
  `)}

  ${media.mobile(css`
    padding: 60px 0;
  `)}
`;

const Subtitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 50px;
  color: #fff;
  svg {
    margin-left: 10px;
  }
`;

const Title = styled.h2`
  display: block;
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  color: #ffffff;

  ${media.tablet(css`
    font-size: 60px;
    line-height: 65px;
    margin-top: 80px;
  `)}

  ${media.mobile(css`
    font-size: 40px;
    line-height: 40px;
    margin-top: 60px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;

  ${media.tablet(css`
    margin-top: 0px;
    flex-wrap: wrap;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
    display: block;
  `)}
`;

const Item = styled.div`
  color: #fff;
  max-width: 350px;
  ${media.tablet(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 0;
    &:not(:first-child) {
      margin-top: 40px;
    }
  `)}
`;

const ItemTitle = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  margin: 30px 0;
  display: block;
`;

const ItemIcon = styled.div`
  display: inline-flex;
`;

const ItemText = styled.div`
  font-size: 22px;
  line-height: 32px;

  p:not(:first-child) {
    margin-top: 18px;
  }
`;

const ButtonRow = styled.div`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

export default MakePaymentsBenefits;
