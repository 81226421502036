import React, { useEffect, useImperativeHandle, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power3 } from 'gsap';

import { Nullable } from '@tager/web-core';

import { media } from '@/utils/mixin';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
};

const ParallaxButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, onClick }, ref) => {
    const textRef = useRef<HTMLSpanElement>(null);
    const circleRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLButtonElement>(null);

    useImperativeHandle(ref, () => {
      return containerRef.current as HTMLButtonElement;
    });

    function parallaxIt(
      e: MouseEvent,
      target: Nullable<HTMLElement>,
      movement: number
    ) {
      const containerElem = containerRef.current;
      if (!containerElem) return;

      const containerCoords = containerElem.getBoundingClientRect();
      const relX = e.pageX - containerCoords.left;
      const relY = e.pageY - containerCoords.top;

      gsap.to(target, {
        x: () =>
          ((relX - containerElem?.clientWidth / 2) /
            containerElem?.clientWidth) *
          movement,
        y: () =>
          ((relY - containerElem?.clientHeight / 2) /
            containerElem?.clientHeight) *
          movement,
        ease: Power3.easeOut,
        duration: 0.8,
      });
    }

    useEffect(() => {
      const containerElem = containerRef.current;
      if (!containerElem) return;

      function handleMouseMove(e: MouseEvent) {
        parallaxIt(e, textRef.current, 40);
        parallaxIt(e, circleRef.current, 70);
      }

      function handleMouseEnter() {
        gsap.to(circleRef.current, { scale: 1.2, duration: 0.3 });
      }

      function handleMouseLeave() {
        gsap.killTweensOf([circleRef.current, textRef.current]);
        gsap.to([circleRef.current, textRef.current], {
          duration: 0.4,
          scale: 1,
          x: 0,
          y: 0,
        });
      }

      containerElem.addEventListener('mousemove', handleMouseMove);
      containerElem.addEventListener('mouseleave', handleMouseLeave);
      containerElem.addEventListener('mouseenter', handleMouseEnter);

      return () => {
        containerElem.removeEventListener('mousemove', handleMouseMove);
        containerElem.removeEventListener('mouseleave', handleMouseLeave);
        containerElem.removeEventListener('mouseenter', handleMouseEnter);
      };
    }, []);

    return (
      <Container ref={containerRef} onClick={onClick}>
        <Circle ref={circleRef} />
        <Text ref={textRef}>{children}</Text>
      </Container>
    );
  }
);

const Container = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 133px;
  padding: 10px 10px 0;
  text-align: center;
  z-index: 0;

  ${media.mobile(css`
    width: 88px;
    height: 88px;
    padding: 10px;
  `)}
`;

const Circle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
`;

const Text = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: #040312;

  ${media.mobile(css`
    font-size: 14px;
    line-height: 18px;
  `)}
`;

export default ParallaxButton;
