import React, { useEffect, useRef, useState } from 'react';
import { FieldArray, Formik, FormikErrors, FormikHelpers } from 'formik';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styled from 'styled-components';

import { convertRequestErrorToMap } from '@tager/web-core';

import ContentContainer from '@/components/ContentContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import TextInput, { Field } from '@/components/TextInput';
import { submitFullForm } from '@/services/requests';
import * as S from '@/components/Form.styles';
import CheckBox from '@/components/Checkbox/Checkbox';
import { scrollToElement } from '@/utils/common';
import { colors } from '@/constants/theme';

import { convertFormValues } from './FormLinks.helpers';
import { appropriateList, interestList } from './FormLinks.constans';
import { FormLinksValuesType } from './FormLinks.types';

gsap.registerPlugin(ScrollTrigger);

type Props = {
  onSuccess: () => void;
};

function ScrollToError({
  errors,
  refMap,
}: {
  errors: FormikErrors<FormLinksValuesType>;
  refMap: Record<string, React.RefObject<HTMLDivElement>>;
}) {
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length !== 0) {
      const errorField = refMap[keys[0]];
      if (errorField.current) {
        scrollToElement(errorField);
      }
    }
  }, [errors]);

  return null;
}

function FormConferenceInner({ onSuccess }: Props) {
  const page = useCurrentPage<FormPageType>();
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitError, setSubmitError] = useState({
    state: false,
    value: 'An error occurred while submitting the form',
  });

  const refMap = {
    email: useRef<HTMLDivElement>(null),
    name: useRef<HTMLDivElement>(null),
    website: useRef<HTMLDivElement>(null),
    company: useRef<HTMLDivElement>(null),
    message: useRef<HTMLDivElement>(null),
    appropriate: useRef<HTMLDivElement>(null),
    interest: useRef<HTMLDivElement>(null),
  };

  if (!page) return null;

  function handleClickOnButton(errors: FormikErrors<FormLinksValuesType>) {
    const keys = Object.keys(errors);
    if (keys.length !== 0) {
      const errorField = refMap[keys[0] as keyof FormLinksValuesType];
      if (errorField.current) {
        scrollToElement(errorField);
      }
    }
  }

  async function handleSubmitForm(
    values: FormLinksValuesType,
    formikHelpers: FormikHelpers<FormLinksValuesType>
  ) {
    setLoading(true);
    setSubmitError({ ...submitError, state: false });

    const payload = convertFormValues(values);
    const formId = page?.templateFields?.form?.id;

    if (!formId) {
      setLoading(false);
      return setSubmitError({
        ...submitError,
        value: 'there is no ID form, please contact support',
        state: true,
      });
    }
    try {
      await submitFullForm(payload, formId);
      if (onSuccess) onSuccess();
    } catch (error) {
      const errors = convertRequestErrorToMap(error);

      if (Object.keys(errors).length !== 0) {
        formikHelpers.setErrors(errors as FormikErrors<FormLinksValuesType>);
      } else {
        setSubmitError({
          ...submitError,
          value: 'An error occurred while submitting the form',
          state: true,
        });
      }

      setLoading(false);
    }
  }

  return (
    <StyledComponent>
      <ContentContainer width={999}>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmitForm}
          initialValues={
            {
              name: '',
              email: '',
              message: '',
              company: '',
              website: '',
              appropriate: [],
              interest: [],
            } as FormLinksValuesType
          }
        >
          {({ values, errors, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <S.Form onSubmit={handleSubmit}>
                <S.FormInputs>
                  <S.FormRow ref={refMap.name}>
                    <TextInput
                      name="name"
                      value={values.name}
                      error={errors.name}
                      placeholder="Full name"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.email}>
                    <TextInput
                      name="email"
                      value={values.email}
                      error={errors.email}
                      placeholder="Email"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.company}>
                    <TextInput
                      name="company"
                      value={values.company}
                      error={errors.company}
                      placeholder="Company"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.website}>
                    <TextInput
                      name="website"
                      value={values.website}
                      error={errors.website}
                      placeholder="Website"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  <S.FormRow ref={refMap.appropriate}>
                    <S.FieldTitle>
                      Please select the appropriate:{' '}
                      {errors.appropriate ? (
                        <S.StaticError>{errors.appropriate}</S.StaticError>
                      ) : null}
                    </S.FieldTitle>

                    <FieldArray
                      name="appropriate"
                      render={() => (
                        <StyledCheckBoxGroup>
                          {appropriateList.map((item) => {
                            return (
                              <CheckBox
                                key={item.value}
                                value={item.value}
                                name="appropriate"
                                label={item.label}
                                checked={values.appropriate.some(
                                  (appropriateItem) =>
                                    appropriateItem.value === item.value
                                )}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const newFieldValue = e.target.checked
                                    ? [...values.appropriate, item]
                                    : values.appropriate.filter(
                                        (selectedAppropriateItem) =>
                                          selectedAppropriateItem.value !==
                                          item.value
                                      );

                                  setFieldValue('appropriate', newFieldValue);
                                }}
                              />
                            );
                          })}
                        </StyledCheckBoxGroup>
                      )}
                    />
                  </S.FormRow>

                  <S.FormRow ref={refMap.interest}>
                    <S.FieldTitle>
                      <span>What services are you interested in?</span>{' '}
                      {errors.interest ? (
                        <S.StaticError>{errors.interest}</S.StaticError>
                      ) : null}
                    </S.FieldTitle>

                    <FieldArray
                      name="interest"
                      render={() => (
                        <StyledCheckBoxGroup>
                          {interestList.map((item) => {
                            return (
                              <CheckBox
                                key={item.value}
                                value={item.value}
                                name="interest"
                                label={item.label}
                                checked={values.interest.some(
                                  (interestItem) =>
                                    interestItem.value === item.value
                                )}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  const newFieldValue = e.target.checked
                                    ? [...values.interest, item]
                                    : values.interest.filter(
                                        (selectedInterestItem) =>
                                          selectedInterestItem.value !==
                                          item.value
                                      );

                                  setFieldValue('interest', newFieldValue);
                                }}
                              />
                            );
                          })}
                        </StyledCheckBoxGroup>
                      )}
                    />
                  </S.FormRow>

                  <S.FormRow ref={refMap.message}>
                    <TextInput
                      name="message"
                      value={values.message}
                      error={errors.message}
                      placeholder="Leave a message"
                      onChange={handleChange}
                    />
                  </S.FormRow>
                  {page.templateFields.agreementText ? (
                    <S.FormRow>
                      <S.CheckboxLabel>
                        <input
                          checked={agreementChecked}
                          onChange={(e) =>
                            setAgreementChecked(!agreementChecked)
                          }
                          type="checkbox"
                        />
                        <StyledSpan
                          dangerouslySetInnerHTML={{
                            __html: page?.templateFields.agreementText,
                          }}
                        />
                      </S.CheckboxLabel>
                    </S.FormRow>
                  ) : null}
                </S.FormInputs>
                <StyledButton
                  disabled={
                    !!page.templateFields.agreementText && !agreementChecked
                  }
                  type="submit"
                  loading={loading}
                  onClick={() => handleClickOnButton(errors)}
                >
                  {page.templateFields.submitButtonLabel}
                </StyledButton>
                {submitError.state ? (
                  <S.FormError>{submitError.value}</S.FormError>
                ) : null}
                <ScrollToError errors={errors} refMap={refMap} />
              </S.Form>
            );
          }}
        </Formik>
      </ContentContainer>
    </StyledComponent>
  );
}

const StyledComponent = styled(S.Component)`
  ${Field} {
    input:focus {
      border-bottom-color: ${colors.linksPrimary};
    }
  }
`;

const StyledCheckBoxGroup = styled(S.CheckBoxGroup)`
  display: block !important;

  div {
    width: 100% !important;

    input::before {
      background: ${colors.linksPrimary} !important;
    }
  }
`;

const StyledButton = styled(S.Button)`
  background: ${colors.linksPrimary} !important;

  &:hover {
    background: ${colors.linksPrimaryHover} !important;
  }
`;

const StyledSpan = styled.span`
  &:after {
    background: ${colors.linksPrimary} !important;
  }
`;

export default FormConferenceInner;
