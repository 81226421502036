import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ContactsPageType } from '@/typings/model';
import { media } from '@/utils/mixin';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';
import LinkWithUnderlineLine from '@/components/LinkWithUnderlineLine';

function ContactsHero() {
  const page = useCurrentPage<ContactsPageType>();
  if (!page) return null;

  const { email, text } = page?.templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Wrapper>
          <ContentBlock>
            <Title>{page.title ?? ''}</Title>
            <TextBlock>
              <LinkWithUnderlineLine href={`mailto:${email}`} label={email} />
              <Text>{text ?? ''}</Text>
            </TextBlock>
          </ContentBlock>
          <ButtonWrapper>
            <HeroCircleArrowButton />
          </ButtonWrapper>
        </Wrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  background: #fff;
  overflow: hidden;

  padding-bottom: 10px;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
`;

export const Title = styled.h1`
  font-size: 292px;
  line-height: 90%;
  font-weight: 700;
  white-space: nowrap;
  margin: 0 0 0 -10px;
  color: ${colors.black};

  ${media.laptop(css`
    margin-left: 0;
    margin-top: 30px;
    font-size: 240px;
  `)}

  ${media.tabletLarge(css`
    font-size: 190px;
  `)}

  ${media.tabletSmall(css`
    font-size: 140px;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
    font-size: 78px;
  `)}

  ${media.mobileSmall(css`
    font-size: 65px;
  `)}
`;

const ContentBlock = styled.div``;

export const TextBlock = styled.div`
  margin-left: 12px;
  transform: translateY(58px);

  ${media.laptop(css`
    transform: none;
    margin-top: 38px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 18px;
  `)}

  ${media.mobile(css`
    margin-left: 0;
  `)}
`;

export const Text = styled.p`
  font-size: 31px;
  line-height: 40px;
  letter-spacing: -0.011em;
  max-width: 650px;
  margin-top: 50px;
  font-weight: 600;

  ${media.laptop(css`
    margin-top: 40px;
    font-size: 27px;
    line-height: 36px;
  `)}

  ${media.tabletLarge(css`
    max-width: 500px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 30px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 19px;
    line-height: 24px;
  `)}
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 110px;
  color: #fff;

  ${media.tabletSmall(css`
    position: relative;
    right: auto;
    bottom: auto;
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  ${media.mobileSmall(css`
    margin-top: 20px;
  `)}
`;

export default ContactsHero;
