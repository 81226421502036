import React, { useState } from 'react';

import Layout from '@/components/Layout';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';

import HomeCallToActions from './components/HomeCallToActions';
import HomeFeatures from './components/HomeFeatures';
import HomeGrow from './components/HomeGrow';
import HomeSimplicity from './components/HomeSimplicity';
import HomeSlogan from './components/HomeSlogan';
import HomeHero from './components/HomeHero';

function Home2023() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="black" dark={true} onPreloaderOut={handlePreloaderOut}>
      <HomeHero animate={isPreloaderOut} />
      <HomeSlogan />
      <HomeFeatures />
      <HomeGrow />
      <HomeSimplicity />
      <HomeCallToActions />
    </Layout>
  );
}

export default Home2023;
