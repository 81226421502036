import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ArrowSwitcherSvg } from '@/assets/svg/arrow_switcher.svg';

type Props = {
  white?: boolean;
  label?: string;
};

const SectionLabel = React.forwardRef<HTMLSpanElement, Props>(
  ({ white, label }, ref) => (
    <Component ref={ref} white={white}>
      {label} <ArrowSwitcherSvg />
    </Component>
  )
);

const Component = styled.span<{ white?: boolean }>`
  display: block;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  font-weight: 600;
  color: ${(props) => (props.white ? '#fff' : '#000')};

  svg {
    margin-left: 7px;
    transform: translateY(2px);

    path {
      fill: ${(props) => (props.white ? '#fff' : '#000')};
    }
  }
`;

export default SectionLabel;
