import React from 'react';

import Layout from '@/components/Layout';
import ContactsHero from '@/modules/Contacts/components/ContactsHero';
import ContactsOffices from '@/modules/Contacts/components/ContactsOffices';
import ContactSales from '@/modules/Contacts/components/ContactsSales';

function Contacts() {
  return (
    <Layout theme="white">
      <ContactsHero />
      <ContactsOffices />
      <ContactSales />
    </Layout>
  );
}

export default Contacts;
