import React from 'react';
import { SplitText } from 'gsap/dist/SplitText';
import gsap from 'gsap';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import useIntroAnimation from '@/modules/common/Links/hooks/useIntroAnimation';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import * as S from '../../common/Links/Intro.styles';

gsap.registerPlugin(SplitText);

function StacksIntro() {
  const { containerRef, bgRef, textRef, titleRef } = useIntroAnimation();

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <S.Inner>
          <TitleBackground ref={bgRef} />
          <Title ref={titleRef}>
            <TitleInner ref={textRef}>
              All you need is on the tip of your finger.
            </TitleInner>
            <CircleContainer>
              <CircleArrow>
                <ArrowIcon />
              </CircleArrow>
              Stacks allows companies and private customers to manage their
              finance in a completely new way
            </CircleContainer>
          </Title>
        </S.Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled(S.Component)`
  padding-bottom: 257px;

  @media (max-width: 1024px) {
    padding-bottom: 200px;
  }

  @media (max-width: 768px) {
    padding-top: 100px !important;
    margin-bottom: -100px;
    padding-bottom: 40px;
  }

  @media (max-width: 320px) {
    margin-bottom: -40px;
  }
`;

const Title = styled(S.Title)`
  @media (max-width: 768px) {
    padding-bottom: 450px;
  }
`;

const CircleArrow = styled(S.CircleArrow)`
  top: 6px !important;
  left: 15px !important;
`;

const TitleInner = styled(S.TitleInner)`
  max-width: 1164px;

  @media (max-width: 1599px) {
    max-width: 950px;
  }

  ${media.laptop(css`
    max-width: 650px;
  `)}

  ${media.tabletLarge(css`
    max-width: 550px;
  `)}

  ${media.tabletSmall(css`
    max-width: 450px;
  `)}

  ${media.mobile(css`
    max-width: 550px;
  `)}
`;

const CircleContainer = styled(S.CircleContainer)`
  width: 481px !important;
  height: 481px !important;
  padding: 49px 53px 49px;
  top: 57%;
  right: 18%;

  &:before {
    background: ${colors.stacksPrimary};
  }

  @media (max-width: 1599px) {
    top: 50%;
    right: 20%;
  }

  @media (max-width: 1400px) {
    top: 10%;
    right: 0;
    width: 400px !important;
    height: 400px !important;
  }

  @media (max-width: 1024px) {
    font-size: 20px;
    top: 40%;
    right: -1%;
    width: 320px !important;
    height: 320px !important;
  }

  @media (max-width: 768px) {
    font-size: 19px;
    top: 48%;

    right: 0;
    &:before {
      max-width: 320px;
      width: 100vw;
      height: 320px;
    }
  }

  @media (max-width: 414px) {
    padding: 49px 73px 49px;
    top: 55%;
    left: 70%;
    transform: translateX(-50%);

    ${CircleArrow} {
      top: -10px !important;
      left: 70px !important;
    }
  }
`;

const TitleBackground = styled(S.TitleBackground)`
  @media (max-width: 768px) {
    height: 60%;
  }
`;

export default StacksIntro;
