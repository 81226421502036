import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowButton } from '@/assets/svg/arrow_button.svg';
import { TryButton } from '@/components/Layout/components/Footer/components/Slogan/Slogan.styles';
import Picture from '@/components/Picture';
import SectionLabel from '@/modules/Home/components/SectionLabel';
import useCurrentPage from '@/hooks/useCurrentPage';
import { HomePageType } from '@/typings/model';

gsap.registerPlugin(SplitText);

const HomeSass = () => {
  const { templateFields } = useCurrentPage<HomePageType>();

  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      ).from(
        textRef.current,
        {
          ease: Power2.easeOut,
          transformOrigin: '50% 100%',
          duration: 0.7,
          opacity: 0,
          delay: 0.2,
          y: 20,
        },
        1
      );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Inner>
          <Content>
            {templateFields.sassSubtitle ? (
              <SectionLabel white label={templateFields.sassSubtitle} />
            ) : null}
            <Title ref={titleRef}>{templateFields.sassTitle}</Title>
            {templateFields.sassList ? (
              <List ref={textRef}>
                {templateFields.sassList.split('\n').map((item) => (
                  <ListItem>{item}</ListItem>
                ))}
              </List>
            ) : null}
            {templateFields.sassButtonLabel && templateFields.sassButtonLink ? (
              <ButtonRow>
                <TryButton
                  to={templateFields.sassButtonLink}
                  variant="whiteRounded"
                >
                  <span>
                    {templateFields.sassButtonLabel} <ArrowButton />
                  </span>
                </TryButton>
              </ButtonRow>
            ) : null}
          </Content>
          {templateFields.sassImage ? (
            <Image>
              <Picture
                {...convertThumbnailToPictureProps(templateFields.sassImage)}
                alt={templateFields.sassTitle || ''}
              />
            </Image>
          ) : null}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: #040312;
  padding: 136px 0;

  margin-bottom: 120px;
  ${media.tabletSmall(css`
    margin-bottom: 80px;
    padding: 90px 0;
  `)}
  ${media.mobile(css`
    margin-bottom: 60px;
    padding: 45px 0;
  `)}
`;

const Inner = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    flex-direction: column-reverse;
  `)}
`;

const Content = styled.div`
  flex: 1 1 1px;
  color: #fff;
  min-width: 775px;

  ${media.tabletLarge(css`
    min-width: 0;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 104px;
  letter-spacing: -0.517105px;
  margin-top: 40px;

  ${media.laptop(css`
    font-size: 62px;
    line-height: 64px;
  `)}

  ${media.tabletLarge(css`
    font-size: 54px;
    line-height: 60px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
    line-height: 60px;
    margin-top: 30px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 42px;
    line-height: 42px;
  `)}
`;

const List = styled.ul`
  margin-top: 50px;
  max-width: 570px;

  ${media.mobile(css`
    max-width: none;
    margin-top: 30px;
  `)}
`;

const ListItem = styled.li`
  font-weight: 500;
  font-size: 31px;
  line-height: 145%;
  position: relative;
  padding-left: 40px;

  ${media.tablet(css`
    font-size: 24px;
    padding-left: 20px;
  `)}

  ${media.mobile(css`
    font-size: 18px;
    padding-left: 17px;
  `)}
  
  &:not(:first-child) {
    margin-top: 20px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #fff;
    border-radius: 50%;
    display: block;
    left: 12px;
    top: 15px;

    ${media.tablet(css`
      width: 6px;
      height: 6px;
      left: 3px;
      top: 12px;
    `)}

    ${media.mobile(css`
      width: 5px;
      height: 5px;
      left: 2px;
      top: 10px;
    `)}
  }
`;

const ButtonRow = styled.div`
  margin-top: 50px;

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Image = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 1px;
`;

export default HomeSass;
