import React from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styled from 'styled-components';

import SectionLabel from '@/modules/Home/components/SectionLabel';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import * as S from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.styles';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import { Component as SimplicityItemComponent } from '@/modules/Home/components/HomeSimplicity/components/SimplicityItem';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType } from '@/typings/model';

import InfinitySlider from './components/InfinitySlider';

gsap.registerPlugin(ScrollTrigger, SplitText);

function HomeSimplicity() {
  const {
    simplicityText,
    simplicityTitle,
    simplicitySubtitle,
    simplicityItems,
  } = useCurrentPage<Home2023PageType>().templateFields;

  const { containerRef, titleRef, labelRef, textRef, setSlideElem } =
    useSimplicityAnimation();

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <S.Inner>
          <S.Left>
            <S.TextWrapper>
              <SectionLabel ref={labelRef} label={simplicitySubtitle || ''} />
            </S.TextWrapper>
            <S.Title ref={titleRef}>{simplicityTitle}</S.Title>
          </S.Left>
          <S.Right>
            <S.Text ref={textRef}>{simplicityText}</S.Text>
          </S.Right>
        </S.Inner>
        <InfinitySlider
          slides={simplicityItems.map((item, index) => {
            return {
              num: '0' + (index + 1),
              color: '#F6F6F6',
              icon: item.icon?.url || '',
              title: item.title || '',
              text: item.text?.replaceAll('\n', '<br/>') || '',
            };
          })}
          setSlideElem={setSlideElem}
        />
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled(S.Component)`
  ${SimplicityItemComponent} {
    color: white !important;
  }
`;

export default HomeSimplicity;
