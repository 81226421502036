import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { createMediaMixin } from '@tager/web-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AcquiringFlexibility() {
  const bgRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '25%',
        },
        0
      ).to(
        innerRef.current,
        {
          ease: 'linear',
          y: -100,
        },
        0
      );
    });

    return () => {
      tl?.kill();
    };
  }, []);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitText = new SplitText(textRef.current, {
      type: 'chars, words',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
        },
      });

      tl.from(
        splitText.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
          delay: 0.2,
        },
        0
      );
    });

    return () => {
      splitText?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentContainer>
        <Inner ref={innerRef}>
          <Text>
            <TextBackground ref={bgRef} />
            <TextInner ref={textRef}>
              Flexibility the quality we never flex on
            </TextInner>
          </Text>
          <Circle>
            <Arrow>
              <ArrowIcon />
            </Arrow>
            <p>
              With our extensive global network of 79 payment method providers,
              we excel at flexible settlements.
            </p>
            <p>
              You can easily receive settlements and transfer funds to your bank
              account, pay your vendors or convert funds into alternative
              currencies.
            </p>
          </Circle>
        </Inner>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 125px;
  overflow: hidden;

  ${media.laptop(css`
    padding-top: 120px;
  `)}

  ${media.tabletSmall(css`
    padding-top: 100px;
  `)}

  ${media.mobile(css`
    padding-top: 50px;
  `)}
`;

const Inner = styled.div`
  position: relative;
  padding-bottom: 377px;

  ${media.tabletLarge(css`
    padding-bottom: 297px;
  `)}

  ${media.tabletSmall(css`
    padding-bottom: 197px;
  `)}

  ${media.mobile(css`
    padding-bottom: 177px;
  `)}
`;

const Text = styled.span`
  position: relative;
  display: block;
  padding-bottom: 152px;
  margin-left: 140px;

  ${media.laptop(css`
    margin-left: 40px;
  `)}

  ${media.tabletLarge(css`
    margin-left: 30px;
    padding-bottom: 162px;
  `)}

  ${media.tabletSmall(css`
    margin-left: 80px;
  `)}

  ${media.mobile(css`
    margin-left: 0;
    padding-bottom: 202px;
  `)}
`;

const TextBackground = styled.div`
  position: absolute;
  top: 87px;
  left: -140px;
  bottom: 0;
  right: 0;
  background: ${colors.grayLight};
  z-index: -1;

  ${media.tabletLarge(css`
    top: 47px;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const TextInner = styled.span`
  position: relative;
  display: block;
  font-size: 232px;
  line-height: 241px;
  font-weight: 600;
  max-width: 1138px;
  letter-spacing: -0.02em;
  z-index: 3;

  ${createMediaMixin({ max: 1530 })(css`
    margin-left: -91px;
  `)}

  ${media.laptop(css`
    max-width: 930px;
    padding-left: 200px;
    font-size: 98px;
    line-height: 114px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    max-width: 820px;
    padding-left: 160px;
    font-size: 88px;
    line-height: 94px;
  `)}

  ${media.tabletSmall(css`
    max-width: 540px;
    padding-left: 0;
    font-size: 68px;
    line-height: 74px;
  `)}

  ${media.mobile(css`
    max-width: 600px;
    padding-right: 18%;
    font-size: 58px;
    line-height: 68px;
  `)}

  ${media.mobileMedium(css`
    padding-right: 16%;
  `)}

  ${media.mobileSmall(css`
    padding-right: 0;
  `)}
`;

const Arrow = styled.div`
  position: absolute;
  left: 50%;
  top: -57px;
  transform: translateX(-50%);
  width: 84px;
  height: 104px;
  color: ${colors.black};
  z-index: 2;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    top: -37px;
    width: 64px;
    height: 84px;
  `)}

  ${media.tabletSmall(css`
    top: -17px;
    width: 54px;
    height: 54px;
  `)}

  ${media.mobile(css`
    top: 13px;
    left: auto;
    right: 20px;
    transform: none;
    width: 46px;
    height: 46px;
  `)}
`;

const Circle = styled.div`
  width: 737px;
  height: 737px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 75px 40px 90px 90px;
  letter-spacing: -0.01em;
  font-size: 37px;
  line-height: 100%;
  font-weight: 600;
  position: absolute;
  right: -20px;
  bottom: 50px;
  z-index: 2;
  color: ${colors.black};

  p {
    display: block;
    margin-top: 20px;

    &:first-child {
      margin-top: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: ${colors.acquiringPrimary};
    z-index: -1;
  }

  ${media.laptop(css`
    right: 140px;
    width: 567px;
    height: 567px;
    font-size: 29px;
  `)}

  ${media.tabletLarge(css`
    right: 100px;
    width: 457px;
    height: 457px;
    padding: 80px;
    font-size: 23px;
  `)}

  ${media.tabletSmall(css`
    right: 80px;
    width: 357px;
    height: 357px;
    padding: 60px;
    font-size: 19px;
  `)}

  ${media.mobile(css`
    right: 0;
    width: 280px;
    height: 400px;
    padding-right: 0;
    line-height: 20px;

    &:before {
      width: calc(100% + 120px);
    }
  `)}
`;

export default AcquiringFlexibility;
