import React from 'react';

import LandingHero from '@/components/LandingHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

const CollectPaymentsHero = () => {
  const {
    hero2xImage,
    heroImage,
    heroImageMobile,
    heroImageMobile2x,
    heroTitle,
  } = useCurrentPage<CollectPaymentsPageType>().templateFields;

  return (
    <LandingHero
      title={heroTitle || ''}
      imgSrc={heroImage?.url || ''}
      img2xSrc={hero2xImage?.url || ''}
      imgMobileSrc={heroImageMobile?.url || ''}
      imgMobile2xSrc={heroImageMobile2x?.url || ''}
    />
  );
};

export default CollectPaymentsHero;
