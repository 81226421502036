import React from 'react';
import styled, { css } from 'styled-components';

import BenefitsInner from '@/components/Benefits';
import { Component as SectionTitleComponent } from '@/components/SectionTitle';
import benefit1Icon from '@/assets/svg/xpp/xpp-icon 1.svg';
import benefit2Icon from '@/assets/svg/xpp/xpp-icon 2.svg';
import benefit3Icon from '@/assets/svg/xpp/xpp-icon 3.svg';
import benefit4Icon from '@/assets/svg/xpp/xpp-icon 4.svg';
import { media } from '@/utils/mixin';

const benefitsList = [
  {
    icon: benefit1Icon,
    title: 'Easy to get started',
    text: `Very simple onboarding procedure that requires only FIVE documents.`,
  },
  {
    icon: benefit2Icon,
    title: 'Commission',
    text: `We reward our partners and help them develop new revenue opportunities.`,
  },
  {
    icon: benefit3Icon,
    title: 'Assistance',
    text: `We are here to help you every step of the way from application to helping customers expand their business.`,
  },
  {
    icon: benefit4Icon,
    title: 'Friendly service',
    text: `Fast onboarding, 24/7 support, global payments, multi-currency and much, much more.`,
  },
];

function Benefits() {
  return (
    <Component>
      <BenefitsInner
        sectionTitle={'Why partner with us?'}
        itemList={benefitsList}
      />
    </Component>
  );
}

const Component = styled.div`
  padding-bottom: 186px;

  ${SectionTitleComponent} {
    max-width: 736px;
  }

  ${media.mobile(css`
    margin-top: -50px;
    padding-bottom: 80px;
  `)}
`;

export default Benefits;
