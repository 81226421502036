import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowIntegration } from '@/assets/svg/arrow_integration.svg';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { CssSnippet } from '@/typings/common';

gsap.registerPlugin(SplitText);

type Props = {
  color: string;
  hoverColor?: string;
  isBig?: boolean;
  isTextLight?: boolean;
  openInNewTab?: boolean;
  link?: string;
  children: React.ReactNode;
  customStyles?: CssSnippet;
  text?: string;
  buttonPosition?: 'top' | 'bottom';
};

function GetStarted({
  color = colors.blue,
  hoverColor = colors.blueHover,
  isBig = false,
  isTextLight = false,
  openInNewTab = false,
  customStyles,
  text,
  link = 'mailto:hello@xpate.com',
  buttonPosition = 'top',
  children,
}: Props) {
  const containerRef = useRef<HTMLAnchorElement>(null);
  const backgroundRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'lines',
    });

    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'lines',
    });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 82%',
          },
        })
        .fromTo(
          backgroundRef.current,
          {
            scaleX: 0,
          },
          {
            ease: 'customEaseInOut',
            transformOrigin: '0 50%',
            duration: 1.8,
            scaleX: 1,
          },
          0
        )
        .from(
          splitTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.8,
            delay: 0.35,
          },
          0
        )
        .from(
          buttonRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.8,
            delay: 0.35,
          },
          0
        );
    });

    return () => {
      delayCall.kill();
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component
      ref={containerRef}
      target={openInNewTab ? '_blank' : ''}
      to={link}
      hoverColor={hoverColor}
      isBig={isBig}
      isLight={isTextLight}
      customStyles={customStyles}
    >
      <Background ref={backgroundRef} color={color} />
      <ContentSmallContainer>
        <Inner>
          <Text ref={titleRef} isBig={isBig}>
            {children}
          </Text>
          {text ? <Note>{text}</Note> : null}
          <ButtonWrapper position={buttonPosition}>
            <Button ref={buttonRef} isBig={isBig}>
              <ArrowIntegration />
            </Button>
          </ButtonWrapper>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Background = styled.div<{ color: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.color};
  transition: background 0.35s ease-out;
  z-index: -1;
`;

const Component = styled(Link)<{
  hoverColor: string;
  isBig: boolean;
  isLight: boolean;
  customStyles?: CssSnippet;
}>`
  display: block;
  position: relative;
  padding: ${(props) => (props.isBig ? '136px 0 117px' : '160px 0 150px')};
  color: ${(props) => (props.isLight ? colors.white : colors.black)};
  z-index: 0;

  ${(props) => (props.customStyles ? props.customStyles : null)}
  &:hover ${Background} {
    background: ${(props) => props.hoverColor};
  }

  ${media.laptop(css`
    padding: 120px 0 95px;
  `)}

  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
`;

const Text = styled.span<{ isBig: boolean }>`
  display: block;
  max-width: 1200px;
  margin-left: -12px;
  font-size: ${(props) => (props.isBig ? '200px' : '102px')};
  font-weight: bold;
  line-height: 0.99;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-left: 0;
    font-size: 98px;
    line-height: 108px;
  `)}

  ${media.tabletLarge(css`
    font-size: 88px;
    line-height: 98px;
  `)}

  ${media.tabletSmall(css`
    padding-bottom: 10px;
    font-size: 68px;
    line-height: 78px;
  `)}

  ${media.mobile(css`
    margin-bottom: 20px;
    font-size: 58px;
    line-height: 64px;
  `)}

  ${media.mobileSmall(css`
    font-size: 48px;
    line-height: 54px;
  `)}
  
  .lineParent:last-child .lineChild {
    padding-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div<{ position: 'top' | 'bottom' }>`
  position: absolute;
  top: ${(props) => (props.position === 'top' ? '0' : 'auto')};
  bottom: ${(props) => (props.position === 'bottom' ? '20px' : 'auto')};
  right: 0;
  overflow: hidden;

  ${media.mobile(css`
    position: static;
    overflow: inherit;
  `)}
`;

export const Button = styled.div<{ isBig: boolean }>`
  width: ${(props) => (props.isBig ? '134px' : '120px')};
  height: ${(props) => (props.isBig ? '134px' : '120px')};
  cursor: pointer;
  border-radius: 100%;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.laptop(css`
    width: 120px;
    height: 120px;
  `)}

  ${media.tabletLarge(css`
    width: 109px;
    height: 109px;
  `)}

  ${media.tabletSmall(css`
    width: 89px;
    height: 89px;
  `)}

  ${media.mobile(css`
    width: 65px;
    height: 65px;
  `)}
`;

const Note = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  display: block;
  margin-top: 50px;
  width: 736px;
  max-width: 100%;
  ${media.mobile(css`
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
  `)}
`;

export default GetStarted;
