import React from 'react';
import styled, { css } from 'styled-components';

import { useMedia } from '@tager/web-core';

import StacksHands from '@/assets/images/stacks/stacks-hero1x.png';
import StacksMobileHands from '@/assets/images/stacks/stacks-hero1x-mobile.png';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { breakpoints, colors } from '@/constants/theme';
import HeroCircleArrowButton from '@/components/HeroCircleArrowButton';
import * as S from '@/modules/Links/components/LinksHero';
import useHeroAnimation from '@/modules/common/Links/hooks/useHeroAnimation';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

interface Props {
  animate: boolean;
}

function StacksHero(props: Props) {
  const isTabletLayout = useMedia(
    `(max-width: ${breakpoints.tabletLarge - 1}px)`
  );

  const { containerRef, buttonRef, textRef, titleInnerRef, innerRef } =
    useHeroAnimation({ config: { animate: props.animate } });

  return (
    <>
      <Component ref={containerRef}>
        <ContentSmallContainer>
          <Wrapper ref={innerRef}>
            <StyledInner>
              <TitleWrapper>
                <TitleInner ref={titleInnerRef}>
                  <AboveTitle>free beta</AboveTitle>
                  <Title>
                    {'xpate\n'}
                    <span>Stacks</span>
                  </Title>
                </TitleInner>
              </TitleWrapper>
              {!isTabletLayout ? (
                <Row>
                  <HeroCircleArrowButton
                    ref={buttonRef}
                    color={colors.stacksPrimary}
                    hoverColor={colors.stacksPrimaryHover}
                  />

                  <Text ref={textRef}>
                    Drag-and-drop cross-border payment solution
                  </Text>
                </Row>
              ) : null}
            </StyledInner>
          </Wrapper>
        </ContentSmallContainer>
        <Hands>
          <StyledPicture
            src={StacksMobileHands}
            mobileLarge={{ src: StacksHands }}
            alt="hands image"
          />
        </Hands>
      </Component>
    </>
  );
}

const Component = styled(S.Component)`
  position: relative;

  ${S.Title} {
    color: white;

    span {
      color: ${colors.stacksPrimary};
    }
  }
`;

const Wrapper = styled(S.Wrapper)`
  @media (max-width: 416px) {
    padding-bottom: 82px;
  }
`;

const Hands = styled(S.Hand)`
  position: static;

  img {
    max-width: initial;
    max-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: -32%;
    bottom: 0;

    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    img {
      right: 0 !important;
    }
  }

  @media (max-width: 416px) {
    img {
      max-height: 88%;
    }
  }

  @media (max-width: 375px) {
    img {
      max-height: 74%;
    }
  }

  @media (max-width: 320px) {
    img {
      height: initial;
      max-height: 85%;
    }
  }
`;

const StyledPicture = styled(Picture)`
  position: static !important;
`;

const TitleInner = styled(S.TitleInner)`
  position: relative;
  flex-direction: column;
`;

const TitleWrapper = styled(S.TitleWrapper)`
  font-size: 220px;

  ${media.laptop(css`
    font-size: 190px;
  `)}

  ${media.tabletLarge(css`
    font-size: 160px;
  `)}

  ${media.tabletSmall(css`
    font-size: 110px;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)};

  @media (max-width: 416px) {
    font-size: 50px;
    line-height: 100%;

    h1 span {
      font-size: 70px;
      line-height: 100%;
    }
  }
`;

const Title = styled(S.Title)`
  font-weight: 800;
  padding: 0 10px 0.35em;

  ${media.laptop(css`
    padding: 0 40px 0.35em;
  `)}

  ${media.tabletLarge(css`
    padding: 0 30px 0.35em;
  `)}


  ${media.tabletSmall(css`
    padding: 0 20px 0.35em;
  `)};

  @media (max-width: 416px) {
    white-space: pre-wrap;
  }
`;

const Text = styled(S.Text)`
  margin-left: 72px;
`;

const AboveTitle = styled.span`
  position: absolute;
  left: 14px;
  display: block;
  font-weight: 600;
  font-size: 42px;
  line-height: 40px;

  ${media.laptop(css`
    left: 40px;
  `)}

  ${media.tabletLarge(css`
    position: static;
    font-size: 32px;
    padding-left: 30px;
    margin-bottom: -20px;
  `)}

  ${media.tabletSmall(css`
    padding-left: 20px;
  `)};

  @media (max-width: 416px) {
    font-size: 22px;
    margin-bottom: 20px;
  }
`;

const Row = styled(S.Row)`
  margin-top: 116px;
`;

const StyledInner = styled(S.Inner)`
  @media (max-width: 1024px) {
    margin-top: 520px;
    ${Row} {
      margin-top: 40px;
    }
  }

  @media (max-width: 414px) {
    ${Row} {
      margin-top: 116px;
    }
  }
`;

export default StacksHero;
