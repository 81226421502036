import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import {
  convertThumbnailToPictureProps,
  ThumbnailType,
} from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

interface Props extends HTMLAttributes<HTMLDivElement> {
  color: string;
  icon?: string | ThumbnailType | null;
  title: string;
  text?: string;
  num: string;
}

function isLinkToHtmlFile(url: string) {
  return url.endsWith('.html');
}

const SimplicityItem = React.forwardRef<HTMLDivElement, Props>(
  ({ id, color, icon, title, text, num, className }, ref) => {
    return (
      <Component className={className ?? 'simplicity'}>
        <Background ref={ref} id={id} color={color} />
        <Inner
          className={className ? className + '__inner' : 'simplicity__inner'}
        >
          {icon &&
            typeof icon === 'string' &&
            (isLinkToHtmlFile(icon) ? (
              <Icon>
                <iframe src={icon} frameBorder="0" />
              </Icon>
            ) : (
              <Icon>
                <img src={icon} alt="icon" />
              </Icon>
            ))}

          {icon && typeof icon !== 'string' ? (
            <Icon>
              <Picture {...convertThumbnailToPictureProps(icon)} />
            </Icon>
          ) : null}

          <Title
            className={className ? className + '__title' : 'simplicity__title'}
          >
            {title}
          </Title>
          {text && (
            <Text
              className={className ? className + '__text' : 'simplicity__text'}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          <Number
            className={
              className ? className + '__number' : 'simplicity__number'
            }
          >
            {num}
          </Number>
        </Inner>
      </Component>
    );
  }
);

export const Component = styled.div`
  position: relative;
  display: flex;
  width: 620px;
  min-height: 620px;
  z-index: 0;
  height: 100%;

  ${media.laptop(css`
    width: 540px;
    min-height: 540px;
  `)}

  ${media.mobile(css`
    width: 100vw;
    min-height: none;
  `)}
`;

const Background = styled.div<{ color?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.color};
  z-index: -1;
`;

const Inner = styled.div`
  width: 100%;
  position: relative;
  padding: 70px;

  ${media.laptop(css`
    padding: 40px;
  `)}

  ${media.mobile(css`
    padding: 60px 20px;
  `)}
`;

export const Icon = styled.div`
  display: block;
  margin-bottom: 30px;
  width: 53px;
  height: 53px;

  iframe {
    max-width: 160px;
    height: 90px;

    svg {
      max-width: 160px;
      height: 90px;
    }
  }

  ${media.laptop(css`
    margin-bottom: 20px;
  `)}

  ${media.mobile(css`
    width: 40px;
    height: 40px;
  `)}
`;

const Title = styled.span`
  font-size: 30px;
  letter-spacing: -0.01em;
  line-height: 30px;
  display: block;
  font-weight: 600;

  ${media.laptop(css`
    font-size: 22px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    line-height: 26px;
  `)}
`;

const Text = styled.div`
  margin-top: 30px;
  font-size: 22px;
  letter-spacing: -0.01em;
  line-height: 30px;
  font-weight: 500;
  max-width: 400px;
  margin-left: 2px;

  padding-right: 40px;

  p,
  ul {
    margin-top: 20px;
  }

  ul {
    margin-top: 20px;
    li {
      padding-left: 14px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: 2px;
        top: 12px;
        width: 4px;
        height: 4px;
        background: #040312;
        border-radius: 50%;
      }
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  ${media.laptop(css`
    margin-top: 20px;
    font-size: 18px;
    line-height: 26px;
  `)}

  ${media.mobile(css`
    font-size: 16px;
    line-height: 22px;
  `)}
`;

const Number = styled.span`
  position: absolute;
  right: 30px;
  bottom: 30px;
  font-size: 16px;
  font-weight: 600;

  ${media.mobile(css`
    right: 20px;
  `)}
`;

export default SimplicityItem;
