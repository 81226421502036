import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { ReleaseNotesPageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import Button from '@/components/Button';
import {
  convertedNotesDate,
  sortByDate,
} from '@/modules/ReleaseNotes/components/ReleaseNotesHero.helpers';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-6.svg';
import scrollManager from '@/services/scroll';

import ReleaseNotesNewsItem from './components/ReleaseNotesNewsItem';

function ReleaseNotesNews() {
  const page = useCurrentPage<ReleaseNotesPageType>();

  const notesNewsData = sortByDate([...page.templateFields.notesNews]);

  const [notesNews, setNotesNews] = useState(notesNewsData.slice(0, 5));

  function handleMoreNews() {
    const numberOfNewsShown = notesNews.length;

    setNotesNews([
      ...notesNews,
      ...notesNewsData.slice(numberOfNewsShown, numberOfNewsShown + 5),
    ]);
  }

  useEffect(() => {
    scrollManager.createResizeObserver();

    return () => scrollManager.destroyResizeObserver();
  }, []);

  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <NotesNews>
            {notesNews.map((news, index) => (
              <ReleaseNotesNewsItem
                {...news}
                key={index}
                date={convertedNotesDate(news.date)}
              />
            ))}
          </NotesNews>
          {notesNews.length !== notesNewsData.length && (
            <ButtonWrapper>
              <NotesNewsMoreButton onClick={handleMoreNews} variant="black">
                <span>View more updates</span> <ArrowIcon />
              </NotesNewsMoreButton>
            </ButtonWrapper>
          )}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Inner = styled.div``;

const NotesNews = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 90px;
  display: flex;
  justify-content: center;
`;

const NotesNewsMoreButton = styled(Button)`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;

const Component = styled.div`
  padding-bottom: 150px;

  @media (max-width: 768px) {
    padding-bottom: 80px;

    ${ButtonWrapper} {
      margin-top: 40px;
    }
  }
`;
export default ReleaseNotesNews;
