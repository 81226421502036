import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import ContentDoubleTitle from '@/modules/Culture/components/ContentDoubleTitle';
import Text from '@/modules/Culture/components/Text';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import glance1Icon from '@/assets/images/links/glance-1.svg';
import glance2Icon from '@/assets/images/links/glance-2.svg';
import glance3Icon from '@/assets/images/links/glance-3.svg';
import glance4Icon from '@/assets/images/links/glance-4.svg';
import glance5Icon from '@/assets/images/links/glance-5.svg';
import { ReactComponent as ArrowSvg } from '@/assets/svg/arrow-usp-3.svg';
import Link from '@/components/Link';
import { styleForDoubleTitle } from '@/constants/common';

import LinksItemList, {
  ButtonIcon,
  ButtonLabel,
  ButtonWrap,
} from './LinksItemList';

const ITEM_LIST = [
  {
    image: glance1Icon,
    title: 'Seamless',
    text: `xpate Links is a white label payment gateway solution offers a full scope of risk management and payment gateway functionality required to accept payment cards.`,
  },
  {
    image: glance2Icon,
    title: 'Fraud Control',
    text: `Intelligent risk management system and individual fraud prevention set up at an acquirer level and merchant level.`,
  },
  {
    image: glance3Icon,
    title: 'Dispute Management',
    text: `Comprehensive approach that focuses on revenue recovery by way of dispute prevention and resolution.`,
  },
  {
    image: glance4Icon,
    title: '24/7 Technical Support',
    text: `Technical assistance to acquirers and merchants on behalf of acquirer to ensure flawless operations 24/7/365.`,
  },
  {
    image: glance5Icon,
    title: 'Consulting',
    text: `Providing professional consulting services on IPS related topics.`,
  },
];

function LinksGlanceBlock() {
  return (
    <Component>
      <DoubleTitle
        firstTitle={'xpate <span>Links</span>'}
        secondTitle={'at a glance'}
        styles={styleForDoubleTitle}
      />
      <ContentSmallContainer>
        <TextContainer>
          <TextRow>
            <Text
              textBold={'No staff involved on acquirer’s side.'}
              text={'No technical interactions on acquirer’s side.'}
              textBoldColor={colors.white}
              textColor={colors.textGray}
              arrow={false}
            />
          </TextRow>
        </TextContainer>
      </ContentSmallContainer>
      <LinksItemList itemList={ITEM_LIST}>
        <ButtonWrap>
          <Button to="/links/overview">
            <ButtonLabel>learn more</ButtonLabel>
            <ButtonIcon>
              <ArrowSvg />
            </ButtonIcon>
          </Button>
        </ButtonWrap>
      </LinksItemList>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 180px;
  padding: 165px 0 185px;
  background: ${colors.black};
  color: ${colors.white};

  ${media.laptop(css`
    margin-top: 120px;
    padding: 125px 0 140px;
  `)}

  ${media.tabletLarge(css`
    margin-top: 100px;
    padding: 85px 0 100px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
    padding: 65px 0 80px;
  `)}
`;

export const DoubleTitle = styled(ContentDoubleTitle)`
  span {
    ${media.laptop(css`
      font-size: 200px;
      line-height: 100%;
    `)}
    ${media.tabletLarge(css`
      font-size: 160px;
    `)}
    ${media.tabletSmall(css`
      font-size: 110px;
    `)}
    ${media.mobile(css`
      font-size: 60px;
    `)}
    ${media.mobileSmall(css`
      font-size: 50px;
    `)}
    & > span {
      color: ${colors.linksPrimary};
    }
  }
`;

const Button = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

const TextRow = styled.div`
  margin-top: 152px;
  margin-left: -3px;

  ${media.laptop(css`
    margin-top: 90px;
    margin-left: 0;
  `)}

  ${media.tabletLarge(css`
    margin-top: 70px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 50px;
  `)}
`;

const TextContainer = styled.div`
  max-width: 1168px;
  width: 100%;
`;

export default LinksGlanceBlock;
