import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import ArrowCircleLink from '@/components/ArrowCircleLink';
import { DoubleTitle } from '@/modules/Links/components/LinksGlanceBlock';
import scrollManager from '@/services/scroll';

const SingaporeSafe = () => {
  const onFaqClick = () => {
    const element = document.getElementById('faq');
    if (!element) return;

    scrollManager.getLocomotiveScroll()?.scrollTo(element);
  };

  return (
    <Component>
      <ContentSmallContainer>
        <DoubleTitle
          firstTitle={'Keep your'}
          secondTitle={'money safe'}
          styles={{
            firstTitle: {
              fontSize: '282px',
            },
            secondTitle: {
              fontSize: '282px',
            },
          }}
        />
        <Text>
          It is our priority. 24/7 accessible, safe to keep, safe to transfer
        </Text>
        <Button
          link="#faq"
          label={'learn more'}
          onClick={onFaqClick}
          color={'#955DFF'}
          textDark={true}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 250px 0;
  color: #040312;
  ${media.laptop(css`
    margin: 200px 0;
  `)}
  ${media.tablet(css`
    margin: 120px 0;
  `)}
  ${media.mobile(css`
    margin: 90px 0;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1px;
  width: 1168px;
  max-width: 100%;

  margin-top: 150px;
  ${media.tablet(css`
    margin-top: 100px;
  `)}
  ${media.mobile(css`
    margin-top: 50px;
  `)}
  
  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
  `)}

  ${media.mobile(css`
    font-size: 38px;
  `)}
`;

const Button = styled(ArrowCircleLink)`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
    justify-content: flex-end;
    margin-right: 10px;
  `)}
`;

export default SingaporeSafe;
