import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { ButtonNextLink } from '@/components/Button/Button';

export const Component = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

export const SloganRowTitle = styled.span`
  display: block;
  font-size: 31px;
  line-height: 40px;
  max-width: 315px;
  font-weight: 500;
  letter-spacing: -0.02em;

  ${media.laptop(css`
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 27px;
    line-height: 35px;
  `)}
`;

export const SloganRowRight = styled.div`
  margin-right: 107px;
  margin-top: 5px;

  ${media.mobile(css`
    margin-top: 70px;
  `)}
`;

export const TryButton = styled(ButtonNextLink)`
  border-radius: 28px;
  height: 56px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.1s;
  display: inline-flex;

  width: 196px;
  white-space: nowrap;

  span {
    position: relative;
    padding-right: 25px;
    display: inline-flex;
    align-items: center;
    height: 18px;
    line-height: 100%;
  }

  svg {
    margin-left: 10px;
    display: block;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    right: 0;

    path {
      fill: #000;
    }
  }
`;
