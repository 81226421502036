import React from 'react';

import LandingHero from '@/components/LandingHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ReallyInstantPageType } from '@/typings/model';

const ReallyInstantHero = () => {
  const {
    heroTitle,
    heroText,
    heroImage,
    heroImage2x,
    heroImageMobile,
    heroImageMobile2x,
  } = useCurrentPage<ReallyInstantPageType>().templateFields;

  return (
    <LandingHero
      title={heroTitle || ''}
      text={heroText || ''}
      imgSrc={heroImage?.url || ''}
      img2xSrc={heroImage2x?.url || ''}
      imgMobileSrc={heroImageMobile?.url || ''}
      imgMobile2xSrc={heroImageMobile2x?.url || ''}
    />
  );
};

export default ReallyInstantHero;
