import React from 'react';
import styled from 'styled-components';

import { ReactComponent as ReleaseNotesTitle } from '@/assets/svg/stacks/release-note-title.svg';
import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';

interface Props {}

function ReleaseNotesHero(props: Props) {
  return (
    <Component>
      <StyledContentContainer>
        <Inner>
          <Content>
            <ReleaseNotesTitleWrapper>
              <ReleaseNotesTitle />
            </ReleaseNotesTitleWrapper>
            <TextWrapper>
              <Text>
                We’re constantly improving xpate Stacks. See the latest features
                and improvements.
              </Text>
            </TextWrapper>
          </Content>
        </Inner>
      </StyledContentContainer>
    </Component>
  );
}

const LogoWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 210px;
  user-select: none;
`;

const StyledContentContainer = styled(ContentSmallContainer)`
  height: 100%;
`;

const Inner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ReleaseNotesTitleWrapper = styled.div`
  margin: 0;
  svg {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  margin-top: -5px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Text = styled.p`
  display: block;
  max-width: 624px;
  width: 100%;
  font-weight: 600;
  font-size: 31px;
  line-height: 40px;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Component = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  background: white;
  color: ${colors.black};

  @media (max-width: 1260px) {
    ${LogoWrapper} {
      left: 165px;

      svg {
        width: 230px;
        height: 50px;
      }
    }
  }

  @media (max-width: 1024px) {
    ${ReleaseNotesTitleWrapper} {
      svg {
        height: 260px;
      }
    }

    ${Text} {
      max-width: 400px;
      font-size: 22px;
      line-height: 34px;
    }

    ${LogoWrapper} {
      top: 20px;
      left: 120px;

      svg {
        width: 200px;
        height: 40px;
      }
    }
  }

  @media (max-width: 768px) {
    ${ReleaseNotesTitleWrapper} {
      svg {
        height: 180px;
      }
    }

    ${Text} {
      max-width: 300px;
      font-size: 18px;
      line-height: 26px;
    }

    ${LogoWrapper} {
      top: 10px;
      left: 58px;

      svg {
        width: 140px;
        height: 25px;
      }
    }
  }

  @media (max-width: 490px) {
    ${ReleaseNotesTitleWrapper} {
      svg {
        height: 140px;
      }
    }

    ${LogoWrapper} {
      top: 5px;
      left: 20px;

      svg {
        width: 100px;
        height: 15px;
      }
    }

    ${TextWrapper} {
      margin-top: 10px;
    }
  }

  @media (max-width: 416px) {
    ${TextWrapper} {
      margin-top: 0;
    }
  }

  @media (max-width: 375px) {
    ${ReleaseNotesTitleWrapper} {
      svg {
        height: 100px;
      }
    }

    ${TextWrapper} {
      margin-top: 20px;
    }
  }

  @media (max-width: 320px) {
    ${TextWrapper} {
      margin-top: 10px;
    }
  }
`;

export default ReleaseNotesHero;
