import React from 'react';

import { nl2br } from '@tager/web-core';

import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';

const LandingCta = () => {
  const { ctaText, ctaTitle, ctaEnabled, ctaLink, ctaOpenInNewTab } =
    useCurrentPage<LandingTemplatePageType>().templateFields;
  if (!ctaEnabled) return null;

  return (
    <GetStarted
      link={ctaLink || ''}
      openInNewTab={ctaOpenInNewTab}
      color={colors.blueDark}
      hoverColor={colors.blueHover}
      isBig={false}
      isTextLight={true}
      buttonPosition="bottom"
      text={ctaText || ''}
    >
      {nl2br(ctaTitle)}
    </GetStarted>
  );
};

export default LandingCta;
