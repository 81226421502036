import React, { useState } from 'react';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import Layout from '@/components/Layout';
import ExchangesIntro from '@/modules/Exchanges/components/ExchangesIntro';
import ExchangesText from '@/modules/Exchanges/components/ExchangesText';
import ExchangesAdvantages from '@/modules/Exchanges/components/ExchangesAdvantages';
import ExchangesImageBlock from '@/modules/Exchanges/components/ExchangesImageBlock';
import HandsHero from '@/components/HandsHero';
import HandsImage from '@/assets/images/exchanges/exchanges-hands.png';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';

function Exchanges() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="exchanges" dark={false} onPreloaderOut={handlePreloaderOut}>
      <HandsHero
        big={true}
        color={colors.exchangesPrimary}
        animate={isPreloaderOut}
        text="Exchanges"
        image={HandsImage}
      />

      <ExchangesIntro />
      <ExchangesText />
      <ExchangesAdvantages />
      <ExchangesImageBlock />

      <GetStarted
        color={colors.exchangesPrimary}
        hoverColor={colors.exchangesPrimaryHover}
      >
        Get started
        <br />
        now!
      </GetStarted>
    </Layout>
  );
}

export default Exchanges;
