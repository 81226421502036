import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-header.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { IndustryPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

function IndustryHero() {
  const page = useCurrentPage<IndustryPageType>();
  if (!page) return null;

  const { title } = page;

  return (
    <Component>
      <ContentSmallContainer>
        <Wrapper>
          <Title>{title}</Title>
          <Arrow>
            <ArrowIcon />
          </Arrow>
        </Wrapper>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  background: ${colors.black};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 689px;
`;

const Title = styled.h1`
  font-size: 132px;
  line-height: 100%;
  font-weight: bold;
  transform: translateY(30px);

  ${media.mobile(css`
    font-size: 92px;
  `)}

  ${media.mobileMedium(css`
    font-size: 62px;
  `)}
`;

const Arrow = styled.button`
  position: absolute;
  bottom: -74px;
  right: 0;
  width: 95px;
  height: 131px;
  background: transparent;
  z-index: 1;

  svg {
    width: 100%;
    height: 100%;
  }

  &:before {
    content: '';
    width: 7px;
    top: 0;
    height: 57px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background: ${colors.white};
    z-index: 1;
  }

  ${media.mobile(css`
    right: 20px;
    bottom: -44px;
    width: 60px;
    height: 83px;

    &:before {
      width: 4px;
      height: 39px;
    }
  `)}
`;

export default IndustryHero;
