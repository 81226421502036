export const questionList = [
  {
    question: 'How do I join xpate Partnership Program?',
    answer:
      'You can either fill in the online application form on our website or contact our support team at sales@xpate.com. We will review your application in 48 hours and send a test account to our web app.',
  },
  {
    question: 'Who can apply to become an partner?',
    answer:
      'Anyone can apply to become xpate’s partner – we already work with hundreds of private individuals, companies and technology platforms all over the globe, we are waiting just for you! ',
  },
  {
    question: 'What types of partnerships do you support?',
    answer:
      'We work with various technological platforms, support individual or business referrals, agent referrals and much more.',
  },
  {
    question: 'What level of commission do you offer?',
    answer: 'Commission details are provided after registration.',
  },
  {
    question: 'When will I get paid?',
    answer: 'Payments are monthly, on 30 day terms.',
  },
  {
    question: 'What transactions security measures are used by xpate?',
    answer:
      'The transactions and the system itself are protected with security protocols such as TSL certification, DDOS protection and hashcode data structuring. <br /> <br /> The xpate payment gateway is certified and works in full compliance with PCI DSS 1.0 standards and restrictions. Our payment gateway is protected by a state of the art anti-fraud system. Our professional cybersecurity analysts constantly monitor the system and keep improving its cybercrime defences. The xpate team also undertakes a number of other system protection measures to keep the maximum level of security for our clients’ accounts and transactions.',
  },
];
