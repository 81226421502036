import React from 'react';

import { nl2br } from '@tager/web-core';

import GetStarted from '@/components/GetStarted';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType } from '@/typings/model';

const HomeCallToActions = () => {
  const { callToActions } = useCurrentPage<Home2023PageType>().templateFields;

  return (
    <>
      {callToActions.map((callToAction) => (
        <GetStarted
          link={callToAction.link || ''}
          openInNewTab={true}
          color={callToAction.color || ''}
          hoverColor={callToAction.hoverColor || ''}
          isBig={true}
          isTextLight={true}
          buttonPosition="bottom"
        >
          {nl2br(callToAction.text)}
        </GetStarted>
      ))}
    </>
  );
};

export default HomeCallToActions;
