import React, { useState } from 'react';

import Layout from '@/components/Layout';
import FormIntro from '@/modules/Form/components/FormIntro/FormIntro';
import FormInner from '@/modules/Form/components/FormShort/FormInner';
import FormConferenceInner from '@/modules/Form/components/FormConference/FormConferenceInner';
import FormLinksInner from '@/modules/Form/components/FormLinks/FormLinksInner';
import FormThanks from '@/modules/Form/components/FormThanks';
import useCurrentPage from '@/hooks/useCurrentPage';
import { FormPageType } from '@/typings/model';
import { stylesFormIntroByTheme } from '@/modules/Form/components/FormIntro/FormIntro.constants';
import { stylesFormThanksByTheme } from '@/modules/Form/components/FormThanks/FormTanks.constants';
import { FORMS } from '@/modules/Form/Form.constants';
import FormFeedback from '@/modules/Form/components/FormFeedback';
import FormConferenceSimple from '@/modules/Form/components/FormConferenceSimple/FormConferenceSimple';

function Form() {
  const page = useCurrentPage<FormPageType>();
  const [success, setSuccess] = useState(false);

  if (!page) return null;

  let inner;

  switch (page.templateFields.form?.type) {
    case FORMS.NAME_AND_EMAIL:
      inner = (
        <FormFeedback
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      );
      break;

    case FORMS.SHORT:
      inner = (
        <FormInner
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      );
      break;
    case FORMS.CONFERENCE:
      inner = (
        <FormConferenceInner
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      );
      break;
    case FORMS.CONFERENCE_SIMPLE:
      inner = (
        <FormConferenceSimple
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      );
      break;
    case FORMS.LINKS:
      inner = (
        <FormLinksInner
          onSuccess={() => {
            setSuccess(true);
          }}
        />
      );
      break;
    default:
      inner = null;
  }

  return (
    <Layout theme="white" simpleFooter={true}>
      {!success ? (
        <>
          <FormIntro
            title={page?.title}
            introText={page?.templateFields.beforeFormText}
            stylesFormIntroByTheme={
              stylesFormIntroByTheme[page.templateFields.theme]
            }
          />
          {inner}
        </>
      ) : (
        <FormThanks
          stylesFormThanksByTheme={
            stylesFormThanksByTheme[page?.templateFields.theme]
          }
        />
      )}
    </Layout>
  );
}

export default Form;
