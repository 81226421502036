import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as ArrowHeaderSvg } from '@/assets/svg/arrow-header.svg';
import { scrollDown } from '@/utils/common';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  hoverColor?: string;
  onClick?: (event?: React.MouseEvent) => void;
}

const HeroCircleArrowButton = forwardRef<HTMLButtonElement, Props>(
  ({ color, hoverColor, onClick, type = 'button', ...rest }, ref) => (
    <Component
      ref={ref}
      color={color}
      type={type}
      hoverColor={hoverColor}
      onClick={onClick ? onClick : scrollDown}
      {...rest}
    >
      <ArrowHeaderSvg />
    </Component>
  )
);

const Component = styled.button<{ color?: string; hoverColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 142px;
  border-radius: 50%;
  background: ${(props) => props.color ?? colors.black};
  cursor: pointer;
  transition: background 0.3s ease;
  color: inherit;
  z-index: 10;

  &:hover {
    background: ${(props) => props.hoverColor ?? '#333'};
  }

  svg {
    height: 67px;
  }

  ${media.tabletSmall(css`
    width: 133px;
    height: 133px;

    svg {
      width: 51px;
      height: 63px;
    }
  `)}

  ${media.mobile(css`
    width: 88px;
    height: 88px;
    margin-top: 30px;

    svg {
      width: 31px;
      height: 43px;
    }
  `)}
  
  @media (max-width: 414px) {
    width: 68px;
    height: 68px;
  }
}
  
`;

export default HeroCircleArrowButton;
