import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import SectionName from '@/components/SectionName';
import SectionTitle from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import Icon01 from '@/assets/images/about/icon_01.svg';
import Icon02 from '@/assets/images/about/icon_02.svg';
import Icon03 from '@/assets/images/about/icon_03.svg';
import Icon04 from '@/assets/images/about/icon_04.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AboutPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AboutTeam() {
  const page = useCurrentPage<AboutPageType>();

  const labelRef = useRef<HTMLSpanElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  const teamItemsLength = page?.templateFields.teamItems?.length ?? 0;

  const cellRefList = useMemo(
    () =>
      Array.from({ length: teamItemsLength }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        bgRef: createRef<HTMLDivElement>(),
        iconRef: createRef<HTMLImageElement>(),
        titleRef: createRef<HTMLSpanElement>(),
        textRef: createRef<HTMLParagraphElement>(),
      })),
    [teamItemsLength]
  );

  useEffect(() => {
    if (!page) return;

    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: titleRef.current,
            start: 'top 87%',
          },
        })
        .from(
          splitTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
          },
          0
        )
        .from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
            delay: 0.3,
          },
          0
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      delayedCall?.kill();
      tl?.kill();
    };
  }, [page]);

  useEffect(() => {
    if (!page) return;

    const tlList: Array<gsap.core.Timeline> = [];

    const delayedCall = gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { bgRef } = cell;
        tlList[index].from(
          bgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            duration: 1.65,
            scaleY: 0,
          },
          0
        );

        const { iconRef } = cell;
        tlList[index].from(
          iconRef.current,
          {
            ease: 'customEaseInOut',
            delay: index / 8,
            duration: 1.45,
            scale: 0,
          },
          0.3
        );

        const { titleRef } = cell;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            yPercent: 100,
            duration: 1.45,
          },
          0.5
        );

        const { textRef } = cell;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            delay: index / 8,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1.3
        );
      });
    });

    return () => {
      delayedCall?.kill();
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, [page]);

  if (!page) return null;

  return (
    <Component>
      <ContentSmallContainer>
        {page.templateFields.teamLabel ? (
          <LabelWrapper>
            <SectionName ref={labelRef} label={page.templateFields.teamLabel} />
          </LabelWrapper>
        ) : null}
        {page.templateFields.teamTitle ? (
          <Title>
            <SectionTitle
              ref={titleRef}
              title={page.templateFields.teamTitle}
            />
          </Title>
        ) : null}
        <Grid>
          {page.templateFields.teamItems?.map((item, index) => (
            <Cell key={index}>
              <Item ref={cellRefList[index].cardRef}>
                <ItemBackground ref={cellRefList[index].bgRef} />
                <ItemIcon>
                  {item.icon ? (
                    <img
                      ref={cellRefList[index].iconRef}
                      alt={item.title || undefined}
                      src={item.icon.url}
                    />
                  ) : null}
                </ItemIcon>
                <ItemTitle ref={cellRefList[index].titleRef}>
                  {item.title}
                </ItemTitle>
                <ItemText ref={cellRefList[index].textRef}>
                  {item.text}
                </ItemText>
                <ItemNumber>0{index + 1}</ItemNumber>
              </Item>
            </Cell>
          ))}
        </Grid>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  margin-top: 150px;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const LabelWrapper = styled.div`
  overflow: hidden;
`;

const Title = styled.div`
  margin-top: 35px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 30px;
  `)}
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 35px -24px 0;

  ${media.laptop(css`
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;
  `)}

  ${media.tabletLarge(css`
    margin-top: 10px;
  `)}
  
  ${media.tabletSmall(css`
    margin-left: -12px;
    margin-right: -12px;
  `)}
`;

const Cell = styled.div`
  flex-basis: 50%;
  padding: 0 24px;
  margin-top: 48px;

  ${media.laptop(css`
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  `)}
  
  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
  height: 100%;
  min-height: 624px;
  padding: 82px 68px;
  z-index: 0;

  ${media.laptop(css`
    min-height: 494px;
    padding: 72px 50px 92px;
  `)}

  ${media.tabletLarge(css`
    padding: 72px 40px 92px;
  `)}
  
  ${media.mobile(css`
    min-height: 424px;
    padding: 42px 0 92px;
  `)}
`;

const ItemBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.grayLight};
  z-index: -1;

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const ItemNumber = styled.span`
  position: absolute;
  right: 65px;
  bottom: 70px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;

  ${media.laptop(css`
    right: 45px;
    bottom: 45px;
  `)}

  ${media.mobile(css`
    right: 0;
  `)}
`;

const ItemIcon = styled.div`
  i {
    width: 55px;
    height: 55px;
    display: block;
    background: ${colors.black};
    border-radius: 100%;
  }
`;

const ItemTitle = styled.span`
  margin-top: 35px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.01em;
  display: block;
  font-weight: 600;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 26px;
  `)}

  ${media.tabletLarge(css`
    font-size: 24px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const ItemText = styled.p`
  margin-top: 22px;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.01em;
  font-weight: 500;
  display: block;
  margin-right: 30px;

  ${media.laptop(css`
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.tabletLarge(css`
    font-size: 18px;
    line-height: 26px;
  `)}
`;

export default AboutTeam;
