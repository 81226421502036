import React from 'react';

import { Page } from '@tager/web-components';

import NotFound from '@/modules/NotFound';

function NotFoundPage() {
  return (
    <Page title="Page not found">
      <NotFound />
    </Page>
  );
}

export default NotFoundPage;
