import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { colors, fonts } from '@/constants/theme';
import Link from '@/components/Link';
import { ReactComponent as LocationIcon } from '@/assets/svg/location_ico.svg';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow_link_2.svg';
import { useTypedSelector } from '@/store/store';
import { selectVacanciesList } from '@/store/reducers/tager/vacancies';
import { media } from '@/utils/mixin';
import { CareersPageType } from '@/typings/model';

function CareersVacancies() {
  const page = useCurrentPage<CareersPageType>();
  const vacancies = useTypedSelector((state) => selectVacanciesList(state));

  const jobsTitle = page?.templateFields?.jobsTitle;

  return (
    <Container>
      <ContentSmallContainer>
        <Title>{jobsTitle}</Title>
      </ContentSmallContainer>
      <VacanciesList>
        {vacancies.map((vacancy) => (
          <VacancyCard key={vacancy.id}>
            <ContentSmallContainer>
              <StyledLink to={`/careers/${vacancy.urlAlias}`}>
                <VacancyTitle>
                  {vacancy.title} <ArrowIcon />
                </VacancyTitle>
              </StyledLink>
              <VacancyExcerpt>{vacancy.excerpt}</VacancyExcerpt>
              <VacancyLocation>
                <VacancyCountry>
                  <LocationIcon />
                  <VacancyCountryInner>{vacancy.location}</VacancyCountryInner>
                </VacancyCountry>
              </VacancyLocation>
            </ContentSmallContainer>
          </VacancyCard>
        ))}
      </VacanciesList>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 177px;
  overflow: hidden;

  ${media.mobileMedium(css`
    margin-top: 50px;
  `)}
`;

const Title = styled.span`
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  font-size: 102px;
  line-height: 104px;

  ${media.mobile(css`
    font-size: 80px;
    line-height: 83px;
  `)}

  ${media.mobileMedium(css`
    font-size: 70px;
    line-height: 73px;
  `)}
`;

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  cursor: pointer;
`;

const VacanciesList = styled.div`
  margin-top: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.mobileMedium(css`
    margin-top: 20px;
  `)}
`;

const VacancyCard = styled.div`
  position: relative;
  width: 100%;
  padding: 55px 0 43px 0;

  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: #ececec;
  }

  &:last-child:after {
    position: absolute;
    display: block;
    content: '';
    bottom: 0;
    width: 100%;
    height: 1px;
    opacity: 0.5;
    background-color: #ececec;
  }
`;

const VacancyTitle = styled.span`
  display: inline;
  //align-items: center;
  color: #1442fa;
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  font-size: 44px;
  line-height: 40px;
  transition: 0.3s;

  svg {
    display: inline;
    margin-left: 25px;
    margin-bottom: 10px;
    width: 13px;
    height: 13px;
    fill: #1442fa;
  }

  &:hover {
    color: #120c8f;

    svg {
      fill: #120c8f;
    }
  }

  ${media.mobileMedium(css`
    font-size: 36px;
    line-height: 42px;

    svg {
      margin-bottom: 5px;
      margin-left: 10px;
    }
  `)}

  ${media.mobileSmall(css`
    font-size: 28px;
    line-height: 36px;

    svg {
      margin-bottom: 5px;
      margin-left: 15px;
    }
  `)}
`;
const VacancyExcerpt = styled.span`
  display: block;
  padding: 26px 0 15px;
  opacity: 0.8;
  color: #58585d;
  font-family: ${fonts.Gilroy};
  font-size: 20px;
  line-height: 40px;
  font-size: 20px;
  line-height: 145%;
`;

const VacancyLocation = styled.span`
  display: block;
  color: ${colors.black};
  font-family: ${fonts.Gilroy};
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
`;

const VacancyCountry = styled.span`
  display: flex;
  align-items: center;

  path {
    width: 13px;
    height: 18px;
  }

  svg path {
    fill: ${colors.black};
  }
`;

const VacancyCountryInner = styled.span`
  padding-left: 8px;
`;

export default CareersVacancies;
