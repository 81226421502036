import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import gsap, { Power1 } from 'gsap';

import { generateNumberArray } from '@tager/web-core';

import StackCard from '@/modules/Stacks/components/StackCard';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import * as S from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.styles';
import ArrowLinkWithLabel from '@/components/ArrowButtonLink';
import { colors } from '@/constants/theme';

import { stackRows } from './StacksInterface.constants';
import { isColorCard } from './StacksInterface.types';

function StacksInterface() {
  const containerRef = useRef<HTMLDivElement>(null);

  const rowRefs = useRef(
    generateNumberArray(stackRows.length).map(() =>
      React.createRef<HTMLDivElement>()
    )
  );

  useEffect(() => {
    let tl: gsap.core.Timeline;
    const rows = rowRefs.current;

    function getRowOffset(
      rowRef: React.RefObject<HTMLSpanElement>,
      direction: 'left' | 'right'
    ) {
      const container = containerRef.current;
      if (!rowRef.current || !container) return 0;

      const result = container.clientWidth - rowRef.current.offsetWidth;

      if (direction === 'left') {
        return Math.sign(result) === -1 ? result : -result;
      } else {
        return Math.sign(result) === -1 ? -result : result;
      }
    }

    function getChildNodeWidth(element: ChildNode | null) {
      if (!element) return 0;
      const htmlElement = element as unknown as HTMLElement;
      return htmlElement.offsetWidth;
    }

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          invalidateOnRefresh: true,
          start: 'top 50%',
          trigger: containerRef.current,
          scrub: 1,
        },
      });

      rows.forEach((row, index) => {
        if (!row.current) return;

        const isRevertDirection = !(index % 2 === 0);

        if (isRevertDirection) {
          gsap.set(row.current, {
            justifyContent: 'flex-end',
          });
        }

        const isSecondRow = index === 1;

        if (isSecondRow) {
          const currentRowInnerWidth = getChildNodeWidth(
            row.current.firstChild
          );

          const isWindowWidthLargeTheRow =
            window.innerWidth > currentRowInnerWidth;

          if (isWindowWidthLargeTheRow) {
            gsap.set(row.current, {
              justifyContent: 'flex-end',
            });
          } else {
            gsap.set(row.current, {
              justifyContent: 'flex-start',
            });
          }
        }

        const direction = index % 2 === 0 ? 'right' : 'left';

        const xPercentValue = getRowOffset(row, direction);

        const dynamicValue = xPercentValue < 100 ? 80 : xPercentValue;

        const result =
          (direction === 'right'
            ? Math.sign(dynamicValue) === -1
              ? -dynamicValue
              : dynamicValue
            : Math.sign(dynamicValue) === -1
            ? dynamicValue
            : -dynamicValue) / 3;

        tl.to(
          row.current,
          {
            xPercent: result,
            ease: Power1.easeInOut,
          },
          0
        );
      });
    });

    return () => {
      delayCall.kill();
      tl?.kill();
    };
  }, []);

  const { containerRef: otherContainerRef, textRef } = useSimplicityAnimation();

  return (
    <Component ref={containerRef}>
      <Cards>
        {stackRows.map((stackList, index) => {
          return (
            <Row key={index}>
              <RowScroller ref={rowRefs.current[index]}>
                <RowInner isRevert={index === rowRefs.current.length - 1}>
                  {stackList.map((stackRow, index) => {
                    return isColorCard(stackRow) ? (
                      <RowItemWrapper key={index}>
                        <StackCard
                          className="stack-card"
                          backgroundColor={stackRow.color}
                        />
                      </RowItemWrapper>
                    ) : (
                      <RowItemWrapper
                        style={{ minWidth: 'initial' }}
                        key={index}
                      >
                        <RowTitle
                          dangerouslySetInnerHTML={{ __html: stackRow.text }}
                        />
                      </RowItemWrapper>
                    );
                  })}
                </RowInner>
              </RowScroller>
            </Row>
          );
        })}
      </Cards>
      <OtherContent ref={otherContainerRef}>
        <ContentSmallContainer>
          <TextWrapper>
            <Text ref={textRef}>
              Our interface has an emotional response because nobody wants to
              talk to a robot. And we know it.
            </Text>
          </TextWrapper>

          <WrapperButton>
            <ArrowLinkWithLabel
              label="get in line"
              to="mailto:hello@xpate.com"
              color={colors.stacksPrimary}
              hoverColor={colors.stacksPrimaryHover}
            />
          </WrapperButton>
        </ContentSmallContainer>
      </OtherContent>
    </Component>
  );
}

const RowInner = styled.div<{ isRevert: boolean }>`
  width: auto;
  display: flex;
  align-items: center;
  transform: translateX(${(props) => (props.isRevert ? '50%' : '-50%')});
`;

const RowScroller = styled.div`
  display: flex;
  width: 100vw;
`;

const RowTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 198px;
  line-height: 224px;
  color: black;
  white-space: nowrap;

  span {
    position: relative;
    background: linear-gradient(
      90deg,
      #b5da6d 0%,
      #5accf1 32.16%,
      #5d99ef 58.44%,
      #e85149 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-right: 2px solid #f7f7f7;
    margin-right: -2px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 150px;
  width: 100%;
`;

const Cards = styled.div`
  display: flex;
  flex-direction: column;

  ${Row} {
    &:first-child {
      margin-top: 0;
    }

    &:nth-child(4) {
      display: none;
    }

    &:nth-child(3) {
      ${RowInner} {
        transform: translateX(-48%);
      }
    }

    &:nth-child(5) {
      display: none;
    }
  }
`;

const RowItemWrapper = styled.div`
  margin-right: 80px;
  min-width: 374px;
`;

const TextWrapper = styled.div`
  margin-top: 167px;
`;

const Text = styled(S.Text)`
  max-width: 490px;
  font-size: 24px;
  line-height: 145%;
  color: black;
`;

const OtherContent = styled.div``;

const WrapperButton = styled.div`
  margin-top: 80px;
  color: black;
`;

const Component = styled.div`
  width: 100%;
  padding: 250px 0 500px;
  overflow: hidden;
  background: #f7f7f7;

  @media (max-width: 1260px) {
    ${Row} {
      margin-top: 80px;
    }
  }

  @media (max-width: 1024px) {
    padding-bottom: 220px;

    ${Row} {
      margin-top: 60px;

      &:nth-child(2) {
        ${RowInner} {
          ${RowItemWrapper} {
            &:nth-child(12) {
              & > .stack-card {
                background: linear-gradient(180deg, #fd9030 0%, #e65828 100%);
              }
            }

            &:nth-child(13) {
              & > .stack-card {
                background: linear-gradient(180deg, #fd7b5e 0%, #db3d3d 100%);
              }
            }
          }
        }
      }

      &:nth-child(3) {
        ${RowInner} {
          transform: translateX(-36%);

          ${RowItemWrapper} {
            &:nth-child(7) {
              & > .stack-card {
                background: linear-gradient(180deg, #fd7b5e 0%, #db3d3d 100%);
              }
            }
          }
        }
      }

      &:last-child {
        ${RowInner} {
          ${RowItemWrapper} {
            &:nth-child(9) {
              & > .stack-card {
                background: linear-gradient(180deg, #529dfa 0%, #4d59db 100%);
              }
            }

            &:nth-child(11) {
              & > .stack-card {
                background: linear-gradient(180deg, #5dd1f4 0%, #3d9edf 100%);
              }
            }
          }
        }
      }
    }

    ${RowTitle} {
      font-size: 120px;
      line-height: 120%;
    }
  }

  @media (max-width: 768px) {
    padding: 100px 0;

    ${Row} {
      margin-top: 40px;

      &:nth-child(2) {
        margin-top: 0;

        ${RowInner} {
          ${RowItemWrapper} {
            &:nth-child(12) {
              & > .stack-card {
                background: linear-gradient(180deg, #fd7b5e 0%, #db3d3d 100%);
              }
            }

            &:nth-child(13) {
              & > .stack-card {
                background: linear-gradient(180deg, #ee73ea 0%, #b83ecb 100%);
              }
            }
          }
        }
      }

      &:nth-child(3) {
        ${RowInner} {
          transform: translateX(-50%);

          ${RowItemWrapper} {
            &:nth-child(7) {
              & > .stack-card {
                background: linear-gradient(180deg, #fd9030 0%, #e65828 100%);
              }
            }
          }
        }
      }

      &:nth-child(4) {
        display: flex;

        ${RowScroller} {
          justify-content: flex-start !important;
        }
      }

      &:nth-child(5) {
        display: flex;
      }

      &:nth-child(1),
      &:nth-child(2) {
        ${RowInner} {
          align-items: flex-end;
        }

        ${RowTitle} {
          display: block;
          white-space: pre-wrap;
        }
      }

      &:last-child {
        ${RowInner} {
          ${RowItemWrapper} {
            &:nth-child(9) {
              & > .stack-card {
                background: linear-gradient(180deg, #FD7B5E 0%, #DB3D3D 100%);
              }
            }

            &:nth-child(11) {
              & > .stack-card {
                background: linear-gradient(180deg, #EE73EA 0%, #B83ECB 100%);
              }
            }
          }
        }
      }
    }

    ${TextWrapper} {
      margin-top: 50px;
    }

    ${RowItemWrapper} {
      margin-right: 13px;
      min-width: 166px;
    }

    ${RowTitle} {
      font-size: 60px;
      line-height: 120%;
    }
  }

  @media (max-width: 416px) {
    ${Text} {
      font-size: 18px;
      line-height: 145%;
    }

    ${WrapperButton} {
      margin-top: 26px;
    }

    ${Row} {
      &:nth-child(1) {
        ${RowInner} {
          ${RowItemWrapper} {
            &:nth-child(12), &:nth-child(13) {
              & > .stack-card {
                display: none;
              }
            }


          }
        }
      }
    }


    &:nth-child(3) {
      ${RowInner} {
        transform: translateX(-41%) !important;
      }
    }

    &:nth-child(4) {
      ${RowInner} {
        transform: translateX(-46%) !important;
      }
    }
  }
}

@media (max-width: 320px) {
  ${Row} {
    &:nth-child(3) {
      ${RowInner} {
        transform: translateX(-42%) !important;
      }
    }

    &:nth-child(4) {
      ${RowInner} {
        transform: translateX(-43%) !important;
      }
    }

    &:nth-child(5) {
      ${RowInner} {
        transform: translateX(-46%) !important;


        ${RowItemWrapper} {
          &:nth-child(9) {
            & > .stack-card {
              background: linear-gradient(180deg, #61D1A8 0%, #289F93 100%);
            }
          }

          &:nth-child(10) {
            & > .stack-card {
              background: linear-gradient(180deg, #B7E684 0%, #73C757 100%);
            }
          }

        }

      }
    }


    &:last-child {
      ${RowInner} {
        ${RowItemWrapper} {
          &:nth-child(9) {
            & > .stack-card {
              display: none;
            }
          }

          &:nth-child(11) {
            & > .stack-card {
              display: none;;
            }
          }
        }
      }
    }

  }
}
`;

export default StacksInterface;
