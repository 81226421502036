import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import ArrowCircleLink from '@/components/ArrowCircleLink';
import { media } from '@/utils/mixin';

const SingaporePricingButton = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <ArrowCircleLink
          link="/xpate-sg/form"
          label="try for free"
          color="#955DFF"
          textDark={true}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 70px 0 150px;

  ${media.tablet(css`
    margin: 50px 0 100px;
  `)}

  ${media.mobile(css`
    margin: 50px 0;
  `)}
`;

export default SingaporePricingButton;
