import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-3.svg';
import { media } from '@/utils/mixin';

function AcquiringConvenience() {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const contentBgRef = useRef<HTMLDivElement>(null);
  const contentTitleRef = useRef<HTMLSpanElement>(null);
  const contentTextRef = useRef<HTMLParagraphElement>(null);
  const contentArrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const splitContentTitleChild = new SplitText(contentTitleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitContentTitleParent = new SplitText(contentTitleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const splitContentTextChild = new SplitText(contentTextRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitContentTextParent = new SplitText(contentTextRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 87%',
        },
      });

      tl.from(
        splitTitleChild.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          yPercent: 100,
          duration: 1.45,
        },
        0
      )
        .from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            y: 20,
          },
          1
        )
        .from(
          contentBgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '0 50%',
            duration: 1.55,
            scaleX: 0,
          },
          0.5
        )
        .from(
          splitContentTitleChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
            delay: 0.25,
          },
          0.5
        )
        .from(
          splitContentTextChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            yPercent: 100,
            duration: 1.45,
            delay: 0.45,
          },
          0.5
        );
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      splitContentTitleParent?.revert();
      splitContentTitleChild?.revert();
      splitContentTextParent?.revert();
      splitContentTextChild?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Header>
          <Title ref={titleRef}>Convenience</Title>
          <SubTitle ref={textRef}>
            Convenience is at the heart of everything we do.
          </SubTitle>
        </Header>
        <Content>
          <ContentBackground ref={contentBgRef} />
          <ArrowContent>
            <ArrowIcon />
          </ArrowContent>
          <ContentTitle ref={contentTitleRef}>Why?</ContentTitle>
          <ContentText>
            <ArrowContentText ref={contentArrowRef}>
              <ArrowIcon />
            </ArrowContentText>
            <ContentTextWrapper ref={contentTextRef}>
              As a business-owner, your prime concern is on growing your
              business. The more convenient your payment solutions, the more
              time you can spend on developing the business you want and the
              business your customers love.
            </ContentTextWrapper>
          </ContentText>
        </Content>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 135px;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}
  
  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.laptop(css`
    max-width: 1068px;
    margin: 0 auto;
  `)}

  ${media.tabletLarge(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const Title = styled.h2`
  font-size: 102px;
  line-height: 100%;
  display: block;
  font-weight: bold;
  letter-spacing: -0.015em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 74px;
  `)}

  ${media.tabletLarge(css`
    margin-bottom: 10px;
    font-size: 64px;
  `)}
  
  ${media.mobileMedium(css`
    font-size: 54px;
  `)}
`;

const SubTitle = styled.span`
  font-size: 31px;
  line-height: 40px;
  max-width: 381px;
  font-weight: 500;
  margin-right: 30px;
  margin-top: 15px;
  letter-spacing: -0.02em;

  ${media.laptop(css`
    font-size: 24px;
    line-height: 34px;
  `)}

  ${media.tabletLarge(css`
    max-width: 320px;
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.tabletLarge(css`
    max-width: 514px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const Content = styled.div`
  position: relative;
  margin-top: 165px;
  max-width: 952px;
  padding: 70px 140px 90px;
  font-size: 30px;
  line-height: 40px;

  ${media.laptop(css`
    max-width: 852px;
    margin-top: 90px;
    font-size: 23px;
    line-height: 33px;
  `)}

  ${media.tabletLarge(css`
    max-width: 712px;
    padding: 50px 110px 60px;
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.tabletSmall(css`
    max-width: none;
    padding: 40px 0;
    margin-top: 60px;
  `)}
`;

const ContentBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.acquiringPrimary};

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}
`;

const ArrowContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 37px;
  height: 31px;
  transform: rotate(90deg) translateX(-35%);

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tabletSmall(css`
    display: block;
  `)}
`;

const ContentTitle = styled.span`
  display: block;
  position: relative;
  font-weight: 500;

  .lineParent {
    overflow: hidden;
  }
`;

const ContentTextWrapper = styled.div`
  margin-top: 60px;

  .lineParent {
    overflow: hidden;
  }

  ${media.laptop(css`
    margin-top: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 640px;
    margin-top: 20px;
  `)}
`;

const ContentText = styled.p`
  position: relative;
  display: block;
  font-weight: 500;
`;

const ArrowContentText = styled.div`
  position: absolute;
  top: 0;
  left: -100px;
  width: 47px;
  height: 41px;

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tabletLarge(css`
    left: -70px;
    width: 37px;
    height: 31px;
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

export default AcquiringConvenience;
