import React from 'react';
import { css } from 'styled-components';

import LandingHero from '@/components/LandingHero';
import useCurrentPage from '@/hooks/useCurrentPage';
import { MakePaymentsPageType } from '@/typings/model';

const MakePaymentsHero = () => {
  const {
    hero2xImage,
    heroImage,
    heroTitle,
    heroImageMobile,
    heroImageMobile2x,
  } = useCurrentPage<MakePaymentsPageType>().templateFields;
  return (
    <LandingHero
      title={heroTitle || ''}
      imgSrc={heroImage?.url || ''}
      img2xSrc={hero2xImage?.url || ''}
      imgMobileSrc={heroImageMobile?.url || ''}
      imgMobile2xSrc={heroImageMobile2x?.url || ''}
      imgCustomStyles={css`
        top: auto;
        bottom: 0;
      `}
    />
  );
};

export default MakePaymentsHero;
