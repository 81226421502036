import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { SplitText } from 'gsap/dist/SplitText';
import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import { generateNumberArray } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { ReactComponent as HomeArrowSvg } from '@/assets/svg/home-arrow.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { scrollDown } from '@/utils/common';
import Picture from '@/components/Picture';
import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType } from '@/typings/model';

gsap.registerPlugin(SplitText);

type Props = {
  animate: boolean;
};

function HomeHero({ animate }: Props) {
  const { heroTitle, heroImage, heroMobileImage, heroText } =
    useCurrentPage<Home2023PageType>().templateFields;

  const containerRef = useRef<HTMLDivElement>(null);

  const titleRef = useRef<HTMLHeadingElement>(null);
  const titleInnerRef = useRef<HTMLDivElement>(null);

  const innerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const arrowRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(innerRef.current, { y: 0 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(innerRef.current, {
        y: '25%',
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'bottom 100%',
          end: 'bottom 0',
          scrub: true,
        },
      });
    });

    return () => {
      tw?.kill();
    };
  }, []);

  function getTitleWidth() {
    if (!titleRef.current) return 0;
    else return titleRef.current.offsetWidth;
  }

  useEffect(() => {
    let fadeTl: gsap.core.Timeline;
    let moveTl: gsap.core.Timeline;

    gsap.set(titleInnerRef.current, { yPercent: 100 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      moveTl = gsap
        .timeline({
          scrollTrigger: {
            id: 'heading',
            scroller: 'body',
            trigger: containerRef.current,
            invalidateOnRefresh: true,
            onLeaveBack: () => moveTl?.pause(),
            onLeave: () => moveTl?.pause(),
            onEnterBack: () => moveTl?.play(),
            onEnter: () => moveTl?.play(),
          },
        })
        .to(titleInnerRef.current, {
          x: () => -getTitleWidth(),
          ease: 'linear',
          duration: 15,
        })
        .to(titleInnerRef.current, {
          x: () => -getTitleWidth() * 2,
          repeat: -1,
          ease: 'linear',
          duration: 15,
        })
        .timeScale(0);

      fadeTl = gsap
        .timeline({
          scrollTrigger: {
            id: 'headingInner',
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(titleInnerRef.current, {
          ease: 'customEaseInOut',
          duration: 2.2,
          yPercent: 0,
        })
        .to(moveTl, {
          ease: Power1.easeIn,
          timeScale: 1,
          duration: 1,
        });
    });

    return () => {
      ScrollTrigger.getById('heading')?.kill();
      ScrollTrigger.getById('headingInner')?.kill();
      fadeTl?.kill();
      moveTl?.kill();
    };
  }, [animate]);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTextChild = new SplitText(textRef.current, {
      linesClass: 'lineChild',
      type: 'lines',
    });
    const splitTextParent = new SplitText(textRef.current, {
      linesClass: 'lineParent',
      type: 'lines',
    });

    gsap.set(buttonRef.current, { scale: 0 });
    gsap.set(arrowRef.current, { yPercent: -100 });
    gsap.set(splitTextChild.lines, { yPercent: 100 });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(
          buttonRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.2,
            delay: 0.65,
            scale: 1,
          },
          0
        )
        .to(
          splitTextChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            duration: 1.5,
            delay: 0.65,
            yPercent: 0,
          },
          0
        )
        .to(
          arrowRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.9,
            yPercent: 0,
          },
          '1.5'
        );
    });

    return () => {
      splitTextParent?.revert();
      splitTextChild?.revert();
      tl?.kill();
    };
  }, [animate]);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Wrapper ref={innerRef}>
          <Inner>
            <TitleWrapper>
              <TitleInner ref={titleInnerRef}>
                {generateNumberArray(4).map((_, index) => (
                  <Title key={index} ref={titleRef}>
                    {heroTitle}
                  </Title>
                ))}
              </TitleInner>
            </TitleWrapper>
          </Inner>
        </Wrapper>
        <Content>
          <ContentText>{heroText}</ContentText>
          <ContentArrow onClick={scrollDown}>
            <HomeArrowSvg />
          </ContentArrow>
        </Content>
      </ContentSmallContainer>

      <Image>
        <Picture
          mobileSmall={{ src: heroMobileImage?.url || '' }}
          tabletSmall={{ src: heroImage?.url || '' }}
        />
      </Image>
    </Component>
  );
}

const Component = styled.div`
  background: #040312;
  overflow: hidden;
  position: relative;

  ${media.mobile(css`
    position: relative;
  `)}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: 600px;
  z-index: 2;

  ${media.mobile(css`
    padding-bottom: 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
  margin-top: -150px;
  margin-left: -5px;

  ${media.mobile(css`
    position: static;
  `)}
`;

const TitleWrapper = styled.div`
  display: flex;
  padding-left: 50vw;
  padding-right: 50vw;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-bottom: -10px;
  font-size: 200px;
  line-height: 100%;
  font-weight: 700;
  letter-spacing: 0;
  overflow: hidden;

  ${media.tabletLarge(css`
    font-size: 190px;
  `)}

  ${media.tabletSmall(css`
    font-size: 140px;
  `)}

  ${media.mobile(css`
    font-size: 90px;
    text-align: center;
    margin-bottom: -60px;
  `)}
`;

const TitleInner = styled.div`
  display: flex;
  flex-shrink: 0;
  margin: 0 -40px;

  ${media.tabletLarge(css`
    margin: 0 -30px;
  `)}

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}
  
  ${media.mobile(css`
    margin: 0 -15px;
  `)}
`;

const Title = styled.h1`
  display: block;
  flex-shrink: 0;
  padding: 0 40px 0.15em;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  white-space: nowrap;

  ${media.tabletLarge(css`
    padding: 0 30px 0.15em;
  `)}

  ${media.tabletSmall(css`
    padding: 0 20px 0.15em;
  `)}
  
  ${media.mobile(css`
    padding: 0 15px 0.15em;
  `)}
`;

const Row = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  align-items: center;
  margin-top: 60px;
  margin-left: 6px;
  z-index: 1;

  @media (min-width: 1024px) and (max-height: 900px) {
    margin-top: 0;
  }

  ${media.mobile(css`
    top: auto;
    bottom: 170px;
  `)}
`;

const Text = styled.p`
  max-width: 500px;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  color: #fff;
  margin-left: 30px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 29px;
    line-height: 38px;
  `)}

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

const Button = styled.button`
  position: absolute;
  bottom: -200px;
  left: 17px;
  width: 97px;
  height: 119px;
  display: block;
  overflow: hidden;
  color: #fff;

  @media (min-width: 1024px) and (max-height: 870px) {
    bottom: -200px;
  }

  @media (min-width: 1024px) and (max-height: 830px) {
    bottom: -160px;
  }

  @media (min-width: 1024px) and (max-height: 790px) {
    bottom: -140px;
  }

  @media (min-width: 1024px) and (max-height: 750px) {
    bottom: -120px;
  }

  @media (min-width: 1024px) and (max-height: 720px) {
    display: none;
  }

  ${media.laptop(css`
    display: block;
  `)}

  svg {
    width: 100%;
    height: 100%;

    @media (min-width: 1024px) and (max-height: 870px) {
      transform: scale(0.85);
    }

    @media (min-width: 1024px) and (max-height: 790px) {
      transform: scale(0.75);
    }

    @media (min-width: 1024px) and (max-height: 750px) {
      transform: scale(0.6);
    }

    ${media.mobile(css`
      transform: scale(0.6);
    `)}
  }

  ${media.mobile(css`
    bottom: -150px;
    transform: translateX(-50%);
  `)}
`;

const ArrowWrapper = styled.span`
  display: block;
`;

const Image = styled.div`
  position: absolute;
  left: -40px;
  right: -40px;
  top: 0;
  bottom: 0;
  transform: rotate(-1.75deg);
  max-height: 100vh;

  > div {
    position: absolute;
    inset: 0;
  }
  div,
  img {
    height: 100%;
    width: calc(100vw + 80px);
  }
`;

const Content = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;

  ${media.laptop(css`
    padding-bottom: 40px;
  `)}

  ${media.mobile(css`
    max-width: 243px;
    padding-bottom: 30px;
  `)}
`;

const ContentText = styled.p`
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  ${media.tablet(css`
    font-size: 20px;
  `)}
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const ContentArrow = styled.button`
  margin-top: 70px;
  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    margin-top: 30px;
    svg {
      width: 40px;
      height: 48px;
    }
  `)}
`;

export default HomeHero;
