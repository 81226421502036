import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as CheckMarkIcon } from '@/assets/svg/check-mark2.svg';
import { media } from '@/utils/mixin';
import Image01 from '@/assets/images/accounts/image_01.png';
import Image02 from '@/assets/images/accounts/image_03.png';
import Image03 from '@/assets/images/accounts/image_02.png';

gsap.registerPlugin(SplitText);

function AccountsItems() {
  const section1 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    titleRef: useRef<HTMLHeadingElement>(null),
    textRef: useRef<HTMLUListElement>(null),
  };
  const section2 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    titleRef: useRef<HTMLHeadingElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
  };
  const section3 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    titleRef: useRef<HTMLHeadingElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
  };

  useEffect(() => {
    const twList: Array<gsap.core.Tween> = [];
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      [section1, section2, section3].forEach((section, index) => {
        const { containerRef } = section;
        if (!containerRef.current) return;

        const { imageRef } = section;
        twList[index] = gsap.to(imageRef.current, {
          y: '20%',
          ease: 'linear',
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 32%',
            scrub: true,
          },
        });

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 82%',
          },
        });

        const { titleRef } = section;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.45,
          },
          0
        );

        const { textRef } = section;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            delay: 0.2,
            y: 20,
          },
          1
        );
      });
    });

    return () => {
      twList.forEach((tw) => tw.kill());
      tlList.forEach((tl) => tl.kill());
    };
  }, []);

  return (
    <Component>
      <ContentContainer width={1390}>
        <Row ref={section1.containerRef}>
          <Image>
            <ImageInner>
              <img ref={section1.imageRef} src={Image01} alt="cards" />
            </ImageInner>
          </Image>
          <Content>
            <Title ref={section1.titleRef}>Named Business account</Title>
            <List ref={section1.textRef}>
              <ListItem>
                <CheckMarkIcon />
                You can pay to over 100 countries, via SEPA and SWIFT, at
                competitive rates
              </ListItem>
              <ListItem>
                <CheckMarkIcon />
                You save on valuable time with our fast processing
              </ListItem>
              <ListItem>
                <CheckMarkIcon />
                You benefit from 24/7 assistance with your dedicated account
                manager
              </ListItem>
            </List>
          </Content>
        </Row>
        <Row ref={section2.containerRef}>
          <Image>
            <StyledImageInner>
              <img ref={section2.imageRef} src={Image02} alt="iban" />
            </StyledImageInner>
          </Image>
          <Content>
            <Title ref={section2.titleRef}>Pooled Business Account</Title>
            <Text ref={section2.textRef}>
              Hosted in xpate’s name, in USD or EUR, a pooled business account
              enables you to make domestic and international payments simply,
              quickly, with peace-of-mind and at a lower rate.
            </Text>
          </Content>
        </Row>
        <Row ref={section3.containerRef}>
          <Image>
            <ImageInner>
              <img ref={section3.imageRef} src={Image03} alt="cards" />
            </ImageInner>
          </Image>
          <Content>
            <Title ref={section3.titleRef}>
              Individual IBAN for&nbsp;each Client
            </Title>
            <Text ref={section3.textRef}>
              Your own account comes with a unique IBAN in EUR. And we guarantee
              flexible limits on your turnover or payments. It’s all about
              taking away the fuss so you can do what you do best.
            </Text>
          </Content>
        </Row>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 150px;

  ${media.laptop(css`
    margin-top: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 100px;
  `)}

  ${media.mobile(css`
    margin-top: 50px;
  `)}
`;

const Content = styled.div`
  margin-left: 80px;
  flex: 1 1 1px;

  ${media.tabletLarge(css`
    margin-left: 60px;
  `)}

  ${media.tabletSmall(css`
    width: 100%;
    margin-left: 0;
  `)}
`;

const Image = styled.div`
  position: relative;
  width: 662px;
  height: 662px;
  border-radius: 100%;
  background: ${colors.grayLight};
  overflow: hidden;

  ${media.laptop(css`
    max-width: 48%;
    height: auto;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-top: 100%;
    }
  `)}

  ${media.tabletSmall(css`
    width: 462px;
    max-width: none;
    flex-basis: 462px;
    margin-bottom: 30px;
    padding-top: 19px;
  `)}

  ${media.mobile(css`
    width: 100%;
    max-width: 462px;
    flex-basis: 100%;
  `)}
`;

const ImageInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletSmall(css`
    min-width: 100%;
    min-height: 85%;
  `)}

  ${media.tabletLarge(css`
    img {
      max-width: 78%;
    }
  `)}
`;

const StyledImageInner = styled(ImageInner)`
  img {
    max-width: 100% !important;
  }

  top: 61%;

  ${media.tabletLarge(css`
    top: 70%;
  `)}

  ${media.tabletSmall(css`
    top: 77%;
  `)}
`;

const Title = styled.h3`
  font-size: 72px;
  line-height: 72px;
  display: block;
  font-weight: bold;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.tabletLarge(css`
    font-size: 64px;
    line-height: 64px;
  `)}

  ${media.mobileMedium(css`
    font-size: 54px;
    line-height: 54px;
  `)}
`;

const Text = styled.p`
  display: block;
  margin-top: 35px;
  padding-left: 5px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: -0.01em;
  max-width: 565px;

  ${media.laptop(css`
    margin-top: 30px;
    padding-left: 0;
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 514px;
  `)}

  ${media.mobileMedium(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const List = styled.ul`
  display: block;
  list-style: none;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;

  .lineParent {
    overflow: hidden;
  }

  &:not(:first-child) {
    margin-top: 40px;
  }

  ${media.laptop(css`
    &:not(:first-child) {
      margin-top: 30px;
    }
  `)}

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.tabletSmall(css`
    max-width: 514px;
  `)}

  ${media.mobileMedium(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const ListItem = styled.li`
  position: relative;
  margin-top: 15px;
  padding-left: 45px;
  max-width: 525px;
  letter-spacing: -0.01em;

  &:first-child {
    margin-top: 0;
  }

  svg {
    position: absolute;
    left: 5px;
    top: 10px;
  }

  ${media.tabletLarge(css`
    margin-top: 10px;
  `)}
`;

const Row = styled.div`
  margin-top: 130px;
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    ${Content} {
      margin-right: 80px;
      margin-left: 30px;
      padding-top: 5px;
    }
  }

  ${media.laptop(css`
    margin-top: 90px;
  `)}
  ${media.tabletLarge(css`
    margin-top: 70px;

    &:nth-child(2n) {
      ${Content} {
        margin-right: 60px;
      }
    }
  `)}
  ${media.tabletSmall(css`
    flex-direction: column;

    &:nth-child(2n) {
      flex-direction: column;

      ${Content} {
        margin-right: 0;
        margin-left: 0;
      }
    }
  `)}
  ${media.mobile(css`
    margin-top: 50px;
  `)}
  &:last-child {
    ${Image} {
      align-items: flex-end;
    }
  }
`;

export default AccountsItems;
