import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import { ReactComponent as ArrowSvg } from '@/assets/svg/singapore-about-arrow.svg';
import { ReactComponent as ArrowMobileSvg } from '@/assets/svg/singapore-about-arrow-mobile.svg';
import aboutImg from '@/assets/images/singapore/about.png';

const SingaporeAbout = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <img src={aboutImg} />
          <ArrowDesktop>
            <ArrowSvg />
          </ArrowDesktop>
          <ArrowMobile>
            <ArrowMobileSvg />
          </ArrowMobile>
          <Title>About xpate SG</Title>
          <Text>
            xpate.sg is offered by Novum Financial Technologies Pte. Ltd
            regulated by the Monetary Authority of Singapore as a MajorPayment
            Institution (licence No. PS20200538). xpate.sg is part of the global
            xpate brand.
          </Text>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  margin: 250px 0;
  color: #040312;
  ${media.laptop(css`
    margin: 200px 0;
  `)}
  ${media.tablet(css`
    margin: 120px 0;
  `)}
  ${media.mobile(css`
    margin: 90px 0;
  `)}
`;

const Inner = styled.div`
  background: #f6f6f6;
  padding: 110px 140px;
  position: relative;

  img {
    position: absolute;
    transform: rotate(15deg);
    right: 10px;
    top: -110px;
    ${media.tabletSmall(css`
      top: -50px;
      max-width: 138px;
    `)}
  }

  ${media.tabletSmall(css`
    padding: 110px 20px 50px;
  `)}
`;

const ArrowDesktop = styled.div`
  position: absolute;
  top: 124px;
  left: 45px;
  ${media.tabletSmall(css`
    display: none;
  `)}
`;

const ArrowMobile = styled.div`
  display: none;
  position: absolute;
  top: 30px;
  left: 20px;
  ${media.tabletSmall(css`
    display: block;
  `)}
`;

const Title = styled.h2`
  font-size: 72px;
  ${media.tablet(css`
    font-size: 50px;
  `)}

  ${media.mobile(css`
    font-size: 38px;
  `)}
  
  display: block;
`;

const Text = styled.p`
  margin-top: 30px;
  display: block;
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  letter-spacing: -0.15209px;
  ${media.tabletSmall(css`
    font-size: 24px;
  `)}
`;

export default SingaporeAbout;
