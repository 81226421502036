import React from 'react';
import styled, { css } from 'styled-components';

import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import useCurrentPage from '@/hooks/useCurrentPage';
import { IndustryPageType, OverviewPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

function IndustryText() {
  const page = useCurrentPage<IndustryPageType>();
  if (!page) return null;

  const { text } = page.templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Text>{text}</Text>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  background: ${colors.white};
  color: ${colors.black};
  padding-top: 130px;

  ${media.laptop(css`
    padding-top: 110px;
  `)}

  ${media.tabletSmall(css`
    padding-top: 90px;
  `)}
`;

const Text = styled.p`
  max-width: 1100px;
  font-size: 64px;
  line-height: 80px;
  font-weight: bold;
  letter-spacing: -0.013em;

  ${media.laptop(css`
    font-size: 60px;
    line-height: 100%;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

export default IndustryText;
