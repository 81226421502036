import { FormikErrors } from 'formik';

import { validators } from '@tager/web-core';

import { FormConferenceValuesType } from './FormConference.types';
import { invalidEmailMessage, requiredMessage } from '@/constants/validation';
import { targetedCountriesList } from './FormConference.constans';

export function handleValidateFormConference(
  values: FormConferenceValuesType
): FormikErrors<FormConferenceValuesType> {
  const errors: FormikErrors<FormConferenceValuesType> = {};

  if (validators.required(values.name)) {
    errors.name = requiredMessage;
  }

  if (validators.required(values.email)) {
    errors.email = requiredMessage;
  } else if (validators.email(values.email)) {
    errors.email = invalidEmailMessage;
  }

  if (validators.required(values.legalName)) {
    errors.legalName = requiredMessage;
  }

  if (validators.required(values.url)) {
    errors.url = requiredMessage;
  }

  if (values.processingHistory.length === 0) {
    errors.processingHistory = requiredMessage;
  }

  if (values.license.length === 0) {
    errors.license = requiredMessage;
  }

  if (values.mcc.length === 0) {
    errors.mcc = requiredMessage;
  }

  if (values.targetedCountries.length === 0) {
    errors.targetedCountries = requiredMessage;
  }

  return errors;
}

export function getNewValues(
  values: FormConferenceValuesType,
  key: 'targetedCountries' | 'mcc'
) {
  const newFieldValue = targetedCountriesList[targetedCountriesList.length - 1];
  const newValues = [...values[key], newFieldValue];

  const targetIndex = values[key].findIndex(
    (oldItem) => oldItem.value === newFieldValue.value
  );

  if (targetIndex !== -1) {
    newValues.splice(targetIndex, 1);
  }

  return newValues;
}

type BaseFieldObjectType = { value: string; label: string };
type BooleanFieldType = { value: boolean; label: string };

function isArrayWithStringValue(
  arr: Array<BaseFieldObjectType | BooleanFieldType>
): arr is Array<BaseFieldObjectType> {
  return typeof arr[0].value === 'string';
}

function isArrayWithBoolean(
  arr: Array<BooleanFieldType | BaseFieldObjectType>
): arr is Array<BooleanFieldType> {
  return typeof arr[0].value === 'boolean';
}

export function convertFormValues(values: FormConferenceValuesType) {
  const result: Record<string, string | Array<string> | boolean> = {};

  Object.keys(values).forEach((key) => {
    const keyword = key as keyof FormConferenceValuesType;
    const field = values[keyword];
    if (field instanceof Array) {
      if (isArrayWithStringValue(field)) {
        if (keyword === 'license') {
          result[key] = field.map((item) => item.value).join('');
        } else {
          result[key] = field.map((item) => item.value);
        }
      }
      if (isArrayWithBoolean(field)) {
        result[key] = field.map((item) => item.value)[0];
      }
    } else {
      result[key] = field;
    }
  });

  return result;
}
