import React, { useState } from 'react';

import Layout from '@/components/Layout';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import ReleaseNotesHero from '@/modules/ReleaseNotes/components/ReleaseNotesHero';
import ReleaseNotesNews from '@/modules/ReleaseNotes/components/ReleaseNotesNews';

interface Props {}

function ReleaseNotes(props: Props) {
  const [, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout onPreloaderOut={handlePreloaderOut}>
      <ReleaseNotesHero />
      <ReleaseNotesNews />
    </Layout>
  );
}

export default ReleaseNotes;
