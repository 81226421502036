import React from 'react';
import styled, { css } from 'styled-components';

import { createMediaMixin } from '@tager/web-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { ContactsPageType } from '@/typings/model';
import { colors } from '@/constants/theme';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowDownSvg } from '@/assets/svg/arrow-usp-1.svg';
import { media } from '@/utils/mixin';

function ContactsOffices() {
  const page = useCurrentPage<ContactsPageType>();
  if (!page) return null;

  const offices = page.templateFields?.offices ?? [];

  return (
    <Component>
      <ContentSmallContainer>
        <Row>
          {offices.length !== 0
            ? offices.map((office, index) => (
                <Cell key={index}>
                  <Item>
                    <Arrow>
                      <ArrowDownSvg />
                    </Arrow>
                    <Name>{office.name ?? ''}</Name>
                    <Address>{office.address ?? ''}</Address>
                  </Item>
                </Cell>
              ))
            : null}
        </Row>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 0 0 162px;

  ${media.laptop(css`
    margin-bottom: 120px;
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 100px;
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px 0;

  ${media.laptop(css`
    margin-left: -20px;
    margin-right: -20px;
  `)}

  ${media.tabletSmall(css`
    margin-left: -12px;
    margin-right: -12px;
  `)}
`;

const Cell = styled.div`
  flex: 1 1 50%;
  padding: 0 24px;
  margin-top: 48px;

  ${media.laptop(css`
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  `)}

  ${createMediaMixin({ max: 763 })(css`
    &:first-child {
      margin-top: 0;
    }
  `)}

  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
  min-height: 446px;
  height: 100%;
  padding: 40px 45px;
  color: ${colors.black};
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${colors.gray};
    z-index: -1;
  }

  ${media.laptop(css`
    padding: 60px 50px;
  `)}

  ${media.tabletLarge(css`
    min-height: 320px;
    padding: 60px 40px;
  `)}

  ${media.mobile(css`
    min-height: 280px;
    padding: 40px 0;

    &:before {
      margin: 0 -20px;
    }
  `)}
`;

const Arrow = styled.div`
  margin-bottom: 40px;

  svg {
    transform: scale(0.75);
  }

  ${media.laptop(css`
    margin-bottom: 30px;
  `)}

  ${media.tabletLarge(css`
    margin-bottom: 25px;

    svg {
      width: 36px;
      height: 42px;
      transform: scale(1);
    }
  `)}

  ${media.tabletSmall(css`
    margin-bottom: 21px;
  `)}

  ${media.mobile(css`
    svg {
      width: 32px;
      height: 38px;
    }
  `)}
`;

const Name = styled.span`
  font-size: 31px;
  line-height: 40px;
  display: block;
  font-weight: bold;
  letter-spacing: -0.02em;

  ${media.laptop(css`
    font-size: 26px;
    line-height: 36px;
  `)}

  ${media.tabletLarge(css`
    font-size: 24px;
    line-height: 34px;
  `)}

  ${media.tabletSmall(css`
    font-size: 22px;
    line-height: 32px;
  `)}

  ${media.mobile(css`
    font-size: 21px;
    line-height: 31px;
  `)}
`;

const Address = styled.span`
  font-size: 31px;
  line-height: 40px;
  display: block;
  font-weight: 500;
  margin-top: 20px;
  letter-spacing: -0.018em;

  ${media.laptop(css`
    font-size: 26px;
    line-height: 35px;
  `)}

  ${media.tabletLarge(css`
    font-size: 24px;
    line-height: 33px;
  `)}

  ${media.tabletSmall(css`
    font-size: 22px;
    line-height: 31px;
  `)}

  ${media.mobile(css`
    font-size: 21px;
    line-height: 31px;
  `)}
`;

export default ContactsOffices;
