import React from 'react';
import styled from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { Home2023PageType, LandingTemplatePageType } from '@/typings/model';
import Features from '@/components/Features';

const LandingImageBlocks = () => {
  const { imageBlocks } =
    useCurrentPage<LandingTemplatePageType>().templateFields;
  if (imageBlocks.length === 0) return null;

  return (
    <Features
      items={imageBlocks.map((item) => {
        return {
          imgSrc: item.image?.url || '',
          imgSrc2x: item.image?.url || '',
          title: item.title || '',
          text: item.text || '',
          buttonLabel: item.buttonLabel || '',
          buttonLink: item.buttonLink || '',
          buttonNewTab: item.buttonNewTab || false,
        };
      })}
    />
  );
};

const Component = styled.section``;

export default LandingImageBlocks;
