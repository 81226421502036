import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

export const Component = styled.div`
  position: relative;
  margin: 100px 0 0 0;
  color: #fff;
  background: #fff;
  overflow: hidden;

  ${media.mobile(css`
    margin: 70px 0 0;
  `)}

  ${media.mobileMedium(css`
    margin-bottom: 0;
  `)}
`;

export const Inner = styled.h2`
  position: relative;
  color: #040312;
  font-weight: bold;
  font-size: 198px;
  letter-spacing: -0.01em;
  line-height: 224px;
  padding-left: 120px;

  @media (max-width: 1599px) {
    font-size: 168px;
    line-height: 174px;
  }

  ${media.laptop(css`
    padding-left: 190px;
    font-size: 98px;
    line-height: 114px;
  `)}

  ${media.tabletLarge(css`
    max-width: 1050px;
    margin: 0 auto;
    padding-left: 100px;
    font-size: 88px;
    line-height: 94px;
  `)}

  ${media.tabletSmall(css`
    max-width: 750px;
    padding-left: 50px;
    font-size: 78px;
    line-height: 84px;
  `)}

  ${media.mobile(css`
    padding-left: 0;
  `)}

  ${media.mobileSmall(css`
    font-size: 58px;
    line-height: 64px;
  `)}
`;

export const TitleBackground = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  bottom: 90px;
  width: 1552px;
  background: #fafafa;
  z-index: 0;

  ${media.laptop(css`
    top: 60px;
    bottom: 60px;
    width: calc(100% + 350px);
  `)}

  ${media.tabletLarge(css`
    width: calc(100% + 250px);
  `)}

  ${media.tabletSmall(css`
    top: 40px;
    bottom: 40px;
    margin: 0 -30px;
  `)}

  ${media.tabletSmall(css`
    top: 40px;
    bottom: 40px;
    margin: 0 -30px;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

export const Title = styled.div`
  position: relative;
  display: block;
  z-index: 1;

  ${media.mobile(css`
    padding-bottom: 150px;
  `)};

  @media (max-width: 619px) {
    padding-bottom: 310px;
  }
`;

export const TitleInner = styled.span`
  position: relative;
  display: block;
  max-width: 1250px;
  z-index: 1;

  @media (max-width: 1599px) {
    max-width: 950px;
  }

  ${media.laptop(css`
    max-width: 650px;
  `)}

  ${media.tabletLarge(css`
    max-width: 550px;
  `)}

  ${media.tabletSmall(css`
    max-width: 450px;
  `)}

  ${media.mobile(css`
    max-width: 550px;
  `)}
`;

export const CircleContainer = styled.div<{ color?: string }>`
  position: absolute;
  top: 30%;
  right: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 560px;
  height: 560px;
  padding: 60px 90px 40px;
  font-size: 30px;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.009em;
  color: ${colors.black};
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${(props) => (props.color ? props.color : '#00f7ec')};
    z-index: -1;
  }

  @media (max-width: 1599px) {
    right: 5%;
    width: 520px;
    height: 520px;
    padding-right: 100px;
    padding-left: 100px;
  }

  ${media.laptop(css`
    top: 20%;
    right: 17%;
    width: 460px;
    height: 460px;
    padding-right: 80px;
    padding-left: 80px;
    font-size: 26px;
  `)}

  ${media.tabletLarge(css`
    right: 11%;
    width: 400px;
    height: 400px;
    padding-right: 50px;
    font-size: 24px;
  `)} 
  
  
  ${media.tabletSmall(css`
    top: 29%;
    right: 1%;
    width: 340px;
    height: 340px;
    font-size: 19px;
  `)} 
  
  
  ${media.mobile(css`
    top: auto;
    bottom: 0;
    right: 0;
    width: 300px;
    height: 400px;
    padding-left: 60px;
    padding-right: 0;

    &:before {
      width: calc(100% + 100px);
    }
  `)};

  @media (max-width: 669px) {
    width: 280px;

    &:before {
      width: calc(100% + 120px);
    }
  }
`;

export const CircleArrow = styled.i`
  position: absolute;
  top: 0;
  left: 10%;
  display: inline-flex;
  width: 90px;

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 1599px) {
    left: 14%;
    width: 80px;
  }

  ${media.laptop(css`
    top: 4%;
    width: 70px;
  `)}

  ${media.tabletLarge(css`
    top: 0;
    left: 16%;
    width: 60px;
  `)} 
  
  
  ${media.tabletSmall(css`
    left: 18%;
    width: 50px;
  `)} 
  
  
  ${media.mobile(css`
    top: 10%;
    width: 50px;
  `)};

  @media (max-width: 619px) {
    left: auto;
    right: 0;
    width: 40px;
  }
`;
