import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import SectionTitle from '@/components/SectionTitle';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { questionList } from '@/modules/Singapore/components/SingaporeFaq';

import { ReactComponent as FaqArrowSvg } from './svg/faq-arrow.svg';

const LandingFaq = () => {
  const { faqText, faqTitle, faqItems, faqEnabled } =
    useCurrentPage<LandingTemplatePageType>().templateFields;

  if (!faqEnabled) return null;

  return (
    <Component>
      <ContentSmallContainer>
        {faqTitle ? <SectionTitle title={faqTitle} /> : null}
        <Inner>
          {faqText ? <Text>{faqText}</Text> : null}
          {faqItems.length ? (
            <Main>
              {faqItems.map((item, index) => (
                <Item key={index}>
                  <ItemQuestion
                    onClick={(e) =>
                      e.currentTarget.parentElement?.classList.toggle('_opened')
                    }
                  >
                    {item.question}
                    <FaqArrowSvg />
                  </ItemQuestion>
                  <ItemAnswerWrapper>
                    <ItemAnswer
                      dangerouslySetInnerHTML={{
                        __html:
                          item.answer?.replaceAll('\n', '<br/><br/>') || '',
                      }}
                    />
                  </ItemAnswerWrapper>
                </Item>
              ))}
            </Main>
          ) : null}
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  color: ${colors.black};
  margin: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Inner = styled.div`
  margin-top: 80px;
  display: flex;

  ${media.tablet(css`
    margin-top: 60px;
    display: block;
  `)}

  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 44px;
  line-height: 120%;
  display: block;
  max-width: 395px;
  margin-right: 100px;

  ${media.tablet(css`
    margin: 0 0 40px;
  `)}

  ${media.mobile(css`
    font-size: 36px;
    margin-bottom: 30px;
  `)}
`;

const Main = styled.div``;

const ItemQuestion = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  position: relative;
  padding-right: 60px;
  display: block;
  cursor: pointer;

  ${media.mobile(css`
    font-size: 22px;
  `)}

  svg {
    position: absolute;
    right: 0;
    top: 0px;
    transition: 0.3s all ease;

    ${media.mobile(css`
      transform: scale(0.65);
    `)}
  }
`;

const ItemAnswerWrapper = styled.div`
  height: 0;
  overflow: hidden;
  transition: 0.3s all ease;
`;

const ItemAnswer = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  padding: 20px 0;

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const Item = styled.div`
  margin-top: 60px;

  &:first-child {
    margin-top: 0 !important;
  }

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  &._opened {
    ${ItemQuestion} {
      svg {
        transform: rotate(180deg);

        ${media.mobile(css`
          transform: scale(0.65) rotate(180deg);
        `)}
      }
    }

    ${ItemAnswerWrapper} {
      height: auto;
    }
  }
`;

export default LandingFaq;
