import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { useRouter } from 'next/router';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';
import Image from '@/assets/images/acquiring/acquiring-banner.png';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { Button } from '@/components/GetStarted';
import { ReactComponent as ArrowIntegration } from '@/assets/svg/arrow_integration.svg';

function AcquiringBanner() {
  const containerRef = useRef<HTMLDivElement>(null);
  //const imageRef = useRef<HTMLImageElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const buttonContainerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useEffect(() => {
    let tw: gsap.core.Tween;
    let tl: gsap.core.Timeline;

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      if (!titleRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });

      const splitTitle = new SplitText(titleRef.current, {
        linesClass: 'lineChild',
        type: 'words, lines',
      });

      new SplitText(titleRef.current, {
        linesClass: 'lineParent',
        type: 'words, lines',
      });

      tl.from(
        splitTitle.lines,
        {
          stagger: 0.15,
          ease: 'customEaseInOut',
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 1.45,
        },
        0
      );

      tl.from(
        textRef.current,
        {
          ease: Power2.easeOut,
          transformOrigin: '50% 100%',
          duration: 0.7,
          opacity: 0,
          delay: 0.2,
          y: 20,
        },
        1
      );
      tl.from(
        buttonContainerRef.current,
        {
          ease: Power2.easeOut,
          transformOrigin: '50% 100%',
          yPercent: 100,
          duration: 0.7,
        },
        1.5
      );
    });

    return () => {
      tw?.kill();
      tl?.kill();
    };
  }, []);

  return (
    <Component>
      <WrapperComponentInner>
        <ContentContainer>
          <Inner>
            <Picture src={Image} />
            <Content ref={containerRef}>
              <ContentInnerWrapper>
                <Title ref={titleRef}>
                  Introducing xpate <span>Links</span>
                </Title>
                <Text ref={textRef}>
                  A white label payment gateway specifically tailored for
                  acquirers.
                </Text>
                <WrapperButton>
                  <InnerWrapperButton ref={buttonContainerRef}>
                    <ButtonLabel>learn more</ButtonLabel>
                    <Button isBig={true} onClick={() => router.push('/links')}>
                      <ArrowIntegration />
                    </Button>
                  </InnerWrapperButton>
                </WrapperButton>
              </ContentInnerWrapper>
            </Content>
          </Inner>
        </ContentContainer>
      </WrapperComponentInner>
    </Component>
  );
}

const Component = styled.div`
  overflow: hidden;
`;

const WrapperComponentInner = styled.div`
  background: ${colors.acquiringPrimary};
  margin: 154px 0 62px;

  ${media.laptop(css`
    margin: 50px 0 0 0;
  `)}

  ${media.mobile(css`
    margin: 40px 0 0 0;
  `)}
`;

const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 90px;

  img {
    max-width: initial;
    ${media.tablet(css`
      max-width: 100%;
    `)}
  }

  ${media.tabletSmall(css`
    flex-direction: column;
    padding: 40px 0;
  `)}
`;

const Content = styled.div`
  flex: 0 0 50%;

  ${media.tabletSmall(css`
    align-self: flex-start;
  `)}
`;

const ContentInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  margin-top: 30px;
  ${media.tabletSmall(css`
    padding-left: 0;
  `)}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 102px;
  line-height: 104px;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.tabletLarge(css`
    font-size: 64px;
    line-height: 100%;
  `)}
  ${media.mobileMedium(css`
    font-size: 54px;
  `)}
  span {
    color: #05e178;
  }
`;

const Text = styled.span`
  display: block;
  max-width: 433px;
  width: 100%;
  font-weight: 600;
  font-size: 31px;
  line-height: 40px;
  margin-top: 80px;

  ${media.tabletSmall(css`
    margin-top: 40px;
    font-size: 20px;
    line-height: 30px;
  `)}

  ${media.mobile(css`
    margin-top: 20px;
  `)}

  ${media.mobileMedium(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const WrapperButton = styled.div`
  margin-top: 60px;
  align-self: flex-end;
  overflow: hidden;

  ${media.tabletSmall(css`
    margin-top: 40px;
  `)}

  ${media.tabletSmall(css`
    align-self: flex-start;
  `)}
`;

const InnerWrapperButton = styled.div`
  display: flex;
  align-items: center;

  padding-right: 16px;
`;
const ButtonLabel = styled.span`
  display: block;
  font-weight: 600;
  font-size: 39.9724px;
  line-height: 100%;
  margin-right: 20px;

  ${media.mobile(css`
    font-size: 32px;
  `)}
`;

export default AcquiringBanner;
