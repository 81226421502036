import React from 'react';
import styled, { css } from 'styled-components';

import { fonts } from '@/constants/theme';
import { media } from '@/utils/mixin';

export function CheckBox({
  name,
  checked,
  value,
  label,
  onChange,
  id,
  type = 'checkbox',
}: {
  name: string;
  checked: boolean;
  label?: string;
  id?: string;
  value: string;
  type?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <Container>
      <Input
        id={label || id}
        type={type}
        value={value}
        onChange={onChange}
        name={name}
        checked={checked}
      />
      {label ? <StyledLabel htmlFor={label}>{label}</StyledLabel> : null}
    </Container>
  );
}

export default CheckBox;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const Input = styled.input<{ checked: boolean }>`
  position: relative;
  min-width: 96px;
  min-height: 96px;
  background: #f8f8f8;
  border: none;
  appearance: none;
  cursor: pointer;

  ${media.mobile(css`
    min-width: 48px;
    min-height: 48px;
  `)}
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    display: none;
    width: 54px;
    height: 54px;
    background: #0060f5;
    border-radius: 5px;

    ${media.mobile(css`
      width: 27px;
      height: 27px;
    `)}
  }

  ${({ checked }) =>
    checked
      ? css`
          &:before {
            display: block;
          }
        `
      : null}
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  font-family: ${fonts.Gilroy};
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  padding-left: 30px;
  cursor: pointer;
  height: 100%;
  width: 100%;

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;
