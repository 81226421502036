import React from 'react';

import Layout from '@/components/Layout';

import ReallyInstantHero from './components/ReallyInstantHero';
import ReallyInstantSteps from './components/ReallyInstantSteps';
import ReallyInstantWhy from './components/ReallyInstantWhy';
import ReallyInstantHow from './components/ReallyInstantHow';
import ReallyInstantCallToAction from './components/ReallyInstantCallToAction';

function ReallyInstant() {
  return (
    <Layout theme="black" dark={true}>
      <ReallyInstantHero />
      <ReallyInstantSteps />
      <ReallyInstantWhy />
      <ReallyInstantHow />
      <ReallyInstantCallToAction />
    </Layout>
  );
}

export default ReallyInstant;
