import React, { createRef, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as ArrowDownSvg } from '@/assets/svg/arrow-usp-1.svg';
import { colors } from '@/constants/theme';
import SectionTitle from '@/components/SectionTitle';
import useCurrentPage from '@/hooks/useCurrentPage';
import { AboutPageType } from '@/typings/model';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function AboutLicenses() {
  const page = useCurrentPage<AboutPageType>();

  const titleRef = useRef<HTMLHeadingElement>(null);

  const licensesItemsLength = page?.templateFields.licensesItems?.length ?? 0;

  const cellRefList = useMemo(
    () =>
      Array.from({ length: licensesItemsLength }, () => ({
        cardRef: createRef<HTMLDivElement>(),
        bgRef: createRef<HTMLDivElement>(),
      })),
    [licensesItemsLength]
  );

  useEffect(() => {
    if (!page) return;

    let tw: gsap.core.Tween;

    const splitTitleChild = new SplitText(titleRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTitleParent = new SplitText(titleRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    const delayedCall = gsap.delayedCall(0, () => {
      if (!titleRef.current) return;

      tw = gsap.from(splitTitleChild.lines, {
        stagger: 0.15,
        ease: 'customEaseInOut',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: titleRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      splitTitleParent?.revert();
      splitTitleChild?.revert();
      delayedCall?.kill();
      tw?.kill();
    };
  }, [page]);

  useEffect(() => {
    if (!page) return;

    const tlList: Array<gsap.core.Timeline> = [];

    const delayedCall = gsap.delayedCall(0, () => {
      cellRefList.forEach((cell, index) => {
        const { cardRef } = cell;
        if (!cardRef.current) return;

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: cardRef.current,
            start: 'top 87%',
          },
        });

        const { bgRef } = cell;
        tlList[index].from(
          bgRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            delay: index / 8,
            duration: 1.65,
            scaleY: 0,
          },
          0
        );
      });
    });

    return () => {
      delayedCall?.kill();
      tlList.forEach((tl) => {
        tl.kill();
      });
    };
  }, [page]);

  if (!page) return null;

  return (
    <Component>
      <ContentSmallContainer>
        {page.templateFields.licensesTitle ? (
          <Title>
            <SectionTitle
              ref={titleRef}
              title={page.templateFields.licensesTitle}
            />
          </Title>
        ) : null}
        <Row>
          {page.templateFields.licensesItems?.map((item, index) => (
            <Cell key={index}>
              <Item ref={cellRefList[index].cardRef}>
                <ItemBackground ref={cellRefList[index].bgRef} />
                <Icon>
                  <ArrowDownSvg />
                </Icon>
                <Text>{item.text}</Text>
              </Item>
            </Cell>
          ))}
        </Row>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  margin: 144px 0 156px;

  ${media.laptop(css`
    margin: 120px 0 130px;
  `)}

  ${media.tabletSmall(css`
    margin: 100px 0 110px;
  `)}
  
  ${media.mobile(css`
    margin: 50px 0 60px;
  `)}
`;

const Title = styled.div`
  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 30px -24px 0;

  ${media.laptop(css`
    margin-top: 25px;
    margin-left: -20px;
    margin-right: -20px;
  `)}

  ${media.tabletLarge(css`
    margin-top: 10px;
  `)}
  
  ${media.tabletSmall(css`
    margin-left: -12px;
    margin-right: -12px;
  `)}
`;

const Cell = styled.div`
  flex-basis: 50%;
  margin-top: 48px;
  padding: 0 24px;

  ${media.laptop(css`
    margin-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
    padding-left: 12px;
    padding-right: 12px;
  `)}
  
  ${media.mobile(css`
    flex-basis: 100%;
    max-width: 100%;
  `)}
`;

const Item = styled.div`
  position: relative;
  min-height: 446px;
  height: 100%;
  padding: 41px 35px 41px 43px;
  color: ${colors.white};
  z-index: 0;

  ${media.laptop(css`
    padding: 60px 50px;
  `)}

  ${media.tabletLarge(css`
    padding: 60px 40px;
  `)}
  
  ${media.mobile(css`
    min-height: 320px;
    padding: 40px 0;
  `)}
`;

const ItemBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.blue};
  z-index: -1;

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

const Icon = styled.div`
  display: inline-flex;

  svg {
    transform: scale(0.75);
  }

  ${media.tabletLarge(css`
    svg {
      width: 36px;
      height: 42px;
      transform: scale(1);
    }
  `)}

  ${media.mobile(css`
    svg {
      width: 32px;
      height: 38px;
    }
  `)}
`;

const Text = styled.p`
  display: block;
  max-width: 485px;
  margin-top: 27px;
  font-size: 31px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.01em;

  ${media.laptop(css`
    font-size: 26px;
    line-height: 36px;
  `)}

  ${media.tabletLarge(css`
    margin-top: 25px;
    font-size: 24px;
    line-height: 34px;
  `)}
  
  ${media.tabletSmall(css`
    margin-top: 21px;
    font-size: 22px;
    line-height: 32px;
  `)}
  
  ${media.mobile(css`
    font-size: 21px;
    line-height: 31px;
  `)}
`;

export default AboutLicenses;
