import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';
import PricingTariffsTable from '@/components/PricingTariffsTable';

const PricingTariffs = () => {
  const {
    tariffsTitle,
    tariffsItems,
    tariffsText,
    tariffsBottomText,
    tariffsDisclaimer,
  } = useCurrentPage<PricingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{tariffsTitle}</Title>
        <Text>{tariffsText}</Text>
        <PricingTariffsTable
          items={tariffsItems}
          bottomText={tariffsBottomText}
        />
        <Disclaimer
          dangerouslySetInnerHTML={{ __html: tariffsDisclaimer ?? '' }}
        />
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.div`
  margin: 180px 0;
  color: ${colors.black};
  ${media.laptop(css`
    margin: 150px 0;
  `)}
  ${media.tablet(css`
    margin: 100px 0;
  `)}
  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Title = styled.h2`
  display: block;
  text-align: center;
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  width: 958px;
  max-width: 100%;
  margin: 0 auto;
  ${media.tablet(css`
    font-size: 50px;
  `)}
  ${media.mobile(css`
    font-size: 30px;
  `)}
`;

const Text = styled.span`
  display: block;
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  text-align: center;
  letter-spacing: -0.15209px;
  margin-top: 30px;

  ${media.tablet(css`
    font-size: 24px;
  `)}
  ${media.mobile(css`
    font-size: 20px;
  `)}
`;

const Disclaimer = styled.p`
  font-size: 22px;
  line-height: 32px;
  width: 1075px;
  max-width: 100%;
  margin: 80px auto 0;

  ${media.tablet(css`
    font-size: 20px;
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    font-size: 18px;
    margin-top: 40px;
  `)}
  b {
    color: ${colors.red};
    font-weight: 800;
  }

  a {
    color: ${colors.blue};
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default PricingTariffs;
