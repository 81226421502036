import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { Draggable } from 'gsap/dist/Draggable';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { InertiaPlugin } from 'gsap/dist/InertiaPlugin';

import { useMedia } from '@tager/web-core';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { getElemOuterWidth } from '@/utils/common';

gsap.registerPlugin(ScrollTrigger, InertiaPlugin);

type Props = {
  id: string;
  items: string[];
};

function Circles({ id, items }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const rowRef = useRef<HTMLOListElement>(null);
  const cellRef = useRef<HTMLLIElement>(null);

  const isTabletLayout = useMedia(
    `(max-width: ${breakpoints.tabletLarge - 1}px)`
  );

  function getRowOffset() {
    const rowElem = rowRef.current;
    const containerElem = containerRef.current;
    if (!rowElem || !containerElem) return 0;

    return containerElem.clientWidth - rowElem.offsetWidth;
  }

  useEffect(() => {
    if (isTabletLayout) return;

    let tw: gsap.core.Tween;

    const initAnimation = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(rowRef.current, {
        x: () => getRowOffset(),
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          invalidateOnRefresh: true,
          trigger: containerRef.current,
          scrub: true,
          id: id,
        },
      });
    });

    return () => {
      initAnimation.kill();
      ScrollTrigger.getById(id)?.kill();
      tw?.kill();
    };
  }, [id, isTabletLayout]);

  useEffect(() => {
    if (!isTabletLayout) return;

    const rowElem = rowRef.current;
    const cellElem = cellRef.current;
    const containerElem = containerRef.current;

    if (!rowElem) return;
    if (!cellElem) return;
    if (!containerElem) return;

    let dr: Draggable[] = [];
    let tw: gsap.core.Tween;

    gsap.registerPlugin(Draggable);

    const proxy = document.createElement('div');

    function getDraggableBounds() {
      return { minX: 0, maxX: getRowOffset(), minY: 0, maxY: 0 };
    }

    gsap.delayedCall(0, () => {
      dr = Draggable.create(proxy, {
        onDrag: updateProgress,
        onThrowUpdate: updateProgress,
        bounds: getDraggableBounds(),
        throwProps: true,
        trigger: containerElem,
        inertia: true,
        type: 'x',
        snap: {
          x: (x) => {
            const elemOuterWidth = getElemOuterWidth(cellElem);
            return Math.round(x / elemOuterWidth) * elemOuterWidth;
          },
        },
      });
    });

    window.addEventListener('resize', handleResize);

    function handleResize() {
      const rowElem = rowRef.current;
      if (!rowElem) return;

      tw?.invalidate();
      dr[0]?.update()?.applyBounds(getDraggableBounds());
    }

    function updateProgress(this: any) {
      const rowElem = rowRef.current;
      if (!rowElem) return;

      gsap.set(rowElem, {
        x: this.x,
      });
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      dr[0]?.kill();
      tw?.kill();
    };
  }, [isTabletLayout]);

  return (
    <Component ref={containerRef}>
      <Row ref={rowRef}>
        {items.map((item, index) => (
          <Item ref={cellRef} key={index}>
            <Number>0{index + 1}</Number>
            <Text>{item}</Text>
          </Item>
        ))}
      </Row>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
`;

const Row = styled.ol`
  display: flex;
  flex-shrink: 0;
  padding: 0 14px;
`;

const Item = styled.li`
  width: 564px;
  height: 564px;
  border-radius: 50%;
  background: ${colors.black};
  color: ${colors.white};
  padding: 170px 90px 0 100px;
  margin: 0 -14px;

  &:nth-child(2n) {
    background: rgba(242, 254, 57, 0.75);
    color: ${colors.black};
    z-index: 0;
  }

  ${media.laptop(css`
    width: 442px;
    height: 442px;
    padding: 120px 80px 0 90px;
  `)}

  ${media.tabletLarge(css`
    width: 342px;
    height: 342px;
    padding: 80px 50px 0 60px;
  `)}
  
  ${media.mobileMedium(css`
    width: 242px;
    height: 242px;
    padding: 60px 30px 0 40px;
  `)}
`;

const Number = styled.span`
  display: block;
  font-size: 15px;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 0.08em;

  ${media.mobileMedium(css`
    font-size: 13px;
    line-height: 100%;
  `)}
`;

const Text = styled.p`
  display: block;
  font-size: 31px;
  line-height: 40px;
  font-weight: 600;
  margin-top: 25px;
  letter-spacing: -0.02em;

  ${media.laptop(css`
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    margin-top: 20px;
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.mobileMedium(css`
    margin-top: 15px;
    font-size: 17px;
    line-height: 27px;
  `)}
`;

export default Circles;
