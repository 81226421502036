import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as Icon01 } from '@/assets/svg/corebanking/1.svg';
import { ReactComponent as Icon02 } from '@/assets/svg/corebanking/2.svg';
import { ReactComponent as Icon03 } from '@/assets/svg/corebanking/3.svg';
import { ReactComponent as Icon04 } from '@/assets/svg/corebanking/4.svg';
import { ReactComponent as Icon05 } from '@/assets/svg/corebanking/5.svg';
import { ReactComponent as Icon06 } from '@/assets/svg/corebanking/6.svg';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CorebankingPageType } from '@/typings/model';

const renderIcon = (num: number): React.ReactNode => {
  switch (num) {
    case 1:
      return <Icon01 />;
    case 2:
      return <Icon02 />;
    case 3:
      return <Icon03 />;
    case 4:
      return <Icon04 />;
    case 5:
      return <Icon05 />;
    case 6:
      return <Icon06 />;
    default:
      return null;
  }
};

const CorebankingIntegrations = () => {
  const { integrationItems, integrationText, integrationTitle } =
    useCurrentPage<CorebankingPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title>{integrationTitle}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: integrationText
              ? integrationText.replace(/\*(.+)\*/gi, '<em>$1</em>')
              : '',
          }}
        />
        {integrationItems ? (
          <List>
            {integrationItems.split('\n').map((item, index) => (
              <ListItem>
                {renderIcon(index + 1)}
                {item.trim()}
              </ListItem>
            ))}
          </List>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  margin: 250px 0;

  ${media.tablet(css`
    margin: 150px 0;
  `)}

  ${media.mobile(css`
    margin: 80px 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  color: #040312;

  ${media.tablet(css`
    font-size: 80px;
  `)}
  ${media.mobile(css`
    font-size: 60px;
  `)}
`;

const Text = styled.p`
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #c2c2c8;
  display: block;
  margin-top: 80px;
  max-width: 1050px;

  ${media.tablet(css`
    font-size: 60px;
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    font-size: 40px;
    margin-top: 40px;
  `)}

  em {
    font-style: normal;
    color: #040312;
  }
`;

const List = styled.ol`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${media.tablet(css`
    margin: 0 -25px;
  `)}

  ${media.mobile(css`
    display: block;
    margin: 0;
  `)}
`;

const ListItem = styled.li`
  width: 33.33333%;
  margin-top: 80px;
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  color: #040312;
  padding-top: 70px;
  position: relative;
  max-width: 350px;

  ${media.tablet(css`
    width: 50%;
    padding: 60px 25px 0;
    max-width: none;
  `)}

  ${media.mobile(css`
    width: 100%;
    padding: 100px 0 0 0;
    max-width: none;
    &:not(:first-child) {
      margin-top: 80px;
    }
  `)}

  svg {
    position: absolute;
    left: 10px;
    top: -20px;

    ${media.tablet(css`
      left: 30px;
    `)}
    ${media.mobile(css`
      left: 10px;
      top: 5px;
    `)}
  }
`;

export default CorebankingIntegrations;
