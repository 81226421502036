import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentPage from '@/hooks/useCurrentPage';
import { LandingTemplatePageType } from '@/typings/model';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import SectionHeader from '@/components/SectionHeader';
import LearnMoreButton from '@/components/LearnMoreButton';

const LandingProcess = () => {
  const {
    processEnabled,
    processItems,
    processText,
    processTitle,
    processButtonLabel,
    processButtonLink,
    processButtonNewTab,
  } = useCurrentPage<LandingTemplatePageType>().templateFields;

  if (!processEnabled) return null;

  return (
    <Component>
      <ContentSmallContainer>
        <SectionHeader
          title={processTitle}
          text={processText}
          maxWidth={960}
          maxTextWidth={900}
        />
        {processItems.length ? (
          <Grid>
            {processItems.map(({ title, text }, ind) => (
              <Item>
                <ItemNumber>{ind + 1}</ItemNumber>
                {title ? <ItemTitle>{title}</ItemTitle> : null}
                {text ? <ItemText>{text}</ItemText> : null}
              </Item>
            ))}
          </Grid>
        ) : null}
        <ButtonRow>
          <LearnMoreButton
            label={processButtonLabel}
            to={processButtonLink}
            variant="whiteRounded58"
            newTab={processButtonNewTab}
          />
        </ButtonRow>
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  background: ${colors.black};
  color: ${colors.white};
  margin: 180px 0;
  padding: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
    padding: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
    padding: 60px 0;
  `)}
`;

const ButtonRow = styled.div`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
`;

const Grid = styled.div`
  margin-top: 80px;
  ${media.tablet(css`
    margin-top: 60px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
  `)}
  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 100px;

  ${media.tablet(css`
    gap: 60px;
    grid-template-columns: 1fr 1fr;
  `)}

  ${media.mobile(css`
    gap: 50px;
    grid-template-columns: 1fr;
  `)}
`;

const Item = styled.div``;

const ItemNumber = styled.span`
  display: block;
  color: ${colors.blueDark};
  font-weight: 900;
  font-size: 150px;
  line-height: 70%;
  ${media.mobile(css`
    font-size: 100px;
  `)}
`;

const ItemTitle = styled.span`
  font-weight: 600;
  font-size: 30px;
  line-height: 145%;
  display: block;
  margin-top: 30px;
  ${media.mobile(css`
    font-size: 24px;
    margin-top: 20px;
  `)}
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 145%;
  display: block;
  margin-top: 30px;
  ${media.mobile(css`
    font-size: 18px;
    margin-top: 20px;
  `)}
`;

export default LandingProcess;
