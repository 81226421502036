import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { createMediaMixin } from '@tager/web-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';
import FcaImage from '@/assets/images/xpp/fca.webp';
import GlobeImage from '@/assets/images/xpp/globe.webp';
import StarImage from '@/assets/images/xpp/star.webp';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-usp-2.svg';

const circleItemList = [
  {
    text: 'Over multiple<br />business customers &<br/>25 banking partners',
    image: StarImage,
    id: 'circle-one',
    showIcon: true,
  },
  {
    text: 'FCA regulated <br /> in  the UK',
    image: FcaImage,
    id: 'circle-two',
    showIcon: false,
  },
  {
    text: 'Global Coverage',
    image: GlobeImage,
    id: 'circle-three',
    showIcon: true,
  },
];

function GlobalPayments() {
  const bgRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const circleItemRefs = Array.from({
    length: circleItemList.length,
  }).map((item) => React.createRef<HTMLParagraphElement>());

  const circleImageRefs = Array.from({
    length: circleItemList.length,
  }).map((item) => React.createRef<HTMLImageElement>());

  useEffect(() => {
    let tl: gsap.core.Timeline;
    let tlForCircleItems: gsap.core.Timeline;
    let tlForImage: gsap.core.Timeline;
    const tlList: Array<gsap.core.Timeline> = [];
    const tlImageList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 27%',
          scrub: true,
        },
      });

      tl.to(
        bgRef.current,
        {
          ease: 'linear',
          y: '25%',
        },
        0
      ).to(
        innerRef.current,
        {
          ease: 'linear',
          y: -100,
        },
        0
      );

      circleItemRefs.forEach((textItem, index) => {
        if (!textItem.current) return;
        tlForCircleItems = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: textItem.current,
            start: 'top 120%',
            scrub: 1,
          },
        });

        tlList[index] = tlForCircleItems.from(circleItemRefs[index].current, {
          ease: 'ease',
          yPercent: 30,
        });
      }, 0);

      circleImageRefs.forEach((image, index) => {
        if (!image.current) return;
        tlForImage = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: image.current,
            start: 'top 120%',
            scrub: 1,
          },
        });

        tlImageList[index] = tlForImage.from(circleImageRefs[index].current, {
          ease: 'ease',
          yPercent: index === circleImageRefs.length - 1 ? 20 : -60,
        });
      }, 0);
    });

    return () => {
      tl?.kill();
      tlForCircleItems?.kill();
      tlForImage?.kill();
      tlList?.forEach((item) => item?.kill());
      tlImageList?.forEach((item) => item?.kill());
    };
  }, [circleImageRefs, circleItemRefs]);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitText = new SplitText(textRef.current, {
      type: 'chars, words',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tl = gsap.timeline({
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
        },
      });

      tl.from(
        splitText.chars,
        {
          stagger: 0.05,
          ease: 'customEaseInOut',
          duration: 0.6,
          opacity: 0,
          delay: 0.2,
        },
        0
      );
    });

    return () => {
      splitText?.revert();
      tl?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Inner ref={innerRef}>
          <Text>
            <TextBackground ref={bgRef} />
            <TextInner ref={textRef}>
              xpate is your trusted global payments partner
            </TextInner>
            <CirclesWrapper>
              {circleItemList.map((item, index) => (
                <Circle key={index} ref={circleItemRefs[index]} id={item.id}>
                  {item.showIcon && (
                    <Arrow>
                      <ArrowIcon />
                    </Arrow>
                  )}
                  <CircleWrapper>
                    <CircleInner>
                      <Picture
                        imageRef={circleImageRefs[index]}
                        src2x={item.image}
                      />
                      <CircleText
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </CircleInner>
                  </CircleWrapper>
                </Circle>
              ))}
            </CirclesWrapper>
          </Text>
        </Inner>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  padding: 100px 0;

  ${media.tabletSmall(css`
    padding: 60px 0;
  `)}

  ${media.mobile(css`
    padding: 30px 0;
  `)}
`;

const Inner = styled.div``;

const Circle = styled.div`
  position: absolute;
  z-index: 10;
`;

const Arrow = styled.div`
  position: absolute;
  cursor: pointer;
  z-index: 15;

  svg {
    path {
      fill: ${colors.black};
    }
  }
`;

const CircleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  border-radius: 50%;
  overflow: hidden;
`;

const CircleText = styled.p`
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  font-weight: 600;
  font-size: 28px;
  line-height: 145%;
  color: white;

  @media (max-width: 1400px) {
    font-size: 22px;
    line-height: 145%;
  }

  @media (max-width: 1024px) {
    max-width: 128px;
    font-weight: 600;
    font-size: 20px;
    line-height: 145%;
  }

  ${media.mobile(
    css`
      br {
        display: none;
      }
    `
  )}

  ${media.mobileMedium(css`
    font-size: 16px;
  `)}
`;

const CircleInner = styled.div`
  padding: 20px 74px;
  border-radius: 50%;
  height: 100%;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: ${colors.xppPrimary};
  }

  img {
    position: absolute;
    max-width: initial;
    user-select: none;
  }

  ${media.tabletSmall(css`
    padding: 20px 43px !important;
  `)}

  ${media.mobileMedium(css`
    padding: 20px 26px !important;
  `)}
`;

const CirclesWrapper = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 100%;
  right: -116px;

  #circle-one {
    width: 510px;
    height: 510px;
    top: 246px;
    right: 69px;

    img {
      top: -46px;
      right: -101px;
    }

    ${Arrow} {
      left: 33px;
    }

    @media (max-width: 414px) {
      ${CircleInner} {
        padding: 20px 43px !important;
      }
    }
  }

  #circle-two {
    width: 325px;
    height: 325px;

    right: 0;
    bottom: 265px;

    img {
      top: -30px;
      right: -118px;
    }

    @media (max-width: 1400px) {
      ${CircleInner} {
        padding: 20px 43px;
      }
    }
  }

  #circle-three {
    bottom: -76px;
    width: 372px;
    height: 372px;
    right: 225px;

    img {
      top: 143px;
    }

    ${Arrow} {
      left: 5px;
    }

    ${CircleText} {
      max-width: 210px;
    }

    svg {
      transform: rotate(270deg);
    }

    ${CircleInner} {
      @media (min-width: 1024px) {
        padding: 20px 52px !important;
      }
    }
  }

  ${createMediaMixin({ max: 1550 })(css`
    right: -50px;
  `)}

  ${createMediaMixin({ max: 1400 })(css`
    right: 0;

    #circle-one {
      top: 0;
      right: 0;
      width: 325px;
      height: 325px;

      img {
        top: -82px;
        right: -120px;
      }

      ${Arrow} {
        top: -30px;
      }
    }

    #circle-two {
      right: 0;
      width: 260px;
      height: 260px;
      bottom: -90px;

      img {
        top: -35px;
        right: -127px;
      }
    }

    #circle-three {
      width: 310px;
      height: 310px;
      bottom: -90px;
      right: 290px;
    }
  `)}

  ${media.tabletSmall(css`
    right: 0;
    margin-top: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;

    ${Circle} {
      position: relative;
      right: 0 !important;
      left: 0 !important;
      top: 0 !important;
      bottom: 0 !important;
    }

    #circle-one {
      img {
        top: -72px;
        right: -56px;
      }
    }

    #circle-two {
      align-self: flex-end;

      img {
        top: -66px;
        right: -74px;
      }
    }

    #circle-three {
      img {
        top: 142px;

        right: -100px;
      }

      svg {
        display: none;
      }
    }
  `)}


  ${media.mobileMedium(css`
    #circle-one {
      width: 220px !important;
      height: 220px !important;

      ${CircleInner} {
        padding: 20px 43px;
      }

      ${CircleText} {
        max-width: 111px;
      }

      ${Arrow} {
        left: 24px;
        top: -5px;
      }

      svg {
        width: 41px;
        height: 51px;
      }

      img {
        width: 115px;
        height: 111px;
        top: -32px;
        right: -40px;
      }
    }

    #circle-two {
      width: 140px !important;
      height: 140px !important;
      align-self: flex-end;

      img {
        width: 77px;
        height: 79px;
        top: -30px;
        right: -33px;
      }
    }

    #circle-three {
      width: 160px !important;
      height: 160px !important;

      ${Arrow} {
        display: none;
      }

      img {
        width: 90px;
        height: 90px;
        top: 71px;
        right: -49px;
      }
    }
  `)}
`;

const Text = styled.span`
  position: relative;
  display: block;
  padding-bottom: 152px;

  ${media.tabletLarge(css`
    padding-bottom: 162px;
  `)}
  ${media.tabletSmall(css`
    padding: 0;
  `)}
  ${media.mobile(css`
    padding-bottom: 0;
  `)}
`;

const TextInner = styled.span`
  position: relative;
  display: block;
  font-size: 198px;
  line-height: 241px;
  font-weight: bold;
  max-width: 1139px;
  letter-spacing: -0.02em;
  z-index: 3;
  color: ${colors.black};
  ${createMediaMixin({ max: 1530 })(css``)}
  ${media.laptop(css`
    max-width: 930px;
    padding-left: 200px;
    font-size: 98px;
    line-height: 114px;
    margin-left: -40px;
  `)} ${media.tabletLarge(css`
    max-width: 820px;
    padding-left: 160px;
    font-size: 88px;
    line-height: 94px;

    margin-left: -100px;
  `)} ${media.tabletSmall(css`
    max-width: 540px;
    padding-left: 0;
    font-size: 68px;
    line-height: 74px;
    margin-left: 0 !important;
  `)} ${media.mobile(css`
    max-width: 600px;
    padding-right: 14%;
    font-weight: bold;
    font-size: 64px;
    line-height: 70px;
  `)};
`;

const TextBackground = styled.div`
  position: absolute;
  top: 114px;
  left: -140px;
  bottom: 0;
  right: -137px;
  background: ${colors.grayLight};
  z-index: -1;

  ${media.tabletLarge(css`
    top: 47px;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

export default GlobalPayments;
