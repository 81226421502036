import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power2 } from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import SectionLabel from '@/modules/Home/components/SectionLabel';
import LearnMoreButton from '@/components/LearnMoreButton';
import Image01 from '@/assets/images/home/image_01.png';
import Image02 from '@/assets/images/home/image_02.png';

gsap.registerPlugin(SplitText);

function HomeSections() {
  const section1 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageCoverRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    labelRef: useRef<HTMLSpanElement>(null),
    titleRef: useRef<HTMLSpanElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
    buttonRef: useRef<HTMLDivElement>(null),
  };
  const section2 = {
    containerRef: useRef<HTMLDivElement>(null),
    imageCoverRef: useRef<HTMLDivElement>(null),
    imageRef: useRef<HTMLImageElement>(null),
    labelRef: useRef<HTMLSpanElement>(null),
    titleRef: useRef<HTMLSpanElement>(null),
    textRef: useRef<HTMLParagraphElement>(null),
    buttonRef: useRef<HTMLDivElement>(null),
  };

  useEffect(() => {
    const twList: Array<gsap.core.Tween> = [];
    const tlList: Array<gsap.core.Timeline> = [];

    gsap.delayedCall(0, () => {
      [section1, section2].forEach((section, index) => {
        const { containerRef } = section;
        if (!containerRef.current) return;

        const { imageRef } = section;
        twList[index] = gsap.to(imageRef.current, {
          y: '20%',
          ease: 'linear',
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 32%',
            scrub: true,
          },
        });

        tlList[index] = gsap.timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
            start: 'top 82%',
          },
        });

        const { imageCoverRef } = section;
        tlList[index].fromTo(
          imageCoverRef.current,
          {
            scaleY: 1,
          },
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 0',
            duration: 1.85,
            scaleY: 0,
          },
          0
        );

        const { titleRef } = section;
        const splitTitle = new SplitText(titleRef.current, {
          linesClass: 'lineChild',
          type: 'words, lines',
        });

        new SplitText(titleRef.current, {
          linesClass: 'lineParent',
          type: 'words, lines',
        });

        tlList[index].from(
          splitTitle.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.45,
            delay: 0.3,
          },
          0
        );

        const { textRef } = section;
        tlList[index].from(
          textRef.current,
          {
            ease: Power2.easeOut,
            transformOrigin: '50% 100%',
            duration: 0.7,
            opacity: 0,
            delay: 0.2,
            y: 20,
          },
          1
        );

        const { buttonRef } = section;
        tlList[index].from(
          buttonRef.current,
          {
            ease: Power2.easeOut,
            duration: 0.7,
            opacity: 0,
            delay: 0.4,
            y: 20,
          },
          1
        );

        const { labelRef } = section;
        tlList[index].from(
          labelRef.current,
          {
            ease: 'customEaseInOut',
            transformOrigin: '50% 100%',
            yPercent: 100,
            duration: 1.25,
            delay: 0.2,
          },
          1
        );
      });
    });

    return () => {
      twList.forEach((tw) => tw?.kill());
      tlList.forEach((tl) => tl?.kill());
    };
  }, []);

  return (
    <Component>
      <ContentContainer>
        <Section ref={section1.containerRef}>
          <Image>
            <Label>
              <SectionLabel ref={section1.labelRef} label="Acquiring" />
            </Label>
            <ImageContainer>
              <img
                ref={section1.imageRef}
                alt="Internet acquiring"
                src={Image01}
              />
            </ImageContainer>
            <ImageCover ref={section1.imageCoverRef} />
          </Image>
          <Content>
            <Title ref={section1.titleRef}>Payment processing</Title>
            <Text ref={section1.textRef}>
              Highly intuitive. No hidden fees. And so fast that — if it was a
              car — it would probably get a speeding ticket.
            </Text>
            <Bottom>
              <ButtonContainer ref={section1.buttonRef}>
                <LearnMoreButton to="/processing" />
              </ButtonContainer>
            </Bottom>
          </Content>
        </Section>
        <Section ref={section2.containerRef}>
          <ImageSecond>
            <Label>
              <SectionLabel ref={section2.labelRef} label="Accounts" />
            </Label>
            <ImageContainer>
              <img
                ref={section2.imageRef}
                alt="Business accounts"
                src={Image02}
              />
            </ImageContainer>
            <ImageCover ref={section2.imageCoverRef} />
          </ImageSecond>
          <Content>
            <Title ref={section2.titleRef}>Business accounts</Title>
            <Text ref={section2.textRef}>
              Let us guide your way around SWIFT, SEPA and IBAN solutions. With
              us, you won’t get lost in a blizzard of acronyms.
            </Text>
            <Bottom>
              <ButtonContainer ref={section2.buttonRef}>
                <LearnMoreButton to="/accounts" />
              </ButtonContainer>
            </Bottom>
          </Content>
        </Section>
      </ContentContainer>
    </Component>
  );
}

const Component = styled.section`
  padding-top: 15px;
  padding-bottom: 135px;
  color: #040312;
  background: #fff;

  ${media.tabletSmall(css`
    padding-bottom: 120px;
  `)}

  ${media.mobile(css`
    padding-top: 45px;
    padding-bottom: 80px;
  `)}
`;
const Content = styled.div`
  width: 100%;
  padding-left: 110px;

  ${media.laptop(css`
    padding-right: 5%;
    padding-left: 10%;
  `)}

  ${media.tabletSmall(css`
    padding: 0;
  `)}
`;

const Label = styled.div`
  position: absolute;
  top: 28px;
  left: 40px;
  overflow: hidden;
  z-index: 1;

  ${media.tabletSmall(css`
    left: 0;
  `)}
`;

const Section = styled.div`
  display: flex;
  align-items: center;
  margin-top: 56px;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(2n) {
    flex-direction: row-reverse;

    ${Content} {
      padding-left: 130px;
    }

    ${Label} {
      left: 50px;
    }
  }

  ${media.laptop(css`
    &:nth-child(2n) {
      ${Content} {
        padding-right: 10%;
        padding-left: 5%;
      }
    }
  `)}

  ${media.tabletSmall(css`
    flex-direction: column;
    width: 100%;
    padding: 0;

    &:nth-child(2n) {
      flex-direction: column;

      ${Content} {
        padding: 0;
      }

      ${Label} {
        left: 0;
      }
    }
  `)}
  
  ${media.mobile(css`
    margin-top: 54px;
  `)}
`;

const ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  img {
    position: relative;
    max-width: 97%;
    max-height: 97%;
  }

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}
`;

const Image = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 50%;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    background: #fafafa;
  }

  ${ImageContainer} {
    left: 50%;
    transform: translateX(-50%);
  }

  ${media.tabletSmall(css`
    width: 100%;
    margin-bottom: 30px;

    &:before {
      padding-top: 50%;
      margin: 0 -20px;
    }
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
  
  ${media.mobileLarge(css`
    &:before {
      padding-top: 100%;
    }
  `)}
`;

const ImageSecond = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 50%;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    background: #fafafa;
  }

  ${ImageContainer} {
    position: absolute;
    left: 0;
  }

  ${media.tabletSmall(css`
    width: 100%;
    margin-bottom: 30px;

    &:before {
      padding-top: 50%;
      margin: 0 -20px;
    }
  `)}

  ${media.mobile(css`
    margin-bottom: 50px;
  `)}
  
  ${media.mobileLarge(css`
    &:before {
      padding-top: 100%;
    }
  `)}
`;

const ImageCover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 1;
`;

const Title = styled.span`
  display: inline-block;
  font-size: 102px;
  line-height: 86px;
  font-weight: bold;
  letter-spacing: -0.01em;

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  ${media.laptop(css`
    font-size: 74px;
    line-height: 90%;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 54px;
  `)}
`;

const Text = styled.p`
  display: block;
  max-width: 480px;
  margin-top: 50px;
  font-size: 24px;
  line-height: 34px;
  margin-left: 5px;
  letter-spacing: -0.01em;
  font-weight: 500;

  ${media.laptop(css`
    max-width: 350px;
    margin-top: 30px;
    font-size: 23px;
    line-height: 32px;
  `)}

  ${media.tabletLarge(css`
    max-width: 270px;
    font-size: 20px;
    line-height: 30px;
  `)}
  
  ${media.tabletSmall(css`
    max-width: 514px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 18px;
    line-height: 28px;
  `)}
`;

const Bottom = styled.div`
  margin-top: 54px;
  margin-left: 5px;

  ${media.laptop(css`
    margin-top: 40px;
  `)}

  ${media.mobile(css`
    margin-bottom: 30px;
  `)}
`;

const ButtonContainer = styled.div`
  display: inline-block;

  button {
    padding: 0 26px 0 29px;
  }
`;

export default HomeSections;
