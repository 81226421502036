import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';

import { isIos } from '@/modules/Stacks/components/StacksServices/components/StacksStatisticsItem.helpers';

interface Props {
  prefix?: React.ReactNode;
  value: number;
  text: {
    preText: string;
    lastText: string;
  };
  placeholderColor: string;
  progressRef: RefObject<HTMLDivElement>;
  valueRef: RefObject<HTMLSpanElement>;
  textRef: RefObject<HTMLDivElement>;
  className?: string;
}

function StacksStatisticsItem({
  placeholderColor,
  value,
  text,
  prefix,
  progressRef,
  valueRef,
  textRef,
  className,
}: Props) {
  return (
    <Component className={className}>
      <Row ref={textRef}>
        {prefix && prefix}
        <Value ref={valueRef}>{value}</Value>
        <Text>
          <span>{text.preText}</span>
          <span>{text.lastText}</span>
        </Text>
      </Row>
      <ProgressLine placeholderColor={placeholderColor}>
        <ProgressDynamic ref={progressRef} />
      </ProgressLine>
    </Component>
  );
}

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -52px;
  }
`;

const Value = styled.span`
  margin-right: 20px;
  font-weight: bold;
  font-size: 102px;
  line-height: 100%;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 34.8px;
  margin-bottom: 6px;

  span:last-child {
    font-weight: 800;
  }

  @media (max-width: 416px) {
    margin-bottom: 4px;
  }
`;

const ProgressDynamic = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px !important;
  overflow: hidden !important;
`;

export const ProgressLine = styled.div<{ placeholderColor: string }>`
  margin-top: 23px;
  width: 100vw;
  height: 15px;
  border-radius: 8px !important;
  background: #f6f6f6;
  overflow: hidden !important;
  transform: translateZ(0);

  ${ProgressDynamic} {
    background: ${(props) => props.placeholderColor};
  }
`;

const Component = styled.div`
  @media (max-width: 1440px) {
    ${Row} {
      svg {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    ${Value} {
      font-size: 50px;
      line-height: 100%;
    }

    ${Row} {
      align-items: center;
    }

    ${Text} {
      line-height: 0;
      white-space: pre-wrap;

      span {
        font-size: 16px;
        line-height: 100%;
      }
    }
  }
`;

export default StacksStatisticsItem;
