import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { ReactComponent as CheckMarkIcon } from '@/assets/svg/check-mark.svg';
import useCurrentPage from '@/hooks/useCurrentPage';
import { OverviewPageType } from '@/typings/model';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function OverviewFeatures() {
  const page = useCurrentPage<OverviewPageType>();
  if (!page) return null;

  const { featuresTitle, featuresItems, featuresContent } = page.templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Title small={!!featuresContent}>{featuresTitle}</Title>
        {featuresContent ? (
          <Content dangerouslySetInnerHTML={{ __html: featuresContent }} />
        ) : featuresItems?.length ? (
          <Rows>
            {featuresItems.map((featureItem, index) => (
              <Row>
                <RowTitle>{featureItem.title}</RowTitle>
                <RowContent>
                  {featureItem.firstColumn && (
                    <Cell>
                      {featureItem.firstColumn.map((item) => (
                        <Item
                          isSingle={
                            !!(
                              index === 0 &&
                              featureItem.secondColumn.length === 0 &&
                              item.item !== 'Currency Exchange'
                            )
                          }
                        >
                          <CheckMarkIcon />
                          {item.item}
                          {item.subItems ? (
                            <List>
                              {item.subItems.split('\n').map((subItem) => (
                                <ListItem>- {subItem}</ListItem>
                              ))}
                            </List>
                          ) : null}
                        </Item>
                      ))}
                    </Cell>
                  )}
                  {featureItem.secondColumn &&
                  featureItem.secondColumn.length ? (
                    <Cell>
                      {featureItem.secondColumn.map((item) => (
                        <Item>
                          <CheckMarkIcon />
                          {item.item}
                          {item.subItems ? (
                            <List>
                              {item.subItems.split('\n').map((subItem) => (
                                <ListItem>- {subItem}</ListItem>
                              ))}
                            </List>
                          ) : null}
                        </Item>
                      ))}
                    </Cell>
                  ) : null}
                </RowContent>
              </Row>
            ))}
          </Rows>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin: 140px 0 180px;

  @media (max-width: 1470px) {
    margin: 130px 0;
  }

  ${media.laptop(css`
    margin: 110px 0;
  `)}

  ${media.tabletLarge(css`
    margin: 90px 0;
  `)}
  
  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Title = styled.h2<{ small: boolean }>`
  display: block;
  font-size: 114px;
  line-height: 100%;
  letter-spacing: -0.01em;

  @media (max-width: 1470px) {
    font-size: 94px;
  }

  ${media.laptop(css`
    font-size: 84px;
  `)}

  ${media.tabletLarge(css`
    font-size: 74px;
  `)}
  
  ${media.tabletSmall(css`
    font-size: 64px;
  `)}
  
  ${media.mobile(css`
    font-size: 50px;
  `)}
  
  ${({ small }) =>
    small &&
    css`
      font-size: 102px;
    `}
`;

const Content = styled.div`
  margin-top: 80px;
  margin-left: -1px;

  h3 {
    font-weight: bold;
    font-size: 44px;
    line-height: 40px;
    letter-spacing: -0.66px;
    color: ${colors.black};

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    ${media.tabletSmall(css`
      font-size: 34px;
      line-height: 30px;
    `)}

    ${media.mobile(css`
      margin-bottom: 25px;
      font-size: 26px;
      line-height: 32px;
    `)}
  }

  ul {
    li {
      position: relative;
      padding-left: 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #58585d;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background-color: ${colors.linksPrimary};
      }

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 80px;
    }

    ${media.tabletSmall(css`
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    `)}
  }
`;

const Rows = styled.div`
  margin-top: 103px;
  margin-left: -1px;
`;

const Row = styled.div`
  display: flex;
  padding-top: 71px;
  margin-top: 68px;
  border-top: 1px solid ${colors.grayDark};

  &:first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: 0 none;
  }

  ${media.laptop(css`
    padding-top: 45px;
    margin-top: 45px;
  `)}

  ${media.tabletSmall(css`
    flex-direction: column;
    padding-top: 35px;
    margin-top: 35px;
  `)}
  
  ${media.mobile(css`
    padding-top: 25px;
    margin-top: 25px;
  `)}
`;

const RowTitle = styled.h3`
  display: block;
  width: 310px;
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: -0.01em;

  ${media.tabletLarge(css`
    width: 260px;
    font-size: 28px;
    line-height: 34px;
  `)}

  ${media.tabletSmall(css`
    width: auto;
    margin-bottom: 35px;
  `)}
  
  ${media.mobile(css`
    margin-bottom: 25px;
    font-size: 26px;
    line-height: 32px;
  `)}
`;

const RowContent = styled.div`
  display: flex;
  flex: 1 1 1px;
  margin-left: 83px;
  margin-right: -40px;
  padding-top: 5px;

  ${media.laptop(css`
    margin-left: 60px;
    margin-right: 0;
  `)}

  ${media.tabletLarge(css`
    margin-left: 30px;
  `)}

  ${media.tabletSmall(css`
    margin: 0 -20px;
  `)}
  
  ${media.mobile(css`
    display: block;
  `)}
`;

const Cell = styled.ul`
  flex: 1 1 auto;
  padding: 0 40px;

  ${media.laptop(css`
    padding: 0 30px;
  `)}

  ${media.tabletLarge(css`
    padding: 0 20px;
  `)}
  
  ${media.mobile(css`
    display: block;
    margin-top: 25px;

    &:first-child {
      margin-top: 0;
    }
  `)}
`;

const List = styled.ul`
  margin-top: 10px;
  margin-left: 24px;
`;

const Item = styled.li<{ isSingle?: boolean }>`
  padding-left: 38px;
  position: relative;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  display: block;
  width: 100%;
  margin-top: 30px;
  max-width: ${(props) => (props.isSingle ? '460px' : '390px')};
  letter-spacing: -0.01em;

  &:first-child {
    margin-top: 0;
  }

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  ${media.tabletLarge(css`
    font-size: 20px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    margin-top: 25px;
  `)}
  
  &:not(:last-child) {
    ${List} {
      margin-bottom: -18px;
    }
  }
`;

const ListItem = styled.li`
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;

  &:first-child {
    margin-top: 0;
  }

  ${media.mobile(css`
    line-height: 30px;
  `)}
`;

export default OverviewFeatures;
