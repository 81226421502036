import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

gsap.registerPlugin(SplitText);

function ExchangesText() {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    const splitTextChild = new SplitText(textRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTextParent = new SplitText(textRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.from(splitTextChild.lines, {
        stagger: 0.15,
        ease: 'customEaseInOut',
        transformOrigin: '50% 100%',
        yPercent: 100,
        duration: 1.45,
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'top 82%',
        },
      });
    });

    return () => {
      splitTextParent?.revert();
      splitTextChild?.revert();
      tw?.kill();
    };
  }, []);

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <Text ref={textRef}>
          <b>Our currency exchange</b> saves you time, provides you a unified
          solution and — through our high levels of security — brings you
          complete peace-of-mind.
        </Text>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.section`
  margin-top: 50px;
`;

const Text = styled.p`
  max-width: 1024px;
  display: block;
  font-size: 72px;
  line-height: 100%;
  font-weight: bold;
  letter-spacing: -0.02em;
  color: ${colors.textGray};

  .lineChild {
    padding-bottom: 0.15em;
  }

  .lineParent {
    margin-bottom: -0.15em;
    overflow: hidden;
  }

  b {
    color: ${colors.black};
  }

  ${media.laptop(css`
    font-size: 60px;
  `)}

  ${media.tabletSmall(css`
    font-size: 50px;
  `)}

  ${media.mobileMedium(css`
    font-size: 40px;
  `)}

  ${media.mobileSmall(css`
    font-size: 38px;
  `)}
`;

export default ExchangesText;
