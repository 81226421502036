export const roadmapList = [
  {
    label: 'Accomplished',
    options: [
      { label: 'Built in-house tailored core-banking software' },
      { label: 'Got non-connected BIC code XPTTGB22' },
      { label: 'Got non-standard sort code 00-99-13' },
      { label: 'Obtained SCT adherence with European Payment Council' },
      { label: 'Obtained SEPA IBAN ONLY Directory participation' },
      { label: 'Launched WebApp for Business' },
      { label: 'Can issue own GB IBANS for xpate customers' },
      { label: 'Launched WebApp for Individuals' },
    ],
  },
  {
    label: 'Coming up next in 2022',
    options: [
      { label: 'Multicurrency Stacks and Stack Exchanges' },
      { label: 'Implementation of GBP clearing ' },
      {
        label:
          'Payment Pages for fast acceptance of Card payments and transfers',
      },
      { label: 'Implementation of SEPA Instant' },
      { label: 'License application in EU jurisdiction' },
      { label: 'Debit Card issuing' },
      { label: 'Active SWIFT membership' },
      { label: 'Launching a Mobile App' },
      {
        label:
          'Completion of the beta testing phase and launch in Europe and the UK 🚀',
      },
    ],
  },
];
