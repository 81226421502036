import React from 'react';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import styled from 'styled-components';

import SectionLabel from '@/modules/Home/components/SectionLabel';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import * as S from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.styles';
import useSimplicityAnimation from '@/modules/common/Home/HomeSimplicity/HomeSimplicity.hooks';
import slideIcon2 from '@/assets/svg/seamless.svg';
import slideIcon1 from '@/assets/svg/simple.svg';
import slideIcon3 from '@/assets/svg/multi-fit-solution.svg';
import { Component as SimplicityItemComponent } from '@/modules/Home/components/HomeSimplicity/components/SimplicityItem';

import InfinitySlider from './components/InfinitySlider';

gsap.registerPlugin(ScrollTrigger, SplitText);

const SLIDE_LIST = [
  {
    num: '01',
    color: '#8100E8',
    icon: slideIcon2,
    title: 'Seamless',
    text: `Client onboarding is accomplished easily and quickly. Welcome to a world without payment headaches.`,
  },
  {
    num: '02',
    color: '#00A0F2',
    icon: slideIcon1,
    title: 'Simple',
    text: `We reimagined the entire sector of cross-border payments. They’ve been too complex for too long and, frankly, we’d had enough.`,
  },
  {
    num: '03',
    color: '#0060F5',
    icon: slideIcon3,
    title: 'Multi-fit solution',
    text: `You get one unified platform for all your payment needs. This means you won’t have to waste time looking for multiple payment partners.`,
  },
];

function HomeSimplicity() {
  const { containerRef, titleRef, labelRef, textRef, setSlideElem } =
    useSimplicityAnimation();

  return (
    <Component ref={containerRef}>
      <ContentSmallContainer>
        <S.Inner>
          <S.Left>
            <S.TextWrapper>
              <SectionLabel ref={labelRef} label="Our mission" />
            </S.TextWrapper>
            <S.Title ref={titleRef}>Simplicity</S.Title>
          </S.Left>
          <S.Right>
            <S.Text ref={textRef}>
              With xpate, simplicity is not just about having a phenomenal user
              experience. It’s about giving you more time to focus on growing
              your business.
            </S.Text>
          </S.Right>
        </S.Inner>
        <InfinitySlider slides={SLIDE_LIST} setSlideElem={setSlideElem} />
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled(S.Component)`
  ${SimplicityItemComponent} {
    color: white !important;
  }
`;

export default HomeSimplicity;
