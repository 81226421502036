import { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';

interface Props {
  config: {
    animate: boolean;
  };
}

gsap.registerPlugin(SplitText);

function useHeroAnimation({ config: { animate } }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleInnerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const arrowRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let tw: gsap.core.Tween;

    gsap.set(innerRef.current, { y: 0 });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;

      tw = gsap.to(innerRef.current, {
        y: '25%',
        ease: 'linear',
        scrollTrigger: {
          scroller: 'body',
          trigger: containerRef.current,
          start: 'bottom 100%',
          end: 'bottom 0',
          scrub: true,
        },
      });
    });

    return () => {
      delayCall.kill();
      tw?.kill();
    };
  }, []);

  useEffect(() => {
    let fadeTl: gsap.core.Timeline;

    gsap.set(titleInnerRef.current, { yPercent: 100 });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      fadeTl = gsap
        .timeline({
          scrollTrigger: {
            id: 'headingInner',
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(titleInnerRef.current, {
          ease: 'customEaseInOut',
          duration: 2.2,
          yPercent: 0,
        });
    });

    return () => {
      delayCall.kill();
      ScrollTrigger.getById('heading')?.kill();
      ScrollTrigger.getById('headingInner')?.kill();
      fadeTl?.kill();
    };
  }, [animate]);

  useEffect(() => {
    let tl: gsap.core.Timeline;

    const splitTextChild = new SplitText(textRef.current, {
      linesClass: 'lineChild',
      type: 'words, lines',
    });
    const splitTextParent = new SplitText(textRef.current, {
      linesClass: 'lineParent',
      type: 'words, lines',
    });

    gsap.set(buttonRef.current, { scale: 0 });
    gsap.set(arrowRef.current, { yPercent: -100 });
    gsap.set(splitTextChild.lines, { yPercent: 100 });

    const delayCall = gsap.delayedCall(0, () => {
      if (!containerRef.current) return;
      if (!animate) return;

      tl = gsap
        .timeline({
          scrollTrigger: {
            scroller: 'body',
            trigger: containerRef.current,
          },
        })
        .to(
          buttonRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.2,
            delay: 0.65,
            scale: 1,
          },
          0
        )
        .to(
          splitTextChild.lines,
          {
            stagger: 0.15,
            ease: 'customEaseInOut',
            duration: 1.5,
            delay: 0.65,
            yPercent: 0,
          },
          0
        )
        .to(
          arrowRef.current,
          {
            ease: 'customEaseInOut',
            duration: 1.9,
            yPercent: 0,
          },
          '1.5'
        );
    });

    return () => {
      delayCall.kill();
      splitTextParent?.revert();
      splitTextChild?.revert();
      tl?.kill();
    };
  }, [animate]);

  return {
    containerRef,
    innerRef,
    textRef,
    titleInnerRef,
    arrowRef,
    buttonRef,
  };
}

export default useHeroAnimation;
