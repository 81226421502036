import React from 'react';
import styled, { css } from 'styled-components';

import ContentSmallContainer from '@/components/ContentSmallContainer';
import Link from '@/components/Link';
import { colors, fonts } from '@/constants/theme';
import { ReactComponent as ArrowLink } from '@/assets/svg/arrow-link.svg';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CareersPageType } from '@/typings/model';

function CareersLinks() {
  const page = useCurrentPage<CareersPageType>();
  const links = page?.templateFields?.links ?? [];

  return (
    <Container>
      <StyledSmallContainer>
        <CardList>
          {links.map((link, index) => (
            <Card key={index}>
              <ArrowLink />
              <CustomLink to={link.link} />
              <CardTitle>{link.label}</CardTitle>
            </Card>
          ))}
        </CardList>
      </StyledSmallContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin-top: 160px;
  overflow: hidden;
  padding-bottom: 182px;
  ${media.tabletSmall(css`
    padding-bottom: 100px;
  `)};
`;

const StyledSmallContainer = styled(ContentSmallContainer)`
  overflow: hidden;
`;

const CardTitle = styled.span`
  display: block;
  color: ${colors.black};
  font-family: ${fonts.Gilroy};
  font-weight: bold;
  font-size: 31px;
  line-height: 40px;
  padding: 150px 0 96px 44px;
  transition: 0.5s;
`;

const Card = styled.div`
  position: relative;
  width: calc(33.33% - 38px);
  height: 277px;
  margin: 0 19px;
  background-color: ${colors.gray};
  transition: 0.5s;

  &:hover {
    background-color: ${colors.blueHover};
    color: ${colors.white};

    ${CardTitle} {
      color: ${colors.white} !important;
    }

    svg {
      fill: ${colors.white};
    }
  }

  svg {
    position: absolute;
    top: 26px;
    right: 28px;
    fill: ${colors.black};
    transition: 0.5s;
    width: 22px;
    height: 22px;
  }

  ${media.tabletLarge(css`
    flex: 1 1 33.33%;
    margin-bottom: 38px;
  `)};

  ${media.tabletSmall(css`
    flex: none;
    width: calc(100% - 38px);
  `)};
`;

const CustomLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
`;

const CardList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -19px 0 -19px;
`;

export default CareersLinks;
