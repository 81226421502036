import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';

interface Props {}

function StacksTechnologyTitle(props: Props) {
  return (
    <Component>
      <ContentSmallContainer>
        <Text>
          <span>
            <span>Think outside</span>
            <span>eeee</span>
          </span>
          <span>the boooring</span>
          <span>old financial</span>
          <span>system</span>
        </Text>
      </ContentSmallContainer>
    </Component>
  );
}

const Component = styled.div`
  overflow: hidden;
`;

const Text = styled.p`
  display: flex;
  flex-direction: column;
  max-width: 1209px;
  font-weight: bold;
  font-size: 198px;
  line-height: 224px;
  color: red;
  white-space: nowrap;

  span {
    background: linear-gradient(
      90deg,
      #b5da6d 0%,
      #5accf1 32.16%,
      #5d99ef 58.44%,
      #e85149 100%
    );

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    span:first-child {
      background: inherit;
    }

    span:last-child {
      background: linear-gradient(
        90deg,
        #e85149 0%,
        #5d99ef 36.11%,
        #5accf1 66.31%,
        #b5da6d 100%
      );

      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  ${media.tabletLarge(css`
    font-size: 160px;
    line-height: 180px;
  `)}

  ${media.tabletSmall(css`
    font-size: 110px;
    line-height: 140px;
  `)}

  ${media.mobile(css`
    font-size: 48px;
    line-height: 56px;
  `)}
`;

export default StacksTechnologyTitle;
