import React from 'react';
import styled, { css } from 'styled-components';

import { Nullable } from '@tager/web-core';
import { ThumbnailType } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { PricingPageType } from '@/typings/model';
import { StringFieldType } from '@/typings/common';
import { colors } from '@/constants/theme';

type Props = {
  items: Array<{
    name: StringFieldType;
    price: StringFieldType;
    text: StringFieldType;
    items: Array<{
      icon: Nullable<ThumbnailType>;
      name: StringFieldType;
      value: boolean;
    }>;
  }>;
  bottomText?: StringFieldType;
};

const PricingTariffsTable: React.FC<Props> = ({ items, bottomText }) => {
  const data = items.map((item) => {
    return {
      name: item.name,
      price: item.price,
      text: item.text,
      items: item.items.map((item) => {
        return {
          icon: item.icon?.url || null,
          name: item.name,
          value: item.value,
        };
      }),
    };
  });

  return (
    <>
      <MobileTable>
        {data.map((item, index) => (
          <MobileTableItem withBackground={index === 0}>
            <ItemHeader>
              <ItemHeaderRow>
                <ItemName>{item.name}</ItemName>
                <ItemPrice>{item.price}</ItemPrice>
              </ItemHeaderRow>
              <ItemText>{item.text}</ItemText>
            </ItemHeader>
            {item.items.map((item, index) => (
              <MobileRow>
                <Param>
                  {item.icon ? (
                    <Icon>
                      <img src={item.icon} />
                    </Icon>
                  ) : null}
                  <Name>{data[0].items[index].name}</Name>
                </Param>
                <Value>{item.value ? '✔' : '❌'}</Value>
              </MobileRow>
            ))}
          </MobileTableItem>
        ))}
      </MobileTable>

      <Inner>
        <Table>
          <TableRow>
            <ItemHeader />
            {data.map((item, index) => (
              <ItemHeader>
                <ItemHeaderRow>
                  <ItemName>{item.name}</ItemName>
                  <ItemPrice>{item.price}</ItemPrice>
                </ItemHeaderRow>
                <ItemText>{item.text}</ItemText>
              </ItemHeader>
            ))}
          </TableRow>
          {data[0].items.map((param, paramIndex) => (
            <TableRow>
              <Param>
                {param.icon ? (
                  <Icon>
                    <img src={param.icon} alt={param.name || ''} />
                  </Icon>
                ) : null}
                <Name>{param.name}</Name>
              </Param>
              {data.map((item) =>
                paramIndex in item.items ? (
                  <Cell>{item.items[paramIndex].value ? '✔' : '❌'}</Cell>
                ) : null
              )}
            </TableRow>
          ))}
        </Table>
        {bottomText ? (
          <InnerTextWrapper>
            <InnerText>{bottomText}</InnerText>
          </InnerTextWrapper>
        ) : null}
      </Inner>
    </>
  );
};

const Inner = styled.div`
  background: #f6f6f6;
  margin-top: 70px;
  color: ${colors.black};

  ${media.tablet(css`
    display: none;
  `)}
`;

const InnerText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  width: 1075px;
  margin: 0 auto;
`;

const InnerTextWrapper = styled.div`
  border-top: 1px solid #fff;
  padding: 50px 0 80px;
`;

const ItemHeader = styled.div`
  padding: 80px 30px 30px 40px;
  border-bottom: 1px solid #fff;
  min-height: 275px;

  &:nth-child(2) {
    background: #d7e6ff;
  }

  ${media.tablet(css`
    padding: 40px;
    min-height: 0;
    border-bottom: 0 none;
  `)}

  ${media.mobile(css`
    padding: 30px;
    min-height: 0;
  `)}
`;

const ItemHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemName = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  display: block;

  ${media.mobile(css`
    font-size: 22px;
  `)}
`;
const ItemPrice = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  display: block;
  ${media.mobile(css`
    font-size: 22px;
  `)}
`;

const ItemText = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  margin-top: 20px;
  max-width: 320px;
  ${media.mobile(css`
    font-size: 18px;
    max-width: 100%;
  `)}
`;

const Icon = styled.div`
  width: 30px;
  margin-right: 30px;
  min-width: 30px;

  ${media.mobile(css`
    padding: 0px;
    margin-bottom: 20px;
  `)}
`;

const Name = styled.span`
  font-weight: 700;
  font-size: 30px;
  line-height: 120%;
  display: block;
  min-width: 300px;
  ${media.mobile(css`
    font-size: 24px;
    max-width: 100%;
    min-width: 0;
  `)}
`;

const Table = styled.div``;

const Param = styled.div`
  padding: 30px 40px 30px 50px;
  display: flex;

  ${media.tablet(css`
    padding: 0px;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

const TableRow = styled.div`
  display: flex;
  align-items: stretch;

  > div {
    flex: 1 1 1px;
    width: 33.33333%;
    max-width: 33.333333%;
  }
`;

const Cell = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 100%;
  display: flex;
  align-items: center;
  padding: 0 40px;

  &:nth-child(2) {
    background: #d7e6ff;
  }
`;

const Value = styled.div`
  font-weight: 700;
  font-size: 30px;
  margin-left: 20px;
  ${media.mobile(css`
    margin-top: 20px;
  `)}
`;

const MobileTable = styled.div`
  display: none;
  margin-top: 50px;
  ${media.tablet(css`
    display: block;
  `)}
`;

const MobileTableItem = styled.div<{ withBackground?: boolean }>`
  background: ${(props) => (props.withBackground ? '#D7E6FF' : '#F6F6F6')};
`;

const MobileRow = styled.div`
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.mobile(css`
    padding: 30px;
  `)}
`;

export default PricingTariffsTable;
