import React from 'react';
import styled, { css } from 'styled-components';

import IntroBlock from '@/components/IntroBlock';
import { colors } from '@/constants/theme';
import {
  ButtonWrap,
  Text,
  ButtonIcon,
} from '@/components/IntroBlock/IntroBlock';
import { media } from '@/utils/mixin';

function IntroSection() {
  return (
    <Component>
      <IntroBlock
        options={{
          buttonColor: colors.xppPrimary,
          boldText: 'xpate makes it simple and fast for our partners ',
          text: 'to refer business and start enjoying the benefits.',
          buttonTitle: 'become our partner',
          boldTextColor: colors.black,
          textColor: '#c2c2c8',
          buttonUrl: 'mailto:hello@xpate.com',
        }}
      />
    </Component>
  );
}

const Component = styled.div`
  padding: 300px 0;

  ${ButtonWrap} {
    margin-top: 80px;

    &:hover {
      ${ButtonIcon} {
        background: ${colors.xppPrimaryHover};
      }
    }
  }

  ${Text} {
    max-width: 1168px;
  }

  ${media.tabletSmall(css`
    padding: 150px 0;
  `)}

  ${media.mobile(css`
    padding: 0 0 60px;

    ${ButtonWrap} {
      margin-top: 20px;
    }
  `)}
`;

export default IntroSection;
