import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureProps } from '@tager/web-modules';

import { ReactComponent as ArrowDownIcon } from '@/assets/svg/arrow-4.svg';
import ContentSmallContainer from '@/components/ContentSmallContainer';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import useCurrentPage from '@/hooks/useCurrentPage';
import { ReallyInstantPageType } from '@/typings/model';

const ReallyInstantSteps = () => {
  const { stepsTitle, stepsItems, stepsSubtitle } =
    useCurrentPage<ReallyInstantPageType>().templateFields;

  return (
    <Component>
      <ContentSmallContainer>
        <Subtitle>
          {stepsSubtitle} <ArrowDownIcon />
        </Subtitle>
        <Title>{stepsTitle}</Title>
        {stepsItems ? (
          <Steps>
            {stepsItems.map((step) => (
              <Step>
                {step.icon ? (
                  <StepNumber>
                    <Picture {...convertThumbnailToPictureProps(step.icon)} />
                  </StepNumber>
                ) : null}
                <StepText>{step.text}</StepText>
              </Step>
            ))}
          </Steps>
        ) : null}
      </ContentSmallContainer>
    </Component>
  );
};

const Component = styled.section`
  color: #040312;
  margin: 180px 0;

  ${media.tablet(css`
    margin: 120px 0;
  `)}

  ${media.mobile(css`
    margin: 60px 0;
  `)}
`;

const Subtitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #040312;
  svg {
    margin-left: 10px;
  }
`;

const Title = styled.h2`
  display: block;
  font-size: 102px;
  line-height: 101%;
  margin-top: 40px;

  ${media.laptop(css`
    font-size: 74px;
  `)}

  ${media.tabletLarge(css`
    font-size: 64px;
  `)}
  
  ${media.mobile(css`
    font-size: 54px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 50px;
  `)}
`;

const Steps = styled.div`
  margin-top: 80px;
  justify-content: space-between;
  display: flex;

  ${media.tablet(css`
    flex-wrap: wrap;
    margin: 40px -20px;
  `)}

  ${media.tablet(css`
    display: block;
    margin: 40px 0 0 0;
  `)}
`;

const Step = styled.div`
  max-width: 350px;

  ${media.tablet(css`
    width: 50%;
    padding: 20px;
  `)}
  ${media.mobile(css`
    width: 100%;
    max-width: 100%;
    padding: 0;
    &:not(:first-child) {
      margin-top: 40px;
    }
  `)}
`;

const StepNumber = styled.div`
  display: inline-flex;
`;

const StepText = styled.div`
  font-size: 22px;
  line-height: 145%;
  color: #040312;
  display: block;
  margin-top: 35px;
`;

export default ReallyInstantSteps;
