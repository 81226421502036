export interface BaseCard {
  color?: string;
  type: 'card' | 'title';
  text?: string;
}

export interface ColorCard extends BaseCard {
  color: string;
  type: 'card';
}

export interface TextCard extends BaseCard {
  type: 'title';
  text: string;
}

export function isColorCard(stack: BaseCard): stack is ColorCard {
  return stack.type === 'card';
}
