import React from 'react';

import Features from '@/components/Features';
import useCurrentPage from '@/hooks/useCurrentPage';
import { CollectPaymentsPageType } from '@/typings/model';

function CollectPaymentsFeatures() {
  const { features } = useCurrentPage<CollectPaymentsPageType>().templateFields;
  return (
    <Features
      items={features.map((feature) => {
        return {
          imgSrc: feature.image?.url || '',
          imgSrc2x: feature.image2x?.url || '',
          title: feature.title || '',
          text: feature.text || '',
        };
      })}
    />
  );
}

export default CollectPaymentsFeatures;
