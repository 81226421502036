import React from 'react';
import styled, { css } from 'styled-components';

import fingersImg from '@/assets/images/singapore/fingers.png';
import fingersMobileImg from '@/assets/images/singapore/fingers-mobile.png';
import Picture from '@/components/Picture';
import { media } from '@/utils/mixin';
import ContentSmallContainer from '@/components/ContentSmallContainer';

const SingaporeFingers = () => {
  return (
    <Component>
      <ContentSmallContainer>
        <Inner>
          <Title>
            Online, transparent, quick and safe payments solution at your
            fingertips
          </Title>
          <Content>
            <p>
              Business account for international payments without monthly fees,
              high rates or hidden charges
            </p>
            <p>
              Pay your employees, contractors, vendors or suppliers all over the
              globe for less
            </p>
          </Content>
        </Inner>
      </ContentSmallContainer>
      <Background>
        <Picture
          mobileSmall={{ src: fingersMobileImg }}
          tabletLarge={{ src: fingersImg }}
        />
      </Background>
    </Component>
  );
};

const Component = styled.section`
  background: #955dff;
  position: relative;
  overflow: hidden;

  padding: 250px 0;
  ${media.laptop(css`
    padding: 200px 0;
  `)}
  ${media.tablet(css`
    padding: 120px 0;
  `)}
  ${media.mobile(css`
    padding: 90px 0;
  `)}
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: 102px;
  line-height: 100%;
  width: 950px;
  max-width: 100%;

  ${media.tablet(css`
    font-size: 75px;
  `)}
  ${media.mobile(css`
    font-size: 45px;
  `)}
`;

const Content = styled.div`
  margin-top: 80px;
  font-weight: 500;
  font-size: 24px;
  line-height: 145%;
  width: 483px;
  max-width: 100%;
  ${media.tablet(css`
    margin-top: 60px;
    font-size: 21px;
  `)}
  ${media.mobile(css`
    margin-top: 40px;
    font-size: 18px;
  `)}
p:not(:first-child) {
    margin-top: 40px;
    ${media.tablet(css`
      margin-top: 30px;
    `)}
    ${media.mobile(css`
      margin-top: 20px;
    `)}
  }
`;

const Inner = styled.div`
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  ${media.laptop(css`
    right: -200px;
  `)}

  >div, img {
    max-height: 100%;
  }

  ${media.tabletSmallOnly(css`
    display: none;
  `)}
  ${media.mobile(css`
    position: static;
    margin: 30px 0 0;
    > div,
    img {
      width: 100vw;
    }
  `)}
`;

export default SingaporeFingers;
