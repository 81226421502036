import React, { useState } from 'react';

import Layout from '@/components/Layout';
import CultureHero from '@/modules/Culture/components/CultureHero';
import ContentBlock from '@/modules/Culture/components/ContentBlock';
import { isPreloaderVisible } from '@/components/Preloader/Preloader.helpers';
import CultureSynergyBlock from '@/modules/Culture/components/CultureSynergyBlock';
import CultureSimpleBlock from '@/modules/Culture/components/CultureSimpleBlock';

function Culture() {
  const [isPreloaderOut, setPreloaderOut] = useState(!isPreloaderVisible());

  function handlePreloaderOut() {
    setTimeout(() => {
      setPreloaderOut(true);
    }, 600);
  }

  return (
    <Layout theme="white" dark={false} onPreloaderOut={handlePreloaderOut}>
      <CultureHero animate={isPreloaderOut} />

      <ContentBlock
        id="content-1"
        textWidth={1005}
        firstTitle="people"
        secondTitle="first"
        textBold="People are our main value."
        text="The&nbsp;team, clients and partners. We love and appreciate each of&nbsp;them."
        items={[
          'We prioritize building long term fulfilling relationships based on trust and respect.',
          'We are mentoring each other to become more efficient.',
          'We are rooted in strong ethics and moral principles.',
        ]}
      />

      <CultureSynergyBlock />

      <ContentBlock
        id="content-2"
        textWidth={1258}
        title="commitment"
        textBold="Commitment for us is the responsibility before the team and clients,"
        text="the dedication to the company and the business we do. Thanks to being committed we can rely on each other. We value managing relationships and people trust."
        items={[
          'We make clients feel confident in the services we deliver.',
          'We are engaged and we care about the common goal.',
          'We support each other by completing our work on time and within budget.',
        ]}
      />

      <CultureSimpleBlock />

      <ContentBlock
        id="content-3"
        textWidth={1134}
        title="fundamental"
        textBold="All we do is fundamental and extraordinary."
        text="To be a leader in the fintech industry we need to remain up-to-date and do a little bit more than possible every day."
        items={[
          'All that we do is big, reliable, fundamental and well-thought out.',
          'We continuously optimise processes and communications.',
          'We showcase our clients the real quality and fast service.',
        ]}
      />
    </Layout>
  );
}

export default Culture;
