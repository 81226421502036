import React from 'react';
import styled from 'styled-components';

import { ReleaseNotesNewsItemModel } from '@/typings/model';

interface Props extends ReleaseNotesNewsItemModel {}

function ReleaseNotesNewsItem({ date, text, title }: Props) {
  return (
    <Component>
      <Inner>
        <Cell>
          {date && <Date>{date}</Date>}
          {title && <Title>{title}</Title>}
        </Cell>
        <MainContent>
          {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
        </MainContent>
      </Inner>
    </Component>
  );
}

const Inner = styled.div`
  display: flex;
`;
const Cell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 436px;
  width: 100%;
  padding-right: 14px;
`;
const Title = styled.span`
  font-weight: normal;
  font-size: 24px;
  line-height: 145%;
`;

const Date = styled.span`
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
`;

const MainContent = styled(Cell)`
  max-width: 100% !important;
  width: 100%;
`;

const Text = styled.div`
  h2,
  h3,
  h4,
  h5,
  strong {
    margin-top: 70px;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;

    &:first-child {
      margin-top: 0 !important;
    }
  }

  p,
  div {
    margin-top: 30px;
    font-weight: normal;
    font-size: 24px;
    line-height: 145%;
  }

  li {
    position: relative;
    padding-left: 24px;
    margin-top: 26px;
    font-weight: normal;
    font-size: 24px;
    line-height: 145%;

    &:before {
      position: absolute;
      left: 0;
      top: 16px;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #0202e4;
    }
  }

  ul {
    margin-top: 30px;
  }

  img,
  video,
  svg {
    margin-top: 60px;
  }

  a {
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: #1442fa;
    transition: color 0.3s ease;

    &:hover {
      color: #0e2dbb;
    }
  }
`;

const Component = styled.div`
  padding: 70px 0 100px;
  border-top: 1px solid rgba(130, 130, 133, 0.3);

  @media (max-width: 1024px) {
    ${Inner} {
      flex-direction: column;
    }

    ${Cell} {
      justify-content: space-between;
      flex-direction: row;
      min-width: 100%;
      padding-right: 0;
    }

    ${Title} {
      order: -1;
      padding-right: 40px;
    }

    ${MainContent} {
      margin-top: 40px;
    }

    ${Date} {
      flex-shrink: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 30px 0 50px;

    ${Title} {
      font-size: 20px;
      line-height: 32px;
    }

    ${Date} {
      font-size: 24px;
      line-height: 100%;
    }

    ${MainContent} {
      margin-top: 10px;
    }

    ${Text} {
      h2,
      h3,
      h4,
      h5,
      strong {
        margin-top: 20px;
        font-weight: bold;
        font-size: 24px;
        line-height: 100%;
      }
    }

    p,
    div {
      margin-top: 20px;
      font-weight: normal;
      font-size: 22px;
      line-height: 135%;
    }

    li {
      position: relative;
      margin-top: 14px;
      font-weight: normal;
      font-size: 22px;
      line-height: 135%;

      &:before {
        position: absolute;
        left: 0;
        top: 12px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #0202e4;
      }
    }

    a {
      font-size: 22px;
    }

    img,
    video {
      max-width: 100%;
      margin-top: 40px;
    }
  }

  @media (max-width: 416px) {
    padding: 15px 0 40px;

    ${Cell} {
      flex-direction: column;
      margin-bottom: 30px;
    }

    ${Date} {
      margin-bottom: 16px;
    }

    ${Title} {
      order: 1;
      padding: 0;
    }

    ${MainContent} {
      margin-top: 0;
    }

    ${Text} {
      margin-top: 10px;

      p,
      div,
      li {
        font-size: 18px;
        line-height: 130%;

        &:before {
          top: 9px;
        }
      }

      p {
        margin-top: 30px;
      }

      h2,
      h3,
      h4,
      h5,
      strong {
        margin-top: 40px;
      }

      li {
        margin-top: 26px;
      }

      ul,
      ol {
        margin-top: 35px;

        li {
          &:first-child {
            margin-top: 0;
          }
        }
      }

      a {
        line-height: 30px;
      }
    }
  }
`;

export default ReleaseNotesNewsItem;
